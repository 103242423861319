import { useQuery } from "@apollo/client";
import _ from "lodash";
import moment from "moment-jalaali";
import momentTz from "moment-timezone";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { Done as IconPaymentAccept } from "@material-ui/icons";
import TableSimple from "./micro/TableSimple";
import { GET_INVOICES_TODO } from "../pages/Invoices/invoicesQueries";
import DialogCheckInvoice from "./dialogs/DialogCheckInvoice";
import { useAccount } from "../app/hooks";

momentTz.tz.setDefault("Asian/Tehran");

const ContractsInvoicesTodo = () => {
  const { t } = useTranslation();
  const [openDialogPay, setOpenDialogPay] = useState<any>();
  const { isAdmin } = useAccount();

  const quGetTimes = useQuery(GET_INVOICES_TODO);

  const headers = [
    {
      title: "id",
    },
    {
      title: "invoiceRequestName",
    }, {
      title: "invoiceStepName",
    },
    {
      title: "invoiceDueDate",
    },
    {
      title: "invoiceStatus",
    },
    {
      title: "installmentApprove",
    },
  ];

  const actions = (detail: any) => (
    <div>
      <IconButton disabled={!isAdmin} onClick={() => setOpenDialogPay(detail)}>
        <IconPaymentAccept style={isAdmin ? { color: "blue" } : {}} />
      </IconButton>
    </div>
  );

  const rows = useMemo(() => {
    const invoices = _.get(quGetTimes, "data.invoices", []);
    return invoices?.map((item) => {
      const dueDate = item.due_date ? moment(item.due_date).format("jYYYY-jMM-jDD") : "-";
      return {
        id: item.id,
        invoiceRequestName: <Link to={`/request/${(item?.request_id?.id)}`}>{`${item?.request_id?.name} ${item?.request_id?.family}`}</Link>,
        invoiceStepName: item?.title,
        invoiceStatus: t(item?.status),
        invoiceDueDate: dueDate,
        installmentApprove: actions(item),
      };
    });
  }, [quGetTimes.data]);

  return (
    <>
      {/* <h2 style={{ margin: "0 0 15px" }}>{t("LatestConsultant")}</h2> */}
      <TableSimple rows={rows} headers={headers} size="small" />
      {openDialogPay ? (
        <DialogCheckInvoice
          open={openDialogPay}
          setOpen={setOpenDialogPay}
        />
      ) : false}
    </>
  );
};
export default ContractsInvoicesTodo;
