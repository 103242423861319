import API from "../../app/utils/Api";

type IData = {
    identifier: string,
    password: string
}
export function login(data: IData) {
  return API.call("POST", "auth/local", data);
}

export default {};
