import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => createStyles({
  description: {
    paddingBottom: "10px",
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
    "& button": {
      height: "52px",
      marginTop: "5px",
    },
  },
}));

export default function PaymentResultDialog(props: any) {
  const loc = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [statePay, setStatePay] = useState();
  const {
    open,
    setOpen,
    invoice,
  } = props;

  // const [verifyPayment, responseVerifyPayment] = useMutation(
  //   VERIFY_PAYMENT,
  //   {
  //     refetchQueries: ["GET_PAYMENT_BY_INVOICE_ID"],
  //   },
  // );

  const handleClose_ = () => {
    setOpen();
    const reqId = invoice.request_id?.id;
    history.push(`/request/${reqId}`);
    // handleClose();
  };

  let state;
  let refNum;
  const queryString = loc?.search;
  if (queryString) {
    const isPaymentOk = queryString.includes("Status=OK");
    if (isPaymentOk) {
      state = "SUCCESS";
    } else {
      state = "ERROR";
    }
    const [fN] = queryString.match(/[0-9]+&/gm);
    const fNE = fN.replace("&", "");
    refNum = Number(fNE);
  }

  if (!queryString) return null;

  useEffect(() => {
    if (state === "SUCCESS") {
      // console.log("v---------", refNum);
      // verifyPayment({ variables: refNum }).then((r) => {
      //   console.log("v---------rrrr", r);
      // });
    }
  }, [state]);

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose_}
      aria-labelledby="form-dialog-title"
    >
      <>
        <DialogContent>
          <Grid container spacing={1} justifyContent="center">
            {state === "SUCCESS" ? (
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}><CheckCircleOutlineIcon style={{ fontSize: 100, color: "green" }} /></div>
                <p>{t("Dialog.ResultPaymentSuccess")}</p>
                <p>
                  {t("referenceNumber")}
                  :
                  <b>{refNum}</b>
                </p>
              </div>
            ) : "" }
            {state === "ERROR" ? (
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}><ErrorOutlineIcon style={{ fontSize: 100, color: "red" }} /></div>
                <p>{t("Dialog.ResultPaymentError")}</p>
                <p>
                  {t("referenceNumber")}
                  :
                  <b>{refNum}</b>
                </p>
              </div>
            ) : "" }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose_} color="primary">
            {t("Close")}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
