import { gql } from "@apollo/client";

export const GET_ME = gql`  
query GET_ME {
    me {
      id
        role{
            id
            name
        }
        username
        email
        lang
        name
    }
    me2 {
        role{
            id
            name
            description
            type
            permissions
        }
    }
  }
`;

export const GET_ROLE_ME = gql`  
query GET_ROLE_ME($id: ID!) {
    role(id: $id){
      name
      permissions {
        id
        action
        controller
        policy
        type
        enabled
      }
    }
  }
`;

export const UPDATE_USER_LOGIN_TIME = gql`
mutation UpdateUserLogin($uId: ID!,$time: DateTime){
    updateUser(input: {
      data: {
        last_login_time: $time
      }
      where: {
          id: $uId
      }
    }){
      user {
        id
      }
    }
  }
`;

export const GET_ANALYTIC_INFO = gql`
query GET_ANALYTIC_INFO($uId: ID){
  messagesConnection(where:{
    seen: false
    user_id_ne: $uId
    user_id :{
        role :{
          type: "customer"
        }
      }
  }){
    aggregate{
      count
      totalCount
    }
  }
  userInfos(where:{
    user :{
      id: $uId
    }
  }){
    notifications
    summary
  }
  configApp {
    rateCouncil
    NotificationsAdmin
  }
}
`;

export const GET_ANALYTIC_INFO_CUSTOMER = gql`
query GET_ANALYTIC_INFO($uId: ID){
  messagesConnection(where:{
    seen: false
    request: {
      userId:{
        id: $uId
      }
    }
    user_id :{
      role :{
        type: "authenticated"
      }
    }
  }){
    aggregate{
      count
      totalCount
    }
  }
  userInfos(where:{
    user :{
      id: $uId
    }
  }){
    notifications
    summary
  }
}
`;

export const UPDATE_USER_NOTIFICATIONS = gql`
mutation UPDATE_USER_NOTIFICATIONS {
  updateNotification
}`;

export default {};
