import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import { GET_MESSAGE_TEMPLATES } from "../../pages/MessageTemplates/messagesQueries";
import SelectBox from "./SelectBox";

const SelectMessage = (props: any) => {
  const { selectTemp, setSelectTemp } = props;

  const { loading, error, data } = useQuery(GET_MESSAGE_TEMPLATES,
    { variables: { sort: "updated_at:desc" } });

  const dataItems = useMemo(() => {
    const items = data?.messageTemplates.map((item:any) => {
      const d = 1;
      return {
        value: item.id,
        label: item.title,
      };
    });

    return items;
  }, [data?.messageTemplates]);

  return (
    <SelectBox
      name="messageTemplate"
      value={selectTemp}
      setValue={setSelectTemp}
      grid={12}
      items={dataItems}
    />
  );
};

export default SelectMessage;
