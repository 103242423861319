import React, { useMemo, useState } from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import moment from "moment-jalaali";
import _ from "lodash";
import ViewIcon from "@material-ui/icons/Edit";
import { useFormik } from "formik";
import { GET_INVOICES } from "./invoicesQueries";
import { GET_ME } from "../../app/layout/layoutQueries";
import TableAdvance from "../../components/micro/TableAdvance";
import FormDialogInvoice from "../../components/FormDialogInvoice";
import {
  GetFakeInvoiceId, IntlFormat, getFakeEvalId, getFakeRequestId,
} from "../../app/utils/helper";
import "./index.scss";
import InputBox from "../../components/micro/InputBox";
import SelectBox from "../../components/micro/SelectBox";
import statics from "../../app/statics";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  pendingForSetTimeSession: {
    backgroundColor: "orange",
    border: "1px solid #eee",
    padding: "5px",
    display: "inline",
    borderRadius: "5px",
  },
  fab: {
    position: "fixed",
    right: "50px",
    bottom: "50px",
    zIndex: 200,
  },
}));

export default function Invoices(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openModalInvoice, setOpenModalInvoice] = useState<boolean>(false);
  const [modeDialogInvoice, setModeDialogInvoice] = useState<"create" | "update">("create");

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  let uId;
  if (role === "Customer") {
    uId = _.get(Me, "data.me.id");
  }
  const getInvoices = useQuery(GET_INVOICES,
    {
      variables: {
        sort: "id:desc",
        limit: 10,
        start: 0,
        where: undefined,
      },
    });

  // if (loading) return null;
  if (getInvoices.error) return `Error! ${getInvoices.error}`;

  // TODO Sort by another column
  const dataTableData = useMemo(() => {
    if (!getInvoices.data) return [];
    const v = _.get(getInvoices.data, "invoicesConnection.values", []);
    return v?.map((item) => {
      const costAffix = item.evaluation ? t("rial") : t("dollar");
      const cost = `${IntlFormat(item.cost)} ${costAffix}`;
      const { id } = item;
      let serviceBtn;
      let name;
      const status = <div className={`tag_div tag__${item.status}`}>{t(item.status)}</div>;

      if (item?.evaluation) {
        name = `${item.evaluation?.name} ${item.evaluation?.family}`;
        serviceBtn = (
          <Button
            color="secondary"
            variant="outlined"
            onClick={
          () => openRoute(getFakeEvalId(item.evaluation.id), "eval")
        }
          >
            {t("booking")}
            {" "}
            {getFakeEvalId(item.evaluation.id)}
          </Button>
        );
        // status = <div className={`tag_div tag__${status}`} />;
      } else if (item.request_id) {
        name = `${item.request_id?.name} ${item.request_id?.family}`;
        serviceBtn = (
          <Button
            color="primary"
            variant="outlined"
            onClick={
          () => openRoute(item.request_id.id)
        }
          >
            {t("contract")}
            {" "}
            {getFakeRequestId(item.request_id.id)}
          </Button>
        );
      }

      return [
        id,
        cost,
        serviceBtn,
        name,
        item.subject,
        status,
        item.due_date,
        item.created_at,
      ];
    });
  }, [getInvoices.data]);
  const totalCount = _.get(getInvoices.data, "invoicesConnection.aggregate.count");

  const openItem = (item) => {
    const id_ = item.tableMeta.rowData[0];
    props.history.push(`/invoice/${id_}`);
  };

  const openRoute = (id, type?) => {
    if (!type) props.history.push(`/request/${id}`);
    props.history.push(`/booking/${id}`);
  };

  const columns = [
    {
      name: t("id"),
      options: {
        filter: true,
        // display: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {GetFakeInvoiceId(value)}
          </div>
        ),
      },
    }, {
      name: t("cost"),
      options: {
        filter: true,
      },
    },
    {
      name: t("service_id"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value
        ),
      },
    },
    {
      name: t("name"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {(value)}
          </div>
        ),
      },
    },
    {
      name: t("subject"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {t(value)}
          </div>
        ),
      },
    }, {
      name: t("status"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value}
          </div>
        ),
      },
    }, {
      name: t("due_date"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {value ? moment(value).format("jYYYY-jMM-jDD HH:mm") : "-"}
          </div>
        ),
      },
    }, {
      name: t("created_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    }, {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue, ...els) => (
          <>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                openItem({
                  value, tableMeta, updateValue, els,
                });
              }}
            >
              <ViewIcon />
            </IconButton>
            {/* <IconButton onClick={(e) => {
              e.stopPropagation();
              deleteRow({ value, tableMeta, updateValue });
            }}
            >
              <DeleteIcon />
            </IconButton> */}
          </>
        ),
      },
    },
  ];

  const formik = useFormik({
    initialValues: {
      searchText: null,
      status: null,
      requestId: null,
      name: null,
      dateStart: null,
      dateEnd: null,
    },
    onSubmit: (e) => {
      handleSearch(e);
    },
  });

  const handleSearch = (data: any) => {};

  const other_ = {};
  statics.statusInvoiceOptions.forEach((k) => {
    other_[k.label] = k.value;
  });
  const options = {
    [t("all")]: "all",
    ...(other_),
  };

  const statusOptions = _.keys(options).map((key) => ({
    label: t(`${key}`),
    value: key,
  }));

  return (
    <>
      {/* <PageTitle title="Tables" /> */}
      <Grid container spacing={4} className="invoices">
        <Grid item xs={12}>
          <div className="filter-bar">
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} className="filter-bar__container">
                <InputBox
                  handler="formik"
                  name="searchText"
                  placeholder="searchText"
                  value={formik.values.searchText}
                  formikI={formik}
                  grid={2}
                />
                <InputBox
                  handler="formik"
                  name="requestId"
                  placeholder="requestId"
                  value={formik.values.requestId}
                  formikI={formik}
                  grid={2}
                />
                <InputBox
                  handler="formik"
                  name="name"
                  placeholder="name"
                  value={formik.values.name}
                  formikI={formik}
                  grid={2}
                />
                <SelectBox
                  handler="formik"
                  name="status"
                  grid={2}
                  value={formik.values.status}
                  items={statusOptions}
                  formikI={formik}
                />
                {/* <DatePicker
                  value={values}
                  onChange={setValues}
                  range
                  calendar={persian}
                  locale={persian_fa}
                  calendarPosition="bottom-right"
                /> */}
              </Grid>
            </form>
          </div>

          <TableAdvance
            {...props}
            title="Invoices"
            data={dataTableData}
            columns={columns}
            itemPath="/invoice/"
            showFilter={false}
            loading={getInvoices.loading}
            serverSide
            totalCount={totalCount}
            query={getInvoices}
            pageOptions={
              {
                main: "invoices",
              }
            }
          />
          <FormDialogInvoice
            openDialog={openModalInvoice}
            setOpenDialog={setOpenModalInvoice}
            mode={modeDialogInvoice}
            handleClose={() => setOpenModalInvoice(false)}
            // cost={price}
            // subject="council"
            // detail={invoiceDetail}
            // invoiceId={}
            requestId={Number(1)}
          />
        </Grid>
      </Grid>
    </>
  );
}
