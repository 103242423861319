import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  Grid, TextField, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import RadioGroupBox from "../micro/RadioGroupBox";
import { statusAgileBook }
  from "../../pages/AgileBooking/AgileBooking";
import { UPDATE_CAMPAIGN_BOOK } from "../../pages/AgileBooking/agileBookingQueries";

const useStyles = makeStyles((theme: Theme) => createStyles({
  description: {
    paddingBottom: "10px",
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
    "& button": {
      height: "52px",
      marginTop: "5px",
    },
  },
}));

export default function DialogUpdateStatusBook(props: any) {
  const history = useHistory();

  const { t } = useTranslation();
  const [status, setStatus] = useState(statusAgileBook.new);
  const [description, setDescription] = useState<string>();
  const isFa = true;
  const {
    open,
    setOpen,
    options,
    setOptions,
    hideFooter,
    className,
  } = props;

  const [update] = useMutation(UPDATE_CAMPAIGN_BOOK, {
    refetchQueries: ["GET_CAMPAIGN_USERS"],
  });

  const handleClose_ = () => {
    setOpen();
  };

  const handleSubmit = () => {
    update({
      variables: {
        status,
        description,
        id: props.selected,
      },
    });
    handleClose_();
  };

  const optionsStatus = _.keys(statusAgileBook).map((key) => (
    {
      label: t(`agile.${key}`),
      value: key,
    }));

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose_}
      aria-labelledby="form-dialog-title"
      className={className}
    >
      <DialogTitle id="form-dialog-title">{t("updateStatusCampaignBook")}</DialogTitle>
      <>
        <DialogContent style={{ paddingTop: 0, textAlign: "justify" }}>
          <Grid container spacing={1}>
            <p>{t("pleaseSelectOption")}</p>
            <RadioGroupBox
              name=""
              value={status}
              setValue={setStatus}
              grid={12}
              items={optionsStatus}
            />
            {status === statusAgileBook.rejected
              ? (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={t("description")}
                  autoFocus
                  value={description}
                  multiline
                  onChange={(e) => setDescription(e.target?.value)}
                />
              ) : ""}
          </Grid>
        </DialogContent>
        {!hideFooter ? (
          <DialogActions>
            {/* <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button> */}
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              {t("SubmitEdit")}
            </Button>
          </DialogActions>
        ) : null }
      </>
    </Dialog>
  );
}
