import { gql } from "@apollo/client";

export const GET_VISAS = gql`  
query GetVisas($sort: String) {
  contractTypes(
    sort: $sort
  ){
    id
    created_at
    updated_at
    title
    title_en
    is_active
    is_online
  }
}
`;

export const GET_VISA = gql`  
query GetVisas($id: ID!) {
  contractType(
    id: $id
  ){
    id
    created_at
    updated_at
    title
    title_en
    summary
    summary_en
    is_active
    contract_text
    contract_text_en
    articles{
      id
    }
    is_online
    is_active
    default_child_visa
    default_husband_visa
    default_attachments {
      id
      title
    }
    service{
      id
    }
    priority_attachments
  }
}
`;

export const CREATE_VISA = gql`
mutation CREATE_VISA(
  $title: String!, 
  $title_en: String!, 
  $summary: String,
  $summary_en: String,
  $default_child_visa: String,
  $default_husband_visa: String,
  $is_active: Boolean,
  $is_online: Boolean,
  $contract_text: String,
  $contract_text_en: String,
  $default_attachments: [ID],
  $service: ID,
  $priority_attachments: JSON,
  ){
  createContractType(input: {
    data: {
      title: $title
      title_en: $title_en
      summary: $summary
      summary_en: $summary_en
      default_child_visa: $default_child_visa
      default_husband_visa: $default_husband_visa
      is_active: $is_active
      is_online: $is_online
      contract_text: $contract_text
      contract_text_en: $contract_text_en
      default_attachments: $default_attachments
      service: $service
      priority_attachments: $priority_attachments
    }
  }){
    contractType {
      id
    }
  }
}
`;

export const UPDATE_VISA = gql`
mutation UPDATE_VISA(
  $id: ID!, 
  $title: String, 
  $title_en: String!, 
  $summary: String,
  $summary_en: String,
  $default_child_visa: String,
  $default_husband_visa: String,
  $is_active: Boolean,
  $is_online: Boolean,
  $contract_text: String,
  $contract_text_en: String,
  $default_attachments: [ID],
  $service: ID,
  $priority_attachments: JSON
  ){
    updateContractType(input: {
      where: {
        id: $id
      }
      data: {
        title: $title
        title_en: $title_en
        summary: $summary
        summary_en: $summary_en
        default_child_visa: $default_child_visa
        default_husband_visa: $default_husband_visa
        is_active: $is_active
        is_online: $is_online
        contract_text: $contract_text
        contract_text_en: $contract_text_en
        default_attachments: $default_attachments
        service: $service
        priority_attachments: $priority_attachments
      }
    }) {
      contractType {
        id
      }
    }
}
`;

export const DELETE_VISA = gql`
mutation DELETE_VISA(
  $id: ID!,
){
  deleteContractType(input: {
    where: {
      id:$id
    }
  }){
    contractType {
      id
    }
  }
}
`;

export default {};
