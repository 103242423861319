import React, { useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab, Grid,
} from "@material-ui/core";

import DoneIcon from "@material-ui/icons/Done";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import RadioGroupBox from "../micro/RadioGroupBox";
import InputBox from "../micro/InputBox";
import { UPDATE_EVALUATION } from "../../pages/Bookings/bookingsQueries";
import SelectBox from "../micro/SelectBox";
import { GET_VISAS } from "../../pages/Visa/visasQueries";

export enum STATUS_BOOK {
  DONE = "DONE",
  CANCELED= "CANCELED",
  REJECTED= "REJECTED",
  WAITING_MEETING= "WAITING_MEETING",
  WAITING_PAYMENT= "WAITING_PAYMENT",
}

const DialogSetEvaluationStatus = (props: any) => {
  const { evalId } = props;
  const { t } = useTranslation();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [status, setStatus] = useState<STATUS_BOOK>(STATUS_BOOK.WAITING_MEETING);
  const [description, setDescription] = useState();
  const [feedbackDescription, setFeedbackDescription] = useState();
  const [offerVisa, setOfferVisa] = useState();
  const [feedbackStatus, setFeedbackStatus] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [updateEval] = useMutation(UPDATE_EVALUATION, {
    refetchQueries: ["GET_EVALUATIONS"],
  });

  const visaQuery = useQuery(GET_VISAS, { variables: { sort: "id" } });

  const handleAccept = () => { setOpenConfirm(true); };

  const handleOk = () => {
    if (status === STATUS_BOOK.REJECTED && _.isEmpty(description)) {
      enqueueSnackbar(t("errorPleaseWriteMessageReject"), {
        variant: "error",
        key: "snackbar",
      });
      return;
    }

    if (status === STATUS_BOOK.WAITING_PAYMENT && _.isEmpty(offerVisa)) {
      enqueueSnackbar(t("errorPleaseSelectOfferVisa"), {
        variant: "error",
        key: "snackbar",
      });
      return;
    }

    if (status === STATUS_BOOK.DONE) {
      if (_.isEmpty(feedbackStatus)) {
        enqueueSnackbar(t("errorSelectFeedbackStatus"), {
          variant: "error",
          key: "snackbar",
        });
        return;
      }

      if (_.isEmpty(feedbackDescription)) {
        enqueueSnackbar(t("errorDescriptionFeedback"), {
          variant: "error",
          key: "snackbar",
        });
        return;
      }
    }
    updateEval({
      variables: {
        id: evalId,
        status,
        rejection_description: description,
        feedback_status: feedbackStatus,
        feedback_description: feedbackDescription,
        offer_visa: offerVisa,
      },
      refetchQueries: ["GET_EVALUATION", "GET_EVALUATIONS"],
      awaitRefetchQueries: true,
    }).then((result) => {
      enqueueSnackbar(t("SuccessfullyUpdated"), {
        variant: "success",
        key: "snackbar",
      });
      setOpenConfirm(false);
      // setTimeout(() => push("/requests"), 1000);
    }).catch((error) => {
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
        key: "snackbar",
      });
    });
  };

  const statusOptions = {
    WAITING_PAYMENT: t("acceptEvaluation"),
    // WAITING_MEETING: t("acceptEvaluationWithoutPayment"),
    REJECTED: t("rejectEvaluation"),
    DONE: t("doneEvaluation"),
    CANCELED: t("cancelEvaluation"),
  };

  const defaultOption = _.keys(statusOptions).map((key) => ({
    label: t(statusOptions[key]),
    value: key,
  }));

  const feedbackOptions = {
    READY_TO_CONTRACT: t("READY_TO_CONTRACT"),
    COMPLETE_REJECTED: t("COMPLETE_REJECTED"),
    FOLLOW_UP: t("FOLLOW_UP"),
    OTHER: t("OTHER"),
  };

  const feedbackOptionsItems = _.keys(feedbackOptions).map((key) => ({
    label: t(feedbackOptions[key]),
    value: key,
  }));

  const visaItems = useMemo(() => {
    const rtn = visaQuery?.data?.contractTypes?.map((item) => ({
      label: item.title,
      value: item.id,
    }));
    return rtn || [];
  }, [visaQuery]);

  return (
    <div className="c">
      <Fab
        id="fab-change-status"
        onClick={handleAccept}
        className="fab-icon fab-icon__action"
        style={{ backgroundColor: "Highlight" }}
        title={t("UpdateState")}
      >
        <DoneIcon />
        <span className="help-text">{t("UpdateState")}</span>
      </Fab>

      {openConfirm ? (
        <Dialog
          open={openConfirm}
          maxWidth="sm"
          fullWidth
          onClose={() => setOpenConfirm((s) => !s)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{t("titleSetEvaluationStatus")}</DialogTitle>
          <>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item>
                  <RadioGroupBox
                    name="status"
                    handler="material"
                    value={status}
                    setValue={setStatus}
                    grid={12}
                    items={defaultOption}
                    required
                    hideLabel
                  />
                </Grid>
                {status === STATUS_BOOK.WAITING_PAYMENT ? (
                  <SelectBox
                    name="offerVisa"
                    grid={12}
                    value={offerVisa}
                    setValue={setOfferVisa}
                    items={visaItems}
                  />
                ) : "" }
                {status === STATUS_BOOK.REJECTED ? (
                  <InputBox
                    name="descriptionReject"
                    value={description}
                    setValue={setDescription}
                    grid={12}
                    rows={3}
                  />
                ) : "" }
                {status === STATUS_BOOK.DONE ? (
                  <>
                    <Grid
                      Item
                      style={{
                        marginTop: "12px",
                        marginBottom: "6px",
                        borderTop: "1px solid #eee",
                        paddingTop: "10px",
                      }}
                    >
                      {t("consultantComment")}
                    </Grid>
                    <SelectBox
                      name="feedbackStatus"
                      grid={12}
                      value={feedbackStatus}
                      setValue={setFeedbackStatus}
                      items={feedbackOptionsItems}
                      required
                    />
                    <InputBox
                      name="feedbackDescription"
                      value={feedbackDescription}
                      setValue={setFeedbackDescription}
                      grid={12}
                      rows={3}
                      required
                    />
                  </>
                ) : "" }
              </Grid>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button> */}
              <Button color="primary" disabled={!status} variant="contained" onClick={handleOk}>
                {t("SubmitEdit")}
              </Button>
            </DialogActions>
          </>
        </Dialog>
      ) : false}
    </div>
  );
};

export default DialogSetEvaluationStatus;
