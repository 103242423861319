import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export type INotification = {
  open: boolean,
  message: string,
  type: Color
  setOpen?: any,
}

export type ColorI = Color;

const Notification = (props: INotification) => {
  const {
    open, message, setOpen, type,
  } = props;
  const { t } = useTranslation();

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent,
    reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen({ open: false });
  };
  // console.log("Notification----------", props);

  return open ? (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert severity={type} onClose={handleClose}>
        {t(message)}
      </Alert>
    </Snackbar>
  ) : <></>;
};

const useNotification = (props:INotification) => {
  const {
    open, message, setOpen, type,
  } = props;
  const { t } = useTranslation();

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent,
    reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen({ open: false });
  };
  // console.log("Notification----------", props);

  return open ? (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert severity={type} onClose={handleClose}>
        {t(message)}
      </Alert>
    </Snackbar>
  ) : <></>;
};

Notification.defaultProps = {
  setOpen: () => {},
};

export default Notification;
