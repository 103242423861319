import { gql } from "@apollo/client";

export const CREATE_NOTE = gql`
mutation CREATE_NOTE($content: String!, $userId: ID!, $requestId: ID!){
  createContractNote(input: {
    data: {
      # user: $userId,
      user_id: $userId,
      content: $content,
      request: $requestId,
    }
  }){
    contractNote{
      id
    }
  }
}`;

export const GET_CONTRACT_NOTES = gql`
query GET_CONTRACT_NOTES($requestId: ID!) {
    contractNotes(where: { request: $requestId}, sort: "id:desc") {
        id
        content
        created_at
        user {
            id
            role {
                name
                type
            }
        }
        attachment {
            id
            url
        }
        user_id {
          id
          name
        }
    }
}
`;

export default {};
