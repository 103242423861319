const transEN = {
  title_site: "Kohanbaba",
  Dashboard: "Dashboard",
  NewEvaluation: "New Evaluation",
  Evaluations: "Evaluations",
  EvaluationsAndConsultants: "Consultancy and Evaluation Requests",
  Contracts: "Contracts",
  Payments: "Financials",
  Messages: "Messages",
  message: "Message",
  Tickets: "Support",
  Profile: "Profile",
  Exit: "Exit",
  detail: "Details",
  typeDisplay: "Display Type",
  admin: "Admin",
  SubmitChanges: "Save Changes",
  password: "Password",
  operator: "Expert",
  email: "Email",
  name: "Name",
  degree_edu: "Educational Degree",
  ielts: "IELTS",
  tef: "TOEFL",
  number_childs: "Number of Children",
  partner_name: "Spouse Name",
  total_assets: "Total Assets in USD",
  partner_degree: "Spouse Educational Degree",
  partner_birthday: "Spouse Date of Birth",
  nameEn: "Name in English",
  phone: "Mobile",
  NewInvoice: "Create Invoice",
  id: "ID",
  from: "From",
  timeConsultant: "Consultation time",
  typeConsultant: "Consultation type",
  statusCons: {
    ALL: "All",
    DRAFT: "Awaiting evaluation approval",
    WAITING_PAYMENT: "Awaiting payment",
    WAITING_PAYMENT_ACCEPT: "Awaiting offline payment approval",
    NEW: "Awaiting session",
    DONE: "Done",
    CANCELED: "Canceled",
    WAITING_MEETING: "Awaiting meeting",
    REJECTED: "Rejected",
  },
  ieltsStatus: {
    true: "Has",
    false: "Doesn't have",
  },
  step_status: {
    all: "All items",
    accepted: "Approved",
    rejected: "Not approved",
    waitUser: "Awaiting user",
    draft: "Awaiting user",
    waitReview: "Awaiting review",
    inReview: "Under review",
    RequestReviewInfo: "Awaiting review",
    InfoAccepted: "Approved",
    SelectVisaWasSet: "",
  },
  pendingUser: "In progress",
  UpdateState: "Apply changes",
  SetContract: "Set new contract",
  step: "Step",
  error: "Error",
  currentStep: "Current step",
  STEP_INFORMATION: "Information",
  STEP_CONTRACT: "Contract",
  STEP_PAYMENT: "Payment",
  STEP_DOCUMENT: "Document completion",
  STEP_END: "Handover to the immigration office",
  "done?": "Done?",
  to: "To",
  accept: "Approve",
  reject: "Not approve",
  ChangeStateOfContract: "Contract status change form and user notification",
  ForAcceptThisLevelSelectAccept: "To approve this step, select the \"Awaiting user\" option, and select \"Not approved\" to reject. Select \"Approved\" after visa approval.",
  PleaseSelectMessageNotice: "Select the relevant message from the messages below. Please note that this message will be sent to the user.",
  PleaseSelectMethodNotice: "Please select the message sending method(s).",
  AfterChangeStatusSelectStep: "After approval or rejection, which step of the contract should be displayed as default for the user. If not selected, the user remains in this step.",
  evalId: "Consultation ID",
  viewActivity: "Activity archive",
  SureForDone: "Change consultation status to Done?",
  createDate: "Creation date",
  Remember_me: "Remember me",
  SignInOrRegister: "Sign in/register to Kohen Baba",
  Sign_In_button: "Sign in",
  Forgot_password: "Forgot password",
  Not_have_account: "Create an account",
  terms_title: "I accept the terms and conditions of using Kohen Baba website.",
  Register_button: "Register",
  back: "Back",
  changeDateSession: "This section is selected by the user, if you need to change the date of the session, select the desired date:",
  Forgot_button: "Forgot Password",
  "Auth.form.error.invalid": "Incorrect username or password",
  "Register.problem_not_updated_name": "If the confirmation email has not been sent to you, please try again.",
  "Register.successfully": "Registration completed successfully. Please confirm your email.",
  "Auth.form.error.email.taken": "Error, email is already taken.",
  "Auth.form.error.confirmed": "Error, please confirm your email.",
  "Request.title": "Create Assessment",
  "Request.titleNumber": "Migration File Number",
  "Request.detailPersonal": "Personal Details",
  "Request.detailFamily": "Family Details",
  "Request.question": "Additional Questions",
  "Request.confirm": "Confirm Information",
  "Request.addDocument": "Add Document",
  "Request.downloadContract": "Download Contract",
  "Request.Add": "Add Document",
  "Dialog.Upload": "Upload Document",
  "Request.saveDate": "Save Session Date",
  soldiery: "Military Service Status",
  LatestConsultant: "Current counseling sessions",
  CostOfSessionWasPaid: "Session cost has been paid: ",
  hasSchengen: "Have you had a Schengen visa in the past 10 years?",
  hasDisease: "Specific illness",
  hascriminal: "Criminal record",
  marriedStatus: "Marital status",
  married: "Married",
  consultant_: "Consultant",
  single: "Single",
  suggestedVisa: "Recommended visas",
  sessionTime: "Counseling session time",
  show_more: "Read more...",
  child__1: "First child",
  child__2: "Second child",
  child__3: "Third child",
  child__4: "Fourth child",
  divorce: "Divorced",
  dead: "Deceased",
  contained: "Under military service",
  ended: "Service completed",
  exempt: "Exempted",
  male: "Mr.",
  female: "Mrs.",
  other: "Other",
  residence: "Residence",
  tourist: "Tourist",
  familyRequestDescription: "What plan did you have on what date?",
  "Request.marriageStatus": "Marital status",
  familyRequested: "Have you or your spouse/child previously applied for entry to Canada?",
  targetCanada: "Your purpose for travel to Canada",
  Submit: "Submit",
  SubmitEnd: "Submit information",
  yes: "Yes",
  no: "No",
  Users: "Users",
  confirmed: "Confirmed",
  birthday: "Date of birth",
  address: "Address",
  addressEn: "Address in Latin",
  gender: "Gender",
  family: "Last name",
  familyEn: "Last name in Latin",
  phoneHome: "Home phone",
  Finish: "Finish",
  Next: "Save and continue",
  Back: "Back",
  search: "Search",
  searchText: "Search...",
  "Request.edit": "Edit Profile",
  partnerName: "Partner's Full Name",
  partnerNameEn: "Partner's Full Name (Latin)",
  partnerFieldStudy: "Partner's Field of Study",
  partnerFieldStudyEn: "Partner's Field of Study (Latin)",
  partnerAge: "Partner's Birth Year",
  partnerDegree: "Partner's Highest Education Degree",
  partnerJob: "Partner's Employment Status",
  partnerInsurance: "Partner's Employment Insurance History",
  yourIelts: "Applicant's IELTS General Certificate",
  partnerIelts: "Partner's IELTS General Certificate",
  partnerTef: "Partner's TEF French Language Certificate",
  yourTef: "Applicant's TEF French Language Certificate",
  "partnerIeltsScore.read": "Partner's IELTS Reading Score",
  "partnerIeltsScore.listen": "Partner's IELTS Listening Score",
  "partnerIeltsScore.write": "Partner's IELTS Writing Score",
  "partnerIeltsScore.speak": "Partner's IELTS Speaking Score",
  "partnerTefScore.read": "Partner's TEF Reading Score",
  "partnerTefScore.listen": "Partner's TEF Listening Score",
  "partnerTefScore.write": "Partner's TEF Writing Score",
  "partnerTefScore.speak": "Partner's TEF Speaking Score",
  pairInfo: "Partner's Information",
  father: "Father's Name",
  fatherEn: "Father's Name (Latin)",
  childsInfo: "Children's Information",
  numberChilds: "Number of Children under 22 Years Old",
  degree: "Highest Education Degree",
  fieldStudy: "Field of Study",
  nationalNumber: "National ID Number",
  fieldStudyEn: "Field of Study (Latin)",
  eduDateStart: "Education Start Date",
  eduDateEnd: "Education End Date",
  totalAssets: "Total Assets of Applicant and Partner, including Bank Accounts and Properties in USD",
  typeJob: "Job Type",
  isWorking: "Are you currently employed?",
  workDescription: "Brief Job Description",
  workDescriptionEn: "Brief Job Description (Latin)",
  jobState: "Employment Status",
  insuranceHistory: "Employment Insurance History",
  "yourIeltsScore.read": "Applicant's IELTS Reading Score",
  "yourIeltsScore.listen": "Applicant's IELTS Listening Score",
  "yourIeltsScore.write": "Applicant's IELTS Writing Score",
  "yourIeltsScore.speak": "Applicant's IELTS Speaking Score",
  "yourTefScore.read": "Applicant's TEF Reading Score",
  "yourTefScore.listen": "Applicant's TEF Listening Score",
  "yourTefScore.write": "Applicant's TEF Writing Score",
  "yourTefScore.speak": "Applicant's TEF Speaking Score",
  levelLang: "Language level",
  familyLiveCanada: "Families living in Canada",
  typeFindUs: "How did you hear about us?",
  fullTime: "Full-time",
  partTime: "Part-time",
  payment: "Payment",
  UnderDiploma: "Under Diploma",
  Diploma: "Diploma",
  Associate: "Associate's Degree",
  Bachelor: "Bachelor's Degree",
  Master: "Master's Degree",
  Doctoral: "Doctoral Degree",
  PostDoc: "Postdoctoral",
  titleInstallment: "Installment Title",
  costOfContract: "Contract Amount",
  percentInstallment: "Installment Percentage",
  amountInstallment: "Price",
  "Request.InvoiceInstalment": "Create Payment Installments Form",
  "Request.Accept": "Accept",
  "Request.Reject": "Reject",
  "Request.registerInstallment": "Add Payment Installment",
  otherDescription: "Other Description",
  PleaseConfirmInfo: "Please click on the Final Submit button after confirming the information.",
  confirmInfoDescription: "All entered information is verified.",
  "Request.stepAPersonalInfo": "Personal Information",
  "Request.stepBReviewRequest": "Evaluation Response",
  "Request.stepCSetSession": "Consultation Session",
  "Request.stepDContract": "Contract",
  "Request.stepEPayment": "Payment",
  "Request.stepECompleteDocs": "Complete Documents",
  "Request.stepFReviewDocs": "Review Documents",
  "Request.stepGEnd": "Delivery to Immigration Office and Result",
  pendingForSetTimeSession: "Waiting for expert approval",
  successRequestSubmitPleaseWaitForStep2: "The initial information has been successfully submitted. Please wait for the expert approval.",
  tourist_visa: "Tourist Visa",
  education_visa: "Student Visa",
  imp_visa: "Work Visa",
  owpp_visa: "Open Work Permit for Spouse",
  entrepreneurship_visa: "Entrepreneurship Visa",
  selectVisaTypes: "Select the relevant visas from the list below:",
  SelectVisaCanCustomerSelect: "Enable the visas that the user can select from the list below:",
  priceForTrySession: "Consultation session fee in Iranian Rials",
  "Request.saveSubmit": "Save Information",
  "Request.goToContract": "Go to Contract",
  "Request.cancelRequest": "Cancel Request",
  reportMessage: "Session Report",
  reportAttachment: "Session Attachment",
  "Request.Upload": "Upload Attachment",
  "Request.UploadFile": "Upload File",
  "Request.CancelUpload": "Cancel",
  "Request.paymentText": "Please enter the total contract amount in US dollars:",
  "Request.registerContractPrice": "Register Contract Amount",
  contractFile: "Contract File",
  Add_Attachment: "Add new document",
  ADD: "Add",
  countInstallment: "Number of payment stages",
  totalPriceRial: "Contract amount in Rials",
  totalPriceDollar: "Total contract amount",
  enterTotalPrice: "Enter contract amount",
  confirmContractStep: "I have read the above and agree",
  confirmContractStepEn: "من شرایط بالا را خواندم و می پذیرم",
  "Request.signContractAdmin": "Location of Manager's signature (via mouse or touchpad)",
  "Request.signContractUser": "Location of user's signature (via mouse or touchpad)",
  "Request.textContractFile": "Upload contract file:",
  "Request.goToCompleteDocs": "Go to complete documents",
  "Request.specify_the_number_installment": "Specify the number of payment stages:",
  installmentPrice: "Price",
  installmentDueDate: "Due date",
  installmentDueDate_: "Due date / Payment Terms",
  installmentPayment: "Payment",
  "Request.pay": "Pay",
  "Request.add_docs": "To complete the registration process, upload the following documents:",
  "Request.pleaseAttentionToDeadLine": "Only upload the documents that are active in the following section, other documents will be activated in the next stages.",
  "Request.pleaseAttentionToDeadLine2": "Pay attention to the deadline for submitting documents, after this date, contact the relevant expert for re-uploading.",
  "Request.DocName": "Document title",
  "Request.DocFile": "Document file",
  "Request.DocDate": "Update date",
  "Request.DocState": "Status",
  National_Card: "National card",
  Birth_Certificate: "Birth certificate",
  Profile_Image: "3x4 photo",
  Finger_Print: "Fingerprint scan",
  Other_Docs: "Other documents",
  AreYouSureForSaveTime: "Are you sure you want to reserve this date?",
  AreYouSureForSave: "Are you sure you want to save?",
  AreYouSureForSaveForCancelRequest: "Are you sure you want to cancel the request?",
  successUploaded: "Uploaded successfully.",
  Requests: "Contracts",
  FooterAdminText: "Click on the option below to approve or reject this stage: ",
  date: "Date",
  status: "Status",
  Invoices: "Invoices",
  due_date: "Due date",
  created_at: "Creation date",
  updated_at: "Last update",
  GetVisa: "Get visa",
  cost: "Amount",
  job: "Job",
  subject: "Subject",
  title: "Title",
  Services: "Services",
  clear: "Reset",
  selectUser: "Select user",
  pleaseSelectUser: "Please select the desired user and click on the register button:",
  createContractText: "In case the user needs to form a file or contract after initial consultation and evaluation, a draft contract form will be sent to the user from this section.",
  AssignContractToUser: "Assign new contract to user",
  AssignContractToOperator: "Change file operator form",
  pleaseSelectOperator: "Please select the relevant operator:",
  selectOperator: "File operator",
  visitLink: "Complete the form",
  "Service.Edit": "Edit service",
  "Service.New": "Add service",
  "Dialog.ConfirmFile": "Confirm file",
  "Dialog.AcceptThisAttachment": "If the file is approved, select (Accept), otherwise select (Reject)",
  PleaseGoToFollowLinkAndCompleteForm: "Please follow the link below, enter the relevant form, and after completing the information, upload the desired PDF file from the section below.",
  accepted: "Accepted",
  RejectFile: "Rejected",
  unsent: "Unsent",
  unknown: "Unknown",
  sent: "Awaiting Approval",
  dueSend: "Deadline to Send",
  dateUpload: "Upload Date",
  acceptDate: "Approval Date",
  visibleClient: "Visible to Client",
  SuccessfullyHide: "The document has been successfully hidden from the client",
  SuccessfullyShow: "The document has been successfully shown to the client",
  actions: "",
  AcceptFile: "Accept",
  ServiceNew: "Add Service",
  bodyMessage: "Message Body",
  typeMessage: "Message Type",
  title_en: "English Title",
  council: "Consultation",
  contract: "Contract",
  done: "Done",
  draft: "Draft",
  waitForApprove: "Awaiting Approval",
  offlinePaidAndWaitForApprove: "The payment has been made offline and is awaiting approval by the expert",
  waitPayment: "Awaiting Payment",
  MessageNumber: "Message #",
  // profile: "Profile",
  "Profile.User": "User Profile",
  "Profile.Title": "Profile",
  "Profile.Security": "Security Settings",
  "panel.customer": "Customer Panel",
  "panel.admin": "Admin Panel",
  "Profile.Settings": "System Settings",
  "Profile.ConsultantTime": "Consultant Schedule",
  PaymentDone: "Payment Confirmed",
  errorUpload: "An error occurred during upload, please try again.",
  OpenRequest: "Active Contracts",
  OpenTicket: "Open Tickets",
  NewPayment: "Unpaid Invoice",
  addConsultant: "Add Consultation Time",
  consultantType: "Consultation Type",
  NewMessage: "New Message",
  NewUser: "New User",
  "dashboard.titleNews": "Announcements",
  upgrade: "Upgrade",
  description: "Description",
  content: "Original text",
  requestId: "Contract ID",
  content_en: "Original text in English",
  description_en: "Description in English",
  descriptionEn: "Description in English",
  SureForAccept: "Are you sure you want to accept?",
  SureForDelete: "Are you sure you want to delete this item?",
  AreYouSureForAbort: "Cancel evaluation?",
  surlyMessage: "Message",
  RequestReviewInfo: "Review request",
  InfoAccepted: "Information accepted",
  InfoAcceptedError: "Rejected initial information",
  SelectVisaWasSet: "Confirm evaluation response",
  SelectVisaError: "Rejected evaluation response",
  SessionDone: "Consultation completed",
  SessionError: "Consultation failed",
  ContractDone: "Contract approved",
  ContractError: "Contract not approved",
  PaymentError: "Payment error",
  DocAdditionalError: "Document error",
  DocAdditionalDone: "Document approved",
  GetVisaError: "Visa retrieval error",
  Accept: "Save",
  Cancel: "Close",
  CostOfContract: "Cost and payment steps",
  SureForReject: "Are you sure you want to reject the request?",
  SureForLock: "Are you sure you want to lock?",
  SureForUnLock: "Are you sure you want to unlock?",
  CanceledRequest: "Canceled",
  CanceledOk: "Cancelled",
  SubmitEdit: "Save changes",
  ConfirmOk: "Confirmed",
  UpdatePasswordHasError: "Previous password is incorrect",
  SuccessfullyUpdated: "Successfully updated",
  SuccessfullyCreated: "Successfully created",
  SuccessfullyDeleted: "Deleted",
  SuccessfullySaved: "Saved",
  ErrorHappened: "Error, please try again",
  PleaseSelectOneFile: "Please select a file",
  transactionNumber: "Transaction number",
  transactionDate: "Transaction date",
  "Invoice.payment": "Payment",
  InvoiceNumber: "Invoice Number",
  paymentOnline: "Online Payment",
  paymentOffline: "Card-to-Card Payment",
  Pay: "Pay",
  Search: "Search",
  ChangePassword: "Change Password",
  Blog: "Blog",
  slug: "Slug",
  installmentTitle: "Invoice Title",
  uploadedFiles: "Uploaded Files",
  newFiles: "Newly Uploaded Files",
  Categories: "Categories",
  slug_en: "English Slug",
  name_en: "English Name",
  Articles: "Articles",
  ArticleNew: "New Article",
  "Article.New": "New Article",
  "Article.Edit": "Edit Article",
  "Category.New": "New Category",
  "Category.Edit": "Edit Category",
  Category: "Categories",
  Visas: "Visas",
  UserInfo: "User Information",
  ResetPassword: "Reset Password",
  VisasDescription: "List of defined visa types in the system",
  isActive: "Status",
  paragraph: "Paragraph",
  online: "Online",
  offline: "In-Person",
  active: "Active",
  disable: "Inactive",
  titleEn: "English Title",
  Attachments: "Attachments",
  NewAttachment: "New Attachment",
  typeDocument: "Document Type",
  "Visa.New": "New Visa",
  "Visa.Edit": "Edit Visa",
  "Attachment.New": "New Document",
  "Attachment.Edit": "Edit Document",
  DEFAULT: "Default view with upload and download capability",
  LINK: "View as a link containing a Microsoft macro link",
  ONLY_ADMIN: "Display only to admins",
  documentType: {
    Finance: "Finance",
    Education: "Education",
    OTHER: "Other",
    Authorization: "Authorization",
  },
  Default_contract_text: "Default visa contract text:",
  Default_contract_text_en: "Default visa contract text in English:",
  isOnline: "Document delivery method",
  ContractTypes: "Tempaltes Visa",
  successPaymentDoneWaitForAccept: "Payment was successful. Please wait for the expert's confirmation.",
  errorPayment: "Payment error",
  thumbnail: "Default image",
  motionGraphic: "Motion graphic",
  category: "Category",
  Drop_files_here_or_click_to_upload: "Click or drag here to upload files...",
  For_attach_files_drag_here: "Click or drag here to attach documents",
  visaThatUserSelected: "User-selected visa",
  "Invoice.create": "Create invoice",
  "Invoice.update": "Update invoice",
  "Invoice.offCodeInvalid": "Invalid discount code.",
  "Invoice.errorAttachment": "Upload payment receipt image",
  attachmentReceipt: "Payment receipt",
  "Request.CreateInvoice": "Create invoice",
  "Request.UpdateInvoice": "Update invoice",
  "Request.contractAttachments": "Contract attachments",
  PleasePayPriceForSession: "Consultation session cost:",
  PleaseCreateInvoiceOfSession: "Please create an invoice for the consultation session cost. (Creating an invoice is subject to the user's visa selection).:",
  sessionNotes: "Important notes for holding the session:",
  sessionNotes_1: "Currently, all sessions are held online via WhatsApp.",
  sessionNotes_2: "If it is not possible to communicate through WhatsApp, coordinate with the counselor at the time of consultation.",
  sessionNotes_3: "On the day of the session, a reminder of the session will be sent via SMS and email.",
  sessionNotes_4: "If it is not possible to attend the session, contact the website support (online chat or support section - ticket) no later than 2 days before the session time.",
  currentVisa: "Current visa",
  defaultVisaHusband: "Default visa for husband",
  defaultVisaChild: "Default visa for child",
  summary: "Summary",
  generateCode: "Generate code",
  offCode: "Discount code",
  offPercent: "Discount percentage",
  summaryEn: "English summary",
  linkPayment: "Payment link",
  dateSession: "Session date",
  timeSession: "Session time",
  TimeOfSession: "Time of session:",
  fileTitles: "File name",
  contract_text: "Contract text",
  defaultAttachments: "Default visa documents",
  contract_text_en: "English contract text",
  selectVisaTypeForContract: "Select visa type for contract",
  setTimeSession: "Set session time",
  "Request.getVsia": "Delivery to immigration office and result",
  paymentDoneNeedForReview: "Offline payment has been done and is awaiting review and approval by the specialist",
  text_help_get_visa: "Your information has been successfully received up to this point and will be sent to the immigration office after being reviewed by the relevant specialist. In case of any discrepancies or changes in obtaining the visa and all correspondence with the immigration office, they will be posted here.",
  elementarySchool: "Elementary school",
  highSchool: "High school",
  studentUni: "University student",
  noEducation: "Does not attend school",
  firstYear: "First year",
  secondYear: "Second year",
  thirdYear: "Third year",
  fourthYear: "Fourth year",
  levelEducation: "Education level",
  levelEducationNumber: "Academic year",
  under_50: "Under $50,000",
  between_50_100: "Between $50,000 and $100,000",
  between_100_200: "Between $100,000 and $200,000",
  between_200_350: "Between $200,000 and $350,000",
  between_350_500: "Between $350,000 and $500,000",
  between_500_750: "Between $500,000 and $750,000",
  between_750_1000: "Between $750,000 and $1,000,000",
  between_1000_1600: "Between $1,000,000 and $1,600,000",
  more_than_1600: "More than $1,600,000",
  Businesslicense: "Business License",
  Company_registered: "Registered Company",
  Medical_System_license: "Engineering or Medical System License",
  Management_history: "Management Experience",
  Management_farm: "Farming Experience",
  Athlete_art_pro: "Professional Athlete or Artist in the Last 5 Years",
  Other: "Other",
  notHave: "Don't have",
  lessThan2: "Less than 2 years",
  years_3: "3 years",
  years_4: "4 years",
  years_5: "5 years",
  more_than_5: "More than 5 years",
  Excellent: "Excellent",
  Normal: "Normal",
  Weak: "Weak",
  brother_sister: "Brother or Sister",
  father_mother: "Father or Mother",
  grandPa_grandMa: "Grandfather or Grandmother",
  aunt: "Aunt",
  uncle: "Uncle",
  child: "Child",
  google: "Google",
  socialMedia: "Social Media",
  friends: "Friends",
  wait: "Waiting for payment",
  viewInvoiceDetail: "View invoice details",
  SiteSupport: "Site Support",
  fillComplete: "Please fill in all fields.",
  contractInstallment: "Contract Installment",
  byContractNumber: "With Contract Number",
  visaCanYouSelected: "Thank you for completing the evaluation form. Based on the provided information, you can apply for the following visas. If you wish to receive consultation, please pay the consultation fee from the payment section.",
  tracking_id: "ID",
  service_id: "Service ID",
  offline_paid: "Paid offline",
  offlinePaymentInfo: "Bank account information for bank transfer",
  offlineCardNumber: "Card Number",
  offlineShebaNumber: "Sheba Number",
  offlineAccountNumber: "Account Number",
  bankname: "Saderat Bank of Iran - Mehrad Kohanbaba",
  afterPaymentOffline: "After payment, please upload the transaction information along with the payment receipt image.",
  waitConfirm: "Waiting for confirmation",
  rejected: "Rejected",
  paid: "Paid",
  callNumber: "WhatsApp Number",
  contractPaymentText: "Dear user, currency payments in the contract phase require necessary coordination with the exchange office and obtaining the payment account number. Therefore, before making any payments, please first coordinate with this number.",
  contractPayText2: "In case of any problems, please contact the support.",
  viewDetails: "Details",
  installmentStatus: "Payment Status",
  installmentViewPayments: "Information",
  installmentDelete: "Delete",
  paymentsHistory: "Transactions",
  useOffCode: "Discount Code",
  bankPaymentId: "Bank Payment ID",
  paymentTime: "Payment Time",
  paymentDate: "Payment Date",
  SureForaccept: "Are you sure you want to confirm?",
  SureForreject: "Are you sure you want to reject?",
  confirmedState: "Confirmation Status",
  confirmedState_: {
    confirmed: "Confirmed",
    not_confirmed: "Not Confirmed",
    Unknown: "Unknown",
  },
  Delete: "Delete",
  Unknown: "Unknown",
  SaveChanges: "Save Changes",
  costDollar: "Cost in dollars",
  dollar: "Dollar",
  contractId: "Contract ID",
  evaluationId: "Evaluation ID",
  rial: "Rial",
  booking: "Booking",
  costRial: "Cost in rials",
  dueDate: "Due date",
  typeCurrency: "Currency type",
  transactionTime: "Transaction time",
  attachment: "Attachment",
  ClientInformation: "Client Information",
  OrderInformation: "Order Information",
  SureForSave: "Are you sure you want to save?",
  user: "User",
  file: "Attachment",
  partnerBirthday: "Partner Birthday",
  validation: {
    emailNotValid: "Invalid email",
    phoneNotValid: "Invalid phone number",
  },
  thisSectionNeedActivedByAdmin: "This section is currently being configured by the relevant expert. Please come back in an hour Or contact us via the Ticket section.",
  invoiceSuccessfullyPayed: "Payment was successful.",
  invoiceUnSuccessfullyPayed: "Payment failed. Please try again later.",
  dateEvent: "Event Date",
  timeEvent: "Event Time",
  start_time: "Start Time",
  approve: "Waiting for Approval",
  startTime: "Start Time",
  endTime: "End Time",
  end_time: "End Time",
  nameConsultant: "Consultant Name",
  requestEval: "Request Evaluation",
  ChangeTime: "Change Time",
  reminder: "",
  CancelSession: "Cancel Session",
  UpdateStatus: "Update Status",
  DetailBook: "Booking Details",
  bookingInformation: "User Information",
  bookingConsultantSetting: "Settings",
  bookingConsultantTime: "Session Schedule",
  bookingConsultantType: "Session Package Information",
  userId: "User ID",
  ForUpdateStatus: "Has the session been held?:",
  ForUpdateTimeClick: "To change the session time, click:",
  ForCancelSessionClick: "To cancel the session time, click:",
  price: "Price",
  RIAL: "Rial",
  Each: "Each",
  month: "Month",
  view: "View",
  remove: "Remove",
  phoneContact: "Phone Number",
  request_link: "Request Link",
  AreYouSureForDelete: "Are you sure you want to delete this item?",
  is_reserved: "Reserved",
  startDate: "Start Date",
  numberInstallment: "Number of Installments",
  totalCost: "Total Cost",
  dueDateDoc: "Deadline for Submission",
  document: "Document",
  Add: "Add",
  download: "Download",
  MessageFull: "Message Text",
  HIGH: "Urgent",
  QuickAccess: "Quick Access",
  AnalyticInfo: "Analytics and Information",
  request_id: "Evaluation ID",
  body: "Content",
  type: "Type",
  receipt: "Payment Receipt",
  InvoiceCreated: "Invoice Created",
  InvoicePayment: "Invoice Payment",
  RequestCreated: "Assessment Created",
  NewTicket: "Create Ticket",
  "Ticket.New": "New Ticket",
  codeCountry: "Country Code",
  lang: "Default Language",
  MessageTemplate: "Message Templates",
  hasMessageEmail: "Persian Email",
  hasMessageEmailEn: "English Email",
  hasSms: "SMS",
  thisInvoicePaySuccess: "The invoice has been successfully paid. Further details can be found at the bottom of the page.",
  ProfileInfo: "User Profile Information",
  MessageTemplates: "Message Templates",
  messageEmailEnglish: "English Email Message Text",
  messageEmailPersian: "Persian Email Message Text",
  messageSms: "SMS Message Text",
  "MessageTemplate.New": "Create New Message",
  "MessageTemplate.Edit": "Edit Message Template",
  sendSms: "Send SMS",
  sendEmail: "Send Email",
  sendEnglish: "Send Message in English",
  sendNotification: "Notification Settings",
  descriptionReject: "Reasons for Rejection",
  "Dialog.ResultPaymentSuccess": "Payment was successful.",
  "Dialog.ResultPaymentError": "Payment was not successful. If the balance is deducted and not returned within the next 24 hours, please contact support.",
  referenceNumber: "Reference Number",
  Close: "Close",
  passwordOld: "Old Password",
  passwordNew: "New Password",
  passwordNewRepeat: "Repeat New Password",
  invoiceWasPaid: "Invoice Was Paid",
  firstTimeTitle: "Hello dear friend!",
  Start: "Start",
  Rows_per_page: "Items per page",
  Rows_of: "of",
  not_match: "No records found",
  not_completed: "Not Completed",
  canceled: "Payment Canceled",
  completed: "Completed",
  SelectedTimeIs: "Session Date",
  hour: "hour",
  Settings: "Settings",
  SuccessfullySavedWaitForAdmin: "The contract information has been successfully saved. Please wait for the expert's confirmation.",
  invoiceType: "Invoice Type",
  instalment: "Contract Payment Stages",
  resubmit: "Resubmit Fee",
  currency: {
    usd: "Dollar",
    riyal: "Riyal",
    euro: "Euro",
  },
  SelectedVisa: "Selected Visa",
  showEvalPage: "Return to Evaluation Page",
  showRequestPage: "Return to Request Page",
  costThatRemainedForInstallment: "Amount remaining for payment installment:",
  installmentMoreThanContractCost: "The entered amount is more than the contract amount.",
  ErrorNotAcceptedAllContractConditions: "Please accept all contract terms.",
  ErrorSignContract: "Please sign the contract.",
  contract_: {
    PersianText: "Contract Translation",
    EnglishText: "Contract Text",
  },
  PleaseReadContractAndAcceptThis: "Please carefully read the entire contract and indicate your acceptance of each term by checking the boxes labeled \"I have read and agree to the above conditions.\" Finally, after signing the contract, please save it.",
  successFullyDone: "Successfully done",
  contractIsReadyTitle: "Download Contract",
  yourContractIsReadyForDownload: "The information has been saved successfully. Now you can download the contract file.",
  rateCouncil: "Default Rate of Consultation Session - Rials",
  ticketStatus: {
    open: "Open",
    response: "Responded",
    close: "Closed",
  },
  ticket_id: "ID",
  ticket: "Ticket",
  titleTicket: "Ticket Title",
  username: "Username",
  selectDate: "Select Date",
  PasswordCurrentWrong: "Current password is incorrect",
  UserNotFound: "User not found",
  NotMatch: "New password does not match",
  ReturnToHome: "Return",
  contractTemp: {
    contractDetails: "Personal Inforamiton",
    title: "Kohanbaba Immigration Consulting Contract",
    dateDownload: "Contract Download Date",
    dateCreated: "Contract Creation Date",
    childsInfo: "Children Inforamtion",
    timeSign: "Contract Signing Date",
    dateSubmit: "Contract Registration Date",
    contractNumber: "Contract Number",
    SignContract: "Place to Sign Contract",
    signOwner: "Signature of the Legal Representative of the Company",
    signUser: "Signature of the Applicant",
    print: "Print Contract",
    ContextText: "Contract Text",
    text1: "This is a contract for immigration consulting and immigration services for the applicant,",
    text2: " with national ID number",
    text3: ", residing at ",
    text4: ", telephone number",
    text5: "under the name of a Canadian company and as an official and legal member of the Canadian Immigration Department with registration number ",
    text6: "at the address of ",
    text7: "as an agent of ",
    and: "and",
    text8: "Kohanbaba Immigration Services LTD",
    text9: "with national code ",
    text10: "at the address: Tehran, Farmanieh, Saeedi St., Koohyar St. No.1 Unit.6",
    text11: "is",
    name: "Name (Persian)",
    nameEn: "Name",
    family: "Family Name (Persian)",
    familyEn: "Family Name (English)",
    email: "Email",
    phone: "Mobile Phone",
    phoneHome: "Home Phone",
    birthday: "Birthday",
    marriedStatus: "Marital Status",
    gender: "Gender",
    soldiery: "Military Status",
    degree: "Academic Degree",
    fieldStudy: "Field of Study (Persian)",
    fieldStudyEn: "Field of Study (English)",
    eduDateStart: "Start Date of Education",
    eduDateEnd: "End Date of Education",
    totalAssets: "Total Assets",
    typeJob: "Type of Job",
    isWorking: "Employment Status",
    jobState: "Employment Status",
    insuranceHistory: "Insurance Status",
    workDescription: "Job Description (Persian)",
    workDescriptionEn: "Job Description (English)",
    familyLiveCanada: "Family Members Residing in Canada",
    levelLang: "English Language Level",
    address: "Address (Persian)",
    addressEn: "Address (English)",
    hasSchengen: "Schengen Visa",
    hasDisease: "Health Status",
    hascriminal: "Criminal Record",
    targetCanada: "Immigration Goal",
    familyRequested: "Have you or your spouse or child applied to enter Canada before?",
    familyRequestDescription: "Your purpose of travel to Canada",
    yourIelts: "IELTS certificate",
    yourIeltsScore: {
      read: "Reading",
      write: "Writing",
      listen: "Listening",
      speak: "Speaking",
    },
    yourTef: "TEF certificate",
    yourTefScore: {
      read: "Reading",
      write: "Writing",
      listen: "Listening",
      speak: "Speaking",
    },
    pairInfo: "Spouse Information",
    partnerName: "Name (Persian)",
    partnerNameEn: "Name",
    partnerFieldStudy: "Field of Study",
    partnerBirthday: "Birthday",
    partnerDegree: "Academic Degree",
    partnerJob: "Job",
    partnerInsurance: "Insurance Status",
    partnerIelts: "IELTS certificate",
    partnerIeltsScore: {
      read: "Reading",
      write: "Writing",
      listen: "Listening",
      speak: "Speaking",
    },
    partnerTef: "TEF certificate",
    partnerTefScore: {
      read: "Reading",
      write: "Writing",
      listen: "Listening",
      speak: "Speaking",
    },
    CostOfContract: "Contract Payments",
    installmentTitle: "Title",
    installmentDueDate: "Due Date",
    installmentDueDate_: "Due date / Payment Terms",
    installmentStatus: "Payment Status",
    installmentPrice: "Payment Amount",
    totalPriceDollar: "Total Contract Amount",
  },
  ActivityDialogTitle: "Activity Archive",
  sample_file: "Sample Document",
  PleaseUploadAsSample: "Please upload the desired document as a sample",
  viewExample: "View Example",
  "Dialog.SetDueDate": "Set Due Date",
  "Dialog.pleaseSelectDueDate": "Please select the desired date:",
  "Dialog.SetUrlAttachment": "Set Form Link",
  "Dialog.pleaseSetUrlAttachment": "Please enter the Microsoft Word or form link below:",
  acceptEvaluation: "Confirm evaluation and create session reservation capability for user",
  // acceptEvaluationWithoutPayment: "Direct confirmation of evaluation without payment",
  rejectEvaluation: "Reject evaluation",
  offerVisa: "Proposed visa based on evaluation form information",
  doneEvaluation: "Consultation session held",
  cancelEvaluation: "Consultation session not held",
  titleSetEvaluationStatus: "Change evaluation status",
  errorPleaseWriteMessageReject: "Please write the reason for rejecting the evaluation request.",
  errorPleaseSelectOfferVisa: "Please select the proposed visa for the user based on the evaluation form information.",
  rejection_description: "Reason for rejection",
  messageStatus: {
    CONTRACT_UPDATED_BY_USER: "Contract updated",
    EVALUATION_UPDATED_BY_USER: "Evaluation updated",
    PAYMENT_OFFLINE_DONE: "Offline payment made",
  },
  StatusChangedToSeen: "Message status changed to seen",
  titleChangeBookingTime: "Change session time",
  titleChangeLocation: "Change session type",
  pleaseSelectNewType: "If necessary, you can change the type of session from this section:",
  pleaseSelectNewTimeForEval: "Please select a new date for the session to be held.",
  general: {
    saveChanges: "Save Changes",
  },
  locationType: "",
  consultantMethod: "Session type",
  consultant_type: {
    OFFLINE: "In-person",
    ONLINE: "Online",
    BOTH: "User's choice",
  },
  methodConsultant: "Type",
  AreYouSureForSaveTypeHold: "Are you sure you want to change the session type?",
  dashboard: {
    statusContracts: "Status Contracts",
    rateFillEvaluation: "Rate Evaluations Fill",
  },
  FileIsToLargeMustBeUnder5Mb: "File Is Too Large, Maximum 5MB",
};

export default transEN;
