import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card, Grid,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { useTranslation } from "react-i18next";
import InputBox from "../../../../components/micro/InputBox";
import PageTitle from "../../../../components/PageTitle";
import { CREATE_CATEGORY, GET_CATEGORY, UPDATE_CATEGORY } from "../categoryQueries";
import Notification, { INotification } from "../../../../components/micro/SnackbarMicro";

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {},
  card: {
    padding: theme.spacing(5),
  },
}));

const Category = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [name, setName] = useState<string>();
  const [nameEn, setNameEn] = useState<string>();
  const [slug, setSlug] = useState<string>();
  const [slugEn, setSlugEn] = useState<string>();

  const [notif, setNotif] = useState<INotification>();
  const currentCategoryId = _.get(props, "match.params.id");

  const { data, loading, refetch } = useQuery(GET_CATEGORY, {
    skip: !currentCategoryId,
    variables: { id: Number(currentCategoryId) },
  });
  const [create, responseCreate] = useMutation(CREATE_CATEGORY, {
    refetchQueries: ["GetCats"],
  });
  const [update, responseUpdate] = useMutation(UPDATE_CATEGORY, {
    refetchQueries: ["GetCats"],
  });

  useEffect(() => {
    if (data) {
      const { category } = data;
      setName(category.name);
      setNameEn(category.nameEn);
      setSlug(category.slug);
      setSlugEn(category.slugEn);
    }
  }, [loading]);

  const handleSave = () => {
    if (currentCategoryId) {
      update({
        variables: {
          id: currentCategoryId,
          name,
          nameEn,
          slug,
          slugEn,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyUpdated", type: "success", setOpen: setNotif,
        });
        setTimeout(() => props.history.goBack(), 1000);
      }).catch((error) => {
        setNotif({
          open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
        });
      });
    } else {
      create({
        variables: {
          name,
          nameEn,
          slug,
          slugEn,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyCreated", type: "success", setOpen: setNotif,
        });
        setTimeout(() => props.history.goBack(), 1000);
      }).catch((error) => {
        setNotif({
          open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
        });
      });
    }
  };

  return (
    <>
      <PageTitle
        title={currentCategoryId ? t("Category.Edit") : t("Category.New")}
      />
      {!loading ? (
        <Card className={classes.card}>
          <Grid container spacing={4}>
            <InputBox
              name="name"
              value={name}
              setValue={setName}
              grid={6}
            />
            <InputBox
              name="nameEn"
              value={nameEn}
              setValue={setNameEn}
              grid={6}
            />

            <InputBox
              name="slug"
              value={slug}
              setValue={setSlug}
              grid={6}
            />

            <InputBox
              name="slugEn"
              value={slugEn}
              setValue={setSlugEn}
              grid={6}
            />

            <Grid item>
              <Button
                onClick={handleSave}
                className={classes.button}
                type="submit"
                variant="contained"
              >
                {t("Submit")}
              </Button>
            </Grid>
          </Grid>
        </Card>
      ) : ""}
      <Notification {...notif} />
    </>
  );
};

export default Category;
