import { gql } from "@apollo/client";

export const GET_REQUEST = gql`  
query GetRequest($id: ID!) {
    request(id: $id){
      id
      created_at
      updated_at
      name
      family
      name_en
      family_en
      father
      father_en
      email
      phone
      phone_home
      gender
      birthday
      address
      address_en
      has_schengen_visa
      has_disease
      criminal_record
      soldiery
      family_requested
      family_requested_description
      partner_name
      national_number
      partner_name_en
      partner_field_study
      partner_field_study_en
      partner_age
      partner_birthday
      partner_degree
      partner_job
      partner_insurance
      partner_ielts
      partner_score_ielts
      partner_tef
      partner_score_tef
      married
      target_canada
      degree_edu
      field_study
      field_study_en
      date_start_edu
      date_end_edu
      total_assets
      type_job
      is_working
      work_description
      work_description_en
      job_state
      insurance_history
      ielts
      tef
      ietls_score
      tef_score
      language_level
      family_live_canada
      message
      number_childs
      childs
      userId {
        id
      }
      visa {
        id
        title
        title_en
      }
    }
  }
`;

export const GET_REQUEST_STATUS = gql`
query GET_REQUEST_STATUS($reqId: ID!) {
  requestStatuses(where:{
    requestId: {
      id:$reqId
    }
  }){
    id
    created_at
    updated_at
    requestId {
      id
    }
    lock_steps
    current_step
    status
    notification
    visas {
      id
      title
      title_en
      articles{
        id
      }
      service {
        id
        slug
        slug_en
      }
      summary
      summary_en
    }
    contract_price_rial
    contract_price_dollar
    contract_count_installments
    attachments_document {
      id
      title
      title_en
      description
      description_en
      type
      sample_file {
          id
          url
        }
    }
    due_document_dates
    selected_visa {
      id
      title
      title_en
      articles{
        id
      }
      summary
      summary_en
      default_attachments {
        id
        title
        title_en
        description
        description_en
        type
        sample_file {
          id
          url
        }
      }
    }
    consultant_time {
      id
    }
  }
}
`;

export const GET_EVENT_LOGS = gql`
query GET_EVENT_LOGS($sort: String, $reqId: ID!) {
    eventLogs(sort: $sort, where: {request: $reqId}){
      id
      created_at
      updated_at
      type
      data
      request {
        id
      }
      user {
        id
        name
        role {
          type
        }
      }
      evaluation {
        id
      }
    }
}
`;

export const CREATE_ATTACHMENT_REQUEST = gql`
mutation CREATE_ATTACHMENT_REQUEST(
  $request: ID!, 
  $attachment: ID!, 
  $section: String!, 
  $title: String!, 
  $visible: Boolean,
  $due_date: DateTime,
  ){
  createRequestAttachment(input: {
    data: {
      request: $request
      attachment: $attachment
      title: $title
      visible: $visible
      due_date: $due_date
      section: $section
    }
  }) {
    requestAttachment {
      id
    }
  }
}
`;

export const DELETE_ATTACHMENT_REQUEST = gql`
mutation DELETE_ATTACHMENT_REQUEST(
  $id: ID!, 
  ){
    deleteRequestAttachment(input: {
    where: {
      id: $id
    }
  }) {
    requestAttachment {
      id
    }
  }
}
`;

export default {};
