/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useState } from "react";
import {
  Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment-jalaali";
import { Drafts, Email, Link as LinkIcon } from "@material-ui/icons";
import parse from "html-react-parser";
import _ from "lodash";
import { GET_MESSAGES, UPDATE_MESSAGE } from "./messagesQueries";
import TableAdvance from "../../components/micro/TableAdvance";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  pendingForSetTimeSession: {
    backgroundColor: "orange",
    border: "1px solid #eee",
    padding: "5px",
    display: "inline",
    borderRadius: "5px",
  },
  summary: {
    // maxHeight: "42px",
    overflow: "hidden",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#fff",
    margin: "auto",
    // boxShadow: theme.shadows[5],
    padding: "8px",
    outline: "none",
  },
}));

export default function Messages(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogContent, setDialogContent] = useState<any>(false);

  const messagesQuery = useQuery(GET_MESSAGES,
    {
      variables: {
        sort: "created_at:desc",
        limit: 10,
        start: 0,
      },
      fetchPolicy: "cache-and-network",
    });

  const [updateMessage, responseUpdateMessage] = useMutation(UPDATE_MESSAGE);

  const dataTableData = useMemo(() => {
    const messages = messagesQuery?.data?.messages;
    return messages?.map((item) => {
      const bodyI = `${item.body.split(" ").slice(0, 10).join(" ")}...`;
      let requestId;
      let name;
      if (item.evaluation) {
        requestId = `${item?.request?.id}_eval`;
        name = `${item?.evaluation?.name} ${item?.evaluation?.family}`;
      }
      if (item.request) {
        requestId = `${item?.evaluation?.id}_contract`;
        name = `${item?.request?.name} ${item?.request?.family}`;
      }
      return {
        id: item.id,
        title: item.title,
        request_id: requestId,
        name,
        body: bodyI || "-",
        bodyFull: item.body || "-",
        type: t(item.type),
        created_at: item.created_at,
        seen: item.seen,
      };
    });
  }, [messagesQuery?.data]);

  const readMessage = (tableMeta) => {
    updateMessage({
      refetchQueries: ["GET_MESSAGES", "GET_MESSAGES_CUSTOMER", "GET_ANALYTIC_INFO"],
      variables: {
        mId: Number(tableMeta.rowData[0]),
      },
    });
  };

  const openMessage = (e: any) => {
    const [id] = e?.rowData || [];
    const item_ = messagesQuery?.data?.messages.find((item) => item.id === id);
    setDialogContent(item_?.body);
  };

  const columns = [
    {
      name: "id",
      label: t("id"),
      options: {
        filter: true,
        // display: false,
      },
    },
    {
      name: "name",
      label: t("user"),
      options: {
        filter: true,
      },
    }, {
      name: "title",
      label: t("title"),
      options: {
        filter: true,
      },
    },
    {
      name: "body",
      label: t("body"),
      options: {
        filter: true,
        customBodyRender: (value:any, tableMeta:any, updateValue: any) => (
          <div
            className={classes.summary}
            onClick={() => openMessage(tableMeta)}
          >
            {parse(value || "")}
          </div>
        ),
      },
    },
    // ,
    // {
    //   name: "type",
    //   label: t("type"),
    //   options: {
    //     filter: true,
    //   },
    // }
    {
      name: "created_at",
      label: t("created_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    },
    {
      label: t("link"),
      name: "request_id",
      options: {
        filter: true,
        customBodyRender: (value:string, xx) => {
          let isEval = false;
          // eslint-disable-next-line react/destructuring-assignment
          if (value.includes("_eval")) {
            isEval = true;
          }
          return (
            <div>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<LinkIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!isEval) {
                    const x = value.replace("_contract", "");
                    // eslint-disable-next-line react/prop-types
                    props.history.push(`/request/${x}`);
                  } else {
                    const x = value.replace("_eval", "");

                    // eslint-disable-next-line react/prop-types
                    props.history.push(`/booking/${x}`);
                  }
                }}
              >
                {isEval ? t("viewEval") : t("viewContract")}
              </Button>
            </div>
          );
        },
      },
    }, {
      name: "seen",
      label: t("status"),
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                readMessage(tableMeta);
              }}
            >
              {!value ? <Tooltip title={t("UN_SEEN")}><Email /></Tooltip>
                : <Tooltip title={t("SEEN")}><Drafts style={{ color: "#ddd" }} /></Tooltip> }
            </IconButton>
          </>
        ),
      },
    },
  ];

  const totalCount = _.get(messagesQuery?.data, "messagesAggregate.aggregate.count");

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!messagesQuery.loading ? (
            <TableAdvance
              {...props}
              title="Messages"
              data={dataTableData}
              columns={columns}
              serverSide
              totalCount={totalCount}
              itemPath="/message/"
              query={messagesQuery}
              showFilter={false}
              pageOptions={
                {
                  main: "messages",
                }
              }
            />
          ) : ""}
        </Grid>
      </Grid>

      <Dialog
        open={dialogContent}
        onClose={() => setDialogContent(false)}
      >
        <DialogTitle>
          {t("MessageFull")}
        </DialogTitle>
        <DialogContent>
          {parse(dialogContent || "")}
        </DialogContent>
      </Dialog>
    </>
  );
}
