import * as React from "react";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import JalaliUtils from "@date-io/jalaali";
import { Grid } from "@material-ui/core";
import { getGrid } from "../../app/utils/helper";

type IProps = {
    name: string,
    value: string,
    grid?: number,
    required?: boolean,
    label?: boolean,
    setValue: Function,
    minDate?: string
    disabled?: boolean
}

function TimePickerComponent(props: IProps) {
  const { t } = useTranslation();
  //   const [value, setValue] = React.useState<Date | null>(new Date());
  const {
    name, label, value, setValue, grid, required, disabled,
  } = props;
  const content = () => (
    <>
      <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
        <TimePicker
          inputVariant="outlined"
          TextFieldComponent={(props_) => <TextField {...props_} />}
          ampm={false} // This is not needed if you are using localization
          label={t(name)}
          value={value}
          fullWidth
          onChange={(newValue) => setValue(newValue)}
          required={required}
          style={{ margin: "15px 0px" }}
          size="small"
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>

    </>
  );
  if (grid) {
    const [xs_, md_, lg_] = getGrid(grid);

    return (
      <Grid
        item
        md={md_}
        xs={xs_}
        lg={lg_}
      >
        {content()}
      </Grid>
    );
  }
  return (
    <>
      {content()}
    </>
  );
}

TimePickerComponent.defaultProps = {
  grid: 0,
  required: false,
  label: true,
  minDate: null,
  disabled: false,
};

export default TimePickerComponent;
