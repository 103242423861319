/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  AppBar, Badge, Button, Grid, Grow, Hidden, IconButton,
  MenuItem, MenuList, Paper, Popper, Theme, Toolbar,
  createStyles, makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useContext, useEffect } from "react";
import HelpIcon from "@material-ui/icons/Help";
import MessageIcon from "@material-ui/icons/Message";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import UserIcon from "@material-ui/icons/AccountCircleOutlined";
import LogoImage from "../../assets/images/logo.png";
import {
  GET_ANALYTIC_INFO, GET_ANALYTIC_INFO_CUSTOMER, GET_ME, UPDATE_USER_NOTIFICATIONS,
} from "./layoutQueries";
import { AppContext } from "./AppContext_";
import { mainPage } from "./Sidebar";
import statics from "../statics";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#f7fcfe",
    boxShadow: "none",
    borderBottom: "1px solid #eee",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // color: "#000",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  searchInput: {
    width: "200px",
  },
  searchIcon: {
    color: theme.palette.icon.default,
  },
  icon: {
    minWidth: "unset",
    color: theme.palette.icon.default,
    "&>svg": {
      fontSize: "1.3em",
    },
  },
  tools: {
    display: "flex",
    justifyContent: "flex-start",
  },
  toolsItems: {
    // paddingRight: "35px",
  },
  iconBar: {
    color: theme.palette.icon.top,
    marginLeft: "0px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 2),
    gap: 4,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  searchBar: {
    display: "flex",
    justifyContent: "flex-end",
  },
  blogBtn: {
    // backgroundColor: "red",
    border: "2px solid #fd7e14",
    color: "#fd7e14",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  user: {
    display: "flex",
    alignItems: "center",
  },
}));

const AppBarLocal = (props: any) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const { loading, error, data } = useQuery(GET_ME);
  const [updateUserLogin, responseUpdateUserLogin] = useMutation(UPDATE_USER_NOTIFICATIONS, {
    refetchQueries: ["GET_ANALYTIC_INFO"],
  });

  const [open, setOpen] = React.useState(false);
  const [badge, setBadge] = React.useState(0);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  let analyticQuery = GET_ANALYTIC_INFO;
  const role = _.get(data, "me.role.name");
  const isCustomer = _.get(data, "me.role.name") === "Customer";

  if (role === "Customer") {
    analyticQuery = GET_ANALYTIC_INFO_CUSTOMER;
  }

  const getAnalyticInfo = useQuery(analyticQuery, {
    skip: !data?.me?.id,
    variables: {
      uId: data?.me?.id,
    },
    // pollInterval: 10000,
  });

  useEffect(() => {
    if (error?.message === "Invalid token.") {
      goTo({ slug: "Exit" });
    }
  }, [error]);

  // useEffect(() => {
  //   if (role && !isCustomer) { changeLang(); }
  // }, [role]);

  useEffect(() => {
    if (role === statics.roles.CUSTOMER) {
      const analytic_ = _.get(getAnalyticInfo, "data.userInfos[0]");
      setBadge(Number(analytic_?.notifications) || 0);
    } else {
      const configApp = _.get(getAnalyticInfo, "data.configApp");
      setBadge(Number(configApp?.NotificationsAdmin) || 0);
    }
  }, [getAnalyticInfo]);

  const goTo = (page: any, e?: any) => {
    if (page.slug === "Exit") {
      localStorage.removeItem("token");
      localStorage.removeItem("roleType");
      // setTimeout(() => props.history.replace("/login"), 1000);
      setTimeout(() => { window.location.href = `${process.env.REACT_APP_LANDING}/login?clc=true`; }, 500);

      // setTimeout(() => { window.location.href = "/login"; }, 500);
    } else {
      props.history.push(mainPage + page.slug);
    }
    if (e) {
      handleClose(e);
    }
  };
  const openTelegramSupport = () => {
    const newWindow = window.open("https://t.me/KohanBaba_Support",
      "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
       anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleClickNotification = () => {
    updateUserLogin();
    goTo({
      slug: "messages",
    });
  };

  return (
    <div>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, {
        // [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5} md={8}>
              <img
              // onClick={changeLang}
                src={LogoImage}
                alt="x"
                style={{ width: "100%", maxWidth: "250px" }}
              />
            </Grid>

            <Hidden xsDown>
              <Grid
                item
                sm={5}
                md={4}
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                }}
              >
                <div className={classes.toolbar}>
                  <div>
                    <a
                      className={classes.user}
                      onClick={handleToggle}
                    >
                      <div>
                        { isCustomer ? data?.me?.name
                          : (
                            <>
                              {data?.me?.name}
                              <sup style={{ marginRight: "5px" }}>
                                (
                                {t("admin")}
                                )
                              </sup>
                            </>
                          )}
                      </div>
                      <IconButton
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        className={classes.iconBar}
                        aria-haspopup="true"
                      >

                        {isCustomer ? <UserIcon /> : <UserIcon color="primary" />}
                      </IconButton>
                    </a>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === "bottom"
                              ? "center top" : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem onClick={(e) => goTo({
                                  slug: "profile",
                                }, e)}
                                >
                                  {t("Settings")}
                                </MenuItem>
                                <MenuItem onClick={(e) => goTo({ slug: "Exit" }, e)}>{t("Exit")}</MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                  <IconButton
                    className={classes.iconBar}
                    onClick={() => goTo({
                      slug: "tickets",
                    })}
                  >
                    <HelpIcon />
                  </IconButton>
                  <IconButton
                    className={classes.iconBar}
                    onClick={() => handleClickNotification()}
                  >
                    <Badge badgeContent={badge} color="error">
                      <MessageIcon className={classes.iconBar} />
                    </Badge>
                  </IconButton>
                  <Button
                    onClick={() => { window.location.href = process.env.REACT_APP_LANDING; }}
                    variant="outlined"
                    className={classes.blogBtn}
                  >
                    {t("Blog")}
                  </Button>
                </div>
                {/* <div className={classes.searchBar}>
              {/* <SearchIcon className={classes.searchIcon} /> */}
                {/* <Grid item>
                <TextField
                  id="standard-basic"
                  className={classes.searchInput}
                  placeholder={`${t("Search")}...`}
                />
              </Grid> */}
                {/* </div> */}
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AppBarLocal;
