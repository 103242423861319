import React from "react";

declare const window: any;

class Crisp extends React.Component {
  // componentDidMount() {
  //   // Include the Crisp code here, without the <script></script> tags
  //   window.$crisp = [];
  //   window.CRISP_WEBSITE_ID = "337291ef-33c1-441c-a256-26ff0152107b";

  //   (function () {
  //     const d = document;
  //     const s = d.createElement("script");

  //     s.src = "https://client.crisp.chat/l.js";
  //     s.async = true;
  //     d.getElementsByTagName("head")[0].appendChild(s);
  //   }());
  // }

  componentDidMount() {
    // Include the Crisp code here, without the <script></script> tags
    // this.initImber();
  }

  initImber = () => {
    window.IMBER_LANG = "fa";
    const a = window;
    const d = document;
    function im() {
      window.IMBER_ID = "u3v741n9bul9iyt0vr";
      window.IMBER_TOKEN = localStorage.getItem("imber_token");
      const i = document.createElement("div");
      i.id = "imber-top-parent";
      const x = document;
      const s = x.createElement("script");
      s.src = `https://widget.imber.live/imber?id=${
        window.IMBER_ID
      }&token=${
        window.IMBER_AUTH
          ? `null&auth=${JSON.stringify(window.IMBER_AUTH)}`
          : window.IMBER_TOKEN}`;
      s.async = true;
      x.getElementsByTagName("head")[0].appendChild(s);
      x.getElementsByTagName("body")[0].appendChild(i);
      window.$imber = {};
    }

    if (d.readyState === "complete") {
      return im();
    }
    return a.attachEvent
      ? a.attachEvent("onload", im)
      : a.addEventListener("load", im, !1);
  }

  render() {
    return null;
  }
}
export default Crisp;
