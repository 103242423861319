import React, { useContext, useEffect, useState } from "react";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import SelectBox from "../../../components/micro/SelectBox";
import InputBox from "../../../components/micro/InputBox";
import DatePickerComponent from "../../../components/micro/Datepicker";
import RadioGroupBox from "../../../components/micro/RadioGroupBox";
import statics from "../../../app/statics";
import { RequestContext } from "../context/requestContext";
import Notification, { INotification } from "../../../components/micro/SnackbarMicro";
import { UPDATE_REQUEST } from "../steps/requestStepAQueries";
import { Boolean_ } from "../../../app/utils/helper";
import NavBarFooter from "./NavBarFooter";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    // paddingRight: theme.spacing(5),
    // paddingLeft: theme.spacing(5),
  },
}));

type IScore = {
    read: number,
    speak: number,
    write: number,
    listen: number
}

const RequestQuestion = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const context = useContext(RequestContext);
  const { stepProps } = props;
  const { requestData } = context;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const validationSchema = yup.object({
    degree: yup
      .string().required(),
    fieldStudy: yup
      .string().required(),
    fieldStudyEn: yup
      .string().required(),
    eduDateStart: yup
      .string().required(),
    eduDateEnd: yup
      .string().required(),
    totalAssets: yup
      .string().required(),
    typeJob: yup
      .string().required(),
    isWorking: yup
      .string().required(),
    workDescription: yup
      .string().required(),
    workDescriptionEn: yup
      .string().required(),
    jobState: yup
      .string().required(),
    insuranceHistory: yup
      .string().required(),
    yourIelts: yup
      .string().required(),
    yourTef: yup
      .string().required(),
    levelLang: yup
      .string().required(),
    familyLiveCanada: yup
      .string().required(),
    // typeFindUs: yup
    //   .string().required(),
  });

  const formik = useFormik({
    initialValues: {
      degree: "",
      fieldStudy: "",
      fieldStudyEn: "",
      eduDateStart: moment().add(-4, "years").format(),
      eduDateEnd: moment().add(-1, "years").format(),
      totalAssets: "",
      typeJob: "",
      isWorking: "",
      workDescription: "",
      workDescriptionEn: "",
      jobState: "",
      insuranceHistory: "",
      yourIelts: "",
      yourTef: "",
      levelLang: "",
      familyLiveCanada: "",
      typeFindUs: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleNext();
    },
  });

  const {
    degree,
    fieldStudy,
    fieldStudyEn,
    eduDateStart,
    eduDateEnd,
    totalAssets,
    typeJob,
    isWorking,
    workDescription,
    workDescriptionEn,
    jobState,
    insuranceHistory,
    yourIelts,
    yourTef,
    levelLang,
    familyLiveCanada,
    typeFindUs,
  } = formik.values;

  // const [degree, setDegree] = useState<string>();
  // const [fieldStudy, setFieldStudy] = useState<string>();
  // const [eduDateStart, setEduDateStart] = useState<string>();
  // const [eduDateEnd, setEduDateEnd] = useState<string>();
  // const [totalAssets, setTotalAssets] = useState<string>();
  // const [typeJob, setTypeJob] = useState<string>();
  // const [isWorking, setIsWorking] = useState<string>();
  // const [workDescription, setWorkDescription] = useState<string>();
  // const [jobState, setJobState] = useState<string>();
  // const [insuranceHistory, setInsuranceHistory] = useState<string>();
  // const [yourIelts, setYourIelts] = useState<string>();
  // const [yourTef, setYourTef] = useState<string>();
  // const [levelLang, setLevelLang] = useState<string>();
  // const [familyLiveCanada, setFamilyLiveCanada] = useState<string>();
  // const [typeFindUs, setTypeFindUs] = useState<string>();

  const [yourIeltsScore, setYourIeltsScore] = useState<IScore>();
  const [yourTefScore, setYourTefScore] = useState<IScore>();
  const [notif, setNotif] = useState<INotification>();
  const [loading, setLoading] = useState<boolean>(false);

  const [updateRequest, responseUpdateRequest] = useMutation(UPDATE_REQUEST);
  useEffect(() => {
    if (requestData?.userId) {
      setLoading(true);
      formik.setFieldValue("degree", requestData.degree_edu);
      formik.setFieldValue("fieldStudy", requestData.field_study);
      formik.setFieldValue("fieldStudyEn", requestData.field_study_en);
      if (requestData.date_start_edu) { formik.setFieldValue("eduDateStart", requestData.date_start_edu); }
      if (requestData.date_end_edu) { formik.setFieldValue("eduDateEnd", requestData.date_end_edu); }
      formik.setFieldValue("totalAssets", requestData.total_assets);
      formik.setFieldValue("typeJob", String(requestData.type_job));
      formik.setFieldValue("isWorking", String(requestData.is_working));
      formik.setFieldValue("workDescription", (requestData.work_description));
      formik.setFieldValue("workDescriptionEn", (requestData.work_description_en));
      formik.setFieldValue("jobState", requestData.job_state);
      formik.setFieldValue("insuranceHistory", requestData.insurance_history ? String(requestData.insurance_history) : null);
      formik.setFieldValue("yourIelts", String(requestData.ielts));
      formik.setFieldValue("yourTef", String(requestData.tef));
      formik.setFieldValue("levelLang", String(requestData.language_level));
      formik.setFieldValue("familyLiveCanada", requestData.family_live_canada);
      // setTypeFindUs(true);
      setYourIeltsScore(requestData.ietls_score);
      setYourTefScore(requestData.tef_score);

      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [requestData]);

  const setIeltsScore = (type: string, value: string) => {
    setYourIeltsScore({ ...yourIeltsScore, [type]: value });
  };
  const setTefScore = (type: string, value: string) => {
    setYourTefScore({ ...yourTefScore, [type]: value });
  };

  const handleNext = (e?) => {
    if (e) e.preventDefault();

    if (stepProps.role === "Customer" && !stepProps.accessUser) {
      stepProps.handleNext();
      return;
    }

    const eDS = moment(eduDateStart).locale("en").format("YYYY-MM-DD");
    const eDE = moment(eduDateEnd).locale("en").format("YYYY-MM-DD");
    const variables = {
      degree_edu: degree,
      field_study: fieldStudy,
      field_study_en: fieldStudyEn,
      date_start_edu: eDS,
      date_end_edu: eDE,
      total_assets: totalAssets,
      type_job: Boolean_(typeJob),
      is_working: Boolean_(isWorking),
      work_description: workDescription,
      work_description_en: workDescriptionEn,
      job_state: jobState,
      insurance_history: insuranceHistory,
      ielts: Boolean_(yourIelts),
      tef: Boolean_(yourTef),
      ietls_score: yourIeltsScore,
      tef_score: yourTefScore,
      language_level: levelLang,
      family_live_canada: familyLiveCanada,
      // type_find_us: $typeFindUs,
    };
    if (requestData?.requestId) {
      // TODO must be updated
      updateRequest({
        variables: { requestId: Number(requestData?.requestId), ...variables },
        refetchQueries: ["GetRequest"],
        awaitRefetchQueries: true,
      }).then((result) => {
        enqueueSnackbar(t("SuccessfullyUpdated"), {
          variant: "success",
        });
        stepProps.handleNext({ requestId: Number(context.requestData?.requestId) });
      }).catch((error) => {
        enqueueSnackbar(t("ErrorHappened"), {
          variant: "error",
        });
      });
    }
  };

  const handleBack = () => {
    stepProps.handleBack();
  };

  return (
    <div>
      {!loading ? (
        <>
          <form onSubmit={formik.handleSubmit} className={classes.root} noValidate autoComplete="on">
            <Grid container spacing={4}>
              <SelectBox
                handler="formik"
                name="degree"
                value={degree}
                formikI={formik}
                grid={2}
                items={statics.degreesOptions}
                required
              />
              <InputBox
                handler="formik"
                name="fieldStudy"
                value={fieldStudy}
                formikI={formik}
                grid={3}
                required
              />
              <InputBox
                handler="formik"
                name="fieldStudyEn"
                value={fieldStudyEn}
                formikI={formik}
                grid={3}
                required
                dir="ltr"
              />
              <DatePickerComponent
                handler="formik"
                name="eduDateStart"
                value={eduDateStart}
                formikI={formik}
                grid={2}
                required
                views="bth"
              />

              <DatePickerComponent
                handler="formik"
                name="eduDateEnd"
                value={eduDateEnd}
                formikI={formik}
                grid={2}
                required
                views="bth"
              />

              <SelectBox
                handler="formik"
                name="totalAssets"
                value={totalAssets}
                formikI={formik}
                grid={5}
                items={statics.totalAssetsOptions}
                required
              />

              <RadioGroupBox
                handler="formik"
                name="typeJob"
                value={typeJob}
                formikI={formik}
                grid={3}
                items={statics.typeJobOptions}
                required
              />
              <RadioGroupBox
                handler="formik"
                name="isWorking"
                value={isWorking}
                formikI={formik}
                grid={4}
                items={statics.defaultOption}
                required
              />
              <InputBox
                handler="formik"
                name="workDescription"
                value={workDescription}
                formikI={formik}
                grid={8}
                required
              />
              <InputBox
                handler="formik"
                name="workDescriptionEn"
                value={workDescriptionEn}
                formikI={formik}
                grid={8}
                required
                dir="ltr"
              />
            </Grid>
            <Grid container spacing={4}>
              <SelectBox
                handler="formik"
                name="jobState"
                value={jobState}
                formikI={formik}
                grid={4}
                items={statics.jobOptions}
                required
              />
              <SelectBox
                handler="formik"
                name="insuranceHistory"
                value={insuranceHistory}
                formikI={formik}
                grid={4}
                items={statics.insuranceOptions}
                required
              />

              <SelectBox
                handler="formik"
                name="familyLiveCanada"
                value={familyLiveCanada}
                formikI={formik}
                grid={4}
                items={statics.familyLiveCanadaOptions}
                // multiple={Boolean(true)}
                required
              />

            </Grid>
            <Grid container spacing={4}>
              <RadioGroupBox
                handler="formik"
                name="yourIelts"
                value={yourIelts}
                formikI={formik}
                grid={4}
                items={statics.defaultOption}
                required
              />
              {yourIelts && yourIelts === "true" && (
                <>
                  <Grid container spacing={2} xs={4}>
                    <InputBox
                      name="yourIeltsScore.read"
                      value={yourIeltsScore?.read}
                      setValue={((v) => setIeltsScore("read", v))}
                      grid={6}
                      type="number"
                    />
                    <InputBox
                      name="yourIeltsScore.listen"
                      value={yourIeltsScore?.listen}
                      setValue={((v) => setIeltsScore("listen", v))}
                      grid={6}
                      type="number"
                    />
                    <InputBox
                      name="yourIeltsScore.write"
                      value={yourIeltsScore?.write}
                      setValue={((v) => setIeltsScore("write", v))}
                      grid={6}
                      type="number"
                    />
                    <InputBox
                      name="yourIeltsScore.speak"
                      value={yourIeltsScore?.speak}
                      setValue={((v) => setIeltsScore("speak", v))}
                      grid={6}
                      type="number"
                    />
                  </Grid>
                  <Grid container spacing={2} xs={4} />
                </>
              ) }
              <RadioGroupBox
                handler="formik"
                name="yourTef"
                value={yourTef}
                formikI={formik}
                grid={4}
                items={statics.defaultOption}
                required
              />
              {yourTef && yourTef === "true" && (
                <>
                  <Grid container spacing={2} xs={4}>
                    <InputBox
                      name="yourTefScore.read"
                      value={yourTefScore?.read}
                      setValue={((v) => setTefScore("read", v))}
                      grid={6}
                      type="number"
                    />
                    <InputBox
                      name="yourTefScore.listen"
                      value={yourTefScore?.listen}
                      setValue={((v) => setTefScore("listen", v))}
                      grid={6}
                      type="number"
                    />
                    <InputBox
                      name="yourTefScore.write"
                      value={yourTefScore?.write}
                      setValue={((v) => setTefScore("write", v))}
                      grid={6}
                      type="number"
                    />
                    <InputBox
                      name="yourTefScore.speak"
                      value={yourTefScore?.speak}
                      setValue={((v) => setTefScore("speak", v))}
                      grid={6}
                      type="number"
                    />
                  </Grid>
                  <Grid container spacing={2} xs={4} />
                </>
              ) }

              <RadioGroupBox
                handler="formik"
                name="levelLang"
                value={levelLang}
                formikI={formik}
                grid={4}
                items={statics.langLevelOptions}
                required
              />

              {/* <SelectBox
                handler="formik"
                name="typeFindUs"
                value={typeFindUs}
                formikI={formik}
                grid={4}
                items={statics.typeFindUsOptions}
              /> */}
            </Grid>
            <NavBarFooter
              step={stepProps.stepIndex}
              stepLength={stepProps.stepLen}
              handleNextCallback={formik.handleSubmit}
              handleBackCallback={handleBack}
            />
          </form>
          <Notification {...notif} />
        </>
      ) : ""}
    </div>
  );
};

export default RequestQuestion;
