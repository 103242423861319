/* eslint-disable react/jsx-closing-tag-location */
import {
  Button, Grid, Theme, Typography, createStyles,
} from "@material-ui/core";
import React, {
  useCallback, useContext, useEffect, useRef,
  useState,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import { useConfirm } from "material-ui-confirm";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { useSnackbar } from "notistack";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import SectionTitle from "../../../components/SectionTitle";
import InputCheckBox from "../../../components/micro/InputCheckBox";
import {
  CREATE_REQUEST_ATTACHMENT_FILE, DELETE_REQUEST_ATTACHMENT_FILE,
  DOWNLOAD_CONTRACT_BY_REQUEST_ID, GET_REQUEST_ATTACHMENTS,
  GET_STATE_REQUEST,
  UPDATE_REQUEST, UPDATE_STATE_REQUEST,
} from "./requestStepAQueries";
import Notification, { INotification } from "../../../components/micro/SnackbarMicro";
import { RequestContext } from "../context/requestContext";
import AdminNavbarStepTools from "../component/AdminNavbarStepTools";
import { GET_VISA, GET_VISAS } from "../../Visa/visasQueries";
import SelectBox from "../../../components/micro/SelectBox";
import InputEditor2 from "../../../components/micro/InputEditor2";
import { GET_ME } from "../../../app/layout/layoutQueries";
import { hasAccessStep } from "../../../app/utils/helper";
import RequestStepEPayment from "./RequestStepEPayment";
import DialogBase from "../../../components/dialogs/DialogBase";
import { STEPS } from "../../../app/statics";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    margin: "0px",
  },
  saveButton: {
    backgroundColor: theme.palette.success.light,
    marginRight: "5px",
    fontSize: "1.1em",
    width: "150px",
  },
  sigCanvas: {
    borderWidth: "1px",
    borderRadius: "5px",
    borderStyle: "solid",
    borderColor: "#ddd",
    width: "100%",
  },
  contractBox: {
    maxHeight: "600px",
    overflowY: "auto",
    padding: "8px",
    border: "3px solid #eee",
    textAlign: "justify",
    margin: "5px 0",
  },
  contractBoxEn: {
    maxHeight: "600px",
    overflowY: "auto",
    padding: "8px",
    border: "3px solid #eee",
    textAlign: "justify",
    direction: "rtl",
    margin: "5px 0",
  },
  textTitle: {
    padding: "8px",
    backgroundColor: "#eee",
    borderRadius: "10px",
  },
  alertBig: {
    width: "100%",
    padding: "50px",
    margin: "20px",
    opacity: "0.5",
  },
  selectedVisa: {
    fontSize: "1.2em",
    fontWeight: "bold",
    marginTop: "5px",
  },
}));

const RequestStepDContract = (props: any) => {
  const [confirmContracts, setConfirmContracts] = useState<Object>({});
  const [files, setFiles] = useState<[]>([]);
  const [newFiles, setNewFiles] = useState<[]>([]);
  const [titles, setTitles] = useState<[]>([]);
  const history = useHistory();

  const [contractText, setContractText] = useState<string>();
  const [contractTextEn, setContractTextEn] = useState<string>();

  const [openModal, setOpenModal] = useState<boolean>();
  const [modalOptions, setModalOptions] = useState<any>();

  const { t } = useTranslation();
  const classes = useStyles();
  const confirm = useConfirm();
  const sigCanvas = useRef<any>();
  const sigCanvasUser = useRef<any>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  const isCustomer = role === "Customer";

  const context = useContext(RequestContext);
  const { requestData } = context;
  const [selectedVisa, setSelectedVisa] = useState<string>(requestData?.visa?.id || "");
  const visasQuery = useQuery(GET_VISAS, { variables: { sort: "id" } });
  const reqStateQuery = useQuery(GET_STATE_REQUEST, {
    variables: {
      reqId: context.requestData.requestId,
    },
    fetchPolicy: "cache-first",
  });
  const { data: dataRequestAttachments } = useQuery(GET_REQUEST_ATTACHMENTS, {
    variables: {
      reqId: context.requestData.requestId,
      section: "contract",
    },
  });
  const [deleteAttachmentRequest, responseDeleteAttachmentRequest] = useMutation(
    DELETE_REQUEST_ATTACHMENT_FILE,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );
  const reqState = _.get(reqStateQuery, "data.requestStatuses[0]");

  const [downloadContract] = useMutation(DOWNLOAD_CONTRACT_BY_REQUEST_ID);

  let accessUser = true;
  const lockSteps = _.get(reqStateQuery.data, "requestStatuses[0].lock_steps");
  if (lockSteps && isCustomer) {
    accessUser = hasAccessStep("step4", lockSteps);
  }

  const visasOptions = useCallback(() => {
    const data_ = _.get(visasQuery, "data.contractTypes", []);
    return data_.map((item) => ({
      label: item.title,
      value: item.id,
    }));
  }, [visasQuery.data]);

  const visaQuery = useQuery(GET_VISA, {
    skip: !selectedVisa,
    variables: { id: selectedVisa },
  });
  const visaData = _.get(visaQuery, "data.contractType");

  useEffect(() => {
    if (requestData?.visa) {
      setSelectedVisa(requestData?.visa?.id);
    }
  }, [requestData?.visa?.id]);
  useEffect(() => {
    if (visaData && !reqStateQuery.loading) {
      if (!isCustomer) {
        sigCanvas.current.fromDataURL(reqState.signature_admin);
      }
      if (!_.isEmpty(reqState.signature_user)) {
        sigCanvasUser.current.fromDataURL(reqState.signature_user);
      }
      if (reqState.confirm_contracts) {
        setConfirmContracts(reqState.confirm_contracts);
      }
      setContractText(reqState.contract_text || visaData.contract_text);
      setContractTextEn(reqState.contract_text_en || visaData.contract_text_en);
    }
  }, [visaQuery?.data, reqStateQuery?.data]);

  useEffect(() => {
    if (dataRequestAttachments) {
      setFiles(dataRequestAttachments?.requestAttachments);
    }
  }, [dataRequestAttachments]);

  const [updateRequest, responseUpdateRequest] = useMutation(UPDATE_REQUEST);
  const [updateRequestState, responseUpdateRequestState] = useMutation(
    UPDATE_STATE_REQUEST,
  );
  const [notif, setNotif] = useState<INotification>();

  const [uploadAttachmentRequest, responseUploadAttachmentRequest] = useMutation(
    CREATE_REQUEST_ATTACHMENT_FILE,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );

  const handleSaveUpload = (newFiles_, newTitles_) => {
    newFiles_.forEach((file, i) => {
      uploadAttachmentRequest({
        variables: {
          title: newTitles_[i],
          requestId: Number(requestData?.requestId),
          file: file?.id,
          section: "contract",
        },
        refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
      }).then((resultUpload) => {
        // setNewFiles([]);
        // setTitles([]);
      }).catch((errorUpload) => {
        console.log("upload-error");
      });
    });
  };

  const handleSave = () => {
    try {
      if (isCustomer) {
        const confirms = Object.values(confirmContracts);
        const isNotCheckedAll = confirms.findIndex((item) => item === false);
        if (confirms.length !== displayTexts.length || isNotCheckedAll >= 0) {
          enqueueSnackbar(t("ErrorNotAcceptedAllContractConditions"), {
            variant: "warning",
          });
          return;
        }
      }
      if (isCustomer && sigCanvasUser.current.isEmpty()) {
        enqueueSnackbar(t("ErrorSignContract"), {
          variant: "warning",
        });
        return;
      }
      // if (!isCustomer && sigCanvas.current.isEmpty()) {
      //   return;
      // }
      const signature = sigCanvas.current?.toDataURL("image/png");
      const signatureUser = sigCanvasUser.current?.toDataURL("image/png");

      confirm({
        title: t("AreYouSureForSave"), description: "", confirmationText: t("yes"), cancellationText: t("no"),
      })
        .then(() => {
          updateRequest({ variables: { requestId: requestData.id, visa: selectedVisa } });

          updateRequestState({
            variables: {
              reqSId: Number(reqState.id),
              contractText,
              contractTextEn,
              signatureAdmin: signature,
              signatureUser,
              confirmContracts,
              selectedVisa,
            },
          }).then((r) => {
            enqueueSnackbar(t("SuccessfullySavedWaitForAdmin"), {
              variant: "success",
            });
            if (isCustomer) {
              setModalOptions({
                title: t("contractIsReadyTitle"),
                content: <div>
                  <p>{t("yourContractIsReadyForDownload")}</p>
                  <Button
                    onClick={handleDownloadContract}
                    className={classes.saveButton}
                  >
                    {t("Request.downloadContract")}
                  </Button>
                </div>,
              });
              setOpenModal(true);
            } else {
              history.goBack();
            }
          })
            .catch((e) => {
              enqueueSnackbar(t("ErrorHappened"), {
                variant: "error",
              });
            });
        })
        .catch(() => { /* ... */ });
    } catch (e) {
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    }
  };

  const handleRemoveAttachment = (file) => {
    deleteAttachmentRequest({ variables: { id: file.id } }).then(() => {
      enqueueSnackbar(t("SuccessfullyDeleted"), {
        variant: "error",
      });
    }).catch((e) => {
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    });
  };

  const handleConfirmContract = (txtId, state) => {
    setConfirmContracts((old) => {
      const old_ = { ...old };
      old_[txtId] = state;
      return old_;
    });
  };

  const handleDownloadContract = () => {
    // downloadContract({
    //   variables: {
    //     id: String(requestData?.requestId),
    //   },
    // }).then((result) => {
    // const file_ = _.get(result, "data.downloadContract.file");
    // window.location.href(file_);
    // window.open(file_, "_blank");
    // }).catch((errorUpload) => {
    //   console.log("upload-error");
    // });
    // http:// localhost:3000/contract/142
    const url = `${window.location.origin}/contract/${context.requestData.requestId}?mode=en`;
    window.open(url, "_blank");
    // TODO LOG HISTORY DOWNLOAD
  };

  let displayTexts = [];
  let displayTextsEn = [];
  if (reqState?.contract_text || visaData?.contract_text) {
    const txt_ = reqState?.contract_text || visaData?.contract_text;
    const txtE_ = reqState?.contract_text_en || visaData?.contract_text_en;
    displayTexts = txt_.split("@break@").map((m) => parse(m));
    displayTextsEn = txtE_.split("@break@").map((m) => parse(m));
  }

  if (isCustomer && !selectedVisa) {
    return (
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12} md={12}>
          <Alert
            className={classes.alertBig}
            variant="outlined"
            severity="warning"
          >
            {t("thisSectionNeedActivedByAdmin")}
          </Alert>
        </Grid>
      </Grid>
    );
  }

  const selectedVisaName = visasOptions()?.find((item) => item.value === selectedVisa);

  return (
    <>
      <SectionTitle
        title=""
      />
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12} md={8}>
          {!isCustomer ? (
            <div>
              <SelectBox
                name="selectVisaTypeForContract"
                value={selectedVisa}
                setValue={setSelectedVisa}
                grid={6}
                items={visasOptions()}
                readOnly={isCustomer}
              />
            </div>
          ) : (
            <div className={classes.selectedVisa}>
              {t("SelectedVisa")}
              :
              {" "}
              {selectedVisaName?.label}
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Alert severity="warning">
            {t("PleaseReadContractAndAcceptThis")}
          </Alert>
        </Grid>

        <Grid item xs={12} md={isCustomer ? 12 : 6}>
          <div>
            <Typography variant="h5" className={classes.textTitle}>{t("contract_.EnglishText")}</Typography>
            {!isCustomer ? (
              <InputEditor2
                label="contract_text_en"
                value={reqState?.contract_text_en || visaData?.contract_text_en}
                setValue={setContractTextEn}
              />
            ) : (
              <div className={classes.contractBoxEn}>
                {displayTextsEn.map((txt, txtId) => (
                  <div>
                    {txt}
                    <Grid item xs={12} md={8}>
                      <InputCheckBox
                        name={`confirmContract${txtId}`}
                        label="confirmContractStepEn"
                        value={confirmContracts[txtId]}
                        setValue={(e) => handleConfirmContract(txtId, e)}
                      />
                    </Grid>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12} md={isCustomer ? 12 : 6}>
          <div>
            <Typography variant="h5" className={classes.textTitle}>{t("contract_.PersianText")}</Typography>
            {!isCustomer ? (
              <InputEditor2
                label="contract_text"
                value={reqState?.contract_text || visaData?.contract_text}
                setValue={setContractText}
                dir="rtl"
              />
            ) : (
              <div className={classes.contractBox}>
                {displayTexts.map((txt, txtId) => (
                  <div>
                    {txt}
                    <Grid item xs={12} md={8}>
                      <InputCheckBox
                        name={`confirmContract${txtId}`}
                        label="confirmContractStep"
                        value={confirmContracts[txtId]}
                        setValue={(e) => handleConfirmContract(txtId, e)}
                      />
                    </Grid>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12} md={12}>
          <RequestStepEPayment mode="contract" />
        </Grid>

        {!isCustomer ? (
          <Grid item xs={12} md={6}>
            <p>
              {t("Request.signContractAdmin")}
              :
            </p>
            <SignatureCanvas
              penColor="blue"
              velocityFilterWeight="1"
              canvasProps={{ height: 350, className: classes.sigCanvas }}
              ref={sigCanvas}
            />
            <Button
              variant="outlined"
              onClick={() => sigCanvas.current.clear()}
            >
              {t("clear")}

            </Button>
          </Grid>
        ) : "" }
        <Grid item xs={12} md={6}>
          <p>
            {t("Request.signContractUser")}
            :
          </p>
          <SignatureCanvas
            penColor="blue"
            velocityFilterWeight="1"
            canvasProps={{ height: 350, className: classes.sigCanvas }}
            ref={sigCanvasUser}
          />
          <Button
            variant="outlined"
            onClick={() => sigCanvasUser.current.clear()}
          >
            {t("clear")}

          </Button>
        </Grid>

        <Grid item xs={8} md={8}>
          <br />
          <Button
            onClick={handleSave}
            className={classes.saveButton}
            disabled={!accessUser}
          >
            {t("Request.saveSubmit")}
          </Button>
          <Button
            onClick={handleDownloadContract}
            className={classes.saveButton}
            // disabled={!accessUser}
          >
            {t("Request.downloadContract")}
          </Button>
        </Grid>
        <Notification {...notif} />
      </Grid>
      <AdminNavbarStepTools stepNumber={STEPS.STEP_CONTRACT} />
      {openModal ? (
        <DialogBase
          open={openModal}
          setOpen={setOpenModal}
          options={modalOptions}
          setOptions={setModalOptions}
        />
      ) : ""}
    </>
  );
};

export default RequestStepDContract;
