/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import {
  Button, Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Files from "react-files";
import CloseIcon from "@material-ui/icons/Close";
import AcceptIcon from "@material-ui/icons/Check";
import ImageIcon from "@material-ui/icons/CloudUpload";
import { useMutation } from "@apollo/client";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { UPLOAD_FILE } from "./inputFileQueries";
import Notification, { INotification } from "./SnackbarMicro";
import { API_SERVER } from "../../Config";
import { getGrid } from "../../app/utils/helper";

const useStyles = makeStyles((theme: Theme) => createStyles({
  box: {
    backgroundColor: "#fff",
    padding: "0px",
  },
  name: {
    marginBottom: "10px",
    marginTop: "15px",
  },
  dropZone: {
    borderWidth: "3px",
    borderStyle: "dashed",
    borderColor: "#ddd",
    padding: "20px",
    textAlign: "center",
  },
  dropZoneHide: {
    display: "none",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "red",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tile: {
    width: "100px",
  },
  tileFull: {
    // width: "100%",
    height: "200px",
    margin: "auto",
  },
  extension: {
    // width: "100px",
    // height: "100px",
    padding: "60px 40px",
    textAlign: "center",
    backgroundColor: "#3c3c3c",
    borderRadius: "10px",
    marginBottom: "10px",
    color: "#fff",
    fontSize: "1.3em",
  },
  ul: {
    margin: "0px",
    marginTop: "20px",
    marginBottom: "50px",
    padding: "0px",
    paddingTop: "20px",
    borderTopWidth: "1px",
    borderTopColor: "#ddd",
    borderTopStyle: "solid",
  },
  remove: {
    padding: "15px",
  },
  bar: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  tileTitle: {
    textAlign: "center",
    padding: "10px",
    fontSize: "40px",
    color: "#787878",
    textTransform: "uppercase",
  },
  saveButton: {
    // padding: "0px !important",
  },
}));

type IPropsInput = {
    name: string,
    multiple?: boolean,
    grid?: number,
    file: object,
    setFile: Function,
    required?: boolean,
    disable?: boolean,
    mode?: "singleImage" | "singleVideo",
    showTitle?: boolean,
    downloadBarEnable?: boolean,
}
const InputFile = (props: IPropsInput) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    name, file,
    setFile, multiple, grid,
    required, disable,
    mode,
    showTitle,
    downloadBarEnable,
  } = props;
  const [notif, setNotif] = useState<INotification>();
  const [fileLocal, setFileLocal] = useState<any>(file);
  const confirm = useConfirm();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setFileLocal(file);
  }, [file]);

  const name_ = name.toString();
  const [uploadFile, responseUploadFile] = useMutation(UPLOAD_FILE);

  const onFilesChange = (fileVal) => {
    setFileLocal(fileVal[0]);
  };

  const onFilesError = (error, file_) => {
    console.log(`error code ${error.code}: ${error.message}`);
    if (error.code === 2) {
      enqueueSnackbar(t("FileIsToLargeMustBeUnder5Mb"), {
        variant: "error",
      });
    } else {
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    }
  };

  const fileRemoveOne = (file_) => {
    confirm({
      title: t("SureForDelete"), description: "", confirmationText: t("yes"), cancellationText: t("no"),
    }).then(() => {
      // removeFile(file);
      setFile(null);
    });
  };

  const fileUpload = async () => {
    uploadFile({
      variables: {
        file: fileLocal,
      },
    })
      .then((result) => {
        setNotif({
          open: true, message: "successUploaded", type: "success", setOpen: setNotif,
        });
        setFile(result.data.upload);
      }).catch((error) => {
        setNotif({
          open: true, message: "errorUpload", type: "error", setOpen: setNotif,
        });
      });
  };

  const hideDropZonePlace = !!fileLocal;
  const isUploaded = !!fileLocal?.created_at;
  const isImage = fileLocal?.type?.search("image") >= 0 || fileLocal?.mime?.search("image") >= 0;
  const extensionFile = fileLocal?.extension || fileLocal?.ext;

  const box = () => (
    <div>
      {showTitle ? <div className={classes.name}>{t(name)}</div> : "" }
      <div className={classes.box}>
        {mode === "singleImage" && fileLocal && (!isUploaded || downloadBarEnable) ? (
          <div className={classes.bar}>
            <Button
              id={fileLocal?.id}
              onClick={() => fileRemoveOne(file)}
              variant="outlined"
              size="small"
            >
              <CloseIcon />
              {t("Request.CancelUpload")}
            </Button>
            <Button
              onClick={fileUpload}
              variant="contained"
              color="secondary"
              size="small"
            >
              {t("Request.UploadFile")}
              {responseUploadFile.loading ? "LOADING" : ""}
            </Button>
          </div>
        ) : ""}

        <Files
          className={hideDropZonePlace ? classes.dropZoneHide : classes.dropZone}
          onChange={onFilesChange}
          onError={onFilesError}
          // accepts={["image/png", ".pdf", "audio/*"]}
          multiple={false}
          maxFileSize={10000000}
          minFileSize={0}
          clickable={!disable}
        >
          <div>
            <div><ImageIcon style={{ fontSize: "64px", color: "#acacac" }} /></div>
            Drop file here or click to upload
          </div>
        </Files>
        {
        mode === "singleImage" && fileLocal && (
        <div style={{ textAlign: "center" }}>
          {isImage ? (
            <img
              className={classes.tileFull}
              alt="x"
              src={fileLocal?.preview?.url || API_SERVER() + fileLocal?.url}
            />
          ) : <div className={classes.tileTitle}>{extensionFile}</div>}

        </div>
        )
      }
        {
        mode === "singleVideo" && fileLocal && (
        // fileLocal?.map((file) => (
        <div>
          <div className={classes.tileFull}>
            {fileLocal?.preview?.type === "image"
              ? <img className={classes.tileFull} alt="x" src={fileLocal?.preview?.url} />
              : <div className={classes.extension}>{fileLocal.extension || fileLocal.ext}</div>}
          </div>
          <div className={classes.bar}>
            <div
              id={fileLocal.id}
              className={classes.remove}
              onClick={() => fileRemoveOne(fileLocal)}
            >
              <CloseIcon />
            </div>
            <Button
              onClick={fileUpload}
              className={classes.saveButton}
              // variant="outlined"
            >
              <AcceptIcon />
              {/* {t("Request.Upload")} */}
            </Button>
          </div>
        </div>
        // ))
        )
      }
        <Notification {...notif} />
      </div>
    </div>
  );

  if (grid) {
    const [xs_, md_, lg_] = getGrid(grid);
    return (
      <Grid
        item
        md={md_}
        xs={xs_}
        lg={lg_}
      >
        {box()}
      </Grid>
    );
  }
  return (
    <>
      {box()}
    </>
  );
};

InputFile.defaultProps = {
  multiple: false,
  grid: 0,
  required: false,
  disable: false,
  mode: "singleImage",
  showTitle: true,
  downloadBarEnable: false,
};

export default InputFile;
