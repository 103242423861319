import { useQuery } from "@apollo/client";
import { Card } from "@material-ui/core";
import _ from "lodash";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Area,
  AreaChart,
  CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from "recharts";
import { dateTimeIso8061_ } from "../../app/utils/helper";
import { GET_DASHBOARD_LAST_MONTH_EVAL } from "../../pages/Dashboard/dashboardQueries";
import "./styles.scss";

const RateOfEvaluationForm = () => {
  const init = [
    {
      name: "1402-01-1",
      value: 0,
    },
    {
      name: "1402-01-2",
      value: 10,
    },
    {
      name: "1402-01-3",
      value: 30,
    },
    {
      name: "1402-01-4",
      value: 10,
    },
    {
      name: "1402-01-5",
      value: 10,
    },
    {
      name: "1402-01-6",
      value: 10,
    },
    {
      name: "1402-01-7",
      value: 10,
    },
    {
      name: "1402-01-8",
      value: 10,
    },
  ];
  const [data, setData] = useState(init);

  // const demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";
  const { t } = useTranslation();
  const q0 = useQuery(GET_DASHBOARD_LAST_MONTH_EVAL, {
    variables: {
      gt: dateTimeIso8061_(moment().add(-2, "month")),
      lt: dateTimeIso8061_(moment()),
    },
  });
  const v0 = _.get(q0, "data.evaluationsConnection.values", []);
  const v1 = _.get(q0, "data.evaluationsConnection.aggregate.count", 0);
  useEffect(() => {
    const dataTemp = [];
    v0.map((item) => {
      const dt_ = moment(item.created_at).locale("en").format("jYYYY-jMM-jDD");
      if (dataTemp[dt_]) { dataTemp[dt_] += 1; } else dataTemp[dt_] = 1;
      return 1;
    });
    const tm = _.keys(dataTemp).reverse().map((k) => ({
      name: k,
      value: dataTemp[k],
      avg: (v1 / 60),
    }));
    setData(tm);
  }, [q0]);
  // data[0].value = v0;
  // data[1].value = v1;
  // data[2].value = v2;
  // data[3].value = v3;
  // data[4].value = v4;
  // data[5].value = v5;

  return (
    <div className="dashboard-card">
      <h2>{t("dashboard.rateFillEvaluation")}</h2>
      <Card title={t("dashboard.statusContracts")} style={{ width: "100%", height: "400px", padding: "10px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="value" label="Evaluations" stroke="#8884d8" fill="#8884d8" />
            <Area type="step" dataKey="avg" label="Average" stroke="red" fill="none" />
          </AreaChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

export default RateOfEvaluationForm;
