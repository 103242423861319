import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { KeyboardArrowDown as ArrowDown, KeyboardArrowUp as ArrowUp } from "@material-ui/icons";

import "./IconExpand.scss";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

const IconExpand = (props:any) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { open } = props;
  return (
    <div className="icon-expand">
      <IconButton
        title={t("detail")}
        onClick={() => props.setOpen(!props.open)}
      >
        {open ? <ArrowUp /> : <ArrowDown />}
      </IconButton>
    </div>
  );
};

export default IconExpand;
