import React, { useEffect, useState } from "react";
import { Card, Fab } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import AddIcon from "@material-ui/icons/Add";
import { DELETE_CONSULTANT, GET_CONSULTANT_TIMES } from "./profileQueries";
import { dateIsoFa2, getFakeEvalId, timeIsoFa } from "../../app/utils/helper";
import TableAdvance from "../../components/micro/TableAdvance";
import DialogAddNewTime from "../../components/dialogs/DialogAddNewTime";

const TimeSheet = (props:any) => {
  const a = 1;

  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>();

  const confirm = useConfirm();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [rows, setRows] = useState([]);

  const columns = [
    {
      label: t("id"),
      name: "id",
      options: {
        filter: true,
        // display: false,
      },
    },
    {
      label: t("nameConsultant"),
      name: "name",
      options: {
        filter: true,
        // display: false,
      },
    },
    {
      label: t("typeConsultant"),
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: t("locationConsultant"),
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: t("date"),
      name: "start_time",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: t("start_time"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: t("end_time"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: t("is_reserved"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value === "Yes" ? <b style={{ color: "green" }}>{t("yes")}</b> : "-" }
          </>
        ),
      },
    },
    {
      name: t("request_link"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: t("remove"),
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value}
          </>
        ),
      },
    },
  ];

  const quGetTimes = useQuery(GET_CONSULTANT_TIMES, {
    variables: {
      sort: "start_time:desc",
      limit: 10,
      start: 0,
      where: undefined,
    },
    fetchPolicy: "cache-and-network",
  });

  const [muDeleteConsultant] = useMutation(DELETE_CONSULTANT, {
    refetchQueries: [],
  });

  useEffect(() => {
    const timesData = _.get(quGetTimes.data, "consultantTimesConnection.values");
    if (timesData) {
      const times = timesData.map((item) => {
        let place = item.is_selectable ? t("consultant_type.BOTH") : undefined;
        if (!place) {
          place = item.is_online ? t("online") : t("offline");
        }
        let link;
        if (item.request_status?.id) {
          link = <Link to={`/request/${item.request_status?.requestId?.id}`}>{t("view")}</Link>;
        } else if (item.evaluation) {
          link = <Link to={`/booking/${getFakeEvalId(item.evaluation?.id)}`}>{t("view")}</Link>;
        } else { link = "-"; }
        return {
        // ...item,
          id: item.id,
          name: item?.user?.name,
          // phoneContact: item?.user?.phone,
          typeConsultant: item?.consultant_type?.title || "-",
          location: place,
          date: dateIsoFa2(item.start_time),
          start_time: timeIsoFa(item.start_time),
          end_time: timeIsoFa(item.end_time),
          is_reserved: item.request_status?.id || item.evaluation?.id ? "Yes" : "No",
          request_link: link,
          remove: <div><a className="link" onClick={() => deleteItem(item)}><DeleteOutlined /></a></div>,
        };
      });
      const times_ = times.map((item) => Object.values(item));
      setRows(times_);
    }
  }, [quGetTimes.data]);

  const totalCount = _.get(quGetTimes.data, "consultantTimesConnection.aggregate.totalCount");

  const deleteItem = async (item: any) => {
    confirm({
      title: t("AreYouSureForDelete"),
      description: "",
      confirmationText: t("yes"),
      cancellationText: t("no"),
    })
      .then(async () => {
        const { id } = item;
        const result = await muDeleteConsultant({
          variables: {
            id,
          },
          refetchQueries: ["GET_CONSULTANT_TIMES"],
        });
        if (result?.data) {
          enqueueSnackbar(t("SuccessfullyDeleted"), {
            variant: "success",
          });
        }
      });
  };

  return (
    <div>
      <Card>
        {/* <TableSimple rows={rows} headers={headers} size="small" /> */}
        <TableAdvance
          {...props}
          data={rows}
          columns={columns}
          className="tbl_info"
          serverSide
          totalCount={totalCount}
          query={quGetTimes}
          defaultOrder="start_time:desc"
        />
      </Card>

      {open ? <DialogAddNewTime open={open} setOpen={setOpen} /> : "" }

      <Fab
        className="fab-icon"
        onClick={() => setOpen(true)}
        title={t("NewArticle")}
      >
        <AddIcon />
      </Fab>

    </div>
  );
};

export default TimeSheet;
