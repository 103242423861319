import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import {
  Chip, IconButton,
} from "@material-ui/core";
import {
  AddBoxOutlined, InfoOutlined as AlertIcon, CloudDownload, Delete, DoneAll as DoneAllIcon,
  Edit as EditIcon,
  VisibilityOff as IconNoVisible,
  Visibility as IconVisible,
  SettingsOutlined as SetIcon,
} from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import FormDialogUploadFile from "./FormDialogUploadFile";
import { dateIsoFa } from "../app/utils/helper";
import { GET_ME } from "../app/layout/layoutQueries";
import DialogSetDueDate from "./dialogs/DialogSetDueDate";
import DialogSetLinkAttachment from "./dialogs/DialogSetLinkAttachement";
import { UPDATE_REQUEST_ATTACHMENT_FILE } from "../pages/Request/steps/requestStepAQueries";
import DialogDeleteAttachment from "./dialogs/DialogDeleteAttachment";
import DialogApproveDocument from "./dialogs/DialogApproveDocument";
import DialogBase from "./dialogs/DialogBase";

import "./AttachmentTable.less";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "&>tbody>tr.doc-lock": {
      opacity: 0.5,
      backgroundColor: "#d0d0d0",
      "&:hover": {
        cursor: "not-allowed",
      },
    },
  },
  ok: {
    backgroundColor: "green",
    color: "#fff",
  },
  rejectArea: {
    display: "block",
    width: "100%",
    fontSize: "1.2em",
    padding: "15px 0px",
  },
});

export default function AttachmentTable(props: any) {
  const {
    attachments, isFileOnServer, handleUploadFile, downloadFile,
    dueDocumentDates,
    confirmStatusAttachment,
    requestSID,
    requestId,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const [openDialogUploadFile, setOpenDialogUploadFile] = useState<boolean>(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState<boolean>(false);
  const [openDialogDeleteConfirm, setOpenDialogDeleteConfirm] = useState<boolean>(false);
  const [openDueDate, setOpenDueDate] = useState<boolean>(false);
  const [openSetAttachment, setOpenSetAttachment] = useState<boolean>(false);
  const [openReason, setOpenReason] = useState<string>();
  const [selectedAttachment, setSelectedAttachment] = useState<any>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  const isCustomer = role === "Customer";

  const [updateAttachmentRequest, responseUpdateAttachmentRequest] = useMutation(
    UPDATE_REQUEST_ATTACHMENT_FILE,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );

  const handleOpenDialogUploadFile = (e, row) => {
    setOpenDialogUploadFile((old) => !old);
    setSelectedAttachment(row);
  };

  const handleDialogSetLink = (e, row) => {
    setOpenSetAttachment((old) => !old);
    setSelectedAttachment(row);
  };

  const handleUploadFile_ = (file) => {
    handleUploadFile(file, selectedAttachment);
    handleOpenDialogUploadFile(null, null);
    setSelectedAttachment(null);
  };

  const handleUploadFile2_ = (file) => {
    updateAttachmentRequest({
      variables: {
        docId: file.id,
        status: "sent",
        file: [file.id],
        id: selectedAttachment.id,
        upload_date: moment().format(),
      },
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    });
  };

  const handleConfirmDialog = (item) => {
    setOpenDialogConfirm((old) => !old);
    setSelectedAttachment(item);
  };

  const handleConfirmDialogDelete = (item) => {
    setOpenDialogDeleteConfirm(item);
  };

  const statusIcon = (status) => {
    if (status === "accepted") {
      return (
        <Chip
          icon={<DoneAllIcon style={{ color: "#fff" }} />}
          label={t(status)}
          variant="outlined"
          className={classes.ok}
        />
      );
    }
    if (status === "rejected") {
      return (
        <Chip
          icon={<CloseIcon style={{ color: "#fff" }} />}
          label={t(status)}
          variant="outlined"
          style={{ color: "#fff", backgroundColor: "red" }}
        />
      );
    }
    if (status === "sent") {
      return (
        <Chip
          icon={<Check style={{ color: "#fff" }} />}
          label={t(status)}
          variant="outlined"
          style={{ color: "#fff", backgroundColor: "#ffb703" }}
        />
      );
    }
    return (
      <Chip
        label={t(status)}
        variant="outlined"
      />
    );
  };

  const comp = (item) => {
    if (dueDocumentDates && dueDocumentDates[item.id]) {
      return -1;
    }
    return 1;
  };

  const changeVisible = (aId: number, type: boolean) => {
    updateAttachmentRequest({
      variables: {
        id: aId,
        visible: type,
      },
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    }).then(() => {
      if (type) {
        enqueueSnackbar(t("SuccessfullyShow"), {
          variant: "info",
        });
      } else {
        enqueueSnackbar(t("SuccessfullyHide"), {
          variant: "info",
        });
      }
    });
  };

  const rows = attachments.sort(comp).map((attach) => {
    const { title, description } = attach;
    const isHigh = dueDocumentDates[attach.id];
    const status = attach?.status;
    const dueDate = attach?.due_date
      ? dateIsoFa(attach?.due_date) : "-";
    const dateUpload = (attach?.upload_date
      ? dateIsoFa(attach?.upload_date) : "") || "-";

    const acceptDate = (attach?.approve_date
      ? dateIsoFa(attach?.approve_date) : "") || "-";

    const viDiv = (
      attach.visible
        ? (
          <IconButton
            disabled={responseUpdateAttachmentRequest.loading}
            onClick={() => changeVisible(attach.id, false)}
          >
            <IconVisible />
          </IconButton>
        )
        : (
          <IconButton
            disabled={responseUpdateAttachmentRequest.loading}
            onClick={() => changeVisible(attach.id, true)}
          >
            <IconNoVisible />
          </IconButton>
        )
    );

    return {
      ...attach,
      title,
      description,
      status,
      dueSend: dueDate,
      acceptDate,
      dateUpload,
      visibleClient: viDiv,
      file: attach?.file,
      isHigh,
      sample_file: attach.sample_file,
    };
  });

  const setDueDate = (e, r) => {
    setOpenDueDate(r);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("title")}</TableCell>
              {/* <TableCell>{t("description")}</TableCell> */}
              <TableCell>{t("status")}</TableCell>
              <TableCell width="120">{t("dueSend")}</TableCell>
              <TableCell width="120">{t("dateUpload")}</TableCell>
              <TableCell width="120">{t("acceptDate")}</TableCell>
              {!isCustomer ? <TableCell width="120">{t("visibleClient")}</TableCell> : null}
              <TableCell width={isCustomer ? "80" : "160"}>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} className={isCustomer && !row.visible ? "doc-lock" : undefined}>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                {/* <TableCell>{row.description}</TableCell> */}
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {statusIcon(row.status)}
                    {row.status === "rejected" ? (
                      <IconButton onClick={() => setOpenReason(row.description)}>
                        <AlertIcon fontSize="large" color="secondary" />
                      </IconButton>
                    ) : ""}
                  </div>
                </TableCell>
                <TableCell className="ltr">
                  {row.dueSend}
                  {!isCustomer ? (
                    <IconButton
                      onClick={(e) => setDueDate(e, row)}
                    // disabled={!isFileOnServer(row.file)}
                      size="small"
                    >
                      <EditIcon fontSize="small" color="disabled" />
                    </IconButton>
                  ) : ""}

                </TableCell>
                <TableCell className="ltr">{row.dateUpload}</TableCell>
                <TableCell className="ltr">{row.acceptDate}</TableCell>
                {!isCustomer ? <TableCell className="ltr">{row.visibleClient}</TableCell> : null}
                <TableCell style={{ width: "100px" }}>
                  {isFileOnServer(row.file) ? (
                    <IconButton
                      onClick={(e) => downloadFile(e, row.file)}
                      disabled={!isFileOnServer(row.file) || (isCustomer && row.status === "accepted")}
                      size="small"
                    >
                      <CloudDownload />
                    </IconButton>
                  ) : "" }
                  {!isCustomer && row.type === "LINK" ? (
                    <IconButton size="small" onClick={(e) => handleDialogSetLink(e, row)}>
                      <SetIcon />
                    </IconButton>
                  ) : "" }

                  <IconButton
                    // onClick={(e) => handleUploadFile(e, row)}
                    onClick={(e) => handleOpenDialogUploadFile(e, row)}
                    disabled={row.status === "accepted" || (isCustomer && !row.visible)}
                    size="small"
                  >
                    <AddBoxOutlined />
                  </IconButton>

                  {!isCustomer ? (
                    <IconButton
                      disabled={row.status === "accepted"}
                      onClick={() => handleConfirmDialog(row)}
                      size="small"
                    >
                      <Check />
                    </IconButton>
                  ) : ""}

                  {!isCustomer ? (
                    <IconButton
                      disabled={row.status === "accepted"}
                      onClick={() => handleConfirmDialogDelete(row)}
                      size="small"
                    >
                      <Delete />
                    </IconButton>
                  ) : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openDialogUploadFile ? (
        <FormDialogUploadFile
          open={openDialogUploadFile}
          setOpen={handleOpenDialogUploadFile}
          handleUploadFile={handleUploadFile2_}
          dataRow={selectedAttachment}
        />
      ) : ""}
      {openDialogConfirm ? (
        <DialogApproveDocument
          open={openDialogConfirm}
          handleClose={handleConfirmDialog}
          selectedAttachment={selectedAttachment}
        />
      ) : ""}

      {}

      {openDueDate ? (
        <DialogSetDueDate
          open={openDueDate}
          setOpen={setOpenDueDate}
          dueDocumentDates={dueDocumentDates}
          requestSID={requestSID}
        />
      ) : ""}

      {openDialogDeleteConfirm ? (
        <DialogDeleteAttachment
          open={openDialogDeleteConfirm}
          setOpen={setOpenDialogDeleteConfirm}
        />
      ) : ""}

      {openSetAttachment ? (
        <DialogSetLinkAttachment
          open={openSetAttachment}
          setOpen={setOpenSetAttachment}
          dataRow={selectedAttachment}
          requestId={requestId}
        />
      ) : ""}

      {openReason ? (
        <DialogBase
          options={{ title: t("RejectReason") }}
          setOptions={() => {}}
          hide
          open={openReason}
          setOpen={setOpenReason}
          hideFooter
          className="dialogError"
        >
          <div className={classes.rejectArea}>
            {openReason}
          </div>
        </DialogBase>
      ) : ""}
    </>
  );
}
