import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { useParams } from "react-router-dom";
import AttachFile from "@material-ui/icons/AttachFile";
import IconNote from "@material-ui/icons/Edit";
import { CREATE_NOTE, GET_CONTRACT_NOTES } from "./ContractNotesQueries";
import { GET_ME } from "../../app/layout/layoutQueries";
import { dateIsoFa3 } from "../../app/utils/helper";

const ContractInbox = () => {
  const [note, setNote] = useState("");
  const { t } = useTranslation();
  const [userType, setUserTypes] = useState([]);
  const RADOM_COLOR = ["#edf2ef", "#d0f4de", "#bfdbf7"];

  const getColor = (userId: string) => {
    const currentIndex = userType.findIndex((item) => item === userId);
    if (currentIndex >= 0) {
      return RADOM_COLOR[currentIndex];
    }
    return RADOM_COLOR[userType.length];
  };

  const [createQuery, createTicketResponse] = useMutation(CREATE_NOTE);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Me = useQuery(GET_ME);
  const uId = _.get(Me, "data.me.id");
  const params = useParams();
  const requestId = _.get(params, "id");
  const getTicketQuery = useQuery(GET_CONTRACT_NOTES, {
    variables: { requestId: Number(requestId) },
  });

  const handleSave = () => {
    createQuery({
      variables: {
        content: note,
        userId: uId,
        requestId,
      },
      refetchQueries: ["GET_CONTRACT_NOTES"],
    })
      .then((result) => {
        setNote("");
      })
      .catch((e) => {
        enqueueSnackbar(t("ErrorHappened"), {
          variant: "error",
        });
      });
  };

  let j = -1;

  return (
    <div className="inbox">
      <Card style={{ backgroundColor: "#fffde9" }}>
        <CardHeader
          title={(
            <div style={{ alignItems: "center", display: "flex", gap: "4px" }}>
              <IconNote />
              <span>{t("ContractNotes")}</span>
            </div>
          )}
        />
        <CardContent>
          <div className="inbox__message">
            <ul>
              {!getTicketQuery.data?.contractNotes?.length ? (
                <li
                  style={{
                    textAlign: "center",
                    border: "1px solid #eee",
                    padding: "10px",
                  }}
                >
                  {t("pleaseWriteNote")}
                </li>
              ) : (
                ""
              )}
              {getTicketQuery.data?.contractNotes?.map((item, i) => {
                if (j > 2) {
                  j = 0;
                } else {
                  j += 1;
                }
                return (
                  <li>
                    <span style={{
                      backgroundColor: getColor(
                        item?.user_id?.id || item?.user?.id,
                      ),
                    }}
                    >
                      <div>{item.content}</div>
                      <div className="footer-message">
                        <i>
                          {item?.type === "customer"
                            ? t("CLIENT")
                            : item?.user_id?.name}
                        </i>
                        {" "}
                        |
                        <i>{dateIsoFa3(item.created_at)}</i>
                      </div>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="inbox__button" style={{ display: "flex", gap: 5 }}>
            <IconButton disabled className="btn-attachment">
              <AttachFile />
            </IconButton>
            <TextField
              type="text"
              value={note}
              onChange={(e: any) => setNote(e?.target?.value)}
              variant="outlined"
              size="small"
              style={{ flexGrow: 1 }}
              placeholder={t("WriteANote")}
            />
            <Button
              onClick={handleSave}
              variant="outlined"
              disabled={!note}
              style={{ lineHeight: 1.5 }}
            >
              {t("SEND")}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ContractInbox;
