import { gql } from "@apollo/client";

export const CREATE_REQUEST = gql`  
mutation CreateRequest(
    $name: String!,
    $name_en: String, 
    $family: String!, 
    $family_en: String, 
    $email: String!, 
    $phone: String!, 
    $national_number: String!, 
    $phone_home: String, 
    $gender: ENUM_REQUEST_GENDER!, 
    $birthday: DateTime!, 
    $address: String!, 
    $address_en: String, 
    $has_schengen_visa: Boolean!, 
    $has_disease: Boolean!, 
    $criminal_record: Boolean!, 
    $soldiery: ENUM_REQUEST_SOLDIERY!, 
    $target_canada: ENUM_REQUEST_TARGET_CANADA!, 
    $married: ENUM_REQUEST_MARRIED!,
    $family_requested: Boolean!,
    $family_requested_description: String, 
    $number_childs: Int,
    $userId: ID!
    ){
    createRequest(input: {
      data:{
        name: $name,
        name_en: $name_en,
        family: $family,
        family_en: $family_en,
        email: $email,
        national_number: $national_number,
        phone: $phone,
        phone_home: $phone_home,
        gender: $gender,
        birthday: $birthday,
        address: $address,
        address_en: $address_en,
        has_schengen_visa: $has_schengen_visa,
        has_disease: $has_disease,
        criminal_record: $criminal_record,
        soldiery: $soldiery,
        target_canada: $target_canada,
        married: $married,
        family_requested: $family_requested,
        family_requested_description: $family_requested_description,
        number_childs: $number_childs,
        userId: $userId
      }
    }){
      request{
        id
      }
    }
  }
`;

export const CREATE_REQUEST_FOR_USER = gql`
mutation CreateRequest(
  $userId: ID!,
  $name: String!,
  $family: String!, 
  $email: String!, 
  $phone: String!, 
  $address: String!, 
  ){ 
    createRequest(input: {
      data:{
        userId: $userId
        name: $name
        family: $family,
        email: $email,
        phone: $phone,
        address: $address,
      }
    }){
      request{
        id
      }
    }
}
`;

export const UPDATE_REQUEST = gql`  
mutation UpdateRequest(
    $requestId: ID!,
    $name: String, 
    $name_en: String, 
    $family: String, 
    $family_en: String, 
    $father: String, 
    $father_en: String, 
    $email: String,
    $phone: String,
    $national_number: String,
    $phone_home: String,
    $gender: ENUM_REQUEST_GENDER,
    $birthday: DateTime,
    $address: String,
    $address_en: String,
    $has_schengen_visa: Boolean,
    $has_disease: Boolean,
    $criminal_record: Boolean,
    $soldiery: ENUM_REQUEST_SOLDIERY,
    $target_canada: ENUM_REQUEST_TARGET_CANADA,
    $married: ENUM_REQUEST_MARRIED,
    $family_requested: Boolean,
    $family_requested_description: String,
    $number_childs: Int,
    $degree_edu: ENUM_REQUEST_DEGREE_EDU,
    $field_study: String,
    $field_study_en: String,
    $date_start_edu: Date,
    $date_end_edu: Date,
    $total_assets: String,
    $type_job: Boolean,
    $is_working: Boolean,
    $work_description: String,
    $work_description_en: String,
    $job_state: ENUM_REQUEST_JOB_STATE,
    $insurance_history: String,
    $ielts: Boolean,
    $tef: Boolean,
    $ietls_score: JSON,
    $tef_score: JSON,
    $partner_name: String,
    $partner_name_en: String,
    $partner_field_study: String,
    $partner_field_study_en: String,
    $partner_birthday: Date,
    $partner_degree: ENUM_REQUEST_PARTNER_DEGREE,
    $partner_job: ENUM_REQUEST_PARTNER_JOB,
    $partner_insurance: Int,
    $partner_ielts: Boolean,
    $partner_tef: Boolean,
    $partner_ielts_score: JSON,
    $partner_tef_score: JSON,
    $language_level: ENUM_REQUEST_LANGUAGE_LEVEL,
    $family_live_canada: String,
    $type_find_us: String,
    $message: String,
    $is_error_fixed: Boolean,
    $visa: ID,
    $childs: JSON
    ){
    updateRequest(input: {
      where: {id: $requestId},
      data:{
        name: $name,
        name_en: $name_en,
        family: $family,
        family_en: $family_en,
        father: $father,
        father_en: $father_en,
        email: $email,
        phone: $phone,
        national_number: $national_number,
        phone_home: $phone_home,
        gender: $gender,
        birthday: $birthday,
        address: $address,
        address_en: $address_en,
        has_schengen_visa: $has_schengen_visa,
        has_disease: $has_disease,
        criminal_record: $criminal_record,
        soldiery: $soldiery,
        target_canada: $target_canada,
        married: $married,
        family_requested: $family_requested,
        family_requested_description: $family_requested_description,
        number_childs: $number_childs,
        degree_edu: $degree_edu,
        field_study: $field_study,
        field_study_en: $field_study_en,
        date_start_edu: $date_start_edu,
        date_end_edu: $date_end_edu,
        total_assets: $total_assets,
        type_job: $type_job,
        is_working: $is_working,
        work_description: $work_description,
        work_description_en: $work_description_en,
        job_state: $job_state,
        insurance_history: $insurance_history,
        ielts: $ielts,
        tef: $tef,
        ietls_score: $ietls_score,
        tef_score: $tef_score,
        partner_name: $partner_name,
        partner_name_en: $partner_name_en,
        partner_field_study: $partner_field_study,
        partner_field_study_en: $partner_field_study_en,
        partner_birthday: $partner_birthday,
        partner_degree: $partner_degree,
        partner_job: $partner_job,
        partner_insurance: $partner_insurance,
        partner_ielts: $partner_ielts,
        partner_tef: $partner_tef,
        partner_score_ielts: $partner_ielts_score,
        partner_score_tef: $partner_tef_score,
        language_level: $language_level,
        family_live_canada: $family_live_canada,
        type_find_us: $type_find_us,
        message: $message,
        is_error_fixed: $is_error_fixed,
        visa: $visa,
        childs: $childs,
      }
    }){
      request{
        id
      }
    }
  }
`;

export const UPDATE_STATE_REQUEST = gql`
mutation UPDATE_STATE_REQUEST($reqId: ID, 
    $status: String, 
    # $statusMessage: String, 
    $reqSId: ID!,
    $reportMessage: String,
    $visas: [ID],
    $consultantTime: ID,
    $contractText: String,
    $contractTextEn: String,
    $signatureAdmin: String,
    $signatureUser: String,
    $contractPriceDollar: Long,
    $attachmentsDocument: [ID],
    $dueDocumentDates: JSON,
    $confirmContracts: JSON,
    $selectedVisa: ID,
    $lockSteps: JSON,
    $notification: JSON,
    $current_step: ENUM_REQUESTSTATUS_CURRENT_STEP,
    ){
  updateRequestStatus(input: {
    data: {
      status: $status
      requestId: $reqId
      report_message: $reportMessage
      visas: $visas,
      consultant_time: $consultantTime,
      contract_text: $contractText
      contract_text_en: $contractTextEn
      signature_user: $signatureUser
      signature_admin: $signatureAdmin
      contract_price_dollar: $contractPriceDollar
      attachments_document: $attachmentsDocument
      due_document_dates: $dueDocumentDates
      confirm_contracts: $confirmContracts
      selected_visa: $selectedVisa
      lock_steps: $lockSteps
      notification: $notification
      current_step: $current_step
    }
    where: {
      id: $reqSId
    }
  }){
    requestStatus{
      id
    }
  }
}
`;

export const UPDATE_REQUEST_ASSIGN_OPERATOR = gql`
mutation UPDATE_REQUEST_ASSIGN_OPERATOR(
  $reqId: ID!, 
  $operatorId: ID
    ){
  updateRequest(input: {
    data: {
      operator: $operatorId
    }
    where: {
      id: $reqId
    }
  }){
    request{
      id
    }
  }
}
`;

export const CREATE_STATE_REQUEST = gql`
mutation CREATE_STATE_REQUEST($reqId: ID, $status: String!){
  createRequestStatus(input: {
    data: {
      requestId: $reqId,
      status: $status,
      current_step: STEP_INFORMATION
    }
  }){
    requestStatus{
      id
    }
  }
}
`;

export const CREATE_REQUEST_ATTACHMENT_FILE = gql`
mutation CREATE_REQUEST_ATTACHMENT_FILE(
  $title: String,
  $requestId: ID,
  $file: [ID],
  $section: String,
  $docId: String,
  $url: String,
  $status: ENUM_REQUESTATTACHMENT_STATUS,
  ){
  createRequestAttachment(input: {
    data: {
      title: $title
      request: $requestId
      file: $file
      section: $section
      doc_id: $docId
      status: $status
      link: $url
    }
  }){
    requestAttachment{
      id
      created_at
      updated_at
      title
      request {
        id
      }
      section
      status
      file{
        id
        name
        alternativeText
        caption
        width
        url
      }
    }
  }
}
`;

export const UPDATE_REQUEST_ATTACHMENT_FILE = gql`
mutation UPDATE_REQUEST_ATTACHMENT_FILE(
  $id: ID!,
  $title: String,
  $requestId: ID,
  $file: [ID],
  $section: String,
  $docId: String,
  $status: ENUM_REQUESTATTACHMENT_STATUS,
  $url: String,
  $upload_date: DateTime,
  $approve_date: DateTime,
  $visible: Boolean
  $description: String
  ){
  updateRequestAttachment(input: {
    where:{
      id:$id
    }
    data: {
      upload_date: $upload_date
      approve_date: $approve_date
      title: $title
      request: $requestId
      file: $file
      section: $section
      doc_id: $docId
      status: $status
      link: $url
      visible: $visible
      description: $description
    }
  }){
    requestAttachment{
      id
      created_at
      updated_at
    }
  }
}
`;

export const GET_REQUEST_ATTACHMENTS = gql`
query GET_REQUEST_ATTACHMENTS($reqId: ID, $section: String){
  requestAttachments(
      sort: "updated_at:desc,visible:desc"
      where: {
        request: $reqId
        section: $section
      }
    ){
    id
    created_at
    updated_at
    title
    status
    description
    attachment {
      id
      title
      description
      type
      type_document
      sample_file {
        id
        created_at
        updated_at
        name
        ext
        url
      }
    }
    file {
      id
      created_at
      updated_at
      name
      caption
      width
      height
      formats
      mime
      size
      url
      previewUrl
      ext
    }
    visible
    link
    doc_id
    due_date
    upload_date
    approve_date
  }
}
`;

export const UPDATE_REQUEST_ATTACHMENT = gql`
mutation UPDATE_REQUEST_ATTACHMENT($attachmentId: ID!, $due_date: DateTime){
  updateRequestAttachment(input: {
  data:{
    due_date: $due_date
  }
  where: {
    id:$attachmentId
  }
}){
  requestAttachment {
    id
  }
}
}
`;

export const DELETE_REQUEST_ATTACHMENT_FILE = gql`
mutation DELETE_REQUEST_ATTACHMENT_FILE($id:ID!){
  deleteRequestAttachment(input:{
    where:{
      id:$id
    }
  }){
    requestAttachment {
      id
      title
    }
  }
}
`;

export const ACCEPT_REQUEST_ATTACHMENT = gql`
mutation ACCEPT_REQUEST_ATTACHMENT($id: ID!, $status: ENUM_REQUESTATTACHMENT_STATUS){
  updateRequestAttachment(input: {
    where: {
      id: $id
    }
    data : {
      status: $status
    }
  }){
    requestAttachment {
      id
    }
  }
}`;

export const DOWNLOAD_CONTRACT_BY_REQUEST_ID = gql`
mutation DOWNLOAD_CONTRACT_BY_REQUEST_ID($id: String, $en: Boolean){
  downloadContract(id:$id, en: $en)
}
`;

export const GET_STATE_REQUEST = gql`
query GET_STATE_REQUEST($reqId: ID){
  requestStatuses(where:{
    requestId: $reqId
  }){
    id
    report_message
    contract_text
    contract_text_en
    signature_admin
    signature_user
    confirm_contracts
    lock_steps
  }
}
`;
