import React, { useMemo, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import PageTitle from "../../components/PageTitle";
import { GET_EVALUATION } from "./bookingsQueries";
import {
  clearObject,
  dateIsoFa2,
  priceFormat,
  timeIsoFa,
} from "../../app/utils/helper";

import DialogSetEvaluationStatus, {
  STATUS_BOOK,
} from "../../components/dialogs/DialogSetEvaluationStatus";
import DialogChangeBookingSession from "../../components/dialogs/DialogChangeBookingSession";
import DialogChangeBookingLocation from "../../components/dialogs/DialogChangeBookingLocation";

export default function Booking(props: any) {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const [openDialogSession, setOpenDialogSession] = useState<boolean>(false);
  const [openDialogLocation, setOpenDialogLocation] = useState<boolean>(false);
  const requestQuery = useQuery(GET_EVALUATION, {
    variables: {
      id: Number(params?.id),
    },
    fetchPolicy: "cache-and-network",
  });

  const data = _.get(requestQuery.data, "evaluation", {});

  const dataItems = useMemo(() => {
    const dataFiltered = clearObject(data, [
      "id",
      "__typename",
      "consultant_time",
      "consultant_location",
      "consultant_type",
      "status",
      "created_at",
      "updated_at",
      "feedback_description",
      "feedback_status",
      "rejection_description",
    ]);

    const results = _.keys(dataFiltered).map((key) => {
      if (key === "birthday") {
        return {
          label: key,
          value: data[key] ? dateIsoFa2(data[key]) : "-",
        };
      }
      if (key === "partner_birthday") {
        return {
          label: key,
          value: data.married === "married" ? dateIsoFa2(data[key]) : "-",
        };
      }
      if (key === "gender") {
        return {
          label: key,
          value: t(data[key]),
        };
      }
      if (key === "married") {
        return {
          label: "marriedStatus",
          value: t(data[key]),
        };
      }
      if (key === "status") {
        return {
          label: key,
          value: t(`statusCons.${data[key]}`),
        };
      }
      if (key === "degree_edu") {
        return {
          label: key,
          value: t(`${data[key]}`),
        };
      }
      if (key === "total_assets") {
        return {
          label: key,
          value: t(`${data[key]}`),
        };
      }
      if (key === "ielts") {
        return {
          label: key,
          value: t(`ieltsStatus.${data[key]}`),
        };
      }
      if (key === "tef") {
        return {
          label: key,
          value: t(`ieltsStatus.${data[key]}`),
        };
      }
      if (key === "partner_degree") {
        return {
          label: key,
          value: data[key] ? t(`${data[key]}`) : "-",
        };
      }
      if (key === "user") {
        return {
          label: "userId",
          value: data[key]?.id,
        };
      }
      if (key === "referer") {
        return {
          label: "referer",
          value: t(data[key]) || "-",
        };
      }
      if (key === "referer_source") {
        return {
          label: "referer_source",
          value: data[key] || "-",
        };
      }
      if (key === "country") {
        return {
          label: "country",
          value: t(`countryOptions.${data[key] || "Default"}`) || "-",
        };
      }
      return {
        label: key,
        value: data[key],
      };
    });
    results.push({
      label: "phone",
      value: data.user?.phone,
    });
    results.push({
      label: "email",
      value: data.user?.email,
    });
    return results;
  }, [data]);

  const consultantTime = useMemo(() => {
    const ct = data.consultant_time;
    const results: any = [];
    if (ct) {
      let v = {
        label: "dateEvent",
        value: dateIsoFa2(ct.start_time),
      };
      results.push(v);
      v = {
        label: "timeEvent",
        value: `${timeIsoFa(ct.start_time)} - ${timeIsoFa(ct.end_time)}`,
      };
      results.push(v);
      v = {
        label: "nameConsultant",
        value: ct.name,
      };
      results.push(v);

      let cv;
      if (ct.is_selectable) {
        cv = t("consultant_type.BOTH");
      } else if (ct.is_online) {
        cv = t("consultant_type.ONLINE");
      } else {
        cv = t("consultant_type.OFFLINE");
      }

      v = {
        label: "methodConsultant",
        value: cv,
      };
      results.push(v);
    }
    return results;
  }, [data]);

  const consultantType = useMemo(() => {
    const ct = data.consultant_type;
    const results: any = [];
    if (ct) {
      let v = {
        label: "price",
        value: priceFormat(ct.price, t(ct.currency)),
      };
      results.push(v);
      v = {
        label: "title",
        value: ct.title,
      };
      results.push(v);
    }
    return results;
  }, [data]);

  const onUpdateStatusEval = () => {
    document.getElementById("fab-change-status")?.click();
  };

  const onUpdateTimeSession = () => {
    setOpenDialogSession(true);
  };

  const onUpdateLocation = () => {
    setOpenDialogLocation(true);
  };

  const status = t(`statusCons.${data?.status}`);
  const dateCreated = dateIsoFa2(data?.created_at);
  const dateUpdated = dateIsoFa2(data?.updated_at);
  const rejectionDescription = data?.rejection_description;

  return (
    <>
      <PageTitle title={t("DetailBook")} />
      <Grid container spacing={4} className="booking bookings">
        {data?.status === "REJECTED" ? (
          <Grid item xs={12}>
            <Alert severity="error">{rejectionDescription}</Alert>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={8}>
          <Card>
            <CardHeader title={t("bookingInformation")} />
            <Grid container>
              {dataItems.map((item) => (
                <Grid item md={4} className="gridItem">
                  <div>
                    <b>
                      {t(item.label)}
                      :
                    </b>
                    <div className="gridItem__value">
                      {_.isString(item.value) || _.isNumber(item.value)
                        ? item.value
                        : "-"}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card title={t("bookingConsultantTime")}>
            <CardHeader title={t("bookingConsultantTime")} />
            {consultantTime.map((item: any) => (
              <Grid item md={12} className="gridItem">
                <div>
                  <b>
                    {t(item.label)}
                    :
                  </b>
                  {_.isString(item.value) && item.value}
                </div>
              </Grid>
            ))}
            {data?.feedback_status ? (
              <>
                <Grid item md={12} className="gridItem">
                  <div>
                    <b>
                      {t("feedbackStatusTitle")}
                      :
                    </b>
                    {t(data?.feedback_status)}
                  </div>
                </Grid>
                <Grid item md={12} className="gridItem">
                  <div>
                    <b>
                      {t("feedbackDescription")}
                      :
                    </b>
                    {data?.feedback_description}
                  </div>
                </Grid>
              </>
            ) : (
              false
            )}
          </Card>
          <br />

          <Card>
            <CardHeader title={t("bookingConsultantSetting")} />
            <CardContent>
              <div className="item__setting">
                <span>{t("created_at")}</span>
                {dateCreated}
              </div>
              <div className="item__setting">
                <span>{t("updated_at")}</span>
                {dateUpdated}
              </div>
              <div className="item__setting">
                <span>{t("status")}</span>
                <Chip
                  label={t(status)}
                  className={`status_button status__${data?.status}`}
                />
              </div>
              {data?.status !== STATUS_BOOK.DONE ? (
                <div className="item__setting">
                  <span>{t("ForUpdateStatus")}</span>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onUpdateStatusEval}
                    size="small"
                  >
                    {t("UpdateStatus")}
                  </Button>
                </div>
              ) : (
                false
              )}
              {data?.status !== STATUS_BOOK.DONE ? (
                <div className="item__setting">
                  <span>{t("ForUpdateTimeClick")}</span>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onUpdateTimeSession}
                    size="small"
                  >
                    {t("ChangeTime")}
                  </Button>
                </div>
              ) : (
                false
              )}

              <div className="item__setting">
                <span>{t("consultantMethod")}</span>
                <Button
                  variant="outlined"
                  color="default"
                  size="small"
                  onClick={onUpdateLocation}
                >
                  {data?.consultant_location
                    ? t(`consultant_type.${data?.consultant_location}`)
                    : t("consultant_type.ONLINE")}
                </Button>
              </div>
              {/* <div className="item__setting">
                <span>{t("ForCancelSessionClick")}</span>
                <Button variant="outlined" color="secondary"
                disabled size="small">{t("CancelSession")}</Button>
              </div> */}
            </CardContent>
          </Card>

          <br />
          <Card title={t("bookingConsultantType")}>
            <CardHeader title={t("bookingConsultantType")} />
            {consultantType.map((item: any) => (
              <Grid item md={12} className="gridItem">
                <div>
                  <b>
                    {t(item.label)}
                    :
                  </b>
                  {_.isString(item.value) && item.value}
                </div>
              </Grid>
            ))}
          </Card>
        </Grid>
      </Grid>
      <DialogSetEvaluationStatus evalId={params.id} />
      <DialogChangeBookingSession
        open={openDialogSession}
        setOpen={setOpenDialogSession}
      />
      <DialogChangeBookingLocation
        open={openDialogLocation}
        setOpen={setOpenDialogLocation}
      />
    </>
  );
}
