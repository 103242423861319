import React, { useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import SelectBox from "../micro/SelectBox";
import TimePickerComponent from "../micro/TimePicker";
import DateRangePicker from "../micro/DateRangePicker";
import {
  ADD_CONSULTANT_TIME_BATCH,
  GET_CONSULTANT_TYPES,
  GET_USERS,
} from "../../pages/Profile/profileQueries";
import statics from "../../app/statics";
import { getUTCtime } from "../../app/utils/helper";

export default function DialogAddNewTime(props: any) {
  const { t } = useTranslation();
  const {
    open, setOpen, options, setOptions,
  } = props;
  const [nameConsultant, setNameConsultant] = useState<string>();
  const [dateEvent, setDateEvent] = useState<[any]>();
  const [startTime, setStartTime] = useState<string>(null);
  const [endTime, setEndTime] = useState<string>(null);
  const [consultantType, setConsultantType] = useState<string>(null);
  const [consultantMethod, setConsultantMethod] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState(0);
  const [rows, setRows] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const queryGetAdviser = useQuery(GET_USERS, {
    variables: {
      role: statics.roleType[0].value,
    },
  });

  const adviserOptions = _.get(queryGetAdviser, "data.users", []).map(
    (item) => ({ value: item.id, label: item.name }),
  );

  const quGetConsultantTypes = useQuery(GET_CONSULTANT_TYPES, {
    variables: { limit: 100 },
  });

  const [muAddConsTime] = useMutation(ADD_CONSULTANT_TIME_BATCH, {
    refetchQueries: [],
  });

  const consultantTypeOptions = useMemo(() => {
    let results = [];
    results = quGetConsultantTypes.data?.consultantTypes?.map((item: any) => ({
      value: item.id,
      label: item.title,
    }));
    return results;
  }, [quGetConsultantTypes.data]);

  const consultantMethodOptions = useMemo(() => {
    let results = [];
    results = _.keys(statics.consultantMethod)?.map((key: any) => ({
      value: statics.consultantMethod[key],
      label: t(`consultant_type.${key}`),
    }));
    return results;
  }, [quGetConsultantTypes.data]);

  const handleClose_ = () => {
    setOpen();
  };

  const handleAddTime = () => {
    if (
      !startTime
      || !endTime
      || !dateEvent
      || !nameConsultant
      || !consultantType
    ) {
      enqueueSnackbar(t("fillComplete"), {
        variant: "error",
      });
      return;
    }

    // const stUtc = getUtcFormat(startTime);
    const stUtc = getUTCtime(startTime);
    const enUtc = getUTCtime(endTime);

    let isOnline = true;
    if (consultantMethod === "false") {
      isOnline = false;
    }
    const isSelectable = consultantMethod === "BOTH";

    muAddConsTime({
      variables: {
        name: adviserOptions.find((i) => i.value === nameConsultant).label,
        nameEn: "",
        startTime: stUtc, // moment(sTime).locale("en").format(),
        endTime: enUtc, // moment(eTime).locale("en").format(),
        dates: dateEvent,
        userId: nameConsultant,
        consultantType,
        isOnline,
        isSelectable,
      },
      refetchQueries: ["GET_CONSULTANT_TIMES"],
    }).then((r) => {
      const response = r?.data?.createBatchConsultantTimes;
      if (response === "OK") {
        enqueueSnackbar(t("SuccessfullyCreated"), {
          variant: "success",
        });
        handleClose_();
      } else {
        const errorMessage = _.get(response, "data[0].messages[0].message");
        enqueueSnackbar(t("errorTimeExist"), {
          variant: "error",
        });
      }
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={handleClose_}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("addConsultant")}</DialogTitle>
      <>
        <DialogContent style={{ paddingTop: 0, textAlign: "justify" }}>
          <Grid container spacing={1}>
            <Grid container spacing={4}>
              <SelectBox
                name="nameConsultant"
                value={nameConsultant}
                setValue={setNameConsultant}
                grid={12}
                items={adviserOptions}
              />
              <Grid item xs={6} md={12}>
                <div style={{ marginTop: "12px" }}>
                  <DateRangePicker setValue={setDateEvent} />
                </div>
              </Grid>
              {/* <DatePickerComponent
                    // handler="formik"
                    name="dateEvent"
                    value={dateEvent}
                    setValue={setDateEvent}
                    // formikI={formik}
                    grid={2}
                    required
                  /> */}
              <TimePickerComponent
                name="startTime"
                value={startTime}
                setValue={(e) => {
                  setStartTime(e);
                  // console.log("e------", );
                  setEndTime(
                    moment(e).locale("en").add(30, "minutes").format(),
                  );
                }}
                grid={12}
                required
              />
              <TimePickerComponent
                name="endTime"
                value={endTime}
                setValue={setEndTime}
                grid={12}
                required
                disabled
              />

              <SelectBox
                name="consultantType"
                value={consultantType}
                setValue={setConsultantType}
                grid={12}
                required
                items={consultantTypeOptions}
              />

              <SelectBox
                name="consultantMethod"
                value={consultantMethod}
                setValue={setConsultantMethod}
                grid={12}
                required
                items={consultantMethodOptions}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button> */}
          <Button variant="contained" color="primary" onClick={handleAddTime}>
            {t("Submit")}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
