/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  AppBar, Button, Grid, Theme,
  Toolbar, createStyles, makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import BackIcon from "@material-ui/icons/ArrowBackIosOutlined";
import PowerIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import LogoImage from "../../assets/images/logo.png";
import {
  GET_ANALYTIC_INFO, GET_ANALYTIC_INFO_CUSTOMER, GET_ME, UPDATE_USER_NOTIFICATIONS,
} from "./layoutQueries";
import { AppContext } from "./AppContext_";
import statics from "../statics";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#f7fcfe",
    boxShadow: "none",
    borderBottom: "1px solid #eee",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // color: "#000",
  },
  backBtn: {
    fontSize: "1.2em",
  },
  leftSide: {
    justifyContent: "flex-end",
    gap: "10px",
    display: "flex",
    "&>button": {
      border: "1px solid #b2b2b2",
      borderRadius: "5px",
    },
  },
}));

const AppBarCustomer = (props: any) => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const { loading, error, data } = useQuery(GET_ME);
  const [updateUserLogin, responseUpdateUserLogin] = useMutation(UPDATE_USER_NOTIFICATIONS, {
    refetchQueries: ["GET_ANALYTIC_INFO"],
  });

  const [open, setOpen] = React.useState(false);
  const [badge, setBadge] = React.useState(0);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  let analyticQuery = GET_ANALYTIC_INFO;
  const role = _.get(data, "me.role.name");
  const isCustomer = _.get(data, "me.role.name") === "Customer";

  if (role === "Customer") {
    analyticQuery = GET_ANALYTIC_INFO_CUSTOMER;
  }

  const getAnalyticInfo = useQuery(analyticQuery, {
    skip: !data?.me?.id,
    variables: {
      uId: data?.me?.id,
    },
    pollInterval: 10000,
  });
  // const analytic = _.get(getAnalyticInfo, "data.messagesConnection.aggregate");

  useEffect(() => {
    if (role === statics.roles.CUSTOMER) {
      const analytic_ = _.get(getAnalyticInfo, "data.userInfos[0]");
      setBadge(Number(analytic_?.notifications) || 0);
    } else {
      const configApp = _.get(getAnalyticInfo, "data.configApp");
      setBadge(Number(configApp?.NotificationsAdmin) || 0);
    }
  }, [getAnalyticInfo]);

  const changeLang = () => {
    const { isRTL } = appContext;
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
       anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("roleType");
    setTimeout(() => { window.location.href = `${process.env.REACT_APP_LANDING}/login?clc=true`; }, 500);
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, "app-bar__customer")}
    >
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={5} md={8}>
            <img
              onClick={changeLang}
              src={LogoImage}
              alt="x"
              style={{ width: "100%", maxWidth: "250px" }}
            />
          </Grid>
          <Grid item xs={5} md={4} className={classes.leftSide}>
            <Button
              onClick={logout}
              variant="text"
              className={classes.backBtn}
            >
              {t("Exit")}
              <PowerIcon fontSize="medium" />
            </Button>
            <Button
              onClick={() => { window.location.href = `${process.env.REACT_APP_LANDING}/account/contracts`; }}
              variant="text"
              className={classes.backBtn}
            >
              {t("ReturnToHome")}
              <BackIcon fontSize="medium" />
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarCustomer;
