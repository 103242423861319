import _, { isArray } from "lodash";
import { saveAs } from "file-saver";
import moment from "moment-jalaali";
import momentTz from "moment-timezone";
import { base64StringToBlob, createObjectURL } from "blob-util";
import parse from "html-react-parser";
import React from "react";
import { useLocation } from "react-router-dom";
import statics, { STEPS } from "../statics";

export const getMessageError = (error: any) => {
  // example: internal server error
  const code = _.get(error, "graphQLErrors[0].extensions.code");
  // example : 400
  const codeNumber = _.get(error, "graphQLErrors[0].extensions.exception.code");
  // example: Auth.form.error.invalid
  const messageId = _.get(
    error,
    "graphQLErrors[0].extensions.exception.data.message[0].messages[0].id",
  );
  // example: it`s wrong password
  const message = _.get(
    error,
    "graphQLErrors[0].extensions.exception.data.message[0].messages[0].message",
  );

  return {
    code,
    codeNumber,
    messageId,
    message,
  };
};

export const Boolean_ = (v) => {
  if (v === "true") return true;
  return false;
};

export const convertToSlug = (Text) => {
  if (Text) {
    let titleStr = Text.replace(/^\s+|\s+$/g, "");
    titleStr = titleStr.toLowerCase();
    // persian support
    titleStr = titleStr
      .replace(/[^a-z0-9_\s-ءاأإآؤئبتثجحخدذرزسشصضطظعغفقكلمنهويةى]#u/, "")
      // Collapse whitespace and replace by -
      .replace(/\s+/g, "-")
      // Collapse dashes
      .replace(/-+/g, "-");
    return titleStr;
  }
  return Text;
};

export const makeRandomCode = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const forceDownloadFile = (url, name) => {
  saveAs(url, name);
  // const link = document.createElement("a");
  // link.href = url;
  // link.setAttribute(
  //   "download",
  //   name,
  // );
  // // Append to html link element page
  // document.body.appendChild(link);
  // // Start download
  // link.click();
  // // // Clean up and remove the link
  // link.parentNode.removeChild(link);
};

export const GetFakeRequestId = (id) => {
  const BaseSecurityId = 101010524;
  return Number(id) + BaseSecurityId;
};

export const getStatePayment = (t, lastPay: any) => {
  if (lastPay.confirmed === null) {
    return `- ${t("waitConfirm")}`;
  }
  if (lastPay.confirmed === false) {
    return `- ${t("rejected")}`;
  }
  return "";
};

export const getFakeRequestId = (id: number) => {
  const BaseSecurityId = 101010524;
  return Number(id) + BaseSecurityId;
};

export const getFakeEvalId = (id, revert?) => {
  const BaseSecurityId = 2010;
  if (revert) {
    return Number(id) - BaseSecurityId;
  }
  return Number(id) + BaseSecurityId;
};

export const GetFakeInvoiceId = (id) => {
  const BaseSecurityId = 1023201;
  return Number(id) + BaseSecurityId;
};

export const hasAccessStep = (step, lockStates) => {
  if (!_.isNull(lockStates[step])) {
    return !lockStates[step];
  }
  return true;
};

export const mutateI = (
  mutate: any,
  t,
  enqueueSnackbar,
  message = "SuccessfullyCreated",
  callBack?: any,
) => {
  try {
    mutate()
      .then((r) => {
        enqueueSnackbar(t(message || "SuccessfullyCreated"), {
          variant: "success",
        });
        if (_.isFunction(callBack)) {
          callBack(r);
        }
      })
      .catch((e) => {
        // console.log("e-------", JSON.stringify(e), e?.graphQLErrors);
        let errorsMSG = e?.graphQLErrors;
        if (isArray(errorsMSG)) {
          errorsMSG = errorsMSG[0].message;
        } else {
          errorsMSG = "ErrorHappened";
        }

        enqueueSnackbar(t(errorsMSG), {
          variant: "error",
        });
      });
  } catch (e) {
    enqueueSnackbar(t("ErrorHappened"), {
      variant: "error",
    });
  }
};

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented
 */
export const IntlReverse = (stringNumber, locale = "en") => {
  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp(`\\${thousandSeparator}`, "g"), "")
      .replace(new RegExp(`\\${decimalSeparator}`), "."),
  );
};

export const IntlFormat = (stringNumber, locale = "en") => Intl.NumberFormat().format(stringNumber);

// export const getU = () => localStorage.getItem("user");

export const getGrid = (grid: number): number[] => {
  const xs_ = 12;
  const md_ = 6;
  const lg_ = grid;
  return [xs_, md_, lg_];
};

export const nationalCodeRegex = /^[0-9]{10}$/g;

export const getFakeTicketId = (id) => {
  const BaseSecurityId = 1010000;
  return Number(id) + BaseSecurityId;
};

export const getRefNumber = (id) => {
  const id_ = id?.replace("A", "");
  return Number(id_) || id_;
};

export const getImageFromB64 = (b64Data, contentType = "image/png") => {
  try {
    const blob = base64StringToBlob(
      b64Data.replace("data:image/png;base64,", ""),
      contentType,
    );
    // const blobUrl = URL.createObjectURL(blob);
    const blobURL = createObjectURL(blob);
    return blobURL;
  } catch (e) {
    return "";
  }
};

export const clearObject = (obj, keys) => {
  const obj_ = { ...obj };
  _.keys(obj).filter((k) => {
    if (keys.find((l) => l === k)) {
      delete obj_[k];
    }
    return "t";
  });
  return obj_;
};
export default {};

export const priceFormat = (price: number, currency?: string) => {
  if (!price) return 0;
  const number_ = new Intl.NumberFormat().format(price);
  return `${number_} ${currency}`;
};

export const getStepIndex = (key) => _.keys(STEPS).findIndex((k) => k === key);

export const stripText = (text) => {
  const strippedString = text.replace(/(<([^>]+)>)/gi, "");

  return parse(strippedString);
};

export const useQueryParams = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const getUserRole = (data) => {
  const role = _.get(data, "data.me.role.name");
  const isCustomer = role === statics.roles.CUSTOMER;
  const isAgent = role === statics.roles.Agent;
  const isAgentMaster = role === statics.roles.AgentMaster;
  const isSecretary = role === statics.roles.Secretory;
  const isEditor = role === statics.roles.Editor;
  const isAdmin = role === statics.roles.Authenticated;
  return {
    isCustomer,
    role,
    isAgent,
    isAgentMaster,
    isSecretary,
    isEditor,
    isAdmin,
  };
};

export const kbToMb = (kb) => {
  if (!kb) return 0;
  if (kb < 1024) {
    return `${kb} KB`;
  }
  return `${(kb / 1024).toFixed(2)} MB`;
};

export const getRoutes = (routePermissions, allPermissions) => {
  if (!routePermissions) return true;
  return routePermissions?.find((key) => _.keys(allPermissions)?.find(
    (i) => i === key && allPermissions[key]?.find?.enabled,
  ));
};

// clean object from null or undefined values
export const cleanObject = (obj) => {
  const obj_ = { ...obj };
  Object.keys(obj_).forEach((key) => {
    if (obj_[key] === null || obj_[key] === undefined || obj_[key] === "") {
      delete obj_[key];
    }
  });
  return obj_;
};

/**
 * TIME
 */
// ----> iran to utc
export const convertJTU = (date, format?) => {
  const tZone = momentTz.tz("Asia/Tehran").format("Z");
  const utcTime = moment.utc(`${date}+${tZone}`, "jYYYY-jMM-jDDTHH:mmZ");
  const output = utcTime.format(format);
  return output;
};

// ----> utc to iran
export const convertUTJ = (date, format?) => {
  const a = momentTz.utc(date).tz("Asia/Tehran");
  const b = moment(a).format(format);
  return b;
};

// 2023-11-08T12:00+03:30 -> 2023-11-08T08:30:00Z
export const getUTCtime = (time) => {
  const a = moment(time).format("YYYY-MM-DDTHH:mm+03:30");
  return moment(a).utc().format();
};

export const timeUtcToLocalZone = (dateTime) => momentTz.tz(dateTime, "Asia/Tehran");
export const getUtcFormat = (dateTime) => moment(dateTime).locale("en").utc().format();

export const getDateTime = (date_: string, time_: string) => {
  if (date_) {
    const dt_ = moment(date_).locale("en").format("jYYYY-jMM-jDD");
    const ti_ = time_.match(/\d+:\d+/g);
    return `${dt_} ${ti_}`;
  }
  return "";
};

export const momentx = (date, defaultZone = "Asia/Tehran") => {
  const defaultZone_ = localStorage.getItem("zone") || defaultZone;
  const x = momentTz.tz(date, defaultZone_);
  return moment(x);
};

export const dateTimeIso8061 = (date) => momentx(date).locale("en").format("YYYY-MM-DD HH:mm");
export const dateTimeIso8061_ = (date) => momentx(date).locale("en").format();
export const dateIso = (date) => momentx(date).locale("en").format("YYYY-MM-DD");
export const timeIso = (date) => momentx(date).locale("en").format("HH:mm");

export const dateIsoFa = (date) => momentx(date).locale("fa").format("jYYYY/jMM/jDD dddd");
export const dateIsoFa3 = (date) => momentx(date).locale("fa").format("jYYYY/jMM/jDD HH:mm");
export const dateIsoFa2 = (date) => `${momentx(date).locale("fa").format("jYYYY/jMM/jDD")}`;
export const dateIsoFa2En = (date) => momentx(date).locale("en").format("jYYYY/jMM/jDD");

export const timeIsoFa = (date) => momentx(date).locale("fa").format("HH:mm");
