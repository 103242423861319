import React, { useState } from "react";

export type DEFAULT = {
  isRTL: boolean,
  setOptions: (v: any) => void,
};
const DEFAULT_V = {
  isRTL: true,
  setOptions: () => {},
};
// eslint-disable-next-line import/prefer-default-export
export const AppContext = React.createContext<DEFAULT>(DEFAULT_V);

const AppContextCore = (props: any) => {
  const { children } = props;
  const [options, setOptions] = useState<DEFAULT>(DEFAULT_V);

  return (
    <AppContext.Provider value={{ ...options, setOptions }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextCore;
