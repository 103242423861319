import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import SelectBox from "./micro/SelectBox";
import { GET_ATTACHMENTS } from "../pages/Attachment/attachmentsQueries";
import DatePickerComponent from "./micro/Datepicker";
import { CREATE_ATTACHMENT_REQUEST } from "../pages/Request/requestQueries";

type IProps = {
    openDialog?: boolean,
    title: string,
    handleClose: Function,
    attachments: any,
    requestId: string,
    // attachmentsThisRequest: any[],
    // dueDocumentDates?: any
    documentProps?: any
}

const useStyles = makeStyles((theme: Theme) => createStyles({
}));

export default function FormDialogNewAttachment(props: IProps) {
  const {
    openDialog, title,
    handleClose,
    attachments,
    requestId,
    documentProps,
  } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(openDialog);
  const [dueDate, setDueDate] = React.useState(null);
  const [document, setDocument] = React.useState<string>(documentProps?.document);
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isBeforeAdded = (attach) => {
    const r = attachments.find((m) => `${m.id}` === `${attach.id}`);
    return r;
  };
  const attachmentsQuery = useQuery(GET_ATTACHMENTS, { variables: { sort: "id" } });

  const attachmentsOptions = attachmentsQuery
    ?.data?.attachments?.filter((attach) => !isBeforeAdded(attach))
    .map((attach) => ({
      value: attach.id,
      label: attach.title,
    }));

  const [addRequestAttachment, responseAddRequestAttachment] = useMutation(
    CREATE_ATTACHMENT_REQUEST,
    {
      refetchQueries: [
        "GET_REQUEST_ATTACHMENTS",
      ],
    },
  );

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose_ = () => {
    setOpen(false);
    handleClose(false);
  };

  const handleSave = () => {
    try {
      const selectedAttach = attachmentsOptions.find((item) => item.value === document);
      addRequestAttachment({
        variables: {
          request: Number(requestId),
          attachment: document,
          title: selectedAttach.label,
          visible: true,
          due_date: dueDate,
          section: "document",
        },
      });
    } catch (e: any) {
      console.log("e----");
    }
    handleClose_();
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      // fullWidth
      fullWidth
      onClose={handleClose_}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t(title)}</DialogTitle>
      <DialogContent>
        <SelectBox
          name="document"
          value={document}
          setValue={setDocument}
          grid={12}
          items={attachmentsOptions}
          // readOnly={documentProp}
        />
        <DatePickerComponent
          name="dueDateDoc"
          value={dueDate}
          required
          setValue={setDueDate}
          grid={12}
        />
        <br />
        <br />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose_} color="primary">
          {t("Cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="outlined">
          {t("Add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormDialogNewAttachment.defaultProps = {
  openDialog: false,
  documentProps: null,
};
