import { Theme, createStyles, makeStyles } from "@material-ui/core";
import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line unused-imports/no-unused-imports
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    marginRight: theme.spacing(1),
  },
  wrapper: {
    // minHeight: "200px",
    border: "1px solid #eee",
    // maxWidth: "800px",
  },
  editor: {
    minHeight: "200px",
    padding: "10px",
  },
}));

const InputEditor2 = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    dir, label, value, setValue,
    height,
  } = props;
  const editorRef = useRef(null);
  const onChange = () => {
    setValue(editorRef.current.getContent());
  };

  return (
    <>
      <p>{t(label)}</p>
      <Editor
        apiKey="5blcre0z9ouzjwn1b1qddh1ak476lqgbqfu6ro1zz718iojw"
        onInit={(evt, editor) => { editorRef.current = editor; }}
        initialValue={value || ""}
        onChange={onChange}
        init={{
          height: height || 500,
          menubar: true,
          directionality: dir || "ltr",
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar: "undo redo | formatselect | "
           + "bold italic backcolor | alignleft aligncenter "
           + "alignright alignjustify | bullist numlist outdent indent | "
           + "removeformat | help",
          content_style: "body { font-family:Tahoma,Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      {/* <Button onClick={log}>Log editor content</Button> */}
    </>
  );
};
export default InputEditor2;
