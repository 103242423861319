import React, { useMemo, useState } from "react";
import {
  Button, Fab, Grid, IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/PostAddOutlined";
import UserIcon from "@material-ui/icons/AssignmentInd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment-jalaali";
import _ from "lodash";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { DELETE_REQUEST, GET_REQUESTS } from "./requestsQueries";
import { GET_ME } from "../../app/layout/layoutQueries";
import TableAdvance from "../../components/micro/TableAdvance";
import InputBox from "../../components/micro/InputBox";
import SelectBox from "../../components/micro/SelectBox";
import DialogAssignContract from "../../components/dialogs/DialogAssignContract";
import "./index.scss";
import { STEPS, STEP_STATUS } from "../../app/statics";
import DialogAssignToOperator from "../../components/dialogs/DialogAssignToOperator";
import { cleanObject, getUserRole, useQueryParams } from "../../app/utils/helper";
import DatePickerComponent from "../../components/micro/Datepicker";
import IconExpand from "../../components/micro/IconExpand";
import DialogSendAlertGroup from "../../components/dialogs/DialogSendAlertGroup";
import SelectOperator from "../../components/micro/SelectOprator";

export default function Requests(props: any) {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [historySearch, setHistorySearch] = useState();
  const [openFormAssignContract, setOpenFormAssignContract] = useState<boolean>(false);
  const [openFormAssignToOperator, setOpenFormAssignToOperator] = useState<boolean>(false);
  const [openGroupSend, setOpenGroupSend] = useState(false);

  const nav = useQueryParams();
  const [expandFilter, setExpandFilter] = useState(false);

  const Me = useQuery(GET_ME);
  const { isCustomer, isAgent } = getUserRole(Me);

  const requestsQuery = useQuery(GET_REQUESTS, {
    variables: {
      sort: "updated_at:desc",
      limit: 10,
      start: 0,
      where: { published_at_null: false },
    },
    fetchPolicy: "network-only",
    // skip: startPage > 0,
  });
  const {
    loading, error, data,
  } = requestsQuery;

  const [deleteItem, responseDeleteItem] = useMutation(DELETE_REQUEST, {
    refetchQueries: ["GET_REQUESTS"],
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getNewTime = (x, y) => {
    if (x) {
      return moment(x).isAfter(y) ? x : y;
    }
    return y;
  };

  // TODO Sort by another column
  const totalCount = _.get(data, "requestsAggregate.aggregate.count");
  const dataTableData = useMemo(() => {
    const results = _.get(data, "requests", []);
    return results?.map((item) => {
      const status = item?.request_status?.status || "draft";
      const currentStep = item?.request_status?.current_step;
      const updatedAt = getNewTime(item?.request_status?.updated_at, item?.updated_at);

      const name = `${item.name} ${item.family}`;
      if (isCustomer) {
        return [
          item.id,
          item.display_id,
          item.userId,
          name,
          item.email,
          item.phone,
          currentStep,
          status,
          updatedAt,
          item.created_at,
        ];
      }
      return [
        item.id,
        item.display_id,
        item.userId,
        name,
        item.email,
        item.phone,
        currentStep,
        status,
        item?.operator,
        updatedAt,
        item.created_at,
      ];
    });
  }, [data]);

  const getPage = (item) => {
    const id_ = item.tableMeta.rowData[0];
    // props.history.push();
    return `/request/${id_}`;
  };

  const deleteRow = (item) => {
    const itemID = item.tableMeta.rowData[0];
    confirm({
      title: t("SureForDelete"), description: "", confirmationText: t("yes"), cancellationText: t("no"),
    }).then(() => {
      deleteItem({
        variables: {
          id: itemID,
        },
      }).then((response) => {
        enqueueSnackbar(t("SuccessfullyDeleted"), {
          variant: "success",
        });
      });
    }).catch((e1) => {
      // enqueueSnackbar(t("ErrorHappened"), {
      //   variant: "warning",
      // });
    });
  };

  const openAssign = (v) => {
    setOpenFormAssignToOperator(v?.tableMeta?.rowData[0]);
  };

  let columns = [
    {
      label: t("id"),
      name: "id_",
      options: {
        filter: true,
        display: false,
      },
    },
    {
      label: t("tracking_id"),
      name: "id",
      options: {
        filter: true,
      },
    },
    {
      label: t("userId"),
      name: "userId",
      options: {
        filter: true,
        display: false,
      },
    },
    {
      label: t("name"),
      name: "name",
      options: {
        filter: true,
      },
    }, {
      label: t("email"),
      name: "email",
      options: {
        filter: true,
      },
    }, {
      label: t("phone"),
      name: "phone",
      options: {
        filter: true,
      },
    }, {
      name: "current_step",
      label: t("currentStep"),
      options: {
        // sort: false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // eslint-disable-next-line react/destructuring-assignment
          const isDone = value;
          return (
            <div className={`current_step ${value}`}>
              {t(value || "draft")}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: t("status"),
      options: {
        // sort: false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // eslint-disable-next-line react/destructuring-assignment
          // const isError = value && value.includes("Error") ? "error" : "";
          // eslint-disable-next-line react/destructuring-assignment
          const val = value ? `step_status.${value}` : STEP_STATUS.waitUser;

          return (
            <div className={`tag_div status__${value}`}>
              {t(val || "-")}
            </div>
          );
        },
      },
    },
    {
      name: "operator",
      label: t("operator"),
      options: {
        // sort: false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const val = value;
          return (
            <div className={`tag_div status__${value}`}>
              { val?.name || "-"}
            </div>
          );
        },
      },
    },
    {
      name: "updated_at",
      label: t("updated_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    }, {
      name: "created_at",
      label: t("created_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue, ...els) => (
          <>
            <Link to={getPage({
              value, tableMeta, updateValue, els,
            })}
            >
              <IconButton>
                <ViewIcon />
              </IconButton>
            </Link>
            {!isCustomer ? (
              <>
                {!isAgent ? (
                  <IconButton onClick={(e) => {
                    e.stopPropagation();
                    deleteRow({ value, tableMeta, updateValue });
                  }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : ""}
                {!isAgent ? (
                  <IconButton
                    title={t("Profile.AssignToAdmin")}
                    onClick={() => openAssign({ value, tableMeta, updateValue })}
                  >
                    <UserIcon />
                  </IconButton>
                ) : null}
              </>
            ) : "" }
          </>
        ),
      },
    },
  ];
  if (isCustomer) {
    columns = columns.filter((item) => item.name !== "username" && item.name !== "operator");
  }

  const formik = useFormik({
    initialValues: {
      searchText: null,
      status: null,
      requestId: null,
      name: null,
      family: null,
      step: null,
      createDateFrom: null,
      createDateEnd: null,
      selectOperator: null,
    },
    onSubmit: (e) => {
      handleSearch(e);
    },
  });

  const handleSearch = (e: any) => {
    const searchData = {
      _q: e.searchText,
      "request_status.status": e.status && e.status !== "all" ? e.status : undefined,
      published_at_null: false,
      id: e.requestId,
      name_contains: e.name,
      family_contains: e.family,
      created_at_gte: e.createDateFrom,
      created_at_lte: e.createDateEnd,
      operator: e.selectOperator,
      "request_status.current_step": e.step && e.step !== "draft" ? e.step : undefined,
      request_status_null: e.step === "draft",
    };
    const searchData_ = cleanObject(searchData);
    setHistorySearch(searchData_);

    requestsQuery.refetch({
      where: searchData_,
    });
  };

  const stepOptions_ = {
    draft: "draft",
    ...(STEPS),
  };
  const stepOptions = _.keys(stepOptions_).map((key) => ({
    label: t(`${key}`),
    value: key,
  }));

  const options = {
    all: "all",
    ...(STEP_STATUS),
  };
  const statusOptions = _.keys(options).map((key) => ({
    label: t(`step_status.${key}`),
    value: key,
  }));

  return (
    <>
      {/* <PageTitle title="Tables" /> */}
      <Grid container spacing={4} className="requests">
        <Grid item xs={12}>
          <Fab
            onClick={() => setOpenFormAssignContract(true)}
            className="fab-icon fab-icon__action"
            style={{ backgroundColor: "Highlight" }}
            title={t("NewEvaluation")}
          >
            <AddIcon />
            <span className="help-text">{t("SetContract")}</span>
          </Fab>
          {!isCustomer ? (
            <div className="filter-bar">
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} className="filter-bar__container">
                  <InputBox
                    handler="formik"
                    name="searchText"
                    placeholder="searchText"
                    value={formik.values.searchText}
                    formikI={formik}
                    grid={2}
                  />
                  <InputBox
                    handler="formik"
                    name="requestId"
                    placeholder="requestId"
                    value={formik.values.requestId}
                    formikI={formik}
                    grid={2}
                  />
                  <InputBox
                    handler="formik"
                    name="name"
                    placeholder="name"
                    value={formik.values.name}
                    formikI={formik}
                    grid={1}
                  />
                  <InputBox
                    handler="formik"
                    name="family"
                    placeholder="family"
                    value={formik.values.family}
                    formikI={formik}
                    grid={1}
                  />
                  <SelectBox
                    handler="formik"
                    name="step"
                    grid={2}
                    value={formik.values.step}
                    items={stepOptions}
                    formikI={formik}
                  />
                  <SelectBox
                    handler="formik"
                    name="status"
                    grid={2}
                    value={formik.values.status}
                    items={statusOptions}
                    formikI={formik}
                  />
                  {expandFilter
                    ? (
                      <>
                        <SelectOperator
                          name="selectOperator"
                          grid={2}
                          formikI={formik}
                        />

                        <DatePickerComponent
                          handler="formik"
                          name="createDateFrom"
                          value={formik.values.createDateFrom}
                          formikI={formik}
                          grid={2}
                        />
                        <DatePickerComponent
                          handler="formik"
                          name="createDateEnd"
                          value={formik.values.createDateEnd}
                          formikI={formik}
                          grid={2}
                        />
                      </>
                    )
                    : ""}
                  <Grid item xs={1} className="filter-bar__btn">
                    <Button
                      type="submit"
                      variant="contained"
                    >
                      {t("Search")}
                    </Button>
                  </Grid>
                  <IconExpand open={expandFilter} setOpen={setExpandFilter} />
                </Grid>
              </form>
            </div>
          ) : "" }
          <TableAdvance
            {...props}
            title="Requests"
            data={dataTableData}
            serverSide
            totalCount={totalCount}
            query={requestsQuery}
            columns={columns}
            itemPath="/request/"
            showFilter={false}
            defaultOrder="updated_at:desc"
            sendMessage
            pageOptions={
              {
                main: "requests",
                startPage: nav.get("page"),
              }
            }
            setOpenGroupSend={setOpenGroupSend}
            // sortDefault="id"
          />
        </Grid>

        {openFormAssignContract ? (
          <DialogAssignContract
            open={openFormAssignContract}
            setOpen={setOpenFormAssignContract}
          />
        ) : ""}

        {openFormAssignToOperator ? (
          <DialogAssignToOperator
            open={openFormAssignToOperator}
            setOpen={setOpenFormAssignToOperator}
          />
        ) : ""}

        {openGroupSend ? (
          <DialogSendAlertGroup
            open={openGroupSend}
            setOpen={setOpenGroupSend}
            searchData={historySearch}
          />
        ) : false}
      </Grid>
    </>
  );
}
