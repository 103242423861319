import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from "@apollo/client";
// import { Color } from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useSnackbar } from "notistack";
import logo from "../../assets/images/logo.svg";

// import { loginUser, userSelector } from '../../features/user/userSlice';
import { CHECK_USER, LOGIN_USER } from "./loginQueries";
import { getMessageError } from "../../app/utils/helper";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.border.default,
    borderRadius: "5px",
    padding: theme.spacing(5),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: "250px",
    background: "#bf0d32",
    padding: "0 10px",
    borderRadius: "10px",
  },
}));

export default function Login(props: any) {
  const loc = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [check, responseCheck] = useMutation(CHECK_USER, {
    onError: (e) => {
      props.history.push("register");
    },
  });
  const [login, responseLogin] = useMutation(LOGIN_USER);

  const classes = useStyles();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const onSubmitCheck = (e) => {
    e.preventDefault();
    if (email) {
      const email_ = _.toLower(_.trim(email));
      check({ variables: { username: email_ } }).then((r) => {
        if (r?.data?.checkExistUser === "NOT_FOUND") {
          enqueueSnackbar(t("ErrorHappened"), {
            variant: "error",
            key: "login",
          });
        }
      });
    }
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();
    const email_ = _.toLower(_.trim(email));
    const password_ = _.trim(password);
    login({ variables: { username: email_, password: password_ } }).catch((e1) => {
      const err = getMessageError(e1);
      enqueueSnackbar(t(err.messageId), {
        variant: "error",
        key: "login",
      });
    });
  };

  useEffect(() => {
    const { data } = responseCheck;
    if (data && data.checkExistUser === "FOUND") {
      setShowPassword(true);
    } else if (data && data.checkExistUser === "NOT_FOUND") {
      props.history.push("register");
    }
  }, [responseCheck.data]);

  useEffect(() => {
    const { data, error } = responseLogin;
    if (data) {
      if (data.login) {
        localStorage.setItem("token", data.login.jwt);
        setTimeout(() => {
          // props.history.("/");
          loc.push("/");
        }, 1000);
      } else {
        console.log("validation=error");
      }
    } else if (error) {
      // const message = responseLogin.error;
      const err = getMessageError(error);
      enqueueSnackbar(t(err.messageId), {
        variant: "error",
        key: "login",
      });
    }
  }, [responseLogin.data, responseLogin.error]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="logo" className={classes.logo} />
        <br />
        <Typography component="h1" variant="h5">
          {t("SignInOrRegister")}
        </Typography>
        {!showPassword && (
        <form className={classes.form} noValidate onSubmit={onSubmitCheck}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("Sign_In_button")}
          </Button>
        </form>
        )}
        {showPassword && (
        <form className={classes.form} noValidate onSubmit={onSubmitLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label={t("password")}
            name="password"
            autoComplete="password"
            autoFocus
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("Sign_In_button")}
          </Button>

        </form>
        ) }
        <Grid container>
          <Grid item xs>
            <Link variant="body2" href="/forgot">
              {t("Forgot_password")}
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
