import { gql } from "@apollo/client";

// # query GET_DASHBOARD_INFO ($lastTimeLogin: DateTime, $uId: ID){
export const GET_DASHBOARD_INFO = gql`
query GET_DASHBOARD_INFO{
    requestsConnection(where: {
      published_at_null: false
    }){
      aggregate{
        count
        totalCount
      }
    }

    ticketsConnection{
      aggregate{
        count
        totalCount
      }
    }

    # evaluationsConnection{
    #   aggregate {
    #     count
    #     totalCount
    #   }
    # }

    evaluationsConnection(where: {
      status: "DONE"
    }){
      aggregate {
        count
        totalCount
      }
    }
    
    invoicesConnection(where: {
      _or: [
          {
            status: "waitPayment"
          },
          {
            status: "waitForApprove"
          }
      ],
      request_id_null: false,
      request_id: {
        request_status: {
          sign_date_null: false
        }
      }
    }){
      aggregate {
        count
        totalCount
      }
    }

    usersConnection{
      aggregate {
        count
        totalCount
      }
    }
    # usersConnection(where:{
    #   created_at_gte: $lastTimeLogin
    # }){
    #   aggregate {
    #     count
    #   }
    # }

    paymentsConnection{
      aggregate{
        count
        totalCount
      }
    }

    systemNews {
      id
      created_at
      updated_at
      title
      description
      type
    }

    messagesConnection{
      aggregate {
        count
        totalCount
      }
    }
}
`;

export const GET_DASHBOARD_STATUS_CONTRACTS = gql`
  query step_one($step: String){
    requestStatusesConnection(where: {
        current_step: $step
      }){
        aggregate {
          count
          totalCount
        }
      }
  }
`;

export const GET_DASHBOARD_STEP_DRAFT = gql`
  query step_draft{
    requestsConnection(where: {
      name_en_null: true 
    }){
      aggregate {
        count
        totalCount
      }
    }
  }
`;

export const GET_DASHBOARD_LAST_MONTH_EVAL = gql`
query x4($gt: DateTime,$lt: DateTime){
  evaluationsConnection(where: {
    created_at_gte: $gt
    created_at_lte: $lt
  },sort: "id:desc"){
    values {
      created_at
      id
    }
    aggregate {
      count
      totalCount
    }
  }
}`;

export default {};
