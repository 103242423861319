import React, { useState } from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import InputBox from "../micro/InputBox";
import { CREATE_REQUEST_ATTACHMENT_FILE, UPDATE_REQUEST_ATTACHMENT_FILE }
  from "../../pages/Request/steps/requestStepAQueries";

const DialogSetLinkAttachment = (props: any) => {
  const {
    open, setOpen, dataRow, requestId,
  } = props;
  const [url, setUrl] = useState<string>(dataRow?.fileInfo?.link);
  const { t } = useTranslation();
  const [create, responsCreate] = useMutation(
    CREATE_REQUEST_ATTACHMENT_FILE,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );

  const [update, responsUpdate] = useMutation(
    UPDATE_REQUEST_ATTACHMENT_FILE,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onFinish = () => {
    try {
      if (!dataRow.fileInfo) {
        create({
          variables: {
            title: dataRow.title_en,
            requestId,
            url,
            section: "document",
            docId: dataRow.id,
            status: "unknown",
          },
          refetchQueries: ["GET_REQUEST_STATUS", "GET_REQUEST_ATTACHMENTS"],
        }).then((resultUpload: any) => {
        // console.log("uploaded", resultUpload);
          enqueueSnackbar(t("SuccessfullyUpdated"), {
            variant: "success",
            key: "saved",
          });
        }).catch((errorUpload: any) => {
          console.log("upload-error", errorUpload);
          enqueueSnackbar(t("ErrorHappened"), {
            variant: "error",
          });
        });
      } else {
        update({
          variables: {
            id: dataRow.fileInfo?.id,
            title: dataRow.title_en,
            requestId,
            url,
            section: "document",
            docId: dataRow.id,
            status: "unknown",
          },
          refetchQueries: ["GET_REQUEST_STATUS", "GET_REQUEST_ATTACHMENTS"],
        }).then((resultUpload: any) => {
        // console.log("uploaded", resultUpload);
          enqueueSnackbar(t("SuccessfullyUpdated"), {
            variant: "success",
            key: "saved",
          });
        }).catch((errorUpload: any) => {
          console.log("upload-error", errorUpload);
          enqueueSnackbar(t("ErrorHappened"), {
            variant: "error",
          });
        });
      }
    } catch (e) {
      console.log("error", e);
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      open={!!open}
      maxWidth="sm"
      fullWidth
      onClose={() => { setOpen(false); }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("Dialog.SetUrlAttachment")}</DialogTitle>
      <form>
        <>
          <DialogContent>
            {t("Dialog.pleaseSetUrlAttachment")}
            <InputBox
              name="url"
              value={url}
              setValue={setUrl}
              grid={12}
              rows={3}
              dir="ltr"
              placeholder="https://kohanbaba-my.sharepoint.com/:w:/p/support/EQ12vpYlCYZJiY0vfgMIcCEBBLPuG3jWJ5dVqdKRAisU3g?e=gXjnhd"
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={onFinish}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </>
      </form>
    </Dialog>
  );
};

export default DialogSetLinkAttachment;
