import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Grid,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import moment from "moment";
import _ from "lodash";
import DatePickerComponent from "./micro/Datepicker";
import InputBox from "./micro/InputBox";
import { CREATE_INVOICE } from "../pages/Request/steps/requestStepBQueries";
import { IntlFormat, IntlReverse } from "../app/utils/helper";
import SelectBox from "./micro/SelectBox";
import statics from "../app/statics";
import { GET_REQUEST_INVOICES } from "../pages/Request/steps/requestStepEQueries";

type IProps = {
    openDialog?: boolean,
    title: string,
    handleClose: Function,
    subject: "council" | "contract" | "contractInstallment",
    requestId: number,
    setOpenDialog: Function,
    totalCost?: number,
}

const invoiceTypeOptions = statics.subjectInvoiceOptions.slice(1);

export default function FormDialogInvoiceInstallment(props: IProps) {
  const {
    openDialog,
    setOpenDialog,
    title,
    subject,
    handleClose,
    requestId,
    totalCost,
  } = props;
  const [isLastInstallment, setIsLastInstallment] = useState(false);
  const [withDescription, setWithDescription] = useState(false);

  const validationSchema = yup.object({
    titleInstallment: yup
      .string().required("Is required"),
    amountInstallment: yup
      .string().required("Is required"),
    invoiceType: yup
      .string().required("Is required"),
    dueDate: yup
      .string().nullable(),
    description: yup
      .string().nullable(),
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [createInvoice, resposneCreateInvoice] = useMutation(
    CREATE_INVOICE,
    {
      refetchQueries: ["GetRequestPaymentInstallment"],
    },
  );

  const installmentsInvoice = useQuery(GET_REQUEST_INVOICES, {
    variables:
    {
      reqId: Number(requestId),
      subject: ["contractInstallment", "resubmit"],
    },
    fetchPolicy: "cache-only",
  });

  const sumRemain = useCallback(() => {
    const ca = installmentsInvoice?.data?.invoices || [];
    let sum = ca.map((a) => (a.cost && a.subject === "contractInstallment" ? a.cost : 0));
    sum = sum.reduce((a, b) => a + b, 0);
    return (totalCost || 0) - (sum || 0);
  }, [installmentsInvoice?.data]);

  useEffect(() => {
    formik.setFieldValue("titleInstallment", getCurrentStepName());
  }, [installmentsInvoice?.data]);

  const getCurrentStepName = () => {
    const invoiceCount = installmentsInvoice?.data?.invoices?.length || 0;
    const numberName = t(`num_${invoiceCount}`);
    const invoiceTitle = `${t("invoiceInstallmentTitle", { num: numberName })}`;
    return invoiceTitle;
  };

  const SUM_REMAIN = Number(sumRemain());

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      titleInstallment: null,
      amountInstallment: null,
      description: null,
      invoiceType: invoiceTypeOptions[1].value,
      dueDate: "",
    },
    validationSchema,
    onSubmit: () => {
      handleSubmit();
    },
  });

  const {
    amountInstallment,
    titleInstallment,
    invoiceType,
    dueDate,
    description,
  } = formik.values;

  const handleClose_ = () => {
    setOpenDialog(false);
    formik.resetForm();
    handleClose();
  };

  const handleSubmit = async () => {
    const dueDate_ = dueDate ? moment(dueDate).locale("en").format("YYYY-MM-DD") : null;
    const amount = _.isNumber(amountInstallment)
      ? amountInstallment : IntlReverse(amountInstallment);
    if (invoiceType === invoiceTypeOptions[1].value && (totalCost && amount > totalCost)) {
      enqueueSnackbar(t("installmentMoreThanContractCost"), {
        variant: "error",
      }); return;
    }

    if (withDescription) {
      if (!description) {
        enqueueSnackbar(t("pleaseAddDescription"), {
          variant: "error",
        }); return;
      }
    }
    if (!withDescription && !dueDate) {
      enqueueSnackbar(t("pleaseSetDueDate"), {
        variant: "error",
      }); return;
    }

    try {
      const response = await createInvoice({
        variables: {
          reqId: requestId,
          title: titleInstallment,
          cost: amount,
          status: "waitPayment",
          subject: invoiceType,
          due_date: dueDate_,
          message: description,
          type: statics.typeCurrency[0].value,
        },
        refetchQueries: ["GetRequestPaymentInstallment"],
      });
      if (response?.data?.createInvoice) {
        enqueueSnackbar(t("SuccessfullyCreated"), {
          variant: "success",
        });
        handleClose_();
      }
    } catch (e) {
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    }
  };

  const handleSetLast = (v) => {
    setIsLastInstallment(v.target.checked);
    if (v.target.checked) {
      formik.setFieldValue("titleInstallment", t("endInvoiceInstallmentTitle"));
      formik.setFieldValue("amountInstallment", String(SUM_REMAIN));
    } else {
      formik.setFieldValue("titleInstallment", getCurrentStepName());
    }
  };

  return (
    <Dialog
      open={openDialog}
      maxWidth="xs"
      fullWidth
      // onClose={handleClose_}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t(title)}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <>
          <DialogContent>
            <Grid container spacing={1}>
              <SelectBox
                handler="formik"
                name="invoiceType"
                value={invoiceType}
                formikI={formik}
                grid={12}
                items={invoiceTypeOptions}
              />

              {invoiceType === invoiceTypeOptions[1].value ? (
                <Grid item xs={12}>
                  <table className="table">
                    <tr>
                      <td>{t("totalCost")}</td>
                      <td>
                        {IntlFormat(totalCost)}
                        {" "}
                        {t("currency.usd")}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("costThatRemainedForInstallment")}</td>
                      <td>
                        {IntlFormat(SUM_REMAIN)}
                        {" "}
                        {t("currency.usd")}
                      </td>
                    </tr>
                  </table>
                </Grid>
              ) : ""}

              <div style={{ marginTop: "10px" }}>
                <Checkbox
                  checked={isLastInstallment}
                  onChange={handleSetLast}
                  style={{ padding: 0 }}
                />
                {t("isLastPayment")}
              </div>

              <InputBox
                handler="formik"
                name="titleInstallment"
                value={titleInstallment}
                formikI={formik}
                grid={12}
              />

              <InputBox
                handler="formik"
                formikI={formik}
                name="amountInstallment"
                value={amountInstallment}
                type="price"
                grid={12}
                dir="ltr"
              />

              <div style={{ marginTop: "10px" }}>
                <Checkbox
                  checked={withDescription}
                  onChange={(e) => { setWithDescription(e?.target?.checked); }}
                  style={{ padding: 0 }}
                />
                {t("hasDescription")}
              </div>

              {withDescription ? (
                <InputBox
                  handler="formik"
                  formikI={formik}
                  name="description"
                  value={description}
                  grid={12}
                  rows={3}
                />
              )
                : (
                  <DatePickerComponent
                    handler="formik"
                    formikI={formik}
                    name="dueDate"
                    value={dueDate}
                    grid={12}
                  />
                )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose_} color="default">
              {t("Cancel")}
            </Button>
            <Button color="primary" variant="outlined" type="submit" disabled={SUM_REMAIN === 0}>
              {t("Accept")}
            </Button>
          </DialogActions>
        </>
      </form>
    </Dialog>
  );
}

FormDialogInvoiceInstallment.defaultProps = {
  openDialog: false,
  totalCost: 0,
};
