import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import moment from "moment";
import DatePickerComponent from "./micro/Datepicker";
import InputBox from "./micro/InputBox";
import { makeRandomCode } from "../app/utils/helper";
import { CREATE_INVOICE, UPDATE_INVOICE } from "../pages/Request/steps/requestStepBQueries";
import SelectBox from "./micro/SelectBox";
import statics from "../app/statics";

const subjectsOptions = [
  {
    label: "council",
    value: "council",
  }, {
    label: "contract",
    value: "contract",
  }, {
    label: "other",
    value: "other",
  },
];
type IProps = {
    openDialog?: boolean,
    // title: string,
    // handleSubmit: Function,
    handleClose: Function,
    setOpenDialog: Function,
    subject?: "council" | "contract" | "other",
    cost?: number,
    detail?: any,
    mode?: "create" | "update" | "delete",
    invoiceId?: number,
    requestId: number,
    typeCurrency?: string,
    refetchQueries?: [string],
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  description: {
    paddingBottom: "10px",
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
    "& button": {
      height: "52px",
      marginTop: "5px",
    },
  },
}));

const validationSchema = yup.object({
  offCode: yup
    .string(), // .required("offPercent is required")

  offPercent: yup
    .number()
    .min(0, "offPercent min 0")
    .max(100, "offPercent max 100"),
  // .required("offPercent is required"),
});

export default function FormDialogInvoice(props: IProps) {
  const {
    openDialog,
    setOpenDialog,
    mode,
    subject,
    cost,
    detail,
    handleClose,
    invoiceId,
    requestId,
    typeCurrency,
    refetchQueries,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(openDialog);
  const [subjectL, setSubjectL] = React.useState<string>();
  const [messageL, setMessageL] = React.useState<string>();
  const [dueDate, setDueDate] = React.useState<string>(null);
  const [costL, setCostL] = React.useState<number>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [createInvoice, responseCreateInvoice] = useMutation(CREATE_INVOICE);
  const [updateInvoice, responseInvoice] = useMutation(UPDATE_INVOICE);

  // const [offCode, setOffCode] = React.useState<string>();
  // const [offPercent, setOffPercent] = React.useState<string>();
  const { t } = useTranslation();

  const handleSubmit = (data: any) => {
    if (mode === "update") {
      handleUpdateInvoice(data);
    } else if (mode === "create") {
      handleCreateInvoice(data);
    }
  };

  const handleCreateInvoice = (data: any) => {
    // if (data === true) {
    //   setOpenModalCreateInvoice(true);
    // } else {
    if (!invoiceId) {
      createInvoice({
        variables: {
          reqId: requestId,
          cost: data.cost,
          status: "draft",
          message: data.message,
          subject: data.subject,
          due_date: moment(data.due_date).locale("en").format("YYYY-MM-DD"),
          off_percent: data.off_percent,
          off_code: data.off_code,
          type: typeCurrency,
        },
        refetchQueries,
      }).then((result) => {
        enqueueSnackbar(t("SuccessfullyCreated"), {
          variant: "success",
        });
        setOpenDialog(false);
      });
    }
    // }
  };

  const handleUpdateInvoice = (data: any) => {
    // if (data === true) {
    //   setOpenModalCreateInvoice(true);
    // } else {

    if (invoiceId) {
      updateInvoice({
        variables: {
          id: invoiceId,
          reqId: requestId,
          cost: data.cost,
          status: "draft",
          message: data.message,
          subject: data.subject,
          due_date: moment(data.due_date).locale("en").format("YYYY-MM-DD"),
          off_percent: data.off_percent,
          off_code: data.off_code,
          type: typeCurrency,
        },
        refetchQueries,
      }).then((result) => {
        enqueueSnackbar(t("SuccessfullyUpdated"), {
          variant: "success",
        });
        setOpenDialog(false);
      });
    }
    // }
  };

  const formik = useFormik({
    initialValues: {
      offPercent: "",
      offCode: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit({
        message: messageL,
        subject: subjectL,
        due_date: dueDate,
        cost: costL,
        off_percent: values.offPercent,
        off_code: values.offCode,
      });
      // setOpen(false);
      // alert(JSON.stringify(values, null, 2));
    },
    // validateOnChange: true,
  });
  const { offPercent, offCode } = formik.values;
  const { offPercent: offPercentT, offCode: offCodeT } = formik.touched;
  const { offPercent: offPercentE, offCode: offCodeE } = formik.errors;

  useEffect(() => {
    setOpen(openDialog);
    setSubjectL((subject));
    setCostL(cost);

    if (detail) {
      setMessageL(detail?.message);
      setDueDate(detail?.due_date);
      formik.setFieldValue("offCode", detail?.off_code);
      formik.setFieldValue("offPercent", detail?.off_percent);
    }
  }, [openDialog]);

  const handleClose_ = () => {
    setOpen(false);
    handleClose();
  };

  const generateCode = () => {
    // setOffCode(makeRandomCode(7));
    if (!offPercent) {
      formik.setFieldError("offPercent", "asdad");
      return;
    }
    formik.setFieldValue("offCode", makeRandomCode(7));
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose_} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t(mode === "update" ? "Invoice.update" : "Invoice.create")}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <>
          <DialogContent>
            <Grid container spacing={1}>
              {/* {!cost
                && (
                <SelectBox
                  name="request"
                  value={subjectL}
                  setValue={setSubjectL}
                  grid={12}
                  items={subjectsOptions}
                />
                )} */}
              <InputBox
                name="costRial"
                value={costL}
                setValue={setCostL}
                grid={12}
                type="number"
                readOnly={!!cost}
              />

              {/* <InputBox
                name="subject"
                value={subjectL}
                setValue={setSubjectL}
                grid={12}
                readOnly={!!subject}
              /> */}
              <SelectBox
                name="subject"
                value={subjectL}
                setValue={setSubjectL}
                grid={12}
                items={subjectsOptions}
                disabled={!!subject}
              />

              <InputBox
                name="description"
                value={messageL}
                setValue={setMessageL}
                grid={12}
                rows={3}
              />
              <InputBox
                handler="formik"
                name="offPercent"
                value={offPercent}
                // setValue={formik.handleChange}
                formikI={formik}
                grid={4}
                type="number"
                max={100}
                min={0}
                error={offPercentT && Boolean(offPercentE)}
              />
              <InputBox
                handler="formik"
                name="offCode"
                value={offCode}
                // setValue={formik.handleChange}
                formikI={formik}
                grid={6}
                readOnly
                error={offCodeT && Boolean(offCodeE)}
              />

              <Grid item xs={2} className={classes.buttonWrap}>
                <Button
                  variant="contained"
                  onClick={generateCode}
                  disabled={!offPercent}
                  size="small"
                  style={{ height: "40px" }}
                >
                  {t("generateCode")}
                </Button>
              </Grid>
              <DatePickerComponent
                name="due_date"
                value={dueDate}
                setValue={setDueDate}
                grid={6}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button>
            <Button color="inherit" type="submit">
              {t("Accept")}
            </Button>
          </DialogActions>
        </>
      </form>
    </Dialog>
  );
}

FormDialogInvoice.defaultProps = {
  openDialog: false,
  subject: "",
  cost: 0,
  detail: {},
  mode: "create",
  invoiceId: null,
  typeCurrency: statics.typeCurrency[1].value,
  refetchQueries: null,
};
