import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import statics from "../../app/statics";
import RadioGroupBox from "../micro/RadioGroupBox";
import { UPDATE_EVAL_LOCATION_TIME } from "../../pages/Bookings/bookingsQueries";

type IProps = {
    setOpen: (params: any) => any,
    open: boolean,
}

const DialogChangeBookingLocation = (props: IProps) => {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const [locationType, setLocationType] = useState("ONLINE");
  const confirm = useConfirm();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams<{id: string}>();

  const [updateEval] = useMutation(UPDATE_EVAL_LOCATION_TIME, {
    refetchQueries: ["GET_EVALUATION", "GET_EVALUATIONS"],
  });

  const handleSave = (e) => {
    confirm({
      title: t("AreYouSureForSaveTypeHold"),
      description: "",
      confirmationText:
      t("yes"),
      cancellationText: t("no"),
    })
      .then(async () => {
        try {
          const resultRequest = await updateEval({
            variables: {
              id: params?.id,
              location_type: locationType,
            },
          });
          enqueueSnackbar(t("successFullyDone"), {
            variant: "success",
          });
          setTimeout(() => {
            setOpen(false);
          }, 1000);
        } catch (e1: any) {
          enqueueSnackbar(t("ErrorHappened"), {
            variant: "error",
          });
        }
      })
      .catch((e2: any) => {
        // enqueueSnackbar(t("ErrorHappened111"), {
        //   variant: "error",
        // });
      });
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={() => setOpen((s) => !s)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("titleChangeLocation")}</DialogTitle>
      <>
        <DialogContent>
          {t("pleaseSelectNewType")}
          <br />
          <br />
          <RadioGroupBox
            name="locationType"
            value={locationType}
            setValue={setLocationType}
            grid={3}
            items={statics.locationTypeOptions}
          />
          <br />
        </DialogContent>
        <DialogActions>
          <Button color="default" variant="outlined" onClick={handleSave}>
            {t("general.saveChanges")}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default DialogChangeBookingLocation;
