import { gql } from "@apollo/client";

const FORGOT_PASSWORDS = gql`
mutation Forgot($email: String!) {
 forgotPassword(email: $email) {
   ok
 }
}
`;

export default FORGOT_PASSWORDS;
