import { CircularProgress, Grid, StepContent } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { useConfirm } from "material-ui-confirm";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import RequestPersonalDetail from "../component/RequestPersonalDetail";
import RequestFamilyDetail from "../component/RequestFamilyDetail";
import RequestQuestion from "../component/RequestQuestion";
import RequestConfirm from "../component/RequestConfirm";
import { RequestContext } from "../context/requestContext";
import statics, { STEPS } from "../../../app/statics";

import Notification, { INotification } from "../../../components/micro/SnackbarMicro";
import AdminNavbarStepTools from "../component/AdminNavbarStepTools";
import { GET_REQUEST, GET_REQUEST_STATUS } from "../requestQueries";
import { hasAccessStep } from "../../../app/utils/helper";
import { GET_ME } from "../../../app/layout/layoutQueries";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  footer: {
    // background: "#eee",
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(5),
    borderTop: "solid",
    borderTopColor: "#eee",
    borderWidth: "10px",
    // paddingLeft: theme.spacing(5),
  },
  acceptButton: {
    backgroundColor: theme.palette.success.light,
    marginRight: "5px",
    fontSize: "1.1em",
    width: "150px",
    color: "#fff",
    fontWeight: "bold",
  },
  rejectButton: {
    backgroundColor: theme.palette.error.light,
    fontSize: "1.1em",
    width: "150px",
    color: "#fff",
    fontWeight: "bold",
  },
  step_content: {
    marginTop: theme.spacing(2),
  },
  labelRow: {
    // backgroundColor: "#eee",
    padding: "10px 0px",
    // borderRadius: "5px",
    borderBottom: "1px solid #eee",
    fontWeight: "bold",
    fontSize: "1.2em",
  },
  loadingWrap: {
    position: "relative",
    width: "100%",
    textAlign: "center",
  },
  loading: {
    position: "absolute",
    top: "30px",
    // right: "40px",
  },
  stepper: {
    position: "relative",
  },
}));

function getSteps() {
  return [
    "Request.detailPersonal",
    "Request.detailFamily",
    "Request.question",
    "Request.confirm",
  ];
}

function getStepContent(
  stepIndex: number,
  stepsLen: number,
  handleNext: Function,
  handleBack: Function,
  stateRequest: any,
  role: string,
) {
  let accessUser = true;
  const lockSteps = _.get(stateRequest, "requestStatuses[0].lock_steps");
  if (lockSteps) {
    accessUser = hasAccessStep("step1", lockSteps);
  }

  const props = {
    stepIndex,
    stepsLen,
    handleNext,
    handleBack,
    accessUser,
    role,
  };

  switch (stepIndex) {
    case 0:
      return <RequestPersonalDetail stepProps={props} />;
    case 1:
      return <RequestFamilyDetail stepProps={props} />;
    case 2:
      return <RequestQuestion stepProps={props} />;
    default:
      return <RequestConfirm stepProps={props} />;
  }
}

const GetStepContent = (
  props_: any,
) => {
  const { props: x } = props_;
  const {
    stepIndex,
    stepsLen,
    handleNext,
    handleBack,
    stateRequest,
    role,
  } = x;

  let accessUser = true;
  const lockSteps = _.get(stateRequest, "requestStatuses[0].lock_steps");
  if (lockSteps) {
    accessUser = hasAccessStep("step1", lockSteps);
  }

  const props = {
    stepIndex,
    stepsLen,
    handleNext,
    handleBack,
    accessUser,
    role,
  };

  switch (stepIndex) {
    case 0:
      return <RequestPersonalDetail stepProps={props} />;
    case 1:
      return <RequestFamilyDetail stepProps={props} />;
    case 2:
      return <RequestQuestion stepProps={props} />;
    default:
      return <RequestConfirm stepProps={props} />;
  }
};

const RequestStepAPersonal = (props: any) => {
  const context = useContext(RequestContext);
  const { requestData } = context;
  const confirm = useConfirm();
  const { t } = useTranslation();
  const classes = useStyles();
  const reqId = context.requestData.requestId;
  const { data: dataGetStaReq, loading } = useQuery(GET_REQUEST_STATUS, {
    skip: !reqId,
    variables: {
      reqId,
    },
    fetchPolicy: "cache-only",
  });
  const requestQuery = useQuery(GET_REQUEST, {
    skip: !reqId,
    variables: { id: reqId },
    // fetchPolicy: "ca",
    // notifyOnNetworkStatusChange: true,
  });

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  const isCustomer = role === "Customer";

  const [activeStep, setActiveStep] = React.useState(0);
  const [notif, setNotif] = useState<INotification>();

  const steps = getSteps();

  const handleNext = (reqData: any) => {
    // requestQuery.refetch().then((r) => {
    //   console.log("requestQuery.data----44", r);
    // });
    // console.log("requestQuery.data----22", requestQuery, context.requestData?.number_childs);

    // if (reqData?.requestId) {
    //   props.refetchRequestQuery(reqData.requestId);
    // }
    if (activeStep === steps.length - 1) {
    // save to server
    } else {
      const reqData_ = reqData || requestData;
      if (activeStep === 0) {
        if (reqData_?.married === statics.marriedOptions[0].value) {
          setActiveStep((prevActiveStep) => prevActiveStep + 2);
          return;
        }
        if ((reqData_?.married === statics.marriedOptions[2].value
          || reqData_?.married === statics.marriedOptions[3].value)
          && reqData_?.number_childs === 0
        ) {
          setActiveStep((prevActiveStep) => prevActiveStep + 2);
          return;
        }
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    // const { requestData } = context;
    const reqData_ = requestData;
    if (activeStep === 2) {
      if (reqData_?.married === statics.marriedOptions[0].value) {
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
        return;
      }
      if ((reqData_?.married === statics.marriedOptions[2].value
        || reqData_?.married === statics.marriedOptions[3].value)
        && reqData_?.number_childs === 0
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Grid container spacing={4} className={classes.stepper}>
        {requestQuery.loading
          ? (
            <div className={classes.loadingWrap}>
              <CircularProgress className={classes.loading} />
            </div>
          ) : "" }

        {/* {!loading ? ( */}
        <Grid item xs={12} md={12}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step active={!isCustomer || (activeStep === index)} key={index}>
                <StepLabel className={classes.labelRow}>{t(label)}</StepLabel>
                <StepContent>
                  <div className={classes.step_content}>
                    {/* {getStepContent(index,
                      steps.length, handleNext,
                      handleBack, dataGetStaReq,
                      role)} */}
                    <GetStepContent props={{
                      stepIndex: index,
                      stepsLen: steps.length,
                      handleNext,
                      handleBack,
                      stateRequest: dataGetStaReq,
                      role,
                    }}
                    />
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
        {/* ) : ""} */}
      </Grid>
      <AdminNavbarStepTools stepNumber={STEPS.STEP_INFORMATION} />
      <Notification
        {...notif}
      />
    </>
  );
};

export default RequestStepAPersonal;
