import { Button, Grid, StepButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import { useMutation, useQuery } from "@apollo/client";
import { HistoryOutlined as ActivityIcon, LockOutlined as LockIcon, LockOpenOutlined as LockOpenIcon } from "@material-ui/icons";
import { useConfirm } from "material-ui-confirm";
import Alert from "@material-ui/lab/Alert";
import PageTitle from "../../components/PageTitle";
import { RequestContext } from "./context/requestContext";
import RequestStepAPersonal from "./steps/RequestStepAPersonal";
import RequestStepDContract from "./steps/RequestStepDContract";
import RequestStepEPayment from "./steps/RequestStepEPayment";
import RequestStepFDocs from "./steps/RequestStepFDocs";
import RequestStepGGetVisa from "./steps/RequestStepGGetVisa";
import { GET_REQUEST, GET_REQUEST_STATUS } from "./requestQueries";
import { STEPS, STEP_STATUS } from "../../app/statics";
import { UPDATE_STATE_REQUEST } from "./steps/requestStepAQueries";
import { getFakeRequestId } from "../../app/utils/helper";
import DialogFirstTime from "../../components/dialogs/DialogFirstTime";
import DialogActivity from "../../components/dialogs/DialogActivity";
import ContractInbox from "../../components/inbox/ContractInbox";
import { useAccount, useContractState } from "../../app/hooks";

import "./Request.scss";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
  },
  page: {
    width: "90%",
    maxWidth: "1400px",
    margin: "auto",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  footer: {
    background: "#eee",
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(5),
  },
  step: {
    "&:hover": {
      cursor: "pointer",
    },
    paddingBottom: "10px",
  },
  stepActive: {
    paddingBottom: "10px",
    borderBottom: "2px solid #03a9f4",
    background: "#f5f5f5",
    "&:hover": {
      cursor: "pointer",
    },
  },
  lock: {
    padding: "0px",
    marginTop: "10px",
    color: "#ddd",
  },
  unLock: {
    padding: "0px",
    marginTop: "10px",
    color: "#4caf50",
  },
  viewActivity: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
}));

function getSteps() {
  // ADMIN
  return [
    "Request.stepAPersonalInfo",
    // "Request.stepBReviewRequest",
    // "Request.stepCSetSession",
    "Request.stepDContract",
    "Request.stepEPayment",
    "Request.stepECompleteDocs",
    // "Request.stepFReviewDocs",
    "Request.stepGEnd",
  ];
}

type IProps = {
  openDialog?: boolean,
  title: string,
  nextStatus: string,
  handleSubmit: Function,
  handelClose: Function,
}

function getStepContent(stepIndex: STEPS, handleNext: any,
  handleBack: any, refetchRequestQuery: any) {
  switch (stepIndex) {
    case STEPS.STEP_INFORMATION:
      return (
        <RequestStepAPersonal
          handleNext={handleNext}
          handleBack={handleBack}
          refetchRequestQuery={refetchRequestQuery}
        />
      );
    case STEPS.STEP_CONTRACT:
      return <RequestStepDContract handleNext={handleNext} handleBack={handleBack} />;
    case STEPS.STEP_PAYMENT:
      return <RequestStepEPayment handleNext={handleNext} handleBack={handleBack} />;
    case STEPS.STEP_DOCUMENT:
      return <RequestStepFDocs handleNext={handleNext} handleBack={handleBack} />;
    case STEPS.STEP_END:
      return <RequestStepGGetVisa handleNext={handleNext} handleBack={handleBack} />;
    default: return "";
  }
}

const Request = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // const reqId_ = _.get(props, "match.params.id");
  const confirm = useConfirm();
  // const [reqId, setReqId] = useState(reqId_);
  const [loading, setLoading] = useState(false);
  const [openInbox, setOpenInbox] = useState(true);
  const [openActivityDialog, setOpenActivityDialog] = useState(false);
  const [openFirstTime, setOpenFirstTime] = useState<any>();
  const [activeStep, setActiveStep] = useState<STEPS>(STEPS.STEP_INFORMATION);
  const currentStepIndex = _.keys(STEPS).findIndex((k) => k === activeStep);

  const { role, isCustomer } = useAccount();

  const {
    reqId,
    availableStep, reqStatus, reqSId,
  } = useContractState();

  useQuery(GET_REQUEST_STATUS, {
    variables: { reqId: Number(reqId) },
    fetchPolicy: "cache-and-network",
    skip: !reqId,
  });

  const [updateRequestState, responseUpdateRequestState] = useMutation(
    UPDATE_STATE_REQUEST,
  );

  const lockSteps = _.get(reqStatus.data, "requestStatuses[0].lock_steps");

  const requestQuery = useQuery(GET_REQUEST, {
    skip: !reqId,
    variables: { id: reqId },
    fetchPolicy: "cache-and-network",
  });
  const [requestData, setRequestData] = React.useState<any>({
    ...requestQuery.data?.request,
    requestId: reqId,
  });

  useEffect(() => {
    if (requestQuery.data) {
      setRequestData({
        ...requestQuery.data?.request,
        requestId: reqId,
      });
    }
  }, [requestQuery.data]);

  // const [activeStep, setActiveStep] = React.useState<STEPS>(STEPS.STEP_INFORMATION);

  useEffect(() => {
    const { data: dataReq } = reqStatus;
    if (dataReq && dataReq?.requestStatuses?.length) {
      setActiveStep(availableStep);
    }
  }, [reqStatus]);

  const handleNext = () => {
  };

  const handleBack = () => {
  };

  const onClickStep = (index) => {
    if (isActiveStep(index)) {
      setActiveStep(STEPS[index]);
    }
  };

  const isActiveStep = (index) => {
    const a = _.keys(STEPS).findIndex((k) => k === index);
    const avI = _.keys(STEPS).findIndex((k) => k === availableStep);
    if (avI >= a) {
      return true;
    }
    return false;
  };

  const refetchRequestQuery = (requestId) => {

  };

  const lockStep = (i, type = "Lock") => {
    if (!isCustomer) {
      confirm({
        title: t(`SureFor${type}`), description: "", confirmationText: t("yes"), cancellationText: t("no"),
      }).then(() => {
        // removeFile(file);
        const lockSteps_ = { ...lockSteps };
        lockSteps_[`step${i}`] = type === "Lock";
        updateRequestState({
          variables: {
            reqSId: Number(reqSId),
            lockSteps: lockSteps_,
          },
          refetchQueries: ["GET_REQUEST_STATUS"],
        })
          .then(() => {
          })
          .catch(() => {
          });
      });
    }
  };

  const checkLock = (index) => {
    const i = index + 1;
    if (!_.isEmpty(lockSteps)) {
      if (!_.isNull(lockSteps[`step${i}`])) {
        if (lockSteps[`step${i}`] === true) {
          return (
            <div
              className={classes.lock}
              title={role !== "Customer" ? "Click to UnLock" : ""}
            >
              <LockIcon onClick={() => lockStep(i, "UnLock")} />
            </div>
          );
        } if (lockSteps[`step${i}`] === false) {
          return (
            <div
              className={classes.unLock}
              title={role !== "Customer" ? "Click to Lock" : ""}
            >
              <LockOpenIcon
                onClick={() => lockStep(i, "Lock")}
              />
            </div>
          );
        }
      }
    }
    return null;
  };

  const handleActivityDialog = () => {
    setOpenActivityDialog(true);
  };

  const status = _.get(reqStatus, "data.requestStatuses[0].status") || "";
  let hasError = false;
  let messageError = "";
  if (status === STEP_STATUS.rejected) {
    hasError = true;
    messageError = _.get(reqStatus, "data.requestStatuses[0].notification.message");
  }

  const reqName = `${requestData?.name} ${requestData?.family}`;

  let mdMain = 12;
  if (!isCustomer) {
    mdMain = openInbox ? 9 : 10;
  }
  return (
    <div className={isCustomer ? classes.page : ""}>
      <Grid container spacing={4}>
        <Grid item xs={8} md={8}>
          <PageTitle
            title={!reqId ? t("Request.title")
              : `${t("Request.titleNumber")} ${getFakeRequestId(reqId)} - ${reqName} `}
          />
        </Grid>
        {!isCustomer ? (
          <Grid
            item
            xs={4}
            md={4}
            className={classes.viewActivity}
          >
            <Button
              variant="outlined"
              color="default"
              startIcon={<ActivityIcon />}
              onClick={handleActivityDialog}
            >
              {t("viewActivity")}

            </Button>
          </Grid>
        ) : "" }
      </Grid>
      {hasError ? (
        <>
          <Alert severity="error">
            {messageError}
          </Alert>
          <br />
        </>
      ) : ""}

      <RequestContext.Provider value={{
        requestData,
        setRequestData,
        loading,
        setLoading,
        activeStep,
      }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={mdMain}
          >
            <div className="stepWrap">
              <Stepper activeStep={currentStepIndex} alternativeLabel>
                {_.keys(STEPS).map((key, index) => (
                  <Step
                    key={key}
                    className={`${key === activeStep
                      ? classes.stepActive : classes.step} ${!isActiveStep(key) ? "step-disabled" : ""}`}
                    onClick={() => onClickStep(key)}
                  >
                    <StepButton className={!isActiveStep(key) ? "button-disabled" : ""}>
                      <div>{t(key)}</div>
                      {checkLock(key)}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div>
              {getStepContent(activeStep, handleNext, handleBack, refetchRequestQuery)}
            </div>
          </Grid>
          {!isCustomer ? (
            <Grid item xs={12} md={openInbox ? 3 : 2}>
              <ContractInbox />
            </Grid>
          ) : null }
        </Grid>
      </RequestContext.Provider>

      <DialogFirstTime open={openFirstTime} setOpen={setOpenFirstTime} />
      {openActivityDialog
      && <DialogActivity open={openActivityDialog} setOpen={setOpenActivityDialog} />}
    </div>
  );
};

export default Request;
