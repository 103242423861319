import React, { useMemo, useState } from "react";
import {
  Button, Chip, Grid, IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { ArrowBack, Check } from "@material-ui/icons";
import { GET_EVALUATIONS, UPDATE_EVALUATION } from "./bookingsQueries";
import { GET_ME } from "../../app/layout/layoutQueries";
import { INotification } from "../../components/micro/SnackbarMicro";
import TableAdvance from "../../components/micro/TableAdvance";
import InputBox from "../../components/micro/InputBox";
import { dateIsoFa2, dateIsoFa3, timeIsoFa } from "../../app/utils/helper";

import "./styles.scss";
import SelectBox from "../../components/micro/SelectBox";
import statics from "../../app/statics";
import DatePickerComponent from "../../components/micro/Datepicker";
import IconExpand from "../../components/micro/IconExpand";
import DialogSendAlertGroup from "../../components/dialogs/DialogSendAlertGroup";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  pendingForSetTimeSession: {
    backgroundColor: "orange",
    border: "1px solid #eee",
    padding: "5px",
    display: "inline",
    borderRadius: "5px",
  },
  draft: {
    backgroundColor: "#eee",
    display: "inline-block",
    padding: "5px",
    borderRadius: "5px",
    "&.PaymentDone": {
      backgroundColor: "#77ffb4",
    },
    "&.error": {
      backgroundColor: "red",
      color: "#fff",
    },
    "&.done": {
      backgroundColor: "#00c357",
      color: "#fff",
    },
  },
  fab: {
    position: "fixed",
    right: "50px",
    bottom: "50px",
    zIndex: 200,
  },
  advForm: {
    // display: "flex",
    // alignItems: "center",
    padding: "10px",
  },
}));

export default function Consultants(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const statusOptions = _.keys(statics.statusBooking).map((key) => ({
    label: t(`statusCons.${key}`),
    value: key,
  }));
  const [expandFilter, setExpandFilter] = useState(false);
  const [openGroupSend, setOpenGroupSend] = useState(false);
  const [historySearch, setHistorySearch] = useState();

  const confirm = useConfirm();
  const [notif, setNotif] = useState<INotification>();
  const navi = useHistory();

  const Me = useQuery(GET_ME);
  // const role = _.get(Me, "data.me.role.name");
  let uId;
  const isCustomer = _.get(Me, "data.me.role.name") === "Customer";
  if (isCustomer) {
    uId = _.get(Me, "data.me.id");
  }

  const requestsQuery = useQuery(GET_EVALUATIONS, {
    variables: {
      sort: "updated_at:desc",
      limit: 10,
      start: 0,
      where: undefined,
    },
    fetchPolicy: "cache-and-network",
  });

  const [updateEval] = useMutation(UPDATE_EVALUATION, {
    refetchQueries: ["GET_EVALUATIONS"],
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // TODO Sort by another column
  const totalCount = _.get(
    requestsQuery.data,
    "evaluationsConnection.aggregate.count",
  );
  const dataTableData = useMemo(() => {
    const results = _.get(
      requestsQuery.data,
      "evaluationsConnection.values",
      [],
    );
    return results?.map((item) => {
      const status = t(`statusCons.${item.status || "DRAFT"}`);
      const name = `${item?.name || ""} ${item?.family || ""}`;
      const sessionTime = item.consultant_time
        ? `${dateIsoFa2(item.consultant_time?.start_time)}
        ${t("from")} ${timeIsoFa(item.consultant_time?.start_time)} 
        ${t("to")} ${timeIsoFa(item.consultant_time?.end_time)}`
        : "-";
      const consTime = item?.consultant_type?.title || "-";
      const type = t(item?.type) || t("EVALUATION");
      const country = t(`countryOptions.${item?.country || "Default"}`);
      return [
        item.id,
        name,
        type,
        sessionTime,
        consTime,
        item?.user?.email,
        item?.user?.phone,
        country,
        status,
        item.updated_at,
        item.created_at,
        item,
      ];
    });
  }, [requestsQuery.data]);

  const getPage = (item) => {
    const id_ = item.tableMeta.rowData[0];
    // props.history.push();
    return `/booking/${id_}`;
  };

  const handleCheck = (meta) => {
    const id_ = meta.rowData[0];
    confirm({
      title: t("SureForDone"),
      description: "",
      confirmationText: t("yes"),
      cancellationText: t("no"),
    })
      .then(() => {
        updateEval({
          variables: {
            id: id_,
            status: "DONE",
          },
        })
          .then((response) => {
            enqueueSnackbar(t("SuccessfullyUpdated"), {
              variant: "success",
            });
            // refetch();
          })
          .catch((e1) => {
            enqueueSnackbar(t("ErrorHappened"), {
              variant: "error",
            });
          });
      })
      .catch((e1) => {});
  };

  const columns = [
    {
      label: t("id"),
      name: "id",
      options: {
        filter: true,
      },
    },
    {
      label: t("name"),
      name: "name",
      options: {
        filter: true,
      },
    },
    {
      label: t("typeForm"),
      name: "type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: t("timeConsultant"),
      name: "consultant_time.start_time",
      options: {
        filter: true,
      },
    },
    {
      label: t("typeConsultant"),
      name: "consultant_time.type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: t("email"),
      name: "user.email",
      options: {
        filter: true,
      },
    },
    {
      label: t("phone"),
      name: "user.phone",
      options: {
        filter: true,
      },
    },
    {
      label: t("country"),
      name: "user.country",
      options: {
        filter: true,
      },
    },
    {
      name: "status",
      label: t("status"),
      options: {
        // sort: false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // eslint-disable-next-line react/destructuring-assignment
          const data = tableMeta.rowData[tableMeta.rowData.length - 1];
          return (
            <Chip
              label={t(value || "DRAFT")}
              className={`status__${data?.status}`}
            />
          );
        },
      },
    },
    {
      name: "updated_at",
      label: t("updated_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>{dateIsoFa3(value)}</div>
        ),
      },
    },
    {
      name: "created_at",
      label: t("created_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>{dateIsoFa3(value)}</div>
        ),
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue, ...els) => {
          const { status } = tableMeta.rowData[tableMeta.rowData.length - 1];
          const isDisabled = status === "DONE" || status === "CANCELED";
          return (
            <>
              <IconButton
                disabled={isDisabled}
                onClick={() => handleCheck(tableMeta)}
                title={t("done?")}
              >
                <Check />
              </IconButton>

              <Link
                to={getPage({
                  value,
                  tableMeta,
                  updateValue,
                  els,
                })}
              >
                <IconButton title={t("detail")}>
                  <ArrowBack className="icon-go" />
                </IconButton>
              </Link>
            </>
          );
        },
      },
    },
  ];

  const formik = useFormik({
    initialValues: {
      searchText: null,
      status: null,
      evalId: null,
      name: null,
      family: null,
      phone: null,
      createDateFrom: null,
      createDateEnd: null,
    },
    onSubmit: (e) => {
      handleSearch(e);
    },
  });

  const handleSearch = (e: any) => {
    const user = {
      phone_contains: e.phone,
    };
    requestsQuery.refetch({
      where: {
        _q: e.searchText || undefined,
        status: e.status && e.status !== "ALL" ? e.status : undefined,
        name_contains: e.name || undefined,
        family_contains: e.family || undefined,
        id: e.evalId || undefined,
        user: e.phone ? user : undefined,
        created_at_gte: e.createDateFrom || undefined,
        created_at_lte: e.createDateEnd || undefined,
      },
    });
  };

  return (
    <>
      {/* <PageTitle title="Tables" /> */}
      <Grid container spacing={4} className="bookings">
        <Grid item xs={12}>
          <div className="filter-bar">
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} className="filter-bar__container">
                <InputBox
                  handler="formik"
                  name="evalId"
                  placeholder="evalId"
                  value={formik.values.evalId}
                  formikI={formik}
                  grid={2}
                />
                <InputBox
                  handler="formik"
                  name="name"
                  placeholder="name"
                  value={formik.values.name}
                  formikI={formik}
                  grid={2}
                />
                <InputBox
                  handler="formik"
                  name="family"
                  placeholder="family"
                  value={formik.values.family}
                  formikI={formik}
                  grid={2}
                />
                <InputBox
                  handler="formik"
                  name="phone"
                  placeholder="phone"
                  value={formik.values.phone}
                  formikI={formik}
                  grid={2}
                />
                <SelectBox
                  handler="formik"
                  name="status"
                  grid={2}
                  value={formik.values.status}
                  items={statusOptions}
                  formikI={formik}
                />
                {expandFilter ? (
                  <>
                    <DatePickerComponent
                      handler="formik"
                      name="createDateFrom"
                      value={formik.values.createDateFrom}
                      formikI={formik}
                      grid={2}
                    />
                    <DatePickerComponent
                      handler="formik"
                      name="createDateEnd"
                      value={formik.values.createDateEnd}
                      formikI={formik}
                      grid={2}
                    />
                  </>
                ) : (
                  ""
                )}
                <Grid item xs={1} className="filter-bar__btn">
                  <Button type="submit" variant="contained">
                    {t("Search")}
                  </Button>
                </Grid>
                <IconExpand open={expandFilter} setOpen={setExpandFilter} />
              </Grid>
            </form>
          </div>
          <TableAdvance
            {...props}
            title="EvaluationsAndConsultants"
            data={dataTableData}
            serverSide
            totalCount={totalCount}
            query={requestsQuery}
            columns={columns}
            itemPath="/booking/"
            showFilter={false}
            sendMessage
            setOpenGroupSend={setOpenGroupSend}
            // sortDefault="id"
          />
        </Grid>

        {openGroupSend ? (
          <DialogSendAlertGroup
            open={openGroupSend}
            setOpen={setOpenGroupSend}
            searchData={historySearch}
          />
        ) : (
          false
        )}
      </Grid>
    </>
  );
}
