/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Tab,
  Tabs,
  Theme,
  createStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AssessmentIcon from "@material-ui/icons/Assessment";
import TicketIcon from "@material-ui/icons/Note";
import PaymentIcon from "@material-ui/icons/Payment";
import PersonIcon from "@material-ui/icons/Person";
import AirplayIcon from "@material-ui/icons/Airplay";
import { makeStyles } from "@material-ui/styles";
import { useQuery } from "@apollo/client";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { Message as MessageIcon } from "@material-ui/icons";
import { GET_DASHBOARD_INFO } from "./dashboardQueries";
import TableSimple from "../../components/micro/TableSimple";
import { mainPage, pagesMain, pagesOther } from "../../app/layout/Sidebar";
import { TabPanel } from "../Profile/Profile";
import DashboardConsultantWidget from "../../components/DashboardConsultantWidget";
import TodoList from "../../components/TodoList";
import StatusContractBarChart from "../../components/charts/StatusContractBarChart";
import RateOfEvaluationForm from "../../components/charts/RateOfEvaluationForm";
import ContractsInvoicesTodo from "../../components/ContractsInvoicesTodo";
import { useAccount } from "../../app/hooks";
import DashboardAgent from "./DashboardAgent";
import statics from "../../app/statics";
import packageJson from "../../../package.json";

const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    fontSize: "100px",
    margin: "10px",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#fff",
    boxShadow: "0 2px 10px 0 rgb(94 86 105 / 10%)",
    border: "1px solid #eee",
    flexGrow: 1,
  },
  blockContainer: {
    display: "flex",
    gap: "10px",
  },

  body: {
    // padding: "10px",
    marginLeft: "20px",
    "&>h3": {
      padding: "0px",
      // margin: "15px",
      // marginTop: "15px",
    },
  },
  bodyNews: {
    background: "#ffffff",
    borderRadius: "10px",
    padding: "20px",
  },
  titleNews: {
    padding: "10px",
    backgroundColor: "#fff",
    border: "1px solid #eee",
    display: "flex",
    alignItems: "center",
  },
  titleNewsIcon: {
    color: theme.palette.secondary.main,
    marginRight: "10px",
  },
  icon1: {
    minWidth: "unset",
    // color: theme.palette.icon.default,
    "&>svg": {
      fontSize: "5em",
    },
  },
  holder: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid #ddd",
    height: "100%",
    padding: "20px 0",
    // margin: "10px",
    borderRadius: "5px",
    "&>div:hover": {
      cursor: "pointer",
    },
  },

  footer: {
    padding: "20px",
    textAlign: "left",
    fontWeight: "bold",
    color: "gray",
  },
}));

const Dashboard = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [analyticInfo, setAnalyticInfo] = useState({});
  const [tab, setTab] = useState(0);

  const {
    isAdmin, isAgent, isAgentMaster, role,
  } = useAccount();
  const { data, loading } = useQuery(GET_DASHBOARD_INFO, {});

  useEffect(() => {
    if (isAgentMaster) {
      setTab(1);
    }
  }, []);

  useEffect(() => {
    if (data) {
      const ContractsCount = data.requestsConnection.aggregate.count;
      const OpenTicket = data.ticketsConnection.aggregate.count;
      const CustomersCount = data.usersConnection.aggregate.totalCount;
      const EvaluationsCount = data.evaluationsConnection.aggregate.totalCount;
      const InvoicesCount = data.invoicesConnection.aggregate.count;
      const ConsultantCount = data.evaluationsConnection.aggregate.count;
      // const unreadCount = _.get(OverviewQuery, 'data.messagesConnection.aggregate.count', 0)
      setAnalyticInfo((preData) => ({
        preData,
        ContractsCount,
        OpenTicket,
        CustomersCount,
        EvaluationsCount,
        InvoicesCount,
        ConsultantCount,
      }));
    }
  }, [loading]);

  const blocks = [
    {
      icon: <AssessmentIcon className={classes.icon} />,
      title: "ContractsCount",
      value: "0",
      link: "requests",
    },
    {
      icon: <TicketIcon className={classes.icon} />,
      title: "EvaluationsCount",
      value: "0",
      link: "requests",
      hide: [statics.roles.AgentMaster],
    },
    {
      icon: <PaymentIcon className={classes.icon} />,
      title: "CustomersCount",
      value: "0",
      link: "requests",
    },
    {
      icon: <PersonIcon className={classes.icon} />,
      title: "InvoicesCount",
      value: "0",
      link: "requests",
    },
    {
      icon: <MessageIcon className={classes.icon} />,
      title: "ConsultantCount",
      value: "0",
      link: "messages",
      hide: [statics.roles.AgentMaster],
    },
  ];

  let blocks_ = [];
  let quickAccess = [];

  const customPages = [
    {
      title: "MessageTemplate",
      slug: "message-templates",
      icon: <AirplayIcon />,
      onlyClient: true,
      dashboard: true,
    },
  ];
  blocks_ = blocks.filter((item) => !item.hide?.includes(role));
  quickAccess = [...pagesMain, ...customPages, ...pagesOther].filter(
    (item) => item.dashboard,
  );

  const headers = [
    {
      title: "title",
    },
    {
      title: "type",
      key: "t",
    },
    {
      title: "created_at",
      key: "date",
    },
  ];

  const rows = data?.systemNews || [];

  const goTo = (page) => {
    props.history.push(mainPage + page.slug);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  if (isAdmin || isAgentMaster) {
    return (
      <Grid container spacing={6} xs={12} md={12}>
        <Grid item xs={12}>
          <h2>{t("AnalyticInfo")}</h2>
          <div className={classes.blockContainer}>
            {blocks_.map((item: any, i) => (
              <Card className={`${classes.card} card_${i}`}>
                <div className={classes.body}>
                  <h3>{t(item.title)}</h3>
                  <h1>{(!loading && analyticInfo[item.title]) || 0}</h1>
                </div>
                <CardMedia title={item.title}>{item.icon}</CardMedia>
              </Card>
            ))}
          </div>
        </Grid>

        <Grid item xs={12} md={12} lg={8}>
          <Card>
            <CardContent>
              <Tabs value={tab} onChange={handleChange}>
                {isAdmin ? (
                  <Tab label={t("LatestConsultant")} value={0} />
                ) : (
                  false
                )}
                <Tab label={t("Messages")} value={1} />
                <Tab label={t("InvoicesTodo")} value={2} />
              </Tabs>
              <TabPanel value={tab} index={0}>
                <DashboardConsultantWidget />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <TodoList />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <ContractsInvoicesTodo />
              </TabPanel>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          {/* <h2 style={{ margin: "0 0 15px" }}>{t("QuickAccess")}</h2> */}
          <Grid container spacing={2} xs={12} md={12}>
            {quickAccess.map((item) => (
              <Grid item lg={4} md={2} xs={6}>
                <div className={classes.holder} onClick={() => goTo(item)}>
                  <div className={classes.icon1}>{item.icon}</div>
                  <div>{t(item.title)}</div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {false ? (
          <Grid item xs={12} md={12} lg={6}>
            <div className={classes.bodyNews}>
              <div className={classes.titleNews}>
                <NotificationsActiveIcon className={classes.titleNewsIcon} />
                {` ${t("dashboard.titleNews")}`}
              </div>
              <TableSimple rows={rows} headers={headers} headerHide />
            </div>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12} md={12} lg={6}>
          <StatusContractBarChart />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <RateOfEvaluationForm />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Card>
            <CardContent>
              <h2>Conversion rate</h2>
              <div>
                # Conversion rate Evaluation Form to Session:
                {" "}
                <b>10/100</b>
              </div>
              <div>
                # Conversion rate Evaluation Session to Contract:
                {" "}
                <b>5/20</b>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div
            className={classes.footer}
          >
            {`Panel V${packageJson.version}`}
          </div>
        </Grid>
      </Grid>
    );
  }
  if (isAgent) {
    return <DashboardAgent />;
  }
  return <div>Nothing...</div>;
};

export default Dashboard;
