import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import logo from "../../assets/images/logo.svg";
import FORGOT_PASSWORDS from "./forgotQueries";
import SnackbarMicro from "../../components/micro/SnackbarMicro";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.border.default,
    borderRadius: "5px",
    padding: theme.spacing(5),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: "250px",
    background: "#bf0d32",
    padding: "0 10px",
    borderRadius: "10px",
  },
}));

export default function Forgot(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>();
  const [snackError, setSnackError] = useState<any>();

  const [forgot, responseForgot] = useMutation(FORGOT_PASSWORDS, {
    onError(error) {
      setSnackError({
        open: true,
        message: error.message,
        type: "error",
      });
    },
  });
  const onSubmit = (e) => {
    e.preventDefault();
    forgot({ variables: { email } });
    // check if not registered before, complete register
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="logo" className={classes.logo} />
        <br />
        <Typography component="h1" variant="h5">
          {t("SignInOrRegister")}
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("Forgot_button")}
          </Button>
          <Grid container>
            <Grid item xs>
              <Button color="default" variant="outlined" onClick={() => props.history.push("login")}>
                {t("back")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <SnackbarMicro
        {...snackError}
        setOpen={setSnackError}
      />
    </Container>
  );
}
