import { useMutation, useQuery } from "@apollo/client";
import { Checkbox, Chip, IconButton } from "@material-ui/core";
import { AddAlarmOutlined, ArrowBack } from "@material-ui/icons";
import _ from "lodash";
import moment from "moment-jalaali";
import { useSnackbar } from "notistack";
import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getFakeEvalId, stripText } from "../app/utils/helper";
import { GET_MESSAGES, UPDATE_MESSAGE } from "../pages/Messages/messagesQueries";
import TableSimple from "./micro/TableSimple";
import "./styles.scss";

const TodoList = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedRow, setSelectedRow] = useState<string>();

  const getMessages = useQuery(GET_MESSAGES, {
    variables: {
      limit: 10,
      start: 0,
      sort: "id:desc",
      seen: false,
    },
  });

  const [updateMessage, responseUpdateMessage] = useMutation(UPDATE_MESSAGE, {
    refetchQueries: ["GET_MESSAGES"],
  });

  const headers = [
    {
      title: "id",
    },
    {
      title: "contractName",
    },
    {
      title: "title",
    },
    {
      title: "actions",
    },
  ];

  const setSeen = (messageId: string) => {
    setSelectedRow(messageId);
    setTimeout(() => {
      updateMessage({
        variables: {
          mId: messageId,
        },
      }).then((item) => {
        enqueueSnackbar(t("StatusChangedToSeen"), {
          variant: "success",
        });
      }).catch(() => {
        enqueueSnackbar(t("ErrorHappened"), {
          variant: "error",
        });
      });
      setSelectedRow("");
    }, 400);
  };

  const rows = useMemo(() => {
    const temps = _.get(getMessages, "data.messages", []);
    return temps?.map((item) => {
      const date = moment(item.created_at).format("jYYYY-jMM-jDD");
      const type_ = t(`messageStatus.${item.type}`);
      const body = stripText(item.body);
      let contractName;
      if (item.request) {
        contractName = `${item.request.name} ${item.request.family} `;
      }
      if (item.evaluation) {
        contractName = `${item.evaluation.name} ${item.evaluation.family} `;
      }
      const reminder = (
        <div className="reminder">
          <span>
            <Checkbox
              name="check"
              checked={selectedRow === item.id || item.seen}
              disabled={item.seen}
              onClick={() => setSeen(item.id)}
            />
            {item.id}
          </span>
          <span style={{ display: "none" }}>
            <IconButton size="small"><AddAlarmOutlined /></IconButton>
          </span>
        </div>
      );

      const actions = (
        <div className="actions">
          {type_ && (
          <span>
            <Chip className="tag" label={type_} />
          </span>
          )}
          <span>{date}</span>
          <a onClick={() => setSeen(item.id)}>
            {item.request ? (
              <Link to={`/request/${item.request?.id}`}>
                <ArrowBack />
              </Link>
            ) : "" }
            {item.evaluation ? (
              <Link to={`/booking/${getFakeEvalId(item.evaluation?.id)}`}>
                <ArrowBack />
              </Link>
            ) : "" }
          </a>
        </div>
      );

      return {
        id: reminder,
        contractName,
        title: body,
        disabled: item.seen,
        actions,
      };
    });
  }, [getMessages.data, selectedRow]);

  return (
    <div className="todo-list">
      {/* <h2 style={{ margin: "0 0 15px" }}>{t("LatestConsultant")}</h2> */}
      <TableSimple rows={rows} headers={headers} headerHide />
    </div>
  );
};
export default TodoList;
