/* eslint-disable no-nested-ternary */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment-jalaali";
import { useTranslation } from "react-i18next";
import { Button, IconButton } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { CloudDownloadOutlined, Delete } from "@material-ui/icons";
import { forceDownloadFile } from "../../app/utils/helper";
import { API_SERVER } from "../../Config";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return {
    name, calories, fat, carbs, protein,
  };
}

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

export default function TableSimple(props: any) {
  const classes = useStyles();
  const {
    headers, rows, headerHide, size,
  } = props;
  const { t } = useTranslation();

  const downloadFile = (file) => {
    if (file) {
      forceDownloadFile(API_SERVER() + file.url, file.name);
    }
  };

  const headers_ = headers.filter((h) => !h.hide === true);

  return (
    <TableContainer>
      <Table className={classes.table} size={size}>
        {!headerHide ? (
          <TableHead>
            <TableRow>
              {headers_.map((header) => (
                <TableCell>{t(header.title)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : "" }
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={row[headers[i]]} className={row?.disabled ? "row--disabled" : "row--default"}>
              {headers_.map((header) => (
                <TableCell scope="row">
                  {header?.key === "date" ? (row[header.title] ? moment(row[header.title]).format("jYYYY-jMM-jDD") : "-") : ""}
                  {header?.key === "dateTime" ? moment(row[header.title]).format("jYYYY-jMM-jDD HH:mm") : ""}
                  {header?.key === "t" ? t(row[header.title]) : ""}
                  {header?.key === "actions" ? (
                    <div>
                      {row[header.title]?.download ? (
                        <div>
                          <IconButton size="small">
                            <CloudDownloadOutlined onClick={() => {
                              downloadFile(row[header.title]?.download);
                            }}
                            />
                          </IconButton>

                          <IconButton size="small">
                            <Delete onClick={() => row[header.title]?.remove()} />
                          </IconButton>
                        </div>
                      ) : "" }
                    </div>
                  ) : ""}
                  {header?.key === "cost" ? (
                    <NumberFormat
                      value={row[header.title]}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  ) : ""}
                  {header?.key === "button" ? (
                    <Button
                      disabled={row[header.title].disabled}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        header.action(row?.data);
                      }}
                    >
                      {t(row[header.title].title)}
                    </Button>
                  ) : ""}
                  {!header?.key ? row[header.title] : ""}
                  {header?.key === "custom" ? (row.custom()) : ""}
                  {header?.key === "custom2" ? (row[header.title]) : ""}
                  {header?.name ? (row[header.name]) : ""}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
