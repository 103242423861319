import React from "react";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { getGrid } from "../../app/utils/helper";

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

type IItem = {
  label: string,
  value: string,
  disabled?: boolean,
  description?: boolean,
}
type IPropsInput = {
    name: string,
    value: string,
    grid?: number,
    required?: boolean,
    items: IItem[],
    setValue?: Function,
    handler?: "formik" | "material",
    formikI?: any,
    row?: boolean,
    hideLabel?: boolean,
    size?: string,
}
const RadioGroupBox = (props: IPropsInput) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    name, value, setValue, grid, items, required,
    handler,
    formikI,
    row,
    hideLabel,
    size,
  } = props;

  const content = () => (
    <FormControl
      component="fieldset"
      required={required}
      error={handler === "formik"
        ? formikI.touched[name] && Boolean(formikI.errors[name]) : false}
    >
      {!hideLabel ? <FormLabel component="legend">{t(name)}</FormLabel> : ""}
      <RadioGroup
        row={row}
        aria-label={name}
        name={name}
        value={value}
        onChange={(e) => (handler === "formik"
          ? formikI.handleChange(e)
          : setValue((e.target as HTMLInputElement).value))}
      >
        {items.map(((item, index) => (
          <div className="radio__option">
            <FormControlLabel
              key={index}
              value={item.value}
              control={<Radio size={size || "medium"} />}
              label={t(item.label)}
              disabled={item.disabled}
            />
            {item && <div className="radio__description">{t(item.description)}</div>}
          </div>
        )))}
      </RadioGroup>
    </FormControl>
  );
  if (grid) {
    const [xs_, md_, lg_] = getGrid(grid);
    return (
      <Grid
        item
        xs={xs_}
        md={md_}
        lg={lg_}
      >
        {content()}
      </Grid>
    );
  }
  return (
    <>
      {content()}
    </>
  );
};

RadioGroupBox.defaultProps = {
  grid: 0,
  required: false,
  hideLabel: false,
  row: false,
  handler: "material",
  formikI: null,
  setValue: null,
  size: "medium",
};

export default RadioGroupBox;
