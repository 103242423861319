import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert/Alert";
import { API_SERVER } from "../Config";
import { AppContext } from "../app/layout/AppContext_";
import InputFilePrime from "./micro/InputFilePrime";

const useStyles = makeStyles((theme: Theme) => createStyles({
  description: {
    paddingBottom: "10px",
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
    "& button": {
      height: "52px",
      marginTop: "5px",
    },
  },
  imgPreview: {
    textAlign: "center",
  },
  imgPreviewImg: {
    width: "60%",
    border: "1px solid #eee",
    borderRadius: "10px",
    padding: "5px",
  },
  rtlDialog: {
    direction: "ltr",
  },
  infoMessage: {
    textAlign: "justify",
    marginBottom: "10px",
  },
}));

export default function FormDialogUploadFile(props: any) {
  const { t } = useTranslation();
  const app = useContext(AppContext);

  const {
    open,
    setOpen,
    handleUploadFile,
    dataRow,
  } = props;
  const classes = useStyles();
  const [attachment, setAttachment] = React.useState();

  const handleClose_ = () => {
    setOpen();
    // handleClose();
  };

  const handleUploadFile_ = (e) => {
    e.preventDefault();
    handleUploadFile(attachment);
    handleClose_();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      className={!app.isRTL ? classes.rtlDialog : undefined}
      // onClose={handleClose_}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("Dialog.Upload")}</DialogTitle>
      <form>
        <>
          <DialogContent>
            <Alert hidden={!dataRow?.attachment?.description} className={classes.infoMessage} severity="warning">{dataRow?.attachment?.description}</Alert>
            {dataRow?.type === "LINK" ? (
              <div>
                <p>{t("PleaseGoToFollowLinkAndCompleteForm")}</p>
                <Button
                  href={dataRow?.fileInfo?.link}
                  target="_blank"
                  rel="noreferrer"
                  color="secondary"
                  variant="outlined"
                >
                  {t("visitLink")}

                </Button>
                <hr />
              </div>
            ) : ""}
            {dataRow?.sample_file ? (
              <div>
                {t("PleaseUploadAsSample")}
                :
                (
                <a href={API_SERVER() + dataRow?.sample_file?.url} target="_blank" rel="noreferrer">{t("viewExample")}</a>
                )
                {/* <div className={classes.imgPreview}>
                  <img className={classes.imgPreviewImg} src={} alt="" />
                </div> */}
                <br />
                <br />
              </div>
            ) : ""}
            <Grid container spacing={1}>
              <InputFilePrime
              // multiple="false"
                showTitle={false}
                name="attachment"
                file={attachment}
                setFile={setAttachment}
                grid={12}
                required
                mode="singleImage"
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button>
            <Button color="inherit" onClick={handleUploadFile_} disabled={!attachment}>
              {t("Accept")}
            </Button>
          </DialogActions>
        </>
      </form>
    </Dialog>
  );
}
