/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import {
  Grid, IconButton, LinearProgress, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Files from "react-files";
import CloseIcon from "@material-ui/icons/Delete";
import ImageIcon from "@material-ui/icons/CloudUpload";
import { useMutation } from "@apollo/client";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { UPLOAD_FILE } from "./inputFileQueries";
import { INotification } from "./SnackbarMicro";
import { API_SERVER } from "../../Config";
import { getGrid, kbToMb } from "../../app/utils/helper";

import "./InputFilePrime.scss";

const useStyles = makeStyles((theme: Theme) => createStyles({
  box: {
    backgroundColor: "#fff",
    padding: "0px",
  },
  name: {
    marginBottom: "10px",
    marginTop: "15px",
  },
  dropZone: {
    borderWidth: "3px",
    borderStyle: "dashed",
    borderColor: "#ddd",
    padding: "20px",
    textAlign: "center",
  },
  dropZoneHide: {
    display: "none",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "red",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tile: {
    width: "100px",
  },
  tileFull: {
    // width: "100%",
    height: "200px",
    margin: "auto",
  },
  extension: {
    // width: "100px",
    // height: "100px",
    padding: "60px 40px",
    textAlign: "center",
    backgroundColor: "#3c3c3c",
    borderRadius: "10px",
    marginBottom: "10px",
    color: "#fff",
    fontSize: "1.3em",
  },
  ul: {
    margin: "0px",
    marginTop: "20px",
    marginBottom: "50px",
    padding: "0px",
    paddingTop: "20px",
    borderTopWidth: "1px",
    borderTopColor: "#ddd",
    borderTopStyle: "solid",
  },
  remove: {
    padding: "15px",
  },
  bar: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  tileTitle: {
    textAlign: "center",
    padding: "10px",
    fontSize: "40px",
    color: "#787878",
    textTransform: "uppercase",
  },
  saveButton: {
    // padding: "0px !important",
  },
}));

type IPropsInput = {
    name: string,
    multiple?: boolean,
    grid?: number,
    file: object,
    setFile: Function,
    required?: boolean,
    disable?: boolean,
    mode?: "singleImage" | "singleVideo",
    showTitle?: boolean,
    downloadBarEnable?: boolean,
}
const InputFilePrime = (props: IPropsInput) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    name, file,
    setFile, multiple, grid,
    required, disable,
    mode,
    showTitle,
    downloadBarEnable,
  } = props;
  const [notif, setNotif] = useState<INotification>();
  const [fileLocal, setFileLocal] = useState<any>(file);
  const confirm = useConfirm();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [percent, setPercent] = useState(100);
  let timer;

  useEffect(() => () => {
    clearInterval(timer);
    setPercent(0);
  }, []);

  useEffect(() => {
    setFileLocal(file);
  }, [file]);

  const name_ = name.toString();
  const [uploadFile, responseUploadFile] = useMutation(UPLOAD_FILE);

  const onFilesChange = (fileVal) => {
    setFileLocal(fileVal[0]);
    setPercent(0);
    fileUpload(fileVal[0]);
  };

  const onFilesError = (error, file_) => {
    if (error.code === 2) {
      enqueueSnackbar(t("FileIsToLargeMustBeUnder5Mb"), {
        variant: "error",
      });
    } else {
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    }
  };

  const fileRemoveOne = (file_) => {
    confirm({
      title: t("SureForDelete"), description: "", confirmationText: t("yes"), cancellationText: t("no"),
    }).then(() => {
      setFile(null);
      clearInterval(timer);
    });
  };

  const fileUpload = async (file_) => {
    timer = setInterval(() => {
      if (percent <= 100) {
        setPercent((p) => p + 1);
      } else {
        clearInterval(this);
      }
    }, 500);

    uploadFile({
      variables: {
        file: file_,
      },
    })
      .then((result) => {
        setPercent(100);
        clearInterval(timer);
        // setNotif({
        //   open: true, message: "successUploaded", type: "success", setOpen: setNotif,
        // });
        setFile(result.data.upload);
      }).catch((error) => {
        console.log("error=======", error);
        clearInterval(timer);
        // enqueueSnackbar(t("ErrorHappened"), {
        //   variant: "error",
        // });
        // setNotif({
        //   open: true, message: "errorUpload", type: "error", setOpen: setNotif,
        // });
      });
  };

  // const hideDropZonePlace = !!fileLocal;
  // const isUploaded = !!fileLocal?.created_at;
  const isImage = fileLocal?.type?.search("image") >= 0 || fileLocal?.mime?.search("image") >= 0;
  const extensionFile = fileLocal?.extension || fileLocal?.ext;

  const box = () => (
    <div className="file-upload">
      {showTitle ? <div className={classes.name}>{t(name)}</div> : "" }
      <div className={classes.box}>

        <Files
          className={classes.dropZone}
          onChange={onFilesChange}
          onError={onFilesError}
          // accepts={["image/png", ".pdf", "audio/*"]}
          multiple={false}
          maxFileSize={40000000}
          minFileSize={0}
          clickable={!disable}
        >
          <div>
            <div><ImageIcon style={{ fontSize: "64px", color: "#acacac" }} /></div>
            Drop file here or click to upload
          </div>
        </Files>

        {mode === "singleImage" && fileLocal ? (
          <div className="file-uploaded">
            <div className="img">
              {isImage ? (
                <img
                  className={classes.tileFull}
                  alt="alt"
                  src={fileLocal?.preview?.url || API_SERVER() + fileLocal?.url}
                />
              ) : <div className={classes.tileTitle}>{extensionFile}</div>}
            </div>

            <div className="detail">
              <div className="name">
                {fileLocal.name}
                {" "}
              </div>
              <LinearProgress className="progress" variant="determinate" value={percent} />
              <div>{fileLocal.sizeReadable || (kbToMb(fileLocal?.size))}</div>
            </div>

            <div className="actions">
              <IconButton
                id={fileLocal?.id}
                onClick={() => fileRemoveOne(file)}
                // variant="outlined"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </div>

          </div>
        ) : ""}
      </div>
    </div>
  );

  if (grid) {
    const [xs_, md_, lg_] = getGrid(grid);
    return (
      <Grid
        item
        md={md_}
        xs={xs_}
        lg={lg_}
      >
        {box()}
      </Grid>
    );
  }
  return (
    <>
      {box()}
    </>
  );
};

InputFilePrime.defaultProps = {
  multiple: false,
  grid: 0,
  required: false,
  disable: false,
  mode: "singleImage",
  showTitle: true,
  downloadBarEnable: false,
};

export default InputFilePrime;
