import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { purple } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const primary = purple[500]; // #f44336

// eslint-disable-next-line no-unused-vars
const NotFound = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          minHeight: "90vh",
          // backgroundColor: primary,
        }}
      >
        <Typography variant="h1" style={{ color: "black" }}>
          404
        </Typography>
        <Typography variant="h6" style={{ color: "gray" }}>
          {t("text404Error")}
        </Typography>
        <Button variant="outlined" onClick={() => history.goBack()}>{t("backToHome")}</Button>
      </Box>
    </div>
  );
};

export default NotFound;
