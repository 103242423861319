import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  TextField, Theme, Typography, createStyles, makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { Done as DoneIcon } from "@material-ui/icons";
import _ from "lodash";
import RadioGroupBox from "../micro/RadioGroupBox";
import DatePickerComponent from "../micro/Datepicker";
import { GET_INVOICE_BY_ID, UPDATE_INVOICE } from "../../pages/Invoice/invoiceQueries";
import { CREATE_PAYMENT } from "../../pages/Request/steps/requestStepBQueries";

import { dateIsoFa2 } from "../../app/utils/helper";
import "./DialogCheckInvoice.less";

const useStyles = makeStyles((theme: Theme) => createStyles({
  description: {
    paddingBottom: "10px",
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
    "& button": {
      height: "52px",
      marginTop: "5px",
    },
  },
  content: {
    display: "flex",
  },
  boxPayPart: {
    border: "1px solid #EEE",
    padding: "10px",
    alignItems: "center",
    display: "flex",
    gap: "10px",
    "& .MuiButton-root": {
      height: "40px",
    },
    "& .MuiFormControl-root": {
      margin: "0px !important",
    },
  },
  table: {
    width: "100%",
    "& td,& th": {
      border: "1px solid #eee",
      padding: "5px",
    },
  },
  payList: {
    marginTop: "10px",
    "&>.title": {
      padding: "10px 5px",
      backgroundColor: "#efefef",
    },
  },
}));

const DialogCheckInvoice = (props: any) => {
  const history = useHistory();
  const [description, setDescription] = useState<any>();
  const [statusInvoice, setInvoiceStatus] = useState<any>();
  const [paymentDate, setPaymentDate] = useState<any>();
  const [partAmount, setPartAmount] = useState<any>();
  const [loading, setLoading] = useState<boolean>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    open,
    setOpen,
  } = props;

  const invoiceQuery = useQuery(GET_INVOICE_BY_ID, { variables: { id: Number(open.id) } });
  const invoiceDetail = _.get(invoiceQuery.data, "invoice", {});

  const [updateInvoice, responseUpdateInvoice] = useMutation(UPDATE_INVOICE, {
    refetchQueries: ["GET_INVOICES_TODO", "GetRequestPaymentInstallment"],
  });
  const [createPayment, responseCreatePayment] = useMutation(CREATE_PAYMENT, {
    refetchQueries: ["GET_INVOICES_TODO", "GetRequestPaymentInstallment"], // "GET_PAYMENT_BY_INVOICE_ID"],
  });

  const classes = useStyles();
  const [attachment, setAttachment] = React.useState();

  const handleClose_ = () => {
    setOpen();
  };

  const onSubmit = async () => {
    setLoading(true);
    const pDate = paymentDate;
    let response;
    try {
      if (statusInvoice === "DONE") {
        createPayment({
          variables: {
            invoiceId: Number(open.id),
            status: "offline_paid",
            payDate: pDate,
            confirmed: true,
            payTime: "01:01:01.000",
            amount: Number(open.cost),
            error_message: description,
          },
        }).then(() => {
          updateInvoice({
            variables: {
              status: "done",
              id: Number(open.id),
            },
          });
        });
      } else if (statusInvoice === "CANCEL") {
        updateInvoice({
          variables: {
            status: "canceled",
            id: Number(open.id),
          },
        });
      }
      setTimeout(() => {
        handleClose_();
      }, 1000);
    } catch (e1: any) {
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    }
  };

  const onSubmitPartPay = async () => {
    setLoading(true);
    if (statusInvoice === "PART") {
      const pDate = paymentDate;
      try {
        createPayment({
          variables: {
            invoiceId: Number(open.id),
            status: "offline_paid",
            payDate: pDate,
            payTime: "01:01:01.000",
            amount: Number(partAmount),
            error_message: "",
            confirmed: true,
          },
        }).then((r) => {
          invoiceQuery.refetch();
          setPartAmount("");
          setPaymentDate(null);
        });
      } catch (e1: any) {
        enqueueSnackbar(t("ErrorHappened"), {
          variant: "error",
        });
      }
    }
  };

  const optionsStatus = [
    { label: t("INVOICE_DONE"), value: "DONE" },
    { label: t("INVOICE_PART_PAID"), value: "PART" },
    { label: t("INVOICE_CANCEL"), value: "CANCEL" },
  ];
  const paymentList = invoiceDetail?.payments?.slice() || [];

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose_}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("UpdateInvoiceStatus")}</DialogTitle>
      <form>
        <>
          <DialogContent style={{ paddingTop: 0, textAlign: "justify" }}>
            <div>
              <p>{t("UpdateInvoiceStatusText")}</p>
              <div>
                <RadioGroupBox
                  name=""
                  value={statusInvoice}
                  setValue={setInvoiceStatus}
                  grid={12}
                  items={optionsStatus}
                />
              </div>
              {statusInvoice === "DONE" ? (
                <DatePickerComponent
                  name="paymentDate"
                  value={paymentDate}
                  required
                  setValue={setPaymentDate}
                  grid={6}
                />
              ) : false}
              {statusInvoice === "PART" ? (
                <form>
                  <div className={classes.boxPayPart}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      label={t("partAmount")}
                      autoFocus
                      value={partAmount}
                      multiline
                      fullWidth
                      size="small"
                      onChange={(e) => setPartAmount(e.target?.value)}
                    />
                    <DatePickerComponent
                      name="paymentDate"
                      value={paymentDate}
                      required
                      setValue={setPaymentDate}
                    />
                    <IconButton disabled={!paymentDate} onClick={onSubmitPartPay}>
                      <DoneIcon style={{ color: paymentDate ? "blue" : "" }} />
                    </IconButton>
                  </div>
                </form>
              ) : false}
              <div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={t("description")}
                  autoFocus
                  value={description}
                  multiline
                  onChange={(e) => setDescription(e.target?.value)}
                />
              </div>

              {paymentList?.length ? (
                <div className={classes.payList}>
                  <Typography className="title">
                    {t("historyPayments")}
                  </Typography>
                  <table className={classes.table}>
                    <tr>
                      <th>{t("partAmount")}</th>
                      <th>{t("paymentDate")}</th>
                    </tr>
                    {paymentList.reverse().map((item:any, i) => (
                      <tr key={i}>
                        <td>
                          {item.amount}
                          {" "}
                          {t("dollar")}
                        </td>
                        <td>{dateIsoFa2(item.payment_date)}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              ) : false }
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button>
            <Button color="inherit" onClick={onSubmit} disabled={!statusInvoice}>
              {t("SAVE")}
            </Button>
          </DialogActions>
        </>
      </form>
    </Dialog>
  );
};

export default DialogCheckInvoice;
