import { gql } from "@apollo/client";

export const GET_ARTICLES = gql`  
query GetArticles($sort: String) {
  articles(
    sort: $sort
  ){
    id
    created_at
    updated_at
    title
    description
    slug
    category {
      id
      name
      slug
    }
    image {
      id
      url
    }
  }
}
`;

export const GET_ARTICLE = gql`  
query GetArticle($id: ID!) {
  article(
    id: $id
  ){
    id
    created_at
    updated_at
    title
    title_en
    description
    description_en
    content
    content_en
    slug
    slug_en
    category {
      id
      name
      slug
    }
    image {
      id
      name
      caption
      width
      height
      previewUrl
      url
      ext
      mime
    }
    attachement {
      id
      created_at
      name
      updated_at
      url
      previewUrl
    }
    motion {
      id
      name
      caption
      width
      height
      previewUrl
      url
      ext
    }
  }
}
`;

export const CREATE_ARTICLE = gql`
mutation CREATE_ARTICLE(
  $title: String!,
  $titleEn: String,
  $description: String!,
  $descriptionEn: String,
  $content: String!,
  $contentEn: String,
  $slug: String!,
  $slugEn: String,
  $thumbnail: ID,
  $attachment: [ID],
  $category: ID,
  $motionGraphic: ID,
  ){
  createArticle(input: {
    data: {
      title: $title
      title_en: $titleEn
      description: $description
      description_en: $descriptionEn
      content: $content
      content_en: $contentEn
      slug: $slug
      slug_en: $slugEn
      image: $thumbnail
      attachement: $attachment
      category: $category
      motion: $motionGraphic
    }
  }){
    article {
      id
    }
  }
}
`;

export const UPDATE_ARTICLE = gql`
mutation UPDATE_ARTICLE(
  $id: ID!, 
  $title: String!,
  $titleEn: String,
  $description: String!,
  $descriptionEn: String,
  $content: String!,
  $contentEn: String,
  $slug: String!,
  $slugEn: String,
  $thumbnail: ID,
  $attachment: [ID],
  $category: ID,
  $motionGraphic: ID,
  ){
    updateArticle(input: {
      where: {
        id: $id
      }
      data: {
        title: $title
        title_en: $titleEn
        description: $description
        description_en: $descriptionEn
        content: $content
        content_en: $contentEn
        slug: $slug
        slug_en: $slugEn
        image: $thumbnail
        attachement: $attachment
        category: $category
        motion: $motionGraphic
      }
    }) {
      article {
        id
      }
    }
}
`;

export const GET_CATEGORIES = gql`
query GetCats($sort: String) {
  categories(sort: $sort){
    id
    created_at
    updated_at
    name
    slug
    name_en
    slug_en
  }
}
`;

export const DELETE_ARTICLE = gql`
mutation DELETE_ARTICLE(
  $id: ID!,
){
  deleteArticle(input: {
    where: {
      id:$id
    }
  }){
    article {
      id
    }
  }
}
`;

export const DELETE_CATEGORY = gql`
mutation DELETE_CATEGORY(
  $id: ID!,
){
  deleteCategory(input: {
    where: {
      id:$id
    }
  }){
    article {
      id
    }
  }
}
`;
export default {};
