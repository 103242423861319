import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";

import { getGrid } from "../../app/utils/helper";

const useStyles = makeStyles((theme: Theme) => createStyles({
  textField: {
    marginRight: theme.spacing(1),
  },
}));

type IPropsInput = {
    name: string,
    value: string | number | undefined | null,
    type?: "number" | "text" | "password" | "price",
    grid?: number,
    rows?: number,
    setValue?: Function,
    required?: boolean,
    label?: boolean,
    readOnly?: boolean,
    defaultValue?: string,
    max?: number,
    min?: number,
    error?: boolean,
    handler?: "formik" | "material",
    formikI?: any,
    helper?: boolean,
    placeholder?: string
    dir?: string,
    variant?: "outlined" | "standard",
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
}

const InputBox = (props: IPropsInput) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    name, value, setValue, type, grid, rows,
    label,
    required, readOnly,
    defaultValue,
    max,
    min,
    error,
    handler,
    formikI,
    helper,
    placeholder,
    dir,
    variant,
  } = props;
  const name_ = name.toString();

  let error_ = true;
  let helperText_ = "";
  if (handler === "formik") {
    error_ = (formikI.touched[name] || formikI.submitCount > 0) && Boolean(formikI.errors[name]);
    helperText_ = helper && formikI.touched[name]
      && Boolean(formikI.errors[name]) ? formikI.errors[name] : "";
  } else {
    error_ = (required && (value !== undefined) && !value) || error;
  }

  const xxx = (e) => {
    if (type === "price") {
      formikI.setFieldValue(name, e.target.value);
    } else formikI.handleChange(e);
  };

  const textBox = () => (
    <TextField
      id={name}
      InputProps={{
        classes: {
          input: classes.textField,
        },
        readOnly,
        inputComponent: type === "price" ? NumberFormatCustom as any : undefined,
      }}
      defaultValue={defaultValue}
      rows={rows}
      multiline={rows ? rows > 1 : undefined}
      value={value}
      onChange={(e) => (handler === "formik"
        ? xxx(e) : setValue(e.target.value))}
      margin="normal"
      label={label && t(name_)}
      placeholder={(placeholder && t(placeholder)) || (label && t(name_))}
      type={type}
      fullWidth={!!grid}
      variant={variant || "outlined"}
      required={required || undefined}
      error={error_}
      helperText={helperText_}
      size="small"
      dir={dir}
    />
  );

  if (grid) {
    const [xs_, md_, lg_] = getGrid(grid);
    return (
      <Grid
        item
        md={md_}
        xs={xs_}
        lg={lg_}
      >
        {textBox()}
      </Grid>
    );
  }
  return (
    <>
      {textBox()}
    </>
  );
};

InputBox.defaultProps = {
  type: "text",
  grid: 0,
  rows: 1,
  required: false,
  label: true,
  readOnly: false,
  defaultValue: "",
  max: 0,
  min: 0,
  error: false,
  handler: "material",
  formikI: null,
  setValue: null,
  helper: false,
  placeholder: null,
  dir: undefined,
  variant: "outlined",
};

export default InputBox;
