import React, {
  useContext, useEffect, useState,
} from "react";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import InputBox from "../../../components/micro/InputBox";
import DatePickerComponent from "../../../components/micro/Datepicker";
import RadioGroupBox from "../../../components/micro/RadioGroupBox";
import SelectBox from "../../../components/micro/SelectBox";
import statics from "../../../app/statics";
import NavBarFooter from "./NavBarFooter";
import { UPDATE_REQUEST } from "../steps/requestStepAQueries";
import { RequestContext } from "../context/requestContext";
import { Boolean_ } from "../../../app/utils/helper";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    // paddingRight: theme.spacing(5),
    // paddingLeft: theme.spacing(5),
  },
  child: {
    marginTop: "10px",
    background: "#eee",
    padding: "5px",
  },
}));

type IScore = {
    read: number,
    speak: number,
    write: number,
    listen: number
}

type IChild = {
    id: string,
    name: string,
    nameEn: string,
    birthday: string,
    levelEducation: string,
    levelEducationNumber: string,
}

const RequestFamilyDetail = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { stepProps } = props;
  const context = useContext(RequestContext);
  const { requestData } = context;
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [updateRequest, responseUpdateRequest] = useMutation(UPDATE_REQUEST, {
    refetchQueries: ["GetRequest"],
  });

  const hasWife = requestData?.married === statics.marriedOptions[1].value;

  const validationSchema = hasWife ? yup.object({
    partnerName: yup
      .string().required(),
    partnerFieldStudy: yup
      .string().required(),
    partnerNameEn: yup
      .string().required(),
    partnerFieldStudyEn: yup
      .string().required(),
    partnerBirthday: yup
      .string().required(),
    partnerDegree: yup
      .string().required(),
    partnerJob: yup
      .string().required(),
    partnerInsurance: yup
      .string().required(),
    partnerIelts: yup
      .string().required(),
    partnerTef: yup
      .string().required(),
  }) : null;

  const formik = useFormik({
    initialValues: {
      partnerName: "",
      partnerNameEn: "",
      partnerFieldStudy: "",
      partnerFieldStudyEn: "",
      partnerBirthday: moment().add(-10, "years").format(),
      partnerDegree: "",
      partnerJob: "",
      partnerInsurance: "",
      partnerIelts: "",
      partnerTef: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleNext();
    },
  });

  const {
    partnerName,
    partnerNameEn,
    partnerFieldStudy,
    partnerFieldStudyEn,
    partnerBirthday,
    partnerDegree,
    partnerJob,
    partnerInsurance,
    partnerIelts,
    partnerTef,
  } = formik.values;

  // TODO fix if changed number childs
  const defaultChilds = Array(Number(requestData?.number_childs || 0))
    .fill(0).map((ch, i) => ({
      id: String(i),
      birthday: "",
      levelEducation: "",
      levelEducationNumber: "",
      name: "",
      nameEn: "",
    }));
  const [childs, setChilds] = useState<IChild[]>(defaultChilds);

  useEffect(() => {
    if (requestData.requestId) {
      setLoading(true);
      formik.setFieldValue("partnerName", requestData.partner_name);
      formik.setFieldValue("partnerNameEn", requestData.partner_name_en);
      formik.setFieldValue("partnerFieldStudy", requestData.partner_field_study);
      formik.setFieldValue("partnerFieldStudyEn", requestData.partner_field_study_en);
      if (requestData.partner_birthday) { formik.setFieldValue("partnerBirthday", requestData.partner_birthday); }
      formik.setFieldValue("partnerDegree", requestData.partner_degree);
      formik.setFieldValue("partnerJob", requestData.partner_job);
      formik.setFieldValue("partnerInsurance", requestData.partner_insurance);
      formik.setFieldValue("partnerIelts", String(requestData.partner_ielts));
      formik.setFieldValue("partnerTef", String(requestData.partner_tef));
      setPartnerIeltsScore(requestData.partner_score_ielts);
      setPartnerTefScore(requestData.partner_score_tef);
      const childs_ = defaultChilds.map((item) => {
        const child = requestData?.childs?.find((item_) => item_.id === item.id);
        if (child) return child;
        return item;
      });
      setChilds(childs_);

      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [requestData]);

  const [partnerIeltsScore, setPartnerIeltsScore] = useState<IScore>();
  const [partnerTefScore, setPartnerTefScore] = useState<IScore>();

  const setIeltsScore = (type: string, value: string) => {
    setPartnerIeltsScore({ ...partnerIeltsScore, [type]: value });
  };
  const setTefScore = (type: string, value: string) => {
    setPartnerTefScore({ ...partnerTefScore, [type]: value });
  };
  const setChild = (id: string, field: string, value: string) => {
    // add or update child
    const childs_ = [...childs];
    // eslint-disable-next-line prefer-const
    // let chs = childs_.find((ch) => `${ch.id}` === `${id}`);
    const chsIndex = childs_.findIndex((ch) => `${ch.id}` === `${id}`);

    const updated_ = { ...childs_[chsIndex], [field]: value };
    childs_[chsIndex] = updated_;
    setChilds(childs_);
  };
  const handleNext = () => {
    if (stepProps.role === "Customer" && !stepProps.accessUser) {
      stepProps.handleNext();
      return;
    }

    if (context.requestData?.requestId) {
      const bth = moment(partnerBirthday).locale("en").format("YYYY-MM-DD");

      const variables = hasWife ? {
        partner_name: partnerName,
        partner_name_en: partnerNameEn,
        partner_field_study: partnerFieldStudy,
        partner_field_study_en: partnerFieldStudyEn,
        partner_birthday: bth,
        partner_degree: partnerDegree,
        partner_job: partnerJob,
        partner_insurance: Number(partnerInsurance),
        partner_ielts: Boolean_(partnerIelts),
        partner_tef: Boolean_(partnerTef),
        partner_ielts_score: partnerIeltsScore,
        partner_tef_score: partnerTefScore,
        childs,
      } : { childs };
      // TODO must be updated
      updateRequest({
        variables: { requestId: Number(context.requestData?.requestId), ...variables },
        refetchQueries: ["GetRequest"],
        awaitRefetchQueries: true,
      }).then((result) => {
        enqueueSnackbar(t("SuccessfullyUpdated"), {
          variant: "success",
        });
        stepProps.handleNext({ requestId: Number(context.requestData?.requestId) });
      }).catch((error) => {
        enqueueSnackbar(t("ErrorHappened"), {
          variant: "error",
        });
      });
    }
  };
  const handleBack = () => {
    stepProps.handleBack();
  };

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className={classes.root}
        // noValidate
        // autoComplete="on"
      >
        {}
        {hasWife ? (
          <fieldset style={{ border: "1px solid #eee", paddingBottom: "40px" }}>
            <legend>{t("pairInfo")}</legend>
            <Grid container spacing={4}>
              <InputBox
                handler="formik"
                name="partnerName"
                value={partnerName}
                formikI={formik}
                grid={4}
                required
              />
              <InputBox
                handler="formik"
                name="partnerNameEn"
                value={partnerNameEn}
                formikI={formik}
                grid={4}
                dir="ltr"
                required
              />
              <InputBox
                handler="formik"
                name="partnerFieldStudy"
                value={partnerFieldStudy}
                formikI={formik}
                grid={4}
                required
              />
              <InputBox
                handler="formik"
                name="partnerFieldStudyEn"
                value={partnerFieldStudyEn}
                formikI={formik}
                grid={4}
                dir="ltr"
                required
              />
              <DatePickerComponent
                handler="formik"
                name="partnerBirthday"
                value={partnerBirthday}
                formikI={formik}
                grid={4}
                required
                minDate={moment().add(-90, "years").format()}
                maxDate={moment().add(-1, "years").format()}
                views="bth"
              />
              <SelectBox
                handler="formik"
                name="partnerDegree"
                value={partnerDegree}
                formikI={formik}
                grid={4}
                items={statics.degreesOptions}
                required
              />
              <SelectBox
                handler="formik"
                name="partnerJob"
                value={partnerJob}
                formikI={formik}
                grid={4}
                items={statics.jobOptions}
                required
              />
              <SelectBox
                handler="formik"
                name="partnerInsurance"
                value={partnerInsurance}
                formikI={formik}
                grid={4}
                items={statics.insuranceOptions}
                required
              />
            </Grid>
            <Grid container spacing={4}>
              <RadioGroupBox
                handler="formik"
                formikI={formik}
                name="partnerIelts"
                value={partnerIelts}
                grid={4}
                items={statics.defaultOption}
              />
              {partnerIelts && partnerIelts === "true" && (
              <>
                <Grid container spacing={2} xs={4}>
                  <InputBox
                    name="partnerIeltsScore.read"
                    value={partnerIeltsScore?.read}
                    setValue={((v) => setIeltsScore("read", v))}
                    grid={6}
                    type="number"
                    required
                  />
                  <InputBox
                    name="partnerIeltsScore.listen"
                    value={partnerIeltsScore?.listen}
                    setValue={((v) => setIeltsScore("listen", v))}
                    grid={6}
                    type="number"
                    required
                  />
                  <InputBox
                    name="partnerIeltsScore.write"
                    value={partnerIeltsScore?.write}
                    setValue={((v) => setIeltsScore("write", v))}
                    grid={6}
                    type="number"
                    required
                  />
                  <InputBox
                    name="partnerIeltsScore.speak"
                    value={partnerIeltsScore?.speak}
                    setValue={((v) => setIeltsScore("speak", v))}
                    grid={6}
                    type="number"
                    required
                  />
                </Grid>
                <Grid container spacing={2} xs={4} />
              </>
              ) }
              <RadioGroupBox
                handler="formik"
                name="partnerTef"
                value={partnerTef}
                formikI={formik}
                grid={4}
                items={statics.defaultOption}
              />
              {partnerTef && partnerTef === "true" && (
              <>
                <Grid container spacing={2} xs={4}>
                  <InputBox
                    name="partnerTefScore.read"
                    value={partnerTefScore?.read}
                    setValue={((v) => setTefScore("read", v))}
                    grid={6}
                    type="number"
                    required
                  />
                  <InputBox
                    name="partnerTefScore.listen"
                    value={partnerTefScore?.listen}
                    setValue={((v) => setTefScore("listen", v))}
                    grid={6}
                    type="number"
                    required
                  />
                  <InputBox
                    name="partnerTefScore.write"
                    value={partnerTefScore?.write}
                    setValue={((v) => setTefScore("write", v))}
                    grid={6}
                    type="number"
                    required
                  />
                  <InputBox
                    name="partnerTefScore.speak"
                    value={partnerTefScore?.speak}
                    setValue={((v) => setTefScore("speak", v))}
                    grid={6}
                    type="number"
                    required
                  />
                </Grid>
                <Grid container spacing={2} xs={4} />
              </>
              ) }
            </Grid>
          </fieldset>
        ) : "" }
        {childs?.length ? (
          <fieldset style={{ border: "1px solid #eee", marginTop: "40px" }}>
            <legend>{t("childsInfo")}</legend>
            <div>
              {childs?.map((child, index) => (
                <>
                  <div className={classes.child}>{`#${t(`child__${index + 1}`)}`}</div>
                  <Grid container xs={12} spacing={2}>
                    <InputBox
                      name="name"
                      value={child.name}
                      setValue={(v) => setChild(child.id, "name", v)}
                      grid={3}
                      required
                      // handler="formik"
                      // formikI={formik}
                    />
                    <InputBox
                      name="nameEn"
                      value={child.nameEn}
                      setValue={(v) => setChild(child.id, "nameEn", v)}
                      grid={3}
                      required
                      dir="ltr"
                      // handler="formik"
                      // formikI={formik}
                    />
                    <DatePickerComponent
                      name="birthday"
                      value={child.birthday}
                      setValue={(v) => setChild(child.id, "birthday", moment(v).locale("en").format())}
                      grid={4}
                      minDate={moment().add(-50, "years").format()}
                      maxDate={moment().format()}
                      views="bth"
                      required
                    />
                    <SelectBox
                      name="levelEducation"
                      value={child.levelEducation}
                      setValue={(v) => setChild(child.id, "levelEducation", v)}
                      grid={5}
                      items={statics.levelEducationOptions}
                      required
                    />
                    {child.levelEducation
                    && (child.levelEducation !== statics.levelEducationOptions[0].value) ? (
                      <SelectBox
                        name="levelEducationNumber"
                        value={child.levelEducationNumber}
                        setValue={(v) => setChild(child.id, "levelEducationNumber", v)}
                        grid={5}
                        items={statics.levelEducationNumberOptions}
                        required
                      />
                      ) : "" }
                  </Grid>
                </>
              ))}
            </div>
          </fieldset>
        ) : ""}
        <NavBarFooter
          step={stepProps.stepIndex}
          stepLength={stepProps.stepLen}
          handleNextCallback={formik.handleSubmit}
          handleBackCallback={handleBack}
        />
      </form>
    </div>
  );
};

export default RequestFamilyDetail;
