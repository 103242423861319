import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import SetSessionTimeComponentNew from "../SetSessionTimeComponentNew";

type IProps = {
    setOpen: (params: any) => any,
    open: boolean,
}

const DialogChangeBookingSession = (props: IProps) => {
  const { t } = useTranslation();
  const { open, setOpen } = props;

  const handleSave = (e) => {
    document.getElementById("submit-change-time").click();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={() => setOpen((s) => !s)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("titleChangeBookingTime")}</DialogTitle>
      <>
        <DialogContent>
          {t("pleaseSelectNewTimeForEval")}
          :
          <br />
          <br />
          <SetSessionTimeComponentNew isAdmin isPaid={false} setOpen={setOpen} />
          <br />
        </DialogContent>
        <DialogActions>
          <Button color="default" variant="outlined" onClick={handleSave}>
            {t("general.saveChanges")}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default DialogChangeBookingSession;
