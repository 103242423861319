import { gql } from "@apollo/client";

export const GET_REQUEST_INVOICES = gql`
query GetRequestPaymentInstallment($reqId: ID!, $subject: [ENUM_INVOICE_SUBJECT]) {
  invoices(where: {
    subject: $subject
    request_id:{
      id: $reqId
    }
  }){
    id
    title
    status
    message
    subject
    cost
    due_date
    payments{
      id
      bank_payment_id
      status
      payment_time
      payment_date
      use_off_code
      attachment{
        id
        url
      }
      invoice {
        id
      }
      confirmed
    }
  }
}`;

export default {};
