/* eslint-disable import/no-unresolved */
/* eslint-disable no-tabs */
import IransSansTTF from "./yekan/ttf/IRANYekanWebRegular.ttf";
import IransSansEOT from "./yekan/eot/IRANYekanWebRegular.eot";
import IransSansWoff from "./yekan/woff/IRANYekanWebRegular.woff";
import IransSansWoff2 from "./yekan/woff2/IRANYekanWebRegular.woff2";
import IransNETtf from "./yekan/yekan-number/iranyekanwebregularfanum.ttf";
import IransNEEot from "./yekan/yekan-number/iranyekanwebregularfanum.eot";
import IransNEWoff from "./yekan/yekan-number/iranyekanwebregularfanum.woff";

export const YekanNumber = {
  fontFamily: "IransSansNumber",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `url('${IransNEEot}?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('${IransNEWoff}') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('${IransNEWoff}') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('${IransNETtf}') format('truetype')`,
};

const IransSans = {
  fontFamily: "IRANSans",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `url('${IransSansEOT}?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('${IransSansWoff2}') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('${IransSansWoff}') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('${IransSansTTF}') format('truetype')`,
};
export default IransSans;
