import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import WelcomePng from "../../assets/images/welcome.jpg";

const useStyles = makeStyles((theme: Theme) => createStyles({
  description: {
    paddingBottom: "10px",
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
    "& button": {
      height: "52px",
      marginTop: "5px",
    },
  },
  content: {
    display: "flex",
  },
}));

export default function DialogFirstTime(props: any) {
  const history = useHistory();

  const { t } = useTranslation();
  const isFa = true;
  const {
    open,
    setOpen,
  } = props;
  const classes = useStyles();
  const [attachment, setAttachment] = React.useState();

  const handleClose_ = () => {
    history.push("/request");
    setOpen();
    // handleClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose_}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("firstTimeTitle")}</DialogTitle>
      <form>
        <>
          <DialogContent style={{ paddingTop: 0, textAlign: "justify" }}>
            <Grid container spacing={1}>
              <div className={classes.content}>
                {isFa ? (
                  <div>
                    <p>
                      فرم ارزیابی ما اولین و
                      مهمترین گام برای بررسی شرایط مهاجرت شماست، لطفا در تکمیل آن حوصله و
                      دقت به خرج دهید تا بتوانیم بررسی درستی از شرایط مهاجرت شما داشته
                      و مشورت صحیحی بر آن اساس ارائه دهیم. فرمهای کامل این امکان را فراهم میکند که
                      شرایط شما را با بیش از ده ها روش مهاجرتی
                      موجود مهاجرت به کانادا تطبیق و ارزیابی دقیقی از شرایط تان به دست آوریم.
                    </p>
                    <p>
                      ما پس از بررسی فرم ارزیابی شما، با شما از طریق ایمیل یا تلفن تماس خواهیم
                      گرفت تا در خصوص بهترین روش مهاجرت تان صحبت کنیم. لطفا اطمینان
                      حاصل کنید که آدرس ایمیل و شماره های تماس خود را درست وارد کرده اید.
                    </p>
                  </div>
                ) : (
                  <div>
                    Our first evaluation form and
                    The most important step in checking your immigration status,
                    please be patient and complete it
                    Be careful so that we can have a proper review of your immigration status
                    And provide proper advice accordingly. Complete forms make it possible to
                    Your conditions with more than a dozen immigration methods
                    Existing Immigration to Canada to obtain a detailed assessment
                    and assessment of your circumstances./
                  </div>
                ) }
                <div><img src={WelcomePng} alt="x" style={{ width: "100%" }} /></div>
              </div>

            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button> */}
            <Button color="inherit" onClick={handleClose_}>
              {t("Start")}
            </Button>
          </DialogActions>
        </>
      </form>
    </Dialog>
  );
}
