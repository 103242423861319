import { Grid, Theme, createStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import SectionTitle from "../../../components/SectionTitle";
import InputFileBatch from "../../../components/micro/InputFileBatch";
import {
  CREATE_REQUEST_ATTACHMENT_FILE, DELETE_REQUEST_ATTACHMENT_FILE,
  GET_REQUEST_ATTACHMENTS, UPDATE_STATE_REQUEST,
} from "./requestStepAQueries";
import { RequestContext } from "../context/requestContext";
import { mutateI } from "../../../app/utils/helper";
import AdminNavbarStepTools from "../component/AdminNavbarStepTools";
import { STEPS } from "../../../app/statics";
import { useAccount } from "../../../app/hooks";
import waitingImage from "../../../assets/images/waiting-image.svg";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    margin: "0px",
  },
  saveButton: {
    backgroundColor: theme.palette.success.light,
    marginRight: "5px",
    fontSize: "1.1em",
    width: "150px",
  },
  sigCanvas: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ddd",
    width: "100%",
  },
  text: {
    // paddingTop: "30px",
    paddingBottom: "0px",
  },
  table: {
    margin: "20px",
  },
  imageCenter: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    "&>h3": {
      maxWidth: "800px",
      textAlign: "center",
    },
  },
  mainImage: {
    maxWidth: "500px",
  },
  instruction: {
    backgroundColor: "#0b7bfa",
    color: "#fff",
    padding: "8px",
    "&>p": {
      padding: "0px",
    },
  },
}));

const RequestStepGGetVisa = (props: any) => {
  const [files, setFiles] = useState<[]>([]);
  const context = useContext(RequestContext);
  const { requestData } = context;

  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    isCustomer,
  } = useAccount();

  const requestAttachmentQuery = useQuery(GET_REQUEST_ATTACHMENTS, {
    variables: {
      reqId: requestData.requestId,
      section: "result",
    },
  });

  useEffect(() => {
    if (requestAttachmentQuery.data) {
      setFiles(requestAttachmentQuery.data?.requestAttachments);
    }
  }, [requestAttachmentQuery.data]);

  const [updateRequestState, responseUpdateRequestState] = useMutation(
    UPDATE_STATE_REQUEST,
  );

  const [deleteAttachmentRequest, responseDeleteAttachmentRequest] = useMutation(
    DELETE_REQUEST_ATTACHMENT_FILE,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );

  const [uploadAttachmentRequest, responseUploadAttachmentRequest] = useMutation(
    CREATE_REQUEST_ATTACHMENT_FILE,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );

  const handleSave = (newFilesI, fileTitlesI) => {
    const uploadFunc = () => {
      newFilesI.forEach((file: any, i) => {
        uploadAttachmentRequest({
          variables: {
            title: fileTitlesI[i],
            requestId: Number(requestData?.requestId),
            file: file?.id,
            section: "result",
          },
          refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
        }).then((resultUpload) => {
        }).catch((errorUpload) => {
          console.log("upload-error");
        });
      });
    };
    uploadFunc();
  };

  const handleRemoveAttachment = (file) => {
    mutateI(() => deleteAttachmentRequest({
      variables: { id: file.id },
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    }), t, enqueueSnackbar);
  };

  return (
    <>
      <SectionTitle
        title=""
      />
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12} md={12} className={classes.text}>
          <h2>{t("Request.getVsia")}</h2>
        </Grid>
        {isCustomer ? (
          <Grid item xs={12} md={12} className={classes.text}>
            <div className={classes.imageCenter}>
              <img className={classes.mainImage} src={waitingImage} alt="imag" />
              <h3>
                {t("text_help_get_visa")}
              </h3>
            </div>
            <InputFileBatch
              name="reportAttachment"
              files={files}
              handleSave={handleSave}
              removeFile={handleRemoveAttachment}
              grid={12}
              titleDrag="For_attach_files_drag_here"
              isUploadDisabled
            />
          </Grid>
        ) : ""}

        {!isCustomer ? (
          <Grid item xs={12} md={12}>
            <div className={classes.instruction}>
              <p>{t("endStepVisaMessages.intro")}</p>
              <ul>
                <li>
                  {t("endStepVisaMessages.m1")}
                </li>
                <li>
                  {t("endStepVisaMessages.m2")}
                </li>
                <li>
                  {t("endStepVisaMessages.m3")}
                </li>
                <li>
                  {t("endStepVisaMessages.m4")}
                </li>
              </ul>
            </div>

            <InputFileBatch
              name="reportAttachment"
              files={files}
              handleSave={handleSave}
              removeFile={handleRemoveAttachment}
              grid={12}
              titleDrag="For_attach_files_drag_here"
            />
          </Grid>
        ) : ""}
      </Grid>
      <AdminNavbarStepTools stepNumber={STEPS.STEP_END} />
    </>
  );
};

export default RequestStepGGetVisa;
