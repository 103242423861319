import { gql } from "@apollo/client";

export const GET_REQUEST_COUNCIL_AMOUNT = gql`
query GET_REQUEST_COUNCIL_AMOUNT($reqId: ID!) {
  invoices(where: {
    request_id:{
      id: $reqId
    }
    subject: "council"
  }){
    id
    created_at
    updated_at
    cost
    status
    message
    subject
    request_id {
      id
    }
    off_code
    off_percent
  }
}`;

export const INVOICE_PAYMENT_COUNCIL_REQUEST = gql`
mutation Invoice_Payment_Council($id: ID!, $status: ENUM_INVOICE_STATUS){
  updateInvoice(input:{
    data: {
      status: $status
    }
    where:{
      id: $id
    }
  }){
    invoice{
      id
      status
      request_id {
        id
      }
    }
  }
}
`;

export const CREATE_INVOICE = gql`
mutation Create_Invoice(
    $reqId: ID!, 
    $cost: Long!, 
    $status: ENUM_INVOICE_STATUS, 
    $message: String, 
    $subject: ENUM_INVOICE_SUBJECT!, 
    $due_date: Date, 
    $off_percent: Int, 
    $off_code: String,
    $title: String,
    $description: String,
    $type: ENUM_INVOICE_TYPE
    ){
  createInvoice(input:{
    data:{
      request_id: $reqId
      cost: $cost
      status: $status
      message: $message
      subject: $subject
      due_date: $due_date
      off_percent: $off_percent
      off_code: $off_code
      title: $title
      description: $description
      type: $type
    }
  }){
    invoice{
      id
      status
    }
  }
}
`;

export const UPDATE_INVOICE = gql`
mutation UPDATE_INVOICE(
  $id: ID!, 
  # $reqId: ID!, 
  $cost: Long!, 
  # $costRial: Long, 
  $status: ENUM_INVOICE_STATUS, 
  $message: String, 
  $title: String, 
  $subject: ENUM_INVOICE_SUBJECT!, 
  $due_date: Date, 
  $off_percent: Int, 
  $type: ENUM_INVOICE_TYPE,
  $off_code: String){
updateInvoice(input:{
  data:{
    # request_id: $reqId
    cost: $cost
    # cost_rial: $costRial
    status: $status
    title: $title
    message: $message
    subject: $subject
    due_date: $due_date
    off_percent: $off_percent
    off_code: $off_code
    type: $type
  }
  where: {
    id: $id
  }
}){
  invoice{
    id
    status
  }
}
}
`;

export const DELETE_INVOICE = gql`
mutation DELETE_INVOICE($id: ID!){
  deleteInvoice(input:{where: {
    id:$id
  }}){
    invoice{
      id
    }
  }
}
`;

export const CREATE_PAYMENT = gql`
mutation CREATE_PAYMENT(
  $invoiceId: ID,
  $status: ENUM_PAYMENT_STATUS, 
  $bankPaymentId: String,
  $payDate: DateTime!, 
  $payTime: Time,
  $useOffCode: Boolean,
  $attachment: ID,
  $amount: Int,
  $confirmed: Boolean,
  ){
  createPayment(input: {
    data: {
      invoice: $invoiceId
      status: $status
      bank_payment_id: $bankPaymentId 
      payment_date: $payDate
      payment_time: $payTime,
      use_off_code: $useOffCode
      attachment: $attachment
      amount: $amount
      confirmed: $confirmed
    }
  }){
    payment{
      id
      status
      bank_payment_id
    }
  }
}
`;

export const GET_PAYMENT_BY_INVOICE_ID = gql`
query GET_PAYMENT_BY_INVOICE_ID($invoiceId: ID!) {
  payments(
    where: {
      invoice: $invoiceId
    }
  ){
    id
    status
    payment_date
    invoice {
      id
      cost
      status
      message
      subject
      due_date
      off_code
      off_percent
    }
  }
}
`;

export const VERIFY_PAYMENT = gql`
mutation VERIFY_PAYMENT($invId: ID!){
  verifyPayment(input:{
    data:{
      invId: $invId
    }
  }){
    invoice{
      id
      status
    }
  }
}
`;

export default {};
