import { useQuery } from "@apollo/client";
import { Card } from "@material-ui/core";
import _ from "lodash";
import React from "react";

import { useTranslation } from "react-i18next";
import {
  Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from "recharts";
import { GET_DASHBOARD_STATUS_CONTRACTS, GET_DASHBOARD_STEP_DRAFT } from "../../pages/Dashboard/dashboardQueries";
import "./styles.scss";

const StatusContractBarChart = () => {
  const data = [
    {
      name: "DRAFT",
      value: 0,
      fill: "orange",
      width: "40px",
    },
    {
      name: "STEP_INFORMATION",
      value: 0,
      fill: "#4cc9f0",
    },
    {
      name: "STEP_CONTRACT",
      value: 0,
      fill: "#3a0ca3",
    },
    {
      name: "STEP_PAYMENT",
      value: 0,
      fill: "#81b29a",
    },
    {
      name: "STEP_DOCUMENT",
      value: 0,
      fill: "#4cc9f0",
    },
    {
      name: "STEP_END",
      value: 0,
      fill: "#57cc99",
    },
  ];

  // const demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";
  const { t } = useTranslation();
  const q0 = useQuery(GET_DASHBOARD_STEP_DRAFT, { variables: { } });
  const q1 = useQuery(GET_DASHBOARD_STATUS_CONTRACTS, { variables: { step: "STEP_INFORMATION" } });
  const q2 = useQuery(GET_DASHBOARD_STATUS_CONTRACTS, { variables: { step: "STEP_CONTRACT" } });
  const q3 = useQuery(GET_DASHBOARD_STATUS_CONTRACTS, { variables: { step: "STEP_PAYMENT" } });
  const q4 = useQuery(GET_DASHBOARD_STATUS_CONTRACTS, { variables: { step: "STEP_DOCUMENT" } });
  const q5 = useQuery(GET_DASHBOARD_STATUS_CONTRACTS, { variables: { step: "STEP_END" } });
  const v0 = _.get(q0, "data.requestsConnection.aggregate.count", 0);
  const v1 = _.get(q1, "data.requestStatusesConnection.aggregate.count", 0);
  const v2 = _.get(q2, "data.requestStatusesConnection.aggregate.count", 0);
  const v3 = _.get(q3, "data.requestStatusesConnection.aggregate.count", 0);
  const v4 = _.get(q4, "data.requestStatusesConnection.aggregate.count", 0);
  const v5 = _.get(q5, "data.requestStatusesConnection.aggregate.count", 0);
  data[0].value = v0;
  data[1].value = v1;
  data[2].value = v2;
  data[3].value = v3;
  data[4].value = v4;
  data[5].value = v5;

  return (
    <div className="dashboard-card">
      <h2>{t("dashboard.statusContracts")}</h2>
      <Card title={t("dashboard.statusContracts")} style={{ width: "100%", height: "400px", padding: "10px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
          // width={500}
          // height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={30}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="value" fill="#82ca9d" background={{ fill: "#eee" }} />
            {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

export default StatusContractBarChart;
