// eslint-disable-next-line import/no-unresolved
// import ConfigData from "ConfigData";

export const API_SERVER = () => {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_HOST_DEV_HOST;
  }
  return process.env.REACT_APP_HOST_PROD_HOST;
};

export default {};
