import React, { useEffect, useState } from "react";

import DatePicker, { DateObject } from "react-multi-date-picker";

import persian from "react-date-object/calendars/persian";
import persianFa from "react-date-object/locales/persian_fa";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DateRangePicker = (props: any) => {
  const today = new Date();
  const tomorrow = new Date();
  const { t } = useTranslation();

  tomorrow.setDate(tomorrow.getDate() + 1);

  //   const [values, setValues] = useState([today, tomorrow]);
  const [values, setValues] = useState<any>([]);

  const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  useEffect(() => {
    const dts: string[] = [];
    values.forEach((item) => {
      if (item instanceof DateObject) {
        const value_ = item.toDate();
        dts.push(moment(value_).locale("en").utc().format());
      }
    });

    props.setValue(dts);
  }, [values]);

  return (
    <DatePicker
      multiple
      value={values}
      onChange={(e) => {
        setValues(e);
      }}
      placeholder={t("selectDate")}
      calendar={persian}
      locale={persianFa}
      digits={digits}
      plugins={[<DatePanel />]}
      style={{
        padding: "10px 10px",
        height: "40px",
        width: "100%",
      }}
      className="custom-calendar"
    />
  );
};

export default DateRangePicker;
