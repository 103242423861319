import { gql } from "@apollo/client";

export const GET_TICKETS = gql`  
query GET_TICKETS($sort: String, $uId: String) {
  tickets(
    sort: $sort,
    where: {
      user_id: {
        id: $uId
      }
    }
  ){
    id
    created_at
    updated_at
    title
    status
    user_id {
      id
      username
      email
      name
    }
  }
}
`;

export const CREATE_TICKET = gql`
mutation CREATE_TICKET($title: String!, $userId: ID!){
  createTicket(input: {
    data: {
      title: $title
      user_id: $userId
    }
  }){
    ticket {
      id
      title
      status
    }
  }
}
`;

export const CREATE_TICKET_MESSAGE = gql`
mutation CREATE_TICKET_MESSAGE ($message: String!, 
  $userId: ID! , $ticketId: ID! ,$file: [ID]){
 createTicketMessage(input : {
    data: {
      message: $message
      user_id: $userId
      ticket_id: $ticketId
      file: $file
    }
  }){
    ticketMessage{
      id
    }
  }
}
`;

export const GET_MESSAGES_TICKET = gql`
query GET_MESSAGES_TICKET($sort: String, $ticketId: ID!){
  ticketMessages(
    sort: $sort
    where :{
      ticket_id: {
        id: $ticketId
      }
  }){
    id
    created_at
    message
    user_id{
      id
      name
    }
    file{
      id
      url
      previewUrl
      width
      caption
    }
    ticket_id{
      id
    }
  }
}
`;

export const GET_TICKET = gql`  
query GET_TICKET($id: ID!) {
  ticket(id:$id){
    id
    created_at
    updated_at
    title
    status
    user_id {
      id
    }
  }
}
`;

export default {};
