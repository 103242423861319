import React, { useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  Grid, Tab, Tabs, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { GET_EVENT_LOGS } from "../../pages/Request/requestQueries";
import TableSimple from "../micro/TableSimple";
import { dateIsoFa3 } from "../../app/utils/helper";
import { TabPanel } from "../../pages/Profile/Profile";

const useStyles = makeStyles((theme: Theme) => createStyles({
  description: {
    paddingBottom: "10px",
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
    "& button": {
      height: "52px",
      marginTop: "5px",
    },
  },
  content: {
    display: "flex",
  },
  dialogContent: {
    maxHeight: "300px",
    overflowY: "scroll",
  },
}));

export default function DialogActivity(props: any) {
  const history = useHistory();
  const params = useParams();
  const reqId_ = params?.id;
  const [tab, setTab] = useState(0);

  const logsQuery = useQuery(GET_EVENT_LOGS, { variables: { sort: "updated_at:desc", reqId: reqId_ } });

  const { t } = useTranslation();
  const isFa = true;
  const {
    open,
    setOpen,
  } = props;
  const classes = useStyles();
  const [attachment, setAttachment] = React.useState();

  const handleClose_ = () => {
    // history.push("/request");
    setOpen();
    // handleClose();
  };

  const rowsCustomer = useMemo(() => {
    let items = _.get(logsQuery, "data.eventLogs", []);
    items = items?.filter((item) => item?.user?.role?.type === "customer");
    return items?.map((item, i:number) => {
      const createDate = dateIsoFa3(item.created_at);
      return {
        ...item,
        id: i + 1,
        user: item?.user?.role?.type,
        type: t(item?.data?.type),
        viewDetails: item?.data?.message,
        createDate,
      };
    });
  }, [logsQuery.data]);

  const rowsAdmin = useMemo(() => {
    let items = _.get(logsQuery, "data.eventLogs", []);
    items = items?.filter((item) => item?.user?.role?.type !== "customer");
    return items?.map((item, i:number) => {
      const createDate = dateIsoFa3(item.created_at);
      return {
        ...item,
        id: i + 1,
        user: item?.user?.name,
        type: t(item?.data?.type),
        viewDetails: item?.data?.message,
        createDate,
      };
    });
  }, [logsQuery.data]);

  const headers = [
    {
      title: "id",
    },
    {
      title: "type",
    }, {
      title: "viewDetails",
    }, {
      title: "user",
    }, {
      title: "createDate",
    },
  ];

  const handleTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={handleClose_}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("ActivityDialogTitle")}</DialogTitle>
      <form>
        <>
          <DialogContent style={{ paddingTop: 0, textAlign: "justify" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Tabs value={tab} onChange={handleTab}>
                  <Tab label={t("UserActivity")} />
                  <Tab label={t("AdminActivity")} />
                </Tabs>
                <div className={classes.dialogContent}>
                  <TabPanel value={tab} index={0}>
                    <TableSimple rows={rowsCustomer} headers={headers} size="small" />
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <TableSimple rows={rowsAdmin} headers={headers} size="small" />
                  </TabPanel>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button>
          </DialogActions>
        </>
      </form>
    </Dialog>
  );
}
