import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { IconButton, CircularProgress as Loader } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { SmsRounded as IconMessage } from "@material-ui/icons";
import { useQueryParams } from "../../app/utils/helper";

const TableAdvance = (props: any) => {
  const { t } = useTranslation();
  const [pageCurrent, setPageCurrent] = useState(1);
  const {
    title, data, columns, itemPath,
    sortDefault,
    showFilter,
    className,
    serverSide,
    totalCount,
    query,
    loading,
    description,
    defaultOrder,
    pageOptions,
    setOpenGroupSend,
    sendMessage,
  } = props;

  const navi = useHistory();
  const nav = useQueryParams();

  const changePage = (page, sortOrder, rowsPerPage) => {
    const sort = sortOrder.name ? `${sortOrder.name}:${sortOrder.direction}` : defaultOrder || "id:desc";
    query.refetch(
      { sort, limit: rowsPerPage, start: (page) * rowsPerPage },
    );
    if (pageOptions) {
      navi.replace((`/${pageOptions.main}?page=${page}`));
    }
  };

  const changePageStatic = (action, state) => {
    const d = {
      page: state?.page,
      searchText: state?.searchText || undefined,
      rowsPerPage: state?.rowsPerPage || undefined,
    };

    const result = _.omitBy(d, (v) => v === undefined);
    const params = new URLSearchParams(result);
    navi.replace((`/${pageOptions.main}?${params.toString()}`));
  };

  return (
    <>
      <MUIDataTable
        title={t(title)}
        data={data}
        columns={columns}
        filter={showFilter}
        options={{
          serverSide,
          count: totalCount,
          onTableChange: (action, tableState) => {
            switch (action) {
              case "changePage":
              case "sort":
              case "changeRowsPerPage":
                if (serverSide) {
                  changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                } else {
                  changePageStatic(action, tableState);
                }
                break;
              default:
                // console.log("action not handled.");
            }
          },
          onSearchChange: _.debounce((action, tableState) => {
            if (!serverSide && action === "search") {
              changePageStatic(action, tableState);
            }
          }, 500),
          page: nav.get("page") || undefined,
          jumpToPage: false,
          selectableRows: "none",
          sortOrder: {
            name: sortDefault,
            direction: sortDefault ? "desc" : "",
          },
          searchText: nav.get("searchText"),
          rowsPerPage: nav.get("rowsPerPage") || 10,
          viewColumns: false,
          filter: showFilter,
          customToolbar: () => (
            sendMessage ? (
              <>
                <IconButton onClick={() => setOpenGroupSend(true)}>
                  <IconMessage />
                </IconButton>
              </>
            ) : false
          ),
          textLabels: {
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: t("Rows_per_page"),
              displayRows: t("Rows_of"), // 1-10 of 30
            },
            body: {
              noMatch: loading ? <Loader /> : t("not_match"),
            },
          },
          search: !serverSide,
        }}
        className={className || "muiDatableMaster"}
      />

    </>
  );
};

export default TableAdvance;
