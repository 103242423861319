import {
  Button, Grid, IconButton, Theme, createStyles,
} from "@material-ui/core";
import React, {
  useCallback, useContext, useEffect, useState,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useConfirm } from "material-ui-confirm";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import SectionTitle from "../../../components/SectionTitle";
import InputBox from "../../../components/micro/InputBox";
import TableSimple from "../../../components/micro/TableSimple";
import { RequestContext } from "../context/requestContext";
import { UPDATE_REQUEST, UPDATE_STATE_REQUEST } from "./requestStepAQueries";
import Notification, { INotification } from "../../../components/micro/SnackbarMicro";
import { GET_REQUEST_STATUS } from "../requestQueries";
import { GET_REQUEST_INVOICES } from "./requestStepEQueries";
import statics, { STEPS } from "../../../app/statics";
import FormDialogInvoiceInstallment from "../../../components/FormDialogInvoiceInstallment";
import FormDialogConfirmPayment from "../../../components/FormDialogConfirmPayment";
import AdminNavbarStepTools from "../component/AdminNavbarStepTools";
import { GET_ME } from "../../../app/layout/layoutQueries";
import { CREATE_PAYMENT, DELETE_INVOICE } from "./requestStepBQueries";
import {
  IntlFormat, getStatePayment, getUserRole, mutateI,
} from "../../../app/utils/helper";
import DialogCheckInvoice from "../../../components/dialogs/DialogCheckInvoice";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    margin: "0px",
  },
  saveButton: {
    backgroundColor: theme.palette.success.light,
    marginRight: "5px",
    fontSize: "1.1em",
    width: "150px",
  },
  sigCanvas: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ddd",
    width: "100%",
  },
  text: {
    // paddingTop: "30px",
    paddingBottom: "0px",
  },
  table: {
    // margin: "20px",
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
  },
  row: {
    display: "flex",
    alignItems: "center",
    border: "1px dashed #4caf50",
    borderRadius: "5px",
    padding: "16px 8px",
    // backgroundColor: "green",
    "&>div": {
      margin: "0 8px",
      // backgroundColor: "red",
    },
  },
  totalPriceCustomer: {
    "&>div": {
      display: "flex",
      alignItems: "center",
      padding: 10,
      fontSize: "1.2em",
    },
    width: "100%",
  },
}));

const RequestStepEPayment = (props: any) => {
  const { mode } = props;
  const history = useHistory();
  const [totalPriceRial, setTotalPriceRial] = useState<number>();
  const [totalPriceDollar, setTotalPriceDollar] = useState<number>(0);
  const [countInstallment, setCountInstallment] = useState<number>(0);
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>();
  const [invoiceDetail, setInvoiceDetail] = useState<any>();
  const [openModalCreateInvoice, setOpenModalCreateInvoice] = useState<boolean>(false);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState<boolean>(false);
  const [createPayment, responseCreatePayment] = useMutation(CREATE_PAYMENT, {
    refetchQueries: ["GetRequestPaymentInstallment"], // "GET_PAYMENT_BY_INVOICE_ID"],
  });
  const [deleteInvoice, responseInvoice] = useMutation(DELETE_INVOICE, {
    refetchQueries: ["GetRequestPaymentInstallment"], // "GET_PAYMENT_BY_INVOICE_ID"],
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openDialogPay, setOpenDialogPay] = useState<any>();

  const Me = useQuery(GET_ME);
  const { isCustomer, role } = getUserRole(Me);

  const [openModalPayment, setOpenModalPayment] = useState<boolean>(false);

  const confirm = useConfirm();

  const context = useContext(RequestContext);
  const { requestData } = context;
  const [updateRequest, responseUpdateRequest] = useMutation(UPDATE_REQUEST);
  const [notif, setNotif] = useState<INotification>();
  const reqStatus = useQuery(GET_REQUEST_STATUS, {
    variables:
    { reqId: Number(requestData?.requestId) },
    fetchPolicy: "cache-only",
  });
  const req = _.get(reqStatus, "data.requestStatuses[0]");
  const [updateRequestState, responseUpdateRequestState] = useMutation(
    UPDATE_STATE_REQUEST,
  );

  const installmentsInvoice = useQuery(GET_REQUEST_INVOICES, {
    variables:
    {
      reqId: Number(requestData?.requestId),
      subject: ["contractInstallment", "resubmit"],
    },
  });

  const handleApprove = (detail) => {
    setOpenDialogPay(detail);
  };

  const invoiceRows = useCallback(() => {
    let row_ = [];
    if (installmentsInvoice?.data?.invoices) {
      row_ = installmentsInvoice.data.invoices.map((invoice) => {
        const { payments } = invoice;
        let status_ = invoice.status;
        let isDisabled = false;
        let isRejected = false;

        if (payments.length && invoice.status !== "partPaymentDone") {
          const lastPay = payments[payments.length - 1];
          status_ = `${t(lastPay.status)} ${getStatePayment(t, lastPay)}`;
          isDisabled = !!lastPay.confirmed;
          isRejected = lastPay.confirmed === false;
        }

        if (invoice.status === statics.statusInvoiceOptions[2].value) {
          status_ = `${t(invoice.status)}`;
          isDisabled = true;
        }

        if (invoice.status === "done") {
          status_ = <div className="tag_div tag__done">{t(status_)}</div>;
        }
        if (invoice.status === "waitPayment") {
          status_ = <div className="tag_div tag__waitPayment">{t(status_)}</div>;
        }
        if (invoice.status === "waitForApprove") {
          status_ = <div className="tag_div tag__waitForApprove">{t(status_)}</div>;
        }
        if (invoice.status === "draft") {
          status_ = <div className="tag_div">{t(status_)}</div>;
        }
        if (invoice.status === "canceled") {
          status_ = <div className="tag_div tag__canceled">{t(status_)}</div>;
        }
        if (invoice.status === "partPaymentDone") {
          status_ = <div className="tag_div tag__partPaymentDone">{t(status_)}</div>;
        }

        let deleteButton = <div><DeleteIcon style={{ color: "#eee" }} /></div>;
        if (!payments.length) {
          deleteButton = (
            <IconButton onClick={() => handleDeleteInvoice(invoice)} size="small">
              <DeleteIcon />
            </IconButton>
          );
        }

        const doneButton = (
          <IconButton onClick={() => handleApprove(invoice)} size="small">
            <DoneIcon style={{ color: "blue" }} />
          </IconButton>
        );

        return (
          {
            installmentInvoiceId: invoice.id,
            installmentPrice: invoice.cost,
            installmentTitle: invoice.title,
            installmentDueDate: invoice.due_date || null,
            installmentStatus: status_,
            invoiceType: t(invoice.subject),
            installmentPayment: {
              title: payments.length && !isRejected ? "payment" : "payment",
              disabled: isDisabled,
            },
            installmentDelete: deleteButton,
            installmentApprove: doneButton,
            data: invoice,
            hasDescriptionTitle: invoice?.message ? t("yes") : t("no"),
            installmentViewPayments: {
              title: "viewInvoiceDetail",
              // disabled: !payments.length,
            },
          }
        );
      });
    }
    return row_;
  }, [installmentsInvoice?.data]);

  const addInstallment = () => {
    setOpenInvoiceDialog(true);
  };

  useEffect(() => {
    if (req) {
      setLoading(true);
      // setTotalPriceRial(req.contract_price_rial);
      setTotalPriceDollar(req.contract_price_dollar);
      setCountInstallment(req.contract_count_installments);
      setTimeout(() => setLoading(false), 100);
    }
  }, [reqStatus]);

  const saveSubmit = () => {
    if (Number(totalPriceDollar)) {
      mutateI(() => updateRequestState({
        variables: {
          requestId: Number(requestData?.requestId),
          reqSId: Number(reqStatus.data.requestStatuses[0].id),
          contractPriceDollar: Number(totalPriceDollar),
        },
        refetchQueries: ["GET_REQUEST_INVOICES", "GET_REQUEST_STATUS"],
      }), t, enqueueSnackbar);
    } else {
      setNotif({
        open: true, message: t("enterTotalPrice"), type: "error", setOpen: setNotif,
      });
    }
  };

  const handlePaymentModal = (invoiceData) => {
    const payments = invoiceData?.payments;
    if (payments.length) {
      const lastPay = payments[payments.length - 1];
      if (lastPay.confirmed) {
        return;
      }
    }

    setOpenModalPayment(true);
    setInvoiceDetail(invoiceData);
  };

  const handlePayment = (paymentInfo: any) => {
    if (paymentInfo.transactionDate && paymentInfo.transactionNumber) {
      const pDate = paymentInfo.transactionDate;
      createPayment({
        variables: {
          invoiceId: Number(paymentInfo.invoice.id),
          status: "offline_paid",
          bankPaymentId: paymentInfo.transactionNumber,
          payDate: pDate,
          payTime: paymentInfo.transactionTime,
          useOffCode: paymentInfo.useOffCode,
          attachment: paymentInfo.attachment ? Number(paymentInfo.attachment) : "",
        },
      }).then((result) => {

      }).catch((e) => {
        setNotif({
          open: true, message: t("errorPayment"), type: "error", setOpen: setNotif,
        });
      });
    }
  };

  const handleViewPayments = (invoiceInfo: any) => {
    history.push(`/invoice/${invoiceInfo.id}`);
  };
  const handleDeleteInvoice = (invoiceInfo: any) => {
    confirm({
      title: t("AreYouSureForDelete"), description: "", confirmationText: t("yes"), cancellationText: t("no"),
    }).then((r) => {
      deleteInvoice({
        variables: {
          id: Number(invoiceInfo.id),
        },
      }).then((r2) => {
        if (r2.data) {
          setNotif({
            open: true, message: t("SuccessfullyDeleted"), type: "success", setOpen: setNotif,
          });
        }
      }).catch(() => {
        setNotif({
          open: true, message: t("ErrorHappened"), type: "error", setOpen: setNotif,
        });
      });
    });
    // history.push(`/invoice/${invoiceInfo.id}`);
  };

  return (
    <>
      {mode === "contract" ? (
        <SectionTitle
          title={t("CostOfContract")}
        />
      ) : ""}
      <Grid container spacing={4} className={classes.root}>

        {!isCustomer ? (
          <>
            <Grid item xs={12} md={12} className={classes.text}>
              <div className={classes.row}>
                <div>{t("Request.paymentText")}</div>
                <InputBox
                  name="totalPriceDollar"
                  value={totalPriceDollar}
                  setValue={setTotalPriceDollar}
                  required
                  type="price"
                />

                <Button variant="contained" color="primary" onClick={saveSubmit}>
                  {t("Request.registerContractPrice")}
                </Button>
              </div>
            </Grid>

          </>
        ) : "" }

        {isCustomer ? (
          <Grid item xs={12} md={12} className={classes.totalPriceCustomer}>
            <Alert>
              {/* <h3> */}
              {t("totalPriceDollar")}
              :
              {" "}
              {IntlFormat(totalPriceDollar)}
              {" "}
              {t("dollar")}
              {/* </h3> */}
            </Alert>
          </Grid>
        ) : ""}

        <Grid item xs={12} md={12} className={classes.table}>
          {req.contract_price_dollar && !installmentsInvoice.loading ? (
            <TableSimple
              headers={
                [
                  {
                    title: "installmentPrice",
                    key: "cost",
                  },
                  {
                    title: "installmentTitle",
                    key: "t",
                  },
                  {
                    title: "invoiceType",
                  },
                  {
                    title: "installmentDueDate",
                    key: "date",
                  },
                  {
                    title: "hasDescriptionTitle",
                  },
                  {
                    title: "installmentStatus",
                    // key: "status",
                  },
                  {
                    title: "installmentPayment",
                    key: "button",
                    action: (e: any) => handlePaymentModal(e),
                  },
                  {
                    title: "installmentViewPayments",
                    key: "button",
                    action: (e: any) => handleViewPayments(e),
                  },
                  role === statics.roles.Authenticated ? {
                    title: "installmentApprove",
                    // key: "button",
                    // action: (e: any) => handleDeleteInvoice(e),
                  } : {},
                  role === statics.roles.Authenticated ? {
                    title: "installmentDelete",
                    // key: "button",
                    // action: (e: any) => handleDeleteInvoice(e),
                  } : {},
                  {
                    title: "installmentData",
                    key: "data",
                    hide: true,
                  },
                ]
            }
              rowsCount={0}
              rows={invoiceRows()}
            />
          ) : "" }
        </Grid>
        {!isCustomer ? (
          <Grid item xs={12} md={12} className={classes.buttonWrap}>
            <Button variant="contained" style={{ padding: "12px 10px" }} onClick={addInstallment}>
              {t("Request.registerInstallment")}
            </Button>
          </Grid>
        ) : "" }
        <Notification {...notif} />
        {openInvoiceDialog ? (
          <FormDialogInvoiceInstallment
            title="Request.InvoiceInstalment"
            openDialog={openInvoiceDialog}
            setOpenDialog={setOpenInvoiceDialog}
            totalCost={totalPriceDollar}
            countInstallment={0}
            subject="contractInstallment"
            requestId={requestData?.requestId}
            handleClose={() => setOpenInvoiceDialog(false)}
          />
        ) : false}
        {openModalPayment ? (
          <FormDialogConfirmPayment
            mode="installment"
            title="Invoice.payment"
            type="CONTRACT"
            openDialog={openModalPayment}
            handleSubmit={handlePayment}
            handleClose={() => setOpenModalPayment(false)}
            invoice={invoiceDetail}
          />
        ) : "" }
      </Grid>
      {mode !== "contract" ? (
        <Grid>
          <AdminNavbarStepTools stepNumber={STEPS.STEP_PAYMENT} />
        </Grid>
      ) : "" }

      {openDialogPay ? (
        <DialogCheckInvoice
          open={openDialogPay}
          setOpen={setOpenDialogPay}
        />
      ) : false}
    </>
  );
};

export default RequestStepEPayment;
