import { gql } from "@apollo/client";

export const UPLOAD_FILE = gql`
mutation UploadFile($file: Upload!){
  upload(
    file: $file
    ) {
      id
      created_at
      updated_at
      name
      caption
      alternativeText
      width
      height
      ext
      mime
      size
      url
      previewUrl
  }
}
`;

export const UPLOAD_FILES = gql`
mutation multipleUpload($files: [Upload]!){
  multipleUpload(
    files: $files
    ) {
      id
      created_at
      updated_at
      name
      caption
      alternativeText
      width
      height
      ext
      mime
      size
      url
      previewUrl
  }
}
`;

export default {};
