import React from "react";
import {
  FormLabel, Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import IconClose from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";
import { getGrid } from "../../app/utils/helper";

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    // direction: "ltr",
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    // minWidth: 120,
    // width: "100%",
    // padding: 0,
    "& .MuiInputLabel-formControl": {
      // top: "-11px",
      // left: "12px",
    },
    "&.MuiFormControl-marginDense": {
      margin: "0px",
    },
    "&.MuiFormControl-marginNormal": {
      marginTop: "13px",
    },
  },
  a: {
    fontSize: ".9em",
    paddingBottom: "3px",
  },
  selectFormTest: {
    "&>.MuiSelect-outlined.MuiSelect-outlined": {
      // padding: 10,
    },
  },
  clearButton: {
    marginRight: "14px",
    marginTop: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export type IItem = {
  label: string,
  value: string,
  disabled?: boolean,
}
type IPropsInput = {
    name: string,
    value: string | undefined | null,
    grid?: number,
    desc?: string,
    items: IItem[] | undefined,
    multiple?: boolean,
    required?: boolean,
    setValue?: Function,
    handler?: "formik" | "material",
    formikI?: any,
    readOnly?: boolean,
    disabled?: boolean,
    t?: boolean,
    variant?: "outlined" | "standard",
    margin?: "dense" | "normal",
}
const SelectBox = (props: IPropsInput) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    name, value, setValue, grid, items, desc, multiple,
    required,
    handler,
    formikI,
    readOnly,
    disabled,
    variant,
    margin,
  } = props;

  const resetButton = () => {
    formikI.setFieldValue(name, null);
  };

  const content = () => (
    (
      <FormControl fullWidth margin={margin} className={classes.formControl} required={required}>
        {name || desc ? (
          <FormLabel component="legend" className={classes.a}>
            {t(desc) || t(name)}
          </FormLabel>
        ) : "" }
        <Select
          className={classes.selectFormTest}
          id={`${name}-id`}
          labelId="a_label"
          // label={name}
          name={t(name)}
          multiline={multiple}
          value={`${value}`}
          onChange={(e) => (handler === "formik"
            ? formikI.handleChange(e)
            : setValue(e.target.value))}
          variant={variant || "outlined"}
          readOnly={readOnly}
          inputProps={{
            name,
          }}
          endAdornment={handler === "formik" && formikI.values[name]
            ? (
              <div
                onClick={resetButton}
                className={classes.clearButton}
              >
                <IconClose style={{ fontSize: "18px" }} />
              </div>
            ) : false}
          error={handler === "formik" ? formikI.touched[name] && Boolean(formikI.errors[name])
            : (required && (value !== undefined) && !value)}
          disabled={disabled}
          // fullWidth={!!grid}
        >
          {items?.map((item) => (
            <MenuItem
              style={{ padding: "5px" }}
              key={item.value}
              value={`${item.value}`}
              disabled={item?.disabled}
            >
              {props.t ? t(item.label) : item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );

  if (grid) {
    const [xs_, md_, lg_] = getGrid(grid);
    return (
      <Grid
        item
        md={md_}
        xs={xs_}
        lg={lg_}
      >
        {content()}
      </Grid>
    );
  }
  return (
    <>
      {content()}
    </>
  );
};

SelectBox.defaultProps = {
  grid: 0,
  desc: "",
  multiple: false,
  required: false,
  handler: "material",
  formikI: null,
  setValue: null,
  readOnly: false,
  disabled: false,
  t: true,
  variant: "outlined",
  margin: "dense",
};

export default SelectBox;
