import { gql } from "@apollo/client";

export const GET_EVALUATIONS = gql`
query GET_EVALUATIONS($sort: String, $limit: Int, $start: Int, $where: JSON) {
  evaluationsConnection(sort: $sort, limit: $limit, start: $start, where: $where){
    values {
      id
      created_at
      updated_at
      name
      family
      gender
      birthday
      job
      married
      status
      type
      user {
        id
        name
        email
        phone
      }
      consultant_time {
        id
        start_time
        end_time
        name
      }
      consultant_type {
        id
        title
        price
      }
      country
    }
    aggregate {
      count
      totalCount
    }
  }
}
`;

export const GET_EVALUATION = gql`  
query GET_EVALUATION($id: ID!) {
  evaluation(id: $id){
    id
    created_at
    updated_at
    name
    family
    gender
    birthday
    job
    married
    status
    degree_edu
    ielts
    tef
    number_childs
    total_assets
    user {
      id
      name
      email
      phone
    }
    consultant_time {
      id
      start_time
      end_time
      name
      is_online
      is_selectable
    }
    consultant_type {
      id
      title
      price
      currency
    }
    partner_name
    partner_degree
    partner_birthday
    description
    rejection_description
    consultant_location
    referer
    referer_source
    feedback_status
    feedback_description
    country
  }
}
`;

export const DELETE_REQUEST = gql`
mutation DELETE_REQUEST(
  $id: ID!,
){
  deleteRequest(input: {
    where: {
      id:$id
    }
  }){
    request {
      id
    }
  }
}
`;

export const UPDATE_EVALUATION = gql`
mutation UPDATE_EVALUATION(
  $id: ID!,
  $status: ENUM_EVALUATION_STATUS!,
  $rejection_description: String,
  $feedback_status: ENUM_EVALUATION_FEEDBACK_STATUS,
  $feedback_description: String,
  $offer_visa: ID,
){
  updateEvaluation(input: {
    where: {
      id: $id
    }
    data: {
      status: $status
      rejection_description: $rejection_description
      offer_visa: $offer_visa
      feedback_status: $feedback_status
      feedback_description: $feedback_description
    }
  }){
    evaluation {
      id
    }
  }
}
`;

export const UPDATE_EVAL_SESSION_TIME = gql`
mutation UPDATE_EVAL_SESSION_TIME(
  $id: ID!,
  $consultant_time: ID
){
  updateEvaluation(input: {
    where: {
      id: $id
    }
    data: {
      consultant_time: $consultant_time
    }
  }){
    evaluation {
      id
    }
  }
}
`;

export const UPDATE_EVAL_LOCATION_TIME = gql`
mutation UPDATE_EVAL_LOCATION_TIME(
  $id: ID!,
  $location_type: ENUM_EVALUATION_CONSULTANT_LOCATION!
){
  updateEvaluation(input: {
    where: {
      id: $id
    }
    data: {
      consultant_location: $location_type
    }
  }){
    evaluation {
      id
    }
  }
}
`;
export default {};
