import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { GET_CONSULTANT_TIMES_FOR_ALERT } from "../pages/Profile/profileQueries";
import { useAccount } from "../app/hooks";

const AlertSystemManager = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const nav = useHistory();
  const { isAdmin } = useAccount();

  const quGetTimes = useQuery(GET_CONSULTANT_TIMES_FOR_ALERT, {
    variables: {
      where: {
        start_time_gt: moment().startOf("day").format(),
        evaluation_null: true,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const showAlert = (d_?) => {
    let d = d_ || quGetTimes;
    d = d.data?.consultantTimesConnection?.aggregate?.count;
    if (_.isNumber(d) && d < 6) {
      if (d === 0) {
        enqueueSnackbar(t("ERROR_THERE_ARE_ANY_TIME_FOR_RESERVE_NOT"), {
          variant: "error",
          key: "time",
          action: <Button variant="outlined" style={{ color: "#fff" }} onClick={() => nav.push("/profile")}>{t("AddTime")}</Button>,
        });
      } else {
        enqueueSnackbar(t("ERROR_THERE_ARE_ANY_TIME_FOR_RESERVE", { times: d }), {
          variant: "warning",
          key: "time",
          action: <Button variant="outlined" style={{ color: "#fff" }} onClick={() => nav.push("/profile")}>{t("AddTime")}</Button>,
        });
      }
    }
  };

  useEffect(() => {
    if (isAdmin) {
      showAlert();
      const timer = setInterval(() => {
        quGetTimes.refetch()?.then((r) => {
          showAlert(r);
        })?.catch((x) => {
        //   console.log(
        //     x,
        //   );
        });
      }, 1000 * 60 * 10);

      return () => {
        clearInterval(timer);
      };
    }
    return () => {};
  }, [quGetTimes.data, isAdmin]);

  return <div />;
};

export default AlertSystemManager;
