import { useMutation, useQuery } from "@apollo/client";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import InputBox from "../../../components/micro/InputBox";
import InputCheckBox from "../../../components/micro/InputCheckBox";
import { RequestContext } from "../context/requestContext";
import {
  CREATE_STATE_REQUEST,
  UPDATE_REQUEST,
  UPDATE_STATE_REQUEST,
} from "../steps/requestStepAQueries";
import NavBarFooter from "./NavBarFooter";
import { GET_REQUEST_STATUS } from "../requestQueries";
import { STEP_STATUS } from "../../../app/statics";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    // paddingRight: theme.spacing(1),
    // paddingLeft: theme.spacing(1),
  },
}));
const RequestConfirm = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { push } = useHistory();

  const context = useContext(RequestContext);
  const { stepProps } = props;
  const lockStep = stepProps.role === "Customer" && !stepProps.accessUser;
  const { requestData } = context;
  const [otherDescription, setOtherDescription] = useState<string>();
  const [confirmInfo, setConfirmInfo] = useState<boolean>();
  const reqStatus = useQuery(GET_REQUEST_STATUS, {
    variables: { reqId: Number(requestData?.requestId) },
    fetchPolicy: "cache-only",
  });
  const [updateRequest, responseUpdateRequest] = useMutation(UPDATE_REQUEST);

  const [createRequestState, responseCreateRequestState] = useMutation(CREATE_STATE_REQUEST);
  const [updateRequestStatus, responseUpdateState] = useMutation(UPDATE_STATE_REQUEST);
  const [loading, setLoading] = useState<boolean>(false);
  const reqSId = _.get(reqStatus.data, "requestStatuses[0].id");

  useEffect(() => {
    if (requestData) {
      setLoading(true);
      setOtherDescription(requestData.message);
      if (requestData.message) {
        setConfirmInfo(true);
      }
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [requestData]);

  const handleNext = (e) => {
    e.preventDefault();
    e?.stopPropagation();

    if (lockStep) {
      stepProps.handleNext();
      return;
    }

    if (!confirmInfo) {
      enqueueSnackbar(t("PleaseConfirmInfo"), {
        variant: "warning",
      });
      return;
    }

    const variables = {
      message: otherDescription,
      is_error_fixed: false,
    };
    const { data: dataReq } = reqStatus;
    if (dataReq && dataReq?.requestStatuses?.length) {
      variables.is_error_fixed = true;
    }
    const stepStatus = STEP_STATUS.waitReview;

    if (requestData?.requestId) {
      updateRequest({
        variables: { requestId: Number(requestData?.requestId), ...variables },
        refetchQueries: ["GetRequest", "GET_REQUESTS"],
        awaitRefetchQueries: true,
      })
        .then((result) => {
          if (!dataReq?.requestStatuses?.length) {
            createRequestState({
              variables: {
                reqId: requestData.requestId,
                status: stepStatus,
                // statusMessage: "",
              },
              // refetchQueries: ["GetRequest", "GET_REQUESTS"],
              // awaitRefetchQueries: true,
            }).then((result2) => {
              enqueueSnackbar(t("successRequestSubmitPleaseWaitForStep2"), {
                variant: "success",
              });
            });
          } else {
            updateRequestStatus({
              variables: {
                reqSId,
                status: stepStatus,
                // statusMessage: "",
              },
              refetchQueries: ["GetRequest"],
              // awaitRefetchQueries: true,
            }).then((result2) => {
              enqueueSnackbar(t("successRequestSubmitPleaseWaitForStep2"), {
                variant: "success",
              });
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(t("ErrorHappened"), {
            variant: "error",
          });
        });
    }
  };

  const handleBack = () => {
    stepProps.handleBack();
  };

  return (
    <div>
      {!loading && (
        <form onSubmit={handleNext} className={classes.root}>
          <Grid container spacing={4}>
            <InputBox
              name="otherDescription"
              value={otherDescription}
              setValue={setOtherDescription}
              grid={8}
              rows={4}
            />
          </Grid>
          <InputCheckBox
            name="confirmInfo"
            label="confirmInfoDescription"
            value={confirmInfo}
            setValue={setConfirmInfo}
          />
          <NavBarFooter
            lockStep={lockStep}
            step={stepProps.stepIndex}
            stepLength={stepProps.stepsLen}
            handleNextCallback={handleNext}
            handleBackCallback={handleBack}
          />
        </form>
      )}
    </div>
  );
};

export default RequestConfirm;
