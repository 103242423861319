import { gql } from "@apollo/client";

export const GET_SERVICES = gql`  
query GetServices($sort: String) {
  services(
    sort: $sort
  ){
    id
    created_at
    updated_at
    title
    description
    slug
    service_category {
      id
      name
      slug
    }
    image {
      id
      url
    }
  }
}
`;

export const GET_SERVICE = gql`  
query GetService($id: ID!) {
  service(
    id: $id
  ){
    id
    created_at
    updated_at
    title
    title_en
    description
    description_en
    content
    content_en
    slug
    slug_en
    service_category {
      id
      name
      slug
    }
    image {
      id
      name
      caption
      width
      height
      previewUrl
      url
      ext
      mime
    }
    attachment {
      id
      created_at
      name
      updated_at
      url
      previewUrl
    }
    motion {
      id
      name
      caption
      width
      height
      previewUrl
      url
      ext
    }
  }
}
`;

export const CREATE_SERVICE = gql`
mutation CREATE_SERVICE(
  $title: String!,
  $titleEn: String,
  $description: String!,
  $descriptionEn: String,
  $content: String!,
  $contentEn: String,
  $slug: String!,
  $slugEn: String,
  $thumbnail: ID,
  $attachment: [ID],
  $category: ID,
  $motionGraphic: ID,
  ){
  createService(input: {
    data: {
      title: $title
      title_en: $titleEn
      description: $description
      description_en: $descriptionEn
      content: $content
      content_en: $contentEn
      slug: $slug
      slug_en: $slugEn
      image: $thumbnail
      attachment: $attachment
      service_category: $category
      motion: $motionGraphic
    }
  }){
    service {
      id
    }
  }
}
`;

export const UPDATE_SERVICE = gql`
mutation UPDATE_SERVICE(
  $id: ID!, 
  $title: String!,
  $titleEn: String,
  $description: String!,
  $descriptionEn: String,
  $content: String!,
  $contentEn: String,
  $slug: String!,
  $slugEn: String,
  $thumbnail: ID,
  $attachment: [ID],
  $category: ID,
  $motionGraphic: ID,
  ){
    updateService(input: {
      where: {
        id: $id
      }
      data: {
        title: $title
        title_en: $titleEn
        description: $description
        description_en: $descriptionEn
        content: $content
        content_en: $contentEn
        slug: $slug
        slug_en: $slugEn
        image: $thumbnail
        attachment: $attachment
        service_category: $category
        motion: $motionGraphic
      }
    }) {
      service {
        id
      }
    }
}
`;

export const GET_SERVICE_CATEGORIES = gql`
query GET_SERVICE_CATEGORIES($sort: String) {
  serviceCategories(sort: $sort){
    id
    created_at
    updated_at
    name
    slug
    name_en
    slug_en
  }
}
`;

export const DELETE_SERVICE = gql`
mutation DELETE_SERVICE(
  $id: ID!,
){
  deleteService(input: {
    where: {
      id:$id
    }
  }){
    service {
      id
    }
  }
}
`;

export const DELETE_SERVICE_CATEGORY = gql`
mutation DELETE_SERVICE_CATEGORY(
  $id: ID!,
){
  deleteServiceCategory(input: {
    where: {
      id:$id
    }
  }){
    service {
      id
    }
  }
}
`;
export default {};
