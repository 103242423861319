import { gql } from "@apollo/client";

export const GET_MESSAGE_TEMPLATES = gql`  
query GET_MESSAGE_TEMPLATES($sort: String) {
  messageTemplates(sort: $sort){
    id
    created_at
    updated_at
    title
    message_email
    message_email_en
    message_sms
    server_side
  }
}
`;

export const GET_MESSAGE_TEMPLATES_BY_STEP = gql`  
query GET_MESSAGE_TEMPLATES($sort: String, $contract_step_number: String) {
  messageTemplates(sort: $sort, where: {
    contract_step_number: $contract_step_number
  }){
    id
    created_at
    updated_at
    title
    message_email
    message_email_en
    message_sms
    server_side
  }
}
`;

export const GET_MESSAGE_TEMPLATE = gql`  
query GET_MESSAGE_TEMPLATE($id: ID!) {
  messageTemplate(id:$id){
    id
    created_at
    updated_at
    title
    title_en
    message_email
    message_email_en
    message_sms
    message_sms_template_id
    key
    server_side
  }
}
`;

export const CREATE_MESSAGE_TEMPLATE = gql`
mutation CREATE_MESSAGE_TEMPLATE(
  $title: String!,
  $titleEn: String,
  $messageEmailPersian: String,
  $messageEmailEnglish: String,
  $messageSms: String,
  $messageSmsTemplateId: String,
  $key: String,
  $serverSide: Boolean,
  ){
  createMessageTemplate(input: {
    data: {
      title: $title
      title_en: $titleEn
      message_email:$messageEmailPersian
      message_email_en: $messageEmailEnglish
      message_sms:$messageSms
      message_sms_template_id:$messageSmsTemplateId
      key:$key
      server_side:$serverSide
    }
  }){
    messageTemplate {
      id
    }
  }
}
`;

export const UPDATE_MESSAGE_TEMPLATE = gql`
mutation UPDATE_MESSAGE_TEMPLATE(
  $id: ID!,
  $title: String,
  $titleEn: String,
  $messageEmailPersian: String,
  $messageEmailEnglish: String,
  $messageSms: String,
  $messageSmsTemplateId: String,
  $key: String,
  $serverSide: Boolean,
  ){
  updateMessageTemplate(input: {
    where: {
      id: $id
    },
    data: {
      title: $title
      title_en: $titleEn
      message_email:$messageEmailPersian
      message_email_en: $messageEmailEnglish
      message_sms:$messageSms
      message_sms_template_id:$messageSmsTemplateId
      key:$key
      server_side:$serverSide
    }
  }){
    messageTemplate {
      id
    }
  }
}
`;

export default {};
