/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Files from "react-files";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { UPLOAD_FILES } from "./inputFileQueries";
import InputBox from "./InputBox";
import { API_SERVER } from "../../Config";
import TableSimple from "./TableSimple";

const useStyles = makeStyles((theme: Theme) => createStyles({
  box: {
    // backgroundColor: "#eee",
    border: "1px solid #eee",
    padding: "10px",
  },
  name: {
    marginBottom: "10px",
  },
  dropZone: {
    borderWidth: "2px",
    borderStyle: "dashed",
    borderColor: "#ddd",
    padding: "25px 20px",
    marginBottom: "10px",
    textAlign: "center",
    // backgroundImage: `url(${Placeholder})`,
    // backgroundPosition: "top",
    // backgroundRepeat: "no-repeat",
    backgroundColor: "#f5f5f5",
    // backgroundSize: "80px",
    // backgroundPositionY: "30px",
    // paddingTop: "20px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8f8",
    },
  },
  dropZoneHide: {
    display: "none",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "red",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#fff",
    width: "100%",
    // justifyContent: "space-between",
    alignItems: "center",
    "&>div": {
      marginLeft: "20px",
    },
  },
  tile: {
    width: "60px",
    height: "55px",
    // height: "60px",
    // marginTop: "15px",
    // marginBottom: "10px",
    borderRadius: "5px",
  },
  tileImage: {
    width: "100%",
    // height: "60px",
    borderRadius: "5px",
  },
  thumbnail: {
    width: "100%",
    // height: "150px",
    overflow: "hidden",
    backgroundColor: "#eee",
    // backgroundColor: "red",
  },
  extension: {
    width: "100%",
    // height: "50px",
    padding: "20px",
    textAlign: "center",
    backgroundColor: "#fff",
    borderRadius: "10px",
  },
  ul: {
    margin: "0px",
    // marginTop: "20px",
    marginBottom: "20px",
    padding: "0px",
    // paddingTop: "20px",
    borderTopWidth: "0px",
    borderTopColor: "#ddd",
    borderTopStyle: "solid",
    // display: "flex",
    // flexDirection: "row",
    // flexWrap: "wrap",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
    // width: "24%",
    marginBottom: "10px",
  },
  remove: {
    // padding: "15px",
    display: "flex",
    position: "absolute",
    backgroundColor: "#ececec96",
    borderRadius: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  saveButton: {},
  placeholder: {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  iconUpload: {
    marginRight: "10px",
    fontSize: "4em",
    color: "#ddd",
  },
  title: {
    padding: "5px",
    backgroundColor: "#fff",
    border: "1px solid #eee",
  },
}));

type IPropsInput = {
    name: string,
    grid?: number,
    files: any,
    disable?: boolean,
    handleSave: Function,
    removeFile: Function,
    titleDrag?: string,
    acceptFiles?: [],
    maxFileSize?: number,
    isUploadDisabled?: boolean,
}

const InputFileBatch = (props: IPropsInput) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const {
    name,
    files,
    grid,
    disable,
    titleDrag,
    acceptFiles,
    maxFileSize,
    handleSave,
    removeFile,
    isUploadDisabled,
  } = props;
  const [fileTitles, setFileTitles] = useState<string[]>([]);
  const [newFiles_, setNewFiles_] = useState<any>([]);

  const [uploadFiles, responseUploadFiles] = useMutation(UPLOAD_FILES);

  const onFilesChange = (filesVal) => {
    setNewFiles_((preFiles) => [...preFiles, ...filesVal]);
  };

  const onFilesError = (error, file) => {
    console.log(`error code ${error.code}: ${error.message}`);
  };

  const filesRemoveOne = (file) => {
    confirm({
      title: t("SureForDelete"),
      description: "",
      confirmationText: t("yes"),
      cancellationText: t("no"),
    }).then(() => {
      removeFile(file);
    });
  };

  const filesUpload = async () => {
    if (newFiles_.length) {
      uploadFiles({
        variables: {
          files: newFiles_,
        },
      })
        .then((result) => {
          enqueueSnackbar(t("successUploaded"), {
            variant: "success",
          });
          handleSave(result.data.multipleUpload, fileTitles);
        }).catch((error) => {
          enqueueSnackbar(t("errorUpload"), {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar(t("PleaseSelectOneFile"), {
        variant: "warning",
      });
    }
  };

  const showThumbnail = (file: any) => {
    if (!file.title) {
      return file.preview?.type === "image"
        ? <img className={classes.tileImage} alt="x" src={file.preview.url} />
        : <div className={classes.extension}>{file.extension}</div>;
    } if (file.title) {
      const file_ = _.get(file, "file[0]");
      if (file_) {
        const url_ = API_SERVER() + file_.url;
        const isImage = file_.mime.search("image");
        if (isImage >= 0) {
          return <img className={classes.tileImage} alt="x" src={url_} />;
        }
        return <div className={classes.extension1}>{file_.ext}</div>;
      }
    }
    return "";
  };

  const hideDropZonePlace = false;

  const headers = [
    {
      title: "title",
    },
    {
      title: "type",
      key: "t",
    },
    {
      title: "created_at",
      key: "dateTime",
    },
    {
      title: "actions",
      key: "actions",
    },
  ];

  const rows = files.map((file) => {
    const type = _.get(file, "file[0].mime", null);
    const date = file.created_at;
    const file_ = _.get(file, "file[0]", undefined);
    return {
      id: file.id,
      title: file.title,
      type,
      created_at: date,
      actions: {
        download: file_,
        remove: () => filesRemoveOne(file),
      },
    };
  });

  const handleClose_ = () => {
    setFileTitles([]);
    setNewFiles_([]);
  };

  const handleSave_ = () => {
    filesUpload();
    handleClose_();
  };

  const box = () => (
    <div className={classes.box}>
      {!isUploadDisabled ? (
        <Files
          key={name}
          className={hideDropZonePlace ? classes.dropZoneHide : classes.dropZone}
          onChange={onFilesChange}
          onError={onFilesError}
          accepts={acceptFiles || ["image/png", "image/jpg",
            "image/jpeg", ".pdf", ".docx", ".zip", ".rar"]}
          multiple
          maxFileSize={maxFileSize || 2000000}
          minFileSize={0}
          clickable={!disable}
        >
          <div className={classes.placeholder}>
            <CloudUploadIcon className={classes.iconUpload} />
            <span>{titleDrag ? t(titleDrag) : t("Drop_files_here_or_click_to_upload")}</span>
          </div>
        </Files>
      ) : ""}
      {
          files?.length > 0
            ? (
              <>
                <TableSimple rows={rows} headers={headers} />
              </>
            )
            : null
      }
      {
        newFiles_?.length ? (
          <Dialog
            open={!!newFiles_?.length}
            maxWidth="xs"
            fullWidth
            onClose={(handleClose_)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">{t("title")}</DialogTitle>
            <DialogContent>
              <div className={classes.gallery}>
                <ul className={classes.ul}>
                  {newFiles_?.map((file, i) => (
                    <li className={classes.column} key={file.id}>

                      <div className={classes.thumbnail}>
                        {showThumbnail(file)}
                      </div>
                      <div className={classes.title}>
                        <InputBox
                          name="fileTitles"
                          value={file?.title || fileTitles[i]}
                          setValue={(e) => {
                            const fs_ = [...fileTitles];
                            fs_[i] = e;
                            setFileTitles(fs_);
                          }}
                          required
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={(handleClose_)} color="primary">
                {t("Cancel")}
              </Button>
              <Button
                onClick={handleSave_}
                disabled={_.isEmpty(fileTitles) || !fileTitles[0]}
                color="primary"
                variant="outlined"
              >
                {t("Add")}
              </Button>
            </DialogActions>
          </Dialog>
        ) : ""
      }

      {
        newFiles_?.length > 0 ? (
          <Button
            onClick={filesUpload}
            className={classes.saveButton}
            variant="outlined"
          >
            {t("Request.Upload")}
          </Button>
        ) : ""
      }
    </div>
  );

  if (grid) {
    return (
      <Grid item xs={grid}>
        {box()}
      </Grid>
    );
  }
  return (
    <>
      {box()}
    </>
  );
};

InputFileBatch.defaultProps = {
  grid: 0,
  // required: false,
  disable: false,
  // removeFile: null,
  titleDrag: null,
  acceptFiles: null,
  maxFileSize: null,
  isUploadDisabled: false,
};

export default InputFileBatch;
