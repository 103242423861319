import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { GET_USERS } from "../../pages/User/usersQueries";
import SelectSearch from "./SelectSearch";
import statics from "../../app/statics";

export default function SelectOperator(props: any) {
  const [user, setUser] = useState("");
  const userQuery = useQuery(GET_USERS, {
    variables: {
      sort: "created_at:desc",
      limit: 20,
      where: {
        _q: undefined,
        _or: [
          {
            role: {
              name: statics.roles.Authenticated,
            },
          },
          {
            role: {
              name: statics.roles.Accountant,
            },
          },
          {
            role: {
              name: statics.roles.Agent,
            },
          },
          {
            role: {
              name: statics.roles.AgentMaster,
            },
          },
        ],
      },
    },
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const {
    name,
    formikI,
    setOpen,
    grid,
  } = props;

  const users = useMemo(() => {
    const data = userQuery.data?.usersConnection?.values?.map((item) => {
      const label = `${item.id}- ${item.name}`;
      return {
        label,
        value: item.id,
        detail: item,
      };
    });

    return data || [];
  }, [userQuery.data]);

  const handleSearchUser = (e: any) => {
    userQuery.refetch({
      where: {
        _q: e.target.value || undefined,
        _or: [
          {
            role: {
              name: statics.roles.Authenticated,
            },
          },
          {
            role: {
              name: statics.roles.Accountant,
            },
          },
        ],
      },
    });
  };

  const setValue_ = (e) => {
    formikI.setFieldValue(name, e);
  };

  return (
    <SelectSearch
      name={name}
      value={user}
      setValue={setValue_}
      grid={grid}
      items={users}
      handleSearch={handleSearchUser}
    />
  );
}
