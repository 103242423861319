import { useMutation, useQuery } from "@apollo/client";
import {
  Card, Grid,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import PageTitle from "../../components/PageTitle";
import { GET_MESSAGE_BY_ID, UPDATE_MESSAGE } from "./messageQueries";

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {},
  card: {
    padding: theme.spacing(5),
  },
  label: {
    padding: 10,
  },
}));

const Message = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [updateMessage, responseUpdateMessage] = useMutation(UPDATE_MESSAGE);

  const messageId = _.get(props, "match.params.id");
  const messageQuery = useQuery(GET_MESSAGE_BY_ID, { variables: { id: Number(messageId) } });
  const messageData = _.get(messageQuery, "data.message");

  useEffect(() => {
    if (messageId) {
      updateMessage({
        refetchQueries: ["GET_MESSAGES", "GET_MESSAGES_CUSTOMER", "GET_ANALYTIC_INFO"],
        variables: {
          mId: Number(messageId),
        },
      });
    }
  }, [messageId]);

  return (
    <>
      <PageTitle
        title={`${t("MessageNumber")}#${messageData?.id}`}
      />
      {!messageQuery.loading
        ? (
          <Card className={classes.card}>
            <div
              className={classes.root}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <span className={classes.label}>{t("title")}</span>
                  :
                  <span>{messageData?.title}</span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.label}>{t("bodyMessage")}</span>
                  :
                  {parse(messageData?.body)}
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.label}>{t("typeMessage")}</span>
                  :
                  {messageData?.type}
                </Grid>
              </Grid>
            </div>
          </Card>
        )
        : ""}
    </>
  );
};

export default Message;
