import { gql } from "@apollo/client";

export const GET_INVOICES = gql`  
query GetInvoices($sort: String, $limit: Int, $start: Int, $where: JSON) {
    invoicesConnection(sort: $sort, limit: $limit, start: $start, where: $where){
        values {
          id
          created_at
          updated_at
          request_id {
            id
            name
            family
            userId {
                id
            }
          }
          evaluation {
            id
            name
            family
            user {
              id
            }
          }
          cost
          status
          message
          subject
          due_date
          published_at
        }
        aggregate {
          count
          totalCount
        }
      }
  }
`;

export const GET_INVOICES_TODO = gql`
query GET_INVOICES_TODO{
  invoices(where: {
    _or: [
      {
        status: "waitPayment"
      },
      {
        status: "waitForApprove"
      }
    ],
    request_id_null: false,
    request_id: {
      request_status: {
        sign_date_null: false
      }
    }
  },
  sort: "due_date:asc"
  limit: 30
  ){
    id
    request_id {
      id
      name
      family
    }
    payments {
      id
      created_at
      amount
    }
    title
    type
    created_at
    due_date
    cost
    status
  }
}
`;
export default {};
