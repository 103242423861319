import { useMutation } from "@apollo/client";
import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { ADD_USER } from "../../pages/User/usersQueries";
import InputBox from "../micro/InputBox";
import DialogBase from "./DialogBase";

type IProps = {
    open: boolean,
    setOpen: Function
}
const DialogAddUserManual = (props: IProps) => {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [addUser, responseAddUser] = useMutation(ADD_USER, {
    refetchQueries: ["GET_USERS"],
  });

  const validationSchema = yup.object({
    name: yup
      .string().required(),
    email: yup
      .string().required(),
    phone: yup
      .string().required(),
    username: yup
      .string().required(),
    password: yup
      .string().required(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      username: "",
    },
    validationSchema,
    onSubmit: (values) => {
      addUser({
        variables: {
          ...values,
          ...{
            phone: `${values.phone}`,
          },
        },
      }).then((r) => {
        enqueueSnackbar(t("SuccessfullyAdded"), {
          variant: "success",
        });
      }).catch((e) => {
        enqueueSnackbar(t("ErrorHappened"), {
          variant: "error",
        });
      });
    },
  });

  const {
    values, touched, errors,
  } = formik;

  return (
    <DialogBase
      options={{ title: t("AddUser") }}
      setOptions={() => {}}
      hide
      open={open}
      setOpen={setOpen}
      hideFooter
    >
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="on"
      >
        <Grid container spacing={1}>
          <InputBox
            handler="formik"
            name="name"
            value={values.name}
            formikI={formik}
            grid={12}
            variant="standard"
          />
          <InputBox
            handler="formik"
            name="username"
            value={values.username}
            formikI={formik}
            grid={12}
            variant="standard"
            placeholder="Username"
          />
          <InputBox
            handler="formik"
            name="phone"
            value={values.phone}
            formikI={formik}
            grid={12}
            variant="standard"
            placeholder="9120238299"
          />
          <InputBox
            handler="formik"
            name="email"
            value={values.email}
            formikI={formik}
            grid={12}
            variant="standard"
            placeholder="email@exmaple.com"
          />
          <InputBox
            handler="formik"
            name="password"
            value={values.password}
            formikI={formik}
            type="password"
            grid={12}
            variant="standard"
          />

          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
            >
              {t("Submit")}
            </Button>
          </Grid>
        </Grid>
      </form>
      <br />
    </DialogBase>
  );
};

export default DialogAddUserManual;
