import React, { useCallback, useState } from "react";
import {
  Checkbox, Fab, Grid, IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import moment from "moment-jalaali";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { GET_MESSAGE_TEMPLATES } from "./messagesQueries";
import { GET_ME } from "../../app/layout/layoutQueries";
import TableAdvance from "../../components/micro/TableAdvance";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  pendingForSetTimeSession: {
    backgroundColor: "orange",
    border: "1px solid #eee",
    padding: "5px",
    display: "inline",
    borderRadius: "5px",
  },
  summary: {
    // maxHeight: "42px",
    overflow: "hidden",
  },
  fab: {
    position: "fixed",
    right: "50px",
    bottom: "50px",
    zIndex: 200,
  },
}));

export default function MessageTemplates(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogContent, setDialogContent] = useState();

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  let uId;
  if (role === "Customer") {
    uId = _.get(Me, "data.me.id");
  }
  const { loading, error, data } = useQuery(GET_MESSAGE_TEMPLATES,
    { variables: { sort: "updated_at:desc" } });

  const dataTableData = useCallback(() => {
    if (data?.messageTemplates?.length) {
      return data?.messageTemplates?.map((item) => [
        item.id,
        item.title,
        !!item.message_email,
        !!item.message_email_en,
        !!item.message_sms,
        !!item.server_side,
      ]);
    }
    return [];
  }, [data?.messageTemplates]);

  const columns = [
    {
      name: t("id"),
      options: {
        filter: true,
        display: false,
      },
    }, {
      name: t("title"),
      options: {
        filter: true,
      },
    },
    {
      name: t("hasMessageEmail"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <Checkbox checked={value} />
          </div>
        ),
      },
    },
    {
      name: t("hasMessageEmailEn"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <Checkbox checked={value} />
          </div>
        ),
      },
    },
    {
      name: t("hasSms"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <Checkbox checked={value} />
          </div>
        ),
      },
    },
    {
      name: t("isServerSide"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <Checkbox checked={value} />
          </div>
        ),
      },
    }, {
      name: t("created_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    }, {
      name: "",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              openItem({
                value, tableMeta, updateValue,
              });
            }}
            >
              <ViewIcon />
            </IconButton>
            <IconButton onClick={() => (deleteRow({ value, tableMeta, updateValue }))}>
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];

  const openItem = (item) => {
    const id_ = item.tableMeta.rowData[0];
    props.history.push(`/message-template/${id_}`);
  };

  const deleteRow = (item) => {
    const itemID = item.tableMeta.rowData[0];
    // confirm({
    //   title: t("SureForDelete"), description: "",
    //  confirmationText: t("yes"), cancellationText: t("no"),
    // }).then(() => {
    //   deleteItem({
    //     variables: {
    //       id: itemID,
    //     },
    //   }).then((response) => {
    //     setNotif({
    //       open: true, message: "SuccessfullyDeleted", type: "success", setOpen: setNotif,
    //     });
    //     refetch();
    //   });
    // }).catch((e1) => {
    //   setNotif({
    //     open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
    //   });
    // });
  };

  return (
    <>
      {/* <PageTitle title="Tables" /> */}
      <Grid container spacing={4}>

        <Grid item xs={12}>
          <Fab
            onClick={() => props.history.push("message-template")}
            className={classes.fab}
            title={t("NewMessageTemplate")}
          >
            <AddIcon />
          </Fab>
          {!loading ? (
            <TableAdvance
              {...props}
              title="MessageTemplates"
              data={dataTableData()}
              columns={columns}
              itemPath="/message-template/"
              showFilter={false}
              pageOptions={
                {
                  main: "message-templates",
                }
              }
            />
          ) : ""}
        </Grid>
      </Grid>
    </>
  );
}
