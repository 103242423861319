import React, { useContext, useState } from "react";
import {
  Fab, Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useConfirm } from "material-ui-confirm";
import DoneIcon from "@material-ui/icons/Done";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { CREATE_STATE_REQUEST, UPDATE_STATE_REQUEST } from "../steps/requestStepAQueries";
import { RequestContext } from "../context/requestContext";
import statics, { STEPS } from "../../../app/statics";
import Notification, { INotification } from "../../../components/micro/SnackbarMicro";
import { GET_ME } from "../../../app/layout/layoutQueries";
import { getStepIndex } from "../../../app/utils/helper";
import FormDialogConfirmStepPrime from "../../../components/FormDialogConfirmStepPrime";
import { useContractState } from "../../../app/hooks";

const useStyles2 = makeStyles((theme: Theme) => createStyles({
  container: {
    // paddingTop: theme.spacing(5),
    backgroundColor: "#eee",
    padding: theme.spacing(2),
    borderStyle: "dashed",
    borderWidth: "2px",
    borderColor: "#fff",
    // paddingLeft: theme.spacing(5),
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  backButton: {},
  acceptButton: {
    backgroundColor: theme.palette.success.light,
    marginRight: "5px",
    fontSize: "1.1em",
    width: "150px",
    color: "#fff",
    fontWeight: "bold",
  },
  rejectButton: {
    backgroundColor: theme.palette.error.light,
    fontSize: "1.1em",
    width: "150px",
    color: "#fff",
    fontWeight: "bold",
  },
  step_content: {
    marginTop: theme.spacing(2),
  },
}));

type IPropsInput = {
  stepNumber: number | STEPS
}

const AdminNavbarStepTools = (props: IPropsInput) => {
  const classes = useStyles2();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const context = useContext(RequestContext);
  const [notif, setNotif] = useState<INotification>();
  const { stepNumber } = props;
  const stepIndex = getStepIndex(stepNumber);
  const { push } = useHistory();

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  const isCustomer = role === "Customer";

  const { reqStatus } = useContractState();
  const { data: dataGetStaReq } = reqStatus;

  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openConfirmReject, setOpenConfirmReject] = React.useState(false);

  const [updateRequestState, responseUpdateRequestState] = useMutation(
    UPDATE_STATE_REQUEST,
  );
  const [createRequestState, responseCreateRequestState] = useMutation(
    CREATE_STATE_REQUEST,
  );

  const handleAccept = () => {
    setOpenConfirm(true);
  };

  const handleReject = () => {
    setOpenConfirmReject(true);
  };

  // const handleRejectDialog = (data) => {
  //   if (dataGetStaReq.requestStatuses.length) {
  //     updateRequestState({
  //       variables: {
  //         reqId: context.requestData.requestId,
  //         // status: statics.StepsStatus[stepNumber + 1].key,
  //         reqSId: dataGetStaReq.requestStatuses[0].id,
  //         notification: data.notification,
  //       },
  //       refetchQueries: ["GetRequest", "GET_REQUESTS"],
  //       awaitRefetchQueries: true,
  //     }).then((result) => {
  //       setNotif({
  //         open: true, message: "CanceledOk", type: "success", setOpen: setNotif,
  //       });
  //       setTimeout(() => push("/requests"), 1000);
  //     }).catch((error2) => {
  //       setNotif({
  //         open: true, message: error2.message, type: "error", setOpen: setNotif,
  //       });
  //     });
  //   }
  // };

  return (
    <div className={classes.container}>
      { !isCustomer
          && (
            <Fab
              onClick={handleAccept}
              className="fab-icon fab-icon__action"
              style={{ backgroundColor: "Highlight" }}
              title={t("UpdateState")}
            >
              <DoneIcon />
              <span className="help-text">{t("UpdateState")}</span>
            </Fab>
          ) }
      <Notification {...notif} />

      {openConfirm ? (
        <FormDialogConfirmStepPrime
          openDialog={openConfirm}
          handelClose={() => setOpenConfirm(false)}
          title="ChangeStateOfContract"
          nextStatus={statics.StepsStatus[0].key}
          handleSubmit={() => {}}
        />
      ) : false}
      {/* <FormDialogConfirmStep
        openDialog={openConfirmReject}
        handelClose={() => setOpenConfirmReject(false)}
        title="SureForReject"
        nextStatus={statics.StepsStatus[1].key}
        handleSubmit={handleRejectDialog}
      /> */}
    </div>
  );
};

export default AdminNavbarStepTools;
