import React from "react";
import {
  Button, Dialog, DialogActions, DialogTitle,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { DELETE_ATTACHMENT_REQUEST } from "../../pages/Request/requestQueries";

const DialogDeleteAttachment = (props: any) => {
  const {
    open, setOpen,
  } = props;
  const { t } = useTranslation();
  const [deleteAttachment, response] = useMutation(
    DELETE_ATTACHMENT_REQUEST,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClick = (type: boolean) => {
    try {
      deleteAttachment({
        variables: {
          id: Number(open?.id),
        },
      })
        .then((r) => {
          enqueueSnackbar(t("SuccessfullyUpdated"), {
            variant: "success",
            key: "saved",
          });
          setOpen();
        }).catch((e) => {
          console.log("error", e);
          enqueueSnackbar(t("ErrorHappened"), {
            variant: "error",
          });
        });
    } catch (e) {
      console.log("error", e);
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      open={!!open}
      maxWidth="sm"
      fullWidth
      onClose={() => { setOpen(); }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("dialog.SureForDeleteDocument")}</DialogTitle>
      <form>
        <>
          <DialogActions>
            <Button
              onClick={() => setOpen()}
              color="secondary"
            >
              {t("no")}
            </Button>
            <Button color="primary" onClick={() => handleClick(true)}>
              {t("yes")}
            </Button>
          </DialogActions>
        </>
      </form>
    </Dialog>
  );
};

export default DialogDeleteAttachment;
