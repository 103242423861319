import React, { useState } from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import DatePickerComponent from "../micro/Datepicker";
import { getUtcFormat } from "../../app/utils/helper";
import { UPDATE_REQUEST_ATTACHMENT } from "../../pages/Request/steps/requestStepAQueries";

const DialogSetDueDate = (props: any) => {
  const {
    open, setOpen, dueDocumentDates, requestSID,
  } = props;
  const [dueDate, setDueDate] = useState<string>(null);
  const { t } = useTranslation();
  // const [update, response] = useMutation(
  //   UPDATE_STATE_REQUEST,
  //   {
  //     refetchQueries: ["GET_REQUEST_STATUS"],
  //   },
  // );
  const [update, response] = useMutation(
    UPDATE_REQUEST_ATTACHMENT,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onFinish = () => {
    const d = getUtcFormat(dueDate);

    if (d) {
      try {
        // update({
        //   variables: {
        //     reqSId: Number(requestSID),
        //     dueDocumentDates: d,
        //   },
        // })
        update({
          variables: {
            attachmentId: Number(open?.id),
            due_date: d,
          },
        })
          .then((r) => {
            enqueueSnackbar(t("SuccessfullyUpdated"), {
              variant: "success",
              key: "saved",
            });
            setOpen();
          }).catch((e) => {
            console.log("error", e);
            enqueueSnackbar(t("ErrorHappened"), {
              variant: "error",
            });
          });
      } catch (e) {
        console.log("error", e);
        enqueueSnackbar(t("ErrorHappened"), {
          variant: "error",
        });
      }
    }
  };

  return (
    <Dialog
      open={!!open}
      maxWidth="sm"
      fullWidth
      onClose={() => { setOpen(false); }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("Dialog.SetDueDate")}</DialogTitle>
      <form>
        <>
          <DialogContent>
            {t("Dialog.pleaseSelectDueDate")}
            <DatePickerComponent
              name="dueDate"
              value={dueDate}
              required
              setValue={setDueDate}
              grid={6}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={onFinish}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </>
      </form>
    </Dialog>
  );
};

export default DialogSetDueDate;
