import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login } from "../../pages/Login/loginApi";

type IUser = {
  username: string,
  password: string
}
export const loginUser = createAsyncThunk(
  "auth/login",
  async (user: IUser, thunkAPI) => {
    try {
      // const response = await fetch(
      //   'https://mock-user-auth-server.herokuapp.com/api/v1/auth',
      //   {
      //     method: 'POST',
      //     headers: {
      //       Accept: 'application/json',
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({
      //       email,
      //       password,
      //     }),
      //   }
      // );

      const { username, password } = user;
      const response = await login({ identifier: username, password });
      const data = await response;

      // if (response.status === 200) {
      //   localStorage.setItem('token', data.token);
      //   return data;
      // } else {
      //   return thunkAPI.rejectWithValue(data);
      // }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    email: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    // Reducer comes here
  },
  extraReducers: {
    // Extra reducer comes here
  },
});
export const userSelector = (state) => state.user;
