import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import momentTZ from "moment-timezone";
// import moment from "moment-jalaali";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Request from "../../pages/Request/Request";
import Requests from "../../pages/Requests/Requests";
import Invoices from "../../pages/Invoices/Invoices";
import { GET_ME, GET_ROLE_ME, UPDATE_USER_LOGIN_TIME } from "./layoutQueries";
import Messages from "../../pages/Messages/Messages";
import MessageTemplates from "../../pages/MessageTemplates/MessageTemplates";
import Tickets from "../../pages/Tickets/Tickets";
import Ticket from "../../pages/Tickets/Ticket/Ticket";
import Profile from "../../pages/Profile/Profile";
import Visas from "../../pages/Visa/Visas";
import Visa from "../../pages/Visa/Visa/Visa";
import Attachments from "../../pages/Attachment/Attachments";
import Attachment from "../../pages/Attachment/Attachment/Attachment";
import Articles from "../../pages/Article/Articles";
import Article from "../../pages/Article/Article/Article";
import Categories from "../../pages/Article/Category/Categories";
import Category from "../../pages/Article/Category/Category/Category";
import Invoice from "../../pages/Invoice/Invoice";
import Message from "../../pages/Message/Message";
import Services from "../../pages/Service/Services";
import Service from "../../pages/Service/Service/Service";
import ServiceCategories from "../../pages/Service/Category/ServiceCategories";
import ServiceCategory from "../../pages/Service/Category/Category/ServiceCategory";
import Sidebar from "./Sidebar";
import AppBarLocal from "./Appbar";
import MessageTemplate from "../../pages/MessageTemplates/Detail/MessageTemplate";
import Users from "../../pages/User/Users";
import Crisp from "../../components/extra/crsip";
import AppBarCustomer from "./AppbarCustomer";
import Contract from "../../pages/Request/contract";
import Consultants from "../../pages/Bookings/Bookings";
import Booking from "../../pages/Bookings/Booking";
import UserDetail from "../../pages/User/UserDetail";
import LoadingApp from "../../components/micro/Loading";
import NotFound from "../../pages/Error/NotFound";
import AgileBooking from "../../pages/AgileBooking/AgileBooking";
import AlertSystemManager from "../../components/AlertSystemManager";
import { PROFILE_UPDATE } from "../../pages/Profile/profileQueries";

momentTZ.tz.setDefault("Asia/Tehran");

// import logo from "../../assets/images/logo.svg";
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 2),
    position: "relative",
    width: "100%",
    overflowX: "hidden",
    marginBottom: "150px",
    // height: "100vh",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
  },
}));

export default function Layout(props: any) {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_ME);
  const roleId = _.get(data, "me.role.id");
  const permissionQuery = useQuery(GET_ROLE_ME, {
    variables: {
      id: roleId,
    },
    skip: !roleId,
  });

  useEffect(() => {
    if (error) {
      console.log("error=====", error.graphQLErrors);
      if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach((item: any) => {
          if (item?.extensions?.exception?.output?.statusCode === 401) {
            localStorage.clear();
            window.location.href = `${process.env.REACT_APP_LANDING}/login?clc=true`;
          }
        });
      }
    }
  }, [error]);

  const [updateUserLogin] = useMutation(UPDATE_USER_LOGIN_TIME);
  const role = _.get(data, "me.role.name");
  const isCustomer = role === "Customer";
  if (localStorage.getItem("user") !== _.get(data, "me.role.name")) {
    localStorage.setItem("roleType", _.get(data, "me.role.name"));
  }

  const [muProfileUpdate] = useMutation(PROFILE_UPDATE, {});

  const langLocal = localStorage.getItem("lang");

  const updateLang = () => {
    if (data && langLocal) {
      muProfileUpdate({
        variables: {
          uId: Number(data.me.id),
          lang: langLocal === "FA" ? "FA" : "EN",
        },
      });
    }
  };
  useEffect(() => {
    // 2021-08-04T08:00:00.000Z
    if (role) {
      const t1 = momentTZ();
      // THIS CORRECT TIME ZONE :America/Danmarkshavn
      setTimeout(() => {
        updateUserLogin({
          variables: {
            uId: Number(data.me.id),
            time: t1.locale("en").tz("America/Danmarkshavn").format(),
          },
        });
        updateLang();
      }, 2000);
    }
  }, [role]);

  if (!data && loading) {
    return <LoadingApp />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {!isCustomer ? <Sidebar {...props} /> : ""}

      <main className={classes.content}>
        {!isCustomer ? <AppBarLocal {...props} /> : ""}
        {isCustomer ? <AppBarCustomer {...props} /> : ""}

        <div className={classes.toolbar} id="app-toolbar" />
        <Crisp />
        {/* <Switch> */}
        {isCustomer ? (
          <Switch>
            <Route path="/request/:id?" component={Request} />
            <Route path="/contract/:id?" component={Contract} />
            <Route path="/invoice/:id?" component={Invoice} />
            <Route path="*" component={NotFound} />
          </Switch>
        ) : (
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/request/:id?" component={Request} />
            <Route path="/contract/:id?" component={Contract} />
            <Route path="/requests" component={Requests} />
            <Route path="/bookings" component={Consultants} />
            <Route path="/booking/:id" component={Booking} />
            <Route path="/agile-books" component={AgileBooking} />
            <Route path="/invoices" component={Invoices} />
            <Route path="/invoice/:id?" component={Invoice} />
            <Route path="/messages" component={Messages} />
            <Route path="/message/:id?" component={Message} />
            <Route path="/message-templates" component={MessageTemplates} />
            <Route path="/message-template/:id?" component={MessageTemplate} />
            <Route path="/tickets" component={Tickets} />
            <Route path="/ticket/:id?" component={Ticket} />
            <Route path="/profile" component={Profile} />
            <Route path="/visas" component={Visas} />
            <Route path="/visa/:id?" component={Visa} />
            <Route path="/attachments" component={Attachments} />
            <Route path="/attachment/:id?" component={Attachment} />
            <Route path="/articles" component={Articles} />
            <Route path="/article/:id?" component={Article} />
            <Route path="/categories" component={Categories} />
            <Route path="/category/:id?" component={Category} />
            <Route path="/services" component={Services} />
            <Route path="/service/:id?" component={Service} />
            <Route path="/service_categories" component={ServiceCategories} />
            <Route path="/service_category/:id?" component={ServiceCategory} />
            <Route path="/users" component={Users} />
            <Route path="/user/:id" component={UserDetail} />
            <Route path="*" component={NotFound} />
          </Switch>
        )}
        {!isCustomer ? <AlertSystemManager /> : ""}
        {/* </Switch> */}
      </main>
    </div>
  );
}
