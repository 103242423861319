import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ArrowForwardIos } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  typo: {
    color: theme.palette.text.primary,
    fontSize: "1.5em",
    fontWeight: "bold",
  },
  button: {
    // boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
    //   boxShadow: theme.customShadows.widgetWide,
    },
  },
  pageTitle: {
    display: "flex",
    alignItems: "center",
    "&>a": {
      paddingRight: "10px",
      display: "flex",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  iconDelete: {
    color: "#b1b1b1",
  },
}));

const PageTitle = (props: any) => {
  const classes = useStyles();
  const { title, button } = props;
  const navi = useHistory();

  return (
    <div className={classes.pageTitleContainer}>
      <div className={classes.pageTitle}>
        <a onClick={() => navi.goBack()}><ArrowForwardIos className={classes.iconDelete} /></a>
        <Typography
          className={classes.typo}
        >
          {title}
        </Typography>
      </div>
      {button}
    </div>
  );
};

export default PageTitle;
