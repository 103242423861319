import React, { useContext, useEffect, useState } from "react";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";

import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import InputBox from "../../../components/micro/InputBox";
import statics from "../../../app/statics";
import { RequestContext } from "../context/requestContext";
import NavBarFooter from "./NavBarFooter";
import { CREATE_REQUEST, UPDATE_REQUEST } from "../steps/requestStepAQueries";
import { Boolean_, nationalCodeRegex } from "../../../app/utils/helper";
import DatePickerComponent from "../../../components/micro/Datepicker";
import SelectBox from "../../../components/micro/SelectBox";
import RadioGroupBox from "../../../components/micro/RadioGroupBox";
import { GET_ME } from "../../../app/layout/layoutQueries";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    // paddingRight: theme.spacing(5),
    // paddingLeft: theme.spacing(5),
  },
}));

const RequestPersonalDetail = (props: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  const uId = _.get(Me, "data.me.id");

  const validationSchema = yup.object({
    name: yup.string().required(),
    family: yup.string().required(),
    nameEn: yup.string().required(),
    familyEn: yup.string().required(),
    email: yup.string().required().email(t("validation.emailNotValid")),
    nationalNumber: yup
      .string()
      .required()
      .matches(nationalCodeRegex, t("validation.nationalNotValid")),
    // .string().required().length(10, t("validation.nationalNotValid")),
    phone: yup.string().required().length(11, t("validation.phoneNotValid")), // .matches(/^[0-9]+$/, t("email_not_valid")),
    // .test("len", "Phone numbers not valid", (val: any) => val.toString().length === 11),
    phoneHome: yup.number().optional(),
    // married: yup.string().required(),
    father: yup.string().required(),
    fatherEn: yup.string().required(),
    birthday: yup.string().required(),
    address: yup.string().required(),
    addressEn: yup.string().required(),
    numberChilds: yup.number().optional(),
    gender: yup.string().required(),
    // soldiery: yup
    //   .string().required(),
    hasSchengen: yup.string().required(),
    hasDisease: yup.string().required(),
    hascriminal: yup.string().required(),
    targetCanada: yup.string().required(),
    familyRequested: yup.string().required(),
  });

  const classes = useStyles();
  const context = useContext(RequestContext);
  const { stepProps } = props;
  const { requestData } = context;

  const formik = useFormik({
    initialValues: {
      name: "",
      nameEn: "",
      family: "",
      familyEn: "",
      father: "",
      fatherEn: "",
      email: "",
      phone: "",
      nationalNumber: "",
      phoneHome: "",
      gender: "",
      birthday: moment().add(-10, "years").format(),
      address: "",
      addressEn: "",
      hasSchengen: "",
      hasDisease: "",
      hascriminal: "",
      soldiery: "",
      targetCanada: "",
      married: "",
      familyRequested: "false",
      familyRequestDescription: "",
      numberChilds: 0,
    },
    validationSchema,
    onSubmit: (values) => {
      handleNext();
    },
  });

  const { values, touched, errors } = formik;

  const {
    name,
    nameEn,
    family,
    familyEn,
    email,
    phone,
    nationalNumber,
    phoneHome,
    gender,
    address,
    addressEn,
    married,
    birthday,
    hasSchengen,
    hasDisease,
    hascriminal,
    soldiery,
    targetCanada,
    familyRequested,
    familyRequestDescription,
    numberChilds,
    father,
    fatherEn,
  } = values;
  const [loading, setLoading] = useState<boolean>(false);

  const [createRequest, responseCreateRequest] = useMutation(CREATE_REQUEST);
  const [updateRequest, responseUpdateRequest] = useMutation(UPDATE_REQUEST);

  useEffect(() => {
    if (requestData.requestId) {
      setLoading(true);
      formik.setFieldValue("name", requestData.name);
      formik.setFieldValue("nameEn", requestData.name_en);
      formik.setFieldValue("family", requestData.family);
      formik.setFieldValue("familyEn", requestData.family_en);
      formik.setFieldValue("father", requestData.father);
      formik.setFieldValue("fatherEn", requestData.father_en);
      formik.setFieldValue("email", requestData.email);
      formik.setFieldValue("phone", requestData.phone);
      formik.setFieldValue("nationalNumber", requestData.national_number);
      if (requestData.phone_home) {
        formik.setFieldValue("phoneHome", requestData.phone_home);
      }
      formik.setFieldValue("gender", requestData.gender);
      if (requestData.birthday) {
        formik.setFieldValue("birthday", requestData.birthday);
      }
      formik.setFieldValue("address", requestData.address);
      formik.setFieldValue("addressEn", requestData.address_en);
      formik.setFieldValue("hasSchengen", requestData.has_schengen_visa);
      formik.setFieldValue("hasDisease", requestData.has_disease || false);
      formik.setFieldValue("hascriminal", requestData.criminal_record);
      formik.setFieldValue("soldiery", requestData.soldiery);
      formik.setFieldValue("targetCanada", requestData.target_canada);
      formik.setFieldValue("married", requestData.married);
      formik.setFieldValue(
        "familyRequested",
        requestData.family_requested?.toString(),
      );
      formik.setFieldValue(
        "familyRequestDescription",
        requestData.family_requested_description,
      );
      if (!_.isNull(requestData.number_childs)) {
        formik.setFieldValue("numberChilds", Number(requestData.number_childs));
      }

      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
    // }, []);
  }, [requestData]);

  useEffect(() => {
    if (responseCreateRequest.data) {
      const { data } = responseCreateRequest;
    } else if (responseCreateRequest.error) {
      const { error } = responseCreateRequest;
    }
  }, [responseCreateRequest.error, responseCreateRequest.data]);

  const handleBack = () => {
    stepProps.handleBack();
  };

  const handleNext = (e?) => {
    if (e) e.preventDefault();

    if (role === "Customer" && !stepProps.accessUser) {
      stepProps.handleNext();
      return;
    }

    const bth = moment(birthday).locale("en").format();

    let description_ = "";
    if (familyRequested || Boolean_(familyRequested)) {
      description_ = familyRequestDescription;
    }

    const variables = {
      name,
      family,
      name_en: nameEn,
      family_en: familyEn,
      father,
      father_en: fatherEn,
      email,
      phone,
      national_number: nationalNumber,
      phone_home: phoneHome,
      gender,
      birthday: bth,
      address,
      address_en: addressEn,
      has_schengen_visa: Boolean_(hasSchengen),
      has_disease: Boolean_(hasDisease),
      criminal_record: Boolean_(hascriminal),
      soldiery: soldiery || statics.soldiery[1].value,
      target_canada: targetCanada,
      married,
      family_requested: Boolean_(familyRequested),
      family_requested_description: description_,
      number_childs: numberChilds || 0,
      userId: uId,
    };
    context.setLoading(true);
    if (requestData?.requestId) {
      // TODO must be updated
      updateRequest({
        variables: { requestId: Number(requestData?.requestId), ...variables },
        refetchQueries: ["GetRequest", "GET_REQUESTS"],
        awaitRefetchQueries: true,
      })
        .then((result) => {
          enqueueSnackbar(t("SuccessfullyUpdated"), {
            variant: "success",
          });
          const newData = { ...requestData, ...variables };
          setTimeout(() => stepProps.handleNext(newData), 500);
        })
        .catch((error) => {
          enqueueSnackbar(t("ErrorHappened"), {
            variant: "error",
          });
        });
    } else {
      createRequest({
        variables,
        refetchQueries: ["GET_REQUESTS", "GetRequest"],
      })
        .then((result) => {
          enqueueSnackbar(t("SuccessfullyCreated"), {
            variant: "success",
          });
          const requestId = _.get(result, "data.createRequest.request.id");
          const newData = { ...variables, requestId };
          context.setRequestData(newData);
          setTimeout(() => stepProps.handleNext(newData), 500);
        })
        .catch((error) => {
          enqueueSnackbar(t("ErrorHappened"), {
            variant: "error",
          });
        });
    }
  };

  return (
    <div>
      {/* {!loading
        ? ( */}
      <form
        onSubmit={formik.handleSubmit}
        className={classes.root}
        noValidate
        autoComplete="on"
      >
        <Grid container spacing={4}>
          <InputBox
            handler="formik"
            name="name"
            value={name}
            formikI={formik}
            grid={3}
            required
          />
          <InputBox
            handler="formik"
            name="nameEn"
            value={nameEn}
            formikI={formik}
            grid={3}
            required
            dir="ltr"
          />
          <InputBox
            handler="formik"
            name="family"
            value={family}
            formikI={formik}
            grid={3}
            required
          />
          <InputBox
            handler="formik"
            name="familyEn"
            value={familyEn}
            formikI={formik}
            grid={3}
            required
            dir="ltr"
          />
          <InputBox
            handler="formik"
            name="father"
            value={father}
            formikI={formik}
            grid={3}
            required
          />
          <InputBox
            handler="formik"
            name="fatherEn"
            value={fatherEn}
            formikI={formik}
            grid={3}
            required
            dir="ltr"
          />
          <InputBox
            handler="formik"
            name="nationalNumber"
            value={nationalNumber}
            formikI={formik}
            grid={3}
            required
          />
          <InputBox
            handler="formik"
            name="email"
            value={email}
            formikI={formik}
            grid={3}
            helper
            required
            dir="ltr"
          />
          <InputBox
            handler="formik"
            name="phone"
            value={phone}
            placeholder="09301001000"
            // type="number"
            formikI={formik}
            grid={3}
            required
            helper
          />
          <InputBox
            handler="formik"
            name="phoneHome"
            value={phoneHome}
            placeholder="02122290437"
            // type="number"
            formikI={formik}
            grid={3}
          />
          <DatePickerComponent
            handler="formik"
            name="birthday"
            value={birthday}
            formikI={formik}
            grid={3}
            required
            minDate={moment().add(-90, "years").format()}
            maxDate={moment().add(-1, "years").format()}
            views="bth"
          />
          <SelectBox
            handler="formik"
            name="married"
            desc="Request.marriageStatus"
            value={married}
            // setValue={setMarried}
            formikI={formik}
            grid={married && married !== "single" ? 2 : 4}
            items={statics.marriedOptions}
            required
          />
          {married && married !== "single" && (
            <InputBox
              handler="formik"
              name="numberChilds"
              value={numberChilds}
              type="number"
              formikI={formik}
              // setValue={setNumberChilds}
              grid={2}
              // required
            />
          )}

          <SelectBox
            handler="formik"
            name="gender"
            value={gender}
            // setValue={setGender}
            grid={gender === "male" ? 2 : 4}
            formikI={formik}
            items={statics.gender}
            required
          />
          {gender && gender === "male" && (
            <SelectBox
              handler="formik"
              name="soldiery"
              value={soldiery}
              // setValue={setSoldiery}
              grid={2}
              formikI={formik}
              items={statics.soldiery}
              required
            />
          )}

          <InputBox
            handler="formik"
            name="address"
            value={address}
            // setValue={setAddress}
            formikI={formik}
            grid={6}
            required
          />

          <InputBox
            handler="formik"
            name="addressEn"
            value={addressEn}
            // setValue={setAddress}
            formikI={formik}
            grid={6}
            required
            dir="ltr"
          />
        </Grid>
        <br />
        <Grid container spacing={4}>
          <RadioGroupBox
            handler="formik"
            name="hasSchengen"
            value={hasSchengen?.toString()}
            // setValue={setHasSchengen}
            formikI={formik}
            grid={4}
            items={statics.defaultOption}
            required
          />
          <RadioGroupBox
            handler="formik"
            name="hasDisease"
            value={hasDisease?.toString()}
            // setValue={setHasDisease}
            formikI={formik}
            grid={4}
            items={statics.defaultOption}
            required
          />

          <RadioGroupBox
            handler="formik"
            name="hascriminal"
            value={hascriminal?.toString()}
            // setValue={setCriminal}
            formikI={formik}
            grid={4}
            items={statics.defaultOption}
            required
          />
          <RadioGroupBox
            handler="formik"
            name="targetCanada"
            value={targetCanada}
            formikI={formik}
            grid={4}
            items={statics.targetCanadaOptions}
            required
          />
          <RadioGroupBox
            handler="formik"
            name="familyRequested"
            value={familyRequested?.toString()}
            formikI={formik}
            grid={4}
            items={statics.defaultOption}
            required
          />
          {familyRequested === "true" && (
            <InputBox
              handler="formik"
              name="familyRequestDescription"
              value={familyRequestDescription}
              formikI={formik}
              grid={4}
              rows={3}
            />
          )}
        </Grid>
        <NavBarFooter
          step={stepProps.stepIndex}
          stepLength={stepProps.stepLen}
          handleNextCallback={handleNext}
          handleBackCallback={handleBack}
        />
      </form>
      {/* ) */}
      {/* : ""} */}
    </div>
  );
};

export default RequestPersonalDetail;
