import { createTheme } from "@material-ui/core";
import defaultTheme from "./default";

// import IRANSans from "../../assets/fonts/IRANSans";
import Yekan, { YekanNumber } from "../../assets/fonts/Yekan";

const overrides = {
  typography: {
    fontFamily: "IRANSans",
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [Yekan, YekanNumber],
      },
    },
    // container: {
    //   ".MuiGrid-spacing-xs-4": {
    //     ".MuiGrid-item>": {
    //       padding: "32px",
    //     },
    //   },
    // },
    // },
  },
};

const themes = {
  default: createTheme({ direction: "rtl", ...defaultTheme, ...overrides }),
  defaultEn: createTheme({ ...defaultTheme, ...overrides }),
};

export default themes;
