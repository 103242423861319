import {
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import RadioGroupBox from "../micro/RadioGroupBox";
import { UPDATE_REQUEST_ATTACHMENT_FILE } from "../../pages/Request/steps/requestStepAQueries";

const DialogApproveDocument = (props:any) => {
  const { open, handleClose, selectedAttachment } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const [status, setStatus] = useState();
  const [description, setDescription] = useState<string>();
  const optionsStatus = [
    { label: t("DOCUMENT_ACCEPTED"), value: "accepted" },
    { label: t("DOCUMENT_REJECTED"), value: "rejected" }];

  const [uploadAttachmentRequest, responseUploadAttachmentRequest] = useMutation(
    UPDATE_REQUEST_ATTACHMENT_FILE,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );

  const onSubmit = () => {
    if (status === "cancel") {
      handleClose();
      return;
    }
    if (status === "rejected" && !description) {
      return;
    }
    uploadAttachmentRequest({
      variables: {
        status,
        id: selectedAttachment.id,
        approve_date: moment().format(),
        description,
      },
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    }).then(() => {
      enqueueSnackbar(t("SuccessfullyUpdated"), {
        variant: "success",
      });
      handleClose();
    }).catch(() => {
      // try again
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("Dialog.ConfirmFile")}</DialogTitle>
      <DialogContent>
        <p>
          {t("Dialog.AcceptThisAttachment")}
          :
        </p>
        <div>
          <RadioGroupBox
            name=""
            value={status}
            setValue={setStatus}
            grid={12}
            items={optionsStatus}
          />
        </div>
        {status === "rejected" ? (
          <div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label={t("reasonReject")}
              autoFocus
              value={description}
              multiline
              required
              error={!description}
              onChange={(e) => setDescription(e.target?.value)}
            />
          </div>
        ) : false}

      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          color="secondary"
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
          className="button-primary"
          disabled={!status}
          endIcon={responseUploadAttachmentRequest.loading
            ? <CircularProgress size="18px" style={{ color: "#fff" }} /> : undefined}
        >
          {t("SubmitEnd")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogApproveDocument;
