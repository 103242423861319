import React from "react";
import {
  Button, Theme, createStyles, makeStyles,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { GET_ME } from "../../../app/layout/layoutQueries";

const useStyles2 = makeStyles((theme: Theme) => createStyles({
  footer: {
    paddingTop: theme.spacing(5),
    // paddingLeft: theme.spacing(5),
  },
  backButton: {
    // color: "#fff",
    marginRight: "10px",
  },
  submitButton: {
    color: "#fff",
  },
}));

type IPropsInput = {
  step: number;
  stepLength: number;
  handleNextCallback?: any;
  handleBackCallback: any;
  lockStep?: boolean;
};

const NavBarFooter = (props: IPropsInput) => {
  const classes = useStyles2();
  const { t } = useTranslation();

  const {
    step, stepLength, handleNextCallback, handleBackCallback, lockStep,
  } = props;

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  const isCustomer = role === "Customer";

  // const handleBack = () => {};
  // const handleNext = () => {};
  if (!isCustomer) {
    return null;
  }

  return (
    <div className={classes.footer}>
      <div>
        <div>
          {step !== 0 ? (
            <Button
              // disabled={step === 0}
              size="large"
              onClick={handleBackCallback}
              className={classes.backButton}
              color="default"
              variant="contained"
            >
              {t("Back")}
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextCallback}
            type="submit"
            disabled={lockStep}
            size="large"
            className={classes.submitButton}
          >
            {step === stepLength - 1 ? t("SubmitEnd") : t("Next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

NavBarFooter.defaultProps = {
  handleNextCallback: null,
  lockStep: false,
};

export default NavBarFooter;
