import { gql } from "@apollo/client";

export const GET_INVOICE = gql`  
query GetInvoice($sort: String, $uId: String) {
    invoices(
        sort: $sort,
        where: {
        request_id : {
            userId: {
                id: $uId
            }
        }
      }){
        id
        created_at
        updated_at
        request_id {
          id
          name
          family
          userId {
              id
          }
        }
        evaluation {
            id
            name
            family
            user {
              id
            }
          }
        cost
        # cost_rial
        status
        message
        subject
        due_date
        published_at
        type
      }
  }
`;

export const GET_INVOICE_BY_ID = gql`  
query GET_INVOICE_BY_ID($id: ID!) {
    invoice(id: $id){
        id
        title
        created_at
        updated_at
        request_id {
          id
          name
          family
          userId {
              id
          }
        }
        evaluation {
          id
          name
          family
          user {
            id
            name
            email
            phone
          }
        }
        cost
        # cost_rial
        status
        message
        subject
        due_date
        published_at
        off_percent
        off_code
        type
        payments{
          id
          bank_payment_id
          status
          payment_time
          payment_date
          use_off_code
          confirmed
          amount
          attachment{
            id
            url
          }
          invoice {
            id
          }
        }
      }
  }
`;

export const UPDATE_PAYMENT = gql`
mutation UPDATE_PAYMENT($id: ID!, $confirmed: Boolean){
  updatePayment(input: {
    where: {
      id:$id
    }
    data: {
      confirmed: $confirmed
    }
  }){
    payment{
      id
      status
    }
  }
}
`;

export const UPDATE_INVOICE = gql`
mutation UPDATE_INVOICE(
  $id: ID!, 
  $requestId: ID, 
  $cost: Long, 
  # $costRial: Long,
  $status: ENUM_INVOICE_STATUS,
  $message: String,
  # $subject: String,
  $due_date: Date,
  $off_code: String,
  $off_percent: Int,
  $title: String,
){
  updateInvoice(input: {
    where: {
      id: $id
    }
    data: {
      request_id: $requestId
      cost: $cost
      # cost_rial: $costRial
      status: $status
      message: $message
      # subject: $subject
      due_date: $due_date
      off_code: $off_code
      off_percent: $off_percent
      title: $title
    }
  }){
    invoice{
      id
    }
  }
}
`;

export default {};
