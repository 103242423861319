import React from "react";
import {
  Chip, Fab, Grid, IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import moment from "moment-jalaali";
import _ from "lodash";
import ViewIcon from "@material-ui/icons/Edit";
import { GET_TICKETS } from "./ticketsQueries";
import { GET_ME } from "../../app/layout/layoutQueries";
import TableAdvance from "../../components/micro/TableAdvance";
import { getFakeTicketId } from "../../app/utils/helper";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  pendingForSetTimeSession: {
    backgroundColor: "orange",
    border: "1px solid #eee",
    padding: "5px",
    display: "inline",
    borderRadius: "5px",
  },
  fab: {
    position: "fixed",
    right: "50px",
    bottom: "50px",
    zIndex: 200,
  },
}));

export default function Tickets(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  let uId;
  const isCustomer = role === "Customer";
  if (role === "Customer") {
    uId = _.get(Me, "data.me.id");
  }
  const { loading, error, data } = useQuery(GET_TICKETS, { variables: { sort: "updated_at:desc", uId } });

  if (loading) return null;
  if (error) return `Error! ${error}`;

  // TODO Sort by another column
  const dataTableData = data?.tickets?.map((item) => {
    let color;
    const label_ = item.status ? t(`ticketStatus.${item.status}`) : t("ticketStatus.close");
    if (item.status === "open") color = "orange";
    else if (item.status === "response") color = "#00c357";
    const status_ = <Chip label={label_} style={{ backgroundColor: color }} />;

    if (!isCustomer) {
      return [
        item.id,
        getFakeTicketId(item.id),
        `${item.user_id?.name}`,
        item.title,
        status_,
        item.updated_at,
        item.created_at,
      ];
    }
    return [
      item.id,
      getFakeTicketId(item.id),
      item.title,
      status_,
      item.updated_at,
      item.created_at,
    ];
  });

  let columns = [
    {
      name: t("id"),
      options: {
        filter: true,
        display: false,
      },
    }, {
      name: t("ticket_id"),
      options: {
        filter: true,
      },
    }, {
      name: t("user"),
      key: "user",
      options: {
        filter: true,
      },
    }, {
      name: t("title"),
      options: {
        filter: true,
      },
    },
    {
      name: t("status"),
      options: {
        filter: true,
      },
    },
    {
      name: t("updated_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    }, {
      name: t("created_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              openItem({
                value, tableMeta, updateValue,
              });
            }}
            >
              <ViewIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];

  if (isCustomer) {
    columns = columns.filter((item) => item.key !== "user");
  }

  const openItem = (item) => {
    const id_ = item.tableMeta.rowData[0];
    props.history.push(`/ticket/${id_}`);
  };

  return (
    <>
      {/* <PageTitle title="Tables" /> */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Fab onClick={() => props.history.push("ticket")} className={classes.fab}>
            {t("NewTicket")}
          </Fab>
          <TableAdvance
            {...props}
            title="Tickets"
            data={dataTableData}
            columns={columns}
            itemPath="/ticket/"
            showFilter={false}
          />
        </Grid>
      </Grid>
    </>
  );
}
