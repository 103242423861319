import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card, Divider,
  Grid,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Delete as DeleteIcon, ArrowDownward as GoDownIcon, ArrowUpward as GoUpIcon } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { useConfirm } from "material-ui-confirm";
import statics from "../../../app/statics";
import InputBox from "../../../components/micro/InputBox";
import RadioGroupBox from "../../../components/micro/RadioGroupBox";
import PageTitle from "../../../components/PageTitle";
import {
  CREATE_VISA, GET_VISA, GET_VISAS, UPDATE_VISA,
} from "../visasQueries";
import { Boolean_ } from "../../../app/utils/helper";
import Notification, { INotification } from "../../../components/micro/SnackbarMicro";
import SelectBox from "../../../components/micro/SelectBox";
import InputEditor2 from "../../../components/micro/InputEditor2";
import { GET_ATTACHMENTS } from "../../Attachment/attachmentsQueries";
import { GET_SERVICES } from "../../Service/servicesQueries";
import TableSimple from "../../../components/micro/TableSimple";

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {},
  card: {
    padding: theme.spacing(5),
  },
  cardSide: {
    padding: theme.spacing(2),
  },
  dividerToolbar: {
    margin: "10px 0px",
  },
}));

interface IVOption {
  label: string
  value: string
}

const Visa = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [title, setTitle] = useState<string>();
  const [titleEn, setTitleEn] = useState<string>();
  const [summary, setSummary] = useState<string>();
  const [summaryEn, setSummaryEn] = useState<string>();
  const [defaultVisaHusband, setDefaultVisaHusband] = useState<string>();
  const [defaultVisaChild, setDefaultVisaChild] = useState<string>();
  const [isOnline, setIsOnline] = useState<string>("true");
  const [isActive, setIsActive] = useState<string>("true");
  const [addAttachmentOpen, setAddAttachmentOpen] = useState<boolean>(false);
  const [selectedNewAttachment, setSelectedNewAttachment] = useState<any>();
  const [contract, setContract] = useState<string>();
  const [service, setService] = useState<string>();
  const [contractEn, setContractEn] = useState<string>();
  const [documents, setDocuments] = useState<any[]>([]);
  const [defaultAttachments, setDefaultAttachments] = useState<[]>([]);
  const [visas, setVisas] = useState<object[]>([]);
  const [notif, setNotif] = useState<INotification>();
  const currentVisaId = _.get(props, "match.params.id");

  const { data, loading, refetch } = useQuery(GET_VISA, {
    skip: !currentVisaId,
    variables: { id: Number(currentVisaId) },
  });
  const visasQuery = useQuery(GET_VISAS, { variables: { sort: "id" } });

  const attachmentsQuery = useQuery(GET_ATTACHMENTS, { variables: { sort: "id" } });
  const getServicesQuery = useQuery(GET_SERVICES, { variables: { sort: "id" } });

  const services = _.get(getServicesQuery, "data.services", []).map((sv) => ({
    value: `${sv.id}`,
    label: sv.title,
  }));
  const attachmentsOptions = attachmentsQuery?.data?.attachments?.map((attach) => ({
    value: attach.id,
    label: attach.title,
  }));

  let visasOptions: IVOption[] = [];
  visasOptions = _.get(visasQuery, "data.contractTypes", []).map((item) => ({
    label: item.title,
    value: item.id,
  }));
  if (!currentVisaId) {
    visasOptions.push({
      label: t("currentVisa"),
      value: "currentVisa",
    });
  }

  const [create, responseCreate] = useMutation(CREATE_VISA, {
    refetchQueries: [
      "GetVisas",
    ],
  });
  const [update, responseUpdate] = useMutation(UPDATE_VISA, {
    refetchQueries: [
      "GetVisas",
    ],
  });

  const contractType = data?.contractType;
  useEffect(() => {
    if (data) {
      setTitle(contractType.title);
      setTitleEn(contractType.title_en);
      setSummary(contractType.summary);
      setSummaryEn(contractType.summary_en);
      setIsOnline(contractType.is_online.toString());
      setIsActive(contractType.is_active.toString());
      setContract(contractType.contract_text);
      setContractEn(contractType.contract_text_en);
      if (contractType.default_husband_visa === "currentVisa") {
        setDefaultVisaHusband(contractType.id);
      } else {
        setDefaultVisaHusband(contractType.default_husband_visa);
      }
      if (contractType.default_child_visa === "currentVisa") {
        setDefaultVisaChild(contractType.id);
      } else {
        setDefaultVisaChild(contractType.default_child_visa);
      }
      if (contractType.default_attachments.length) {
        setDefaultAttachments(contractType.default_attachments.map((m) => m));
      }
      setService(`${contractType?.service?.id}`);
    //   setTitle(data.title);
    }
  }, [data]);

  const handleSave = () => {
    if (!title || !titleEn || !summary || !contract || !contractEn) {
      setNotif({
        open: true, message: "fillComplete", type: "error", setOpen: setNotif,
      });
      return;
    }
    const priorityAttachments = documents.filter((item) => !!item?.id)
      .map((item, index) => ({ id: item.id, priority: index }));
    if (currentVisaId) {
      update({
        variables: {
          id: currentVisaId,
          title,
          title_en: titleEn,
          summary,
          summary_en: summaryEn,
          default_child_visa: defaultVisaChild,
          default_husband_visa: defaultVisaHusband,
          is_active: Boolean_(isActive),
          is_online: Boolean_(isOnline),
          contract_text: contract,
          contract_text_en: contractEn,
          default_attachments: priorityAttachments.map((i) => i.id),
          service,
          priority_attachments: priorityAttachments,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyUpdated", type: "success", setOpen: setNotif,
        });
        setTimeout(() => props.history.goBack(), 1000);
      }).catch((error) => {
        setNotif({
          open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
        });
      });
    } else {
      create({
        variables: {
          title,
          title_en: titleEn,
          summary,
          summary_en: summaryEn,
          default_child_visa: defaultVisaChild,
          default_husband_visa: defaultVisaHusband,
          is_active: Boolean_(isActive),
          is_online: Boolean_(isOnline),
          contract_text: contract,
          contract_text_en: contractEn,
          service,
          priority_attachments: priorityAttachments,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyCreated", type: "success", setOpen: setNotif,
        });
        // refetch();
        setTimeout(() => props.history.goBack(), 1000);
      }).catch((error) => {
        setNotif({
          open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
        });
      });
    }
  };

  const headers = [
    {
      title: "id",
    },
    {
      title: "title",
    },
    {
      title: "actions",
    },
  ];

  const goUp = (i) => {
    if (i < 1) return;
    const d = [...documents];
    const temp = d[i - 1];
    d[i - 1] = d[i];
    d[i] = temp;
    setDocuments(d);
  };

  const goDown = (i) => {
    if (i >= documents.length - 1) return;
    const d = [...documents];
    const temp = d[i + 1];
    d[i + 1] = d[i];
    d[i] = temp;
    setDocuments(d);
  };

  const confirm = useConfirm();
  const deleteDocument = (i) => {
    confirm({
      title: t("AreYouSureForDelete"),
      description: "",
      confirmationText: t("yes"),
      cancellationText: t("no"),
    })
      .then(async () => {
        const d = [...documents];
        setDocuments(d.filter((k) => k.id !== i));
      });
  };

  useEffect(() => {
    if (contractType && attachmentsQuery?.data) {
      let defaultDocs = _.get(contractType, "default_attachments", []);
      if (!defaultDocs.length) {
        defaultDocs = attachmentsQuery?.data?.attachments || [];
      }

      let temps1 = _.get(contractType, "priority_attachments", []);
      if (temps1?.length) {
        temps1 = temps1
          .map((item) => defaultDocs.find((i) => i.id === item.id));
        setDocuments(temps1);
      } else {
        setDocuments(defaultDocs);
      }
    }
  }, [contractType, attachmentsQuery]);

  const rows = useMemo(() => {
    if (!documents?.length) return [];
    return documents?.map((item, l) => {
      // const date = moment(item?.created_at).format("jYYYY-jMM-jDD");
      // const type_ = t(`messageStatus.${item.type}`);
      const actions = (
        <div className="actions" style={{ minWidth: "110px" }}>
          <GoUpIcon onClick={() => goUp(l)} />
          <GoDownIcon onClick={() => goDown(l)} />
          <DeleteIcon onClick={() => deleteDocument(item.id)} style={{ marginRight: "8px" }} />
        </div>
      );

      return {
        id: l + 1,
        title: item?.title,
        data: item,
        actions,
      };
    });
  }, [documents]);

  const submitAttachment = () => {
    if (documents.find((i) => Number(i.id) === Number(selectedNewAttachment))) {
      return;
    }
    let newDocEntity = attachmentsOptions.find((i) => i.value === selectedNewAttachment);
    newDocEntity = { id: newDocEntity.value, title: newDocEntity.label };
    setDocuments((d) => [...(d || []), newDocEntity]);
    setAddAttachmentOpen(false);
    setSelectedNewAttachment(null);
  };

  return (
    <>
      <PageTitle
        title={currentVisaId ? t("Visa.Edit") : t("Visa.New")}
      />
      {!loading ? (
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Card className={classes.card}>
              <Grid container spacing={4}>
                <InputBox
                  name="title"
                  value={title}
                  setValue={setTitle}
                  grid={6}
                />
                <InputBox
                  name="titleEn"
                  value={titleEn}
                  setValue={setTitleEn}
                  grid={6}
                />
                <InputBox
                  name="summary"
                  value={summary}
                  setValue={setSummary}
                  grid={6}
                  rows={3}
                />
                <InputBox
                  name="summaryEn"
                  value={summaryEn}
                  setValue={setSummaryEn}
                  grid={6}
                  rows={3}
                />

                {/* {!attachmentsQuery.loading ? (
                  <SelectBoxMulti
                    grid={8}
                    title="defaultAttachments"
                    values={defaultAttachments}
                    setValues={setDefaultAttachments}
                    options={attachmentsOptions}
                  />
                ) : ""} */}

                <Grid item xs={12}>
                  <InputEditor2
                    label="Default_contract_text"
                    value={contractType?.contract_text}
                    setValue={setContract}
                    dir="rtl"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputEditor2
                    label="Default_contract_text_en"
                    value={contractType?.contract_text_en}
                    setValue={setContractEn}
                  />
                </Grid>

              </Grid>
            </Card>
            <br />
            <Card className={classes.card}>
              <h2>{t("defaultDocuments")}</h2>

              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <Button
                    variant="outlined"
                    onClick={() => (addAttachmentOpen ? setAddAttachmentOpen(false)
                      : setAddAttachmentOpen(true))}
                  >
                    {addAttachmentOpen ? t("Cancel") : t("Add_Attachment")}
                  </Button>
                  {addAttachmentOpen ? (
                    <>
                      <SelectBox
                        name=""
                        value={selectedNewAttachment}
                        setValue={setSelectedNewAttachment}
                        grid={6}
                        items={attachmentsOptions?.filter(
                          (item) => !documents.find((im) => (
                            im?.id && Number(im.id) === Number(item.value))),
                        )}
                      />
                      <Button
                        style={{ marginRight: "5px" }}
                        variant="outlined"
                        size="medium"
                        color="secondary"
                        onClick={submitAttachment}
                      >
                        {t("ADD")}

                      </Button>
                    </>
                  ) : ""}
                </div>
              </Grid>

              <TableSimple
                rows={rows}
                headers={headers}
                size="small"
              />
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.cardSide} style={{ position: "sticky" }}>
              {!visasQuery.loading ? (
                <SelectBox
                  name="defaultVisaHusband"
                  value={defaultVisaHusband}
                  setValue={setDefaultVisaHusband}
                  grid={12}
                  items={visasOptions}
                />
              ) : "" }
              <Divider className={classes.dividerToolbar} />
              <SelectBox
                name="defaultVisaChild"
                value={defaultVisaChild}
                setValue={setDefaultVisaChild}
                grid={12}
                items={visasOptions}
              />
              <Divider className={classes.dividerToolbar} />
              <RadioGroupBox
                name="isOnline"
                value={isOnline}
                setValue={setIsOnline}
                grid={12}
                items={statics.onlineOptions}
              />
              <Divider className={classes.dividerToolbar} />
              <RadioGroupBox
                name="isActive"
                value={isActive}
                setValue={setIsActive}
                grid={12}
                items={statics.activeOptions}
              />
              <Divider className={classes.dividerToolbar} />
              {!getServicesQuery?.loading ? (
                <SelectBox
                  name="service"
                  value={service}
                  setValue={setService}
                  grid={12}
                  items={services}
                />
              ) : ""}
            </Card>
            <br />
            <Grid item>
              <Button
                onClick={handleSave}
                className={classes.button}
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                {t("SubmitChanges")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : ""}
      <Notification {...notif} />
    </>
  );
};

export default Visa;
