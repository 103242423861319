import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => createStyles({
  description: {
    paddingBottom: "10px",
  },
  buttonWrap: {
    display: "flex",
    alignItems: "center",
    "& button": {
      height: "52px",
      marginTop: "5px",
    },
  },
}));

export default function DialogBase(props: any) {
  const history = useHistory();
  const { children } = props;

  const { t } = useTranslation();
  const isFa = true;
  const {
    open,
    setOpen,
    options,
    setOptions,
    hideFooter,
    className,
  } = props;

  const { title, content } = options;

  const handleClose_ = () => {
    setOpen();
    setOptions();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose_}
      aria-labelledby="form-dialog-title"
      className={className}
    >
      {title ? <DialogTitle id="form-dialog-title">{title}</DialogTitle> : "" }
      <>
        <DialogContent style={{ paddingTop: 0, textAlign: "justify" }}>
          <Grid container spacing={1}>
            {content || children}
          </Grid>
        </DialogContent>
        {!hideFooter ? (
          <DialogActions>
            {/* <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button> */}
            <Button color="inherit" onClick={handleClose_}>
              {t("Ok")}
            </Button>
          </DialogActions>
        ) : null }
      </>
    </Dialog>
  );
}
