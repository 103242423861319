import { useQuery } from "@apollo/client";
import { ArrowBack } from "@material-ui/icons";
import _ from "lodash";
import moment from "moment-jalaali";
import momentTz from "moment-timezone";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { dateTimeIso8061_, getFakeEvalId, timeIsoFa } from "../app/utils/helper";
import { GET_CONSULTANT_TIMES_ON_TODO_LIST } from "../pages/Profile/profileQueries";
import TableSimple from "./micro/TableSimple";

momentTz.tz.setDefault("Asian/Tehran");

const DashboardConsultantWidget = () => {
  const { t } = useTranslation();
  const nTime = dateTimeIso8061_(moment().add(0, "days").startOf("day"));
  const eTime = dateTimeIso8061_(moment().add(40, "days").endOf("day"));

  const quGetTimes = useQuery(GET_CONSULTANT_TIMES_ON_TODO_LIST, {
    variables: {
      nowTime: nTime,
      endToday: eTime,
    },
  });

  const headers = [
    {
      title: "id",
    }, {
      title: "user",
    }, {
      title: "nameConsultant",
    },
    {
      title: "date",
    },
    {
      title: "startTime",
    },
    {
      title: "endTime",
    },
    {
      title: "meetingLink",
    },
    {
      title: "meetingLink2",
    }, {
      title: "actions",
    },
  ];

  const rows = useMemo(() => {
    const consTimes = _.get(quGetTimes, "data.consultantTimes", []);
    return consTimes?.map((item) => {
      const date = moment(item.start_time).format("jYYYY-jMM-jDD");
      // const startTime = moment(item.start_time).format("HH:mm");
      // const endTime = moment(item.end_time).format("HH:mm");
      const startTime = timeIsoFa(item.start_time);
      const endTime = timeIsoFa(item.end_time);
      const req = item?.evaluation;
      const requestId = req?.id;
      const name = `${req?.name} ${req?.family}`;

      return {
        id: item.id,
        user: <Link to={`/booking/${getFakeEvalId(requestId)}`}>{name}</Link>,
        nameConsultant: item?.user?.name,
        date,
        startTime,
        endTime,
        meetingLink: item?.evaluation?.meeting_link
          ? (
            <Button
              href={item?.evaluation?.meeting_link}
              size="small"
              variant="text"
              color="primary"
              target="_blank"
              rel="noreferrer"
              style={{ backgroundColor: "rebeccapurple", color: "#fff" }}
            >
              {t("START")}
            </Button>
          ) : "-",
        meetingLink2: item?.evaluation?.meeting_link_support
          ? (
            <Button
              href={item?.evaluation?.meeting_link_support}
              size="small"
              variant="text"
              color="secondary"
              target="_blank"
              rel="noreferrer"
              style={{ backgroundColor: "coral", color: "#fff" }}
            >
              {t("START")}
            </Button>
          ) : "-",
        actions: <Link to={`/booking/${getFakeEvalId(requestId)}`}><ArrowBack className="icon-go" /></Link>,
      };
    });
  }, [quGetTimes.data]);

  return (
    <>
      {/* <h2 style={{ margin: "0 0 15px" }}>{t("LatestConsultant")}</h2> */}
      <TableSimple rows={rows} headers={headers} />
    </>
  );
};
export default DashboardConsultantWidget;
