import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  SectionTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    // marginBottom: theme.spacing(4),
    // marginTop: theme.spacing(5),
    background: "#eee",
    padding: theme.spacing(1),
  },
  typo: {
    color: theme.palette.text.primary,
    fontSize: "18px",
  },
  button: {
    // boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
    //   boxShadow: theme.customShadows.widgetWide,
    },
  },
}));

const SectionTitle = (props: any) => {
  const classes = useStyles();
  const { title, button } = props;

  return (
    <div className={classes.SectionTitleContainer}>
      <Typography
        className={classes.typo}
      >
        {title}
      </Typography>
      {button}
    </div>
  );
};

export default SectionTitle;
