import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import _ from "lodash";
import SelectMessage from "../micro/SelectMessage";
import { GET_REQUESTS, SEND_FOLLOW_UP_MESSAGE } from "../../pages/Requests/requestsQueries";

export default function DialogSendAlertGroup(props: any) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isFa = true;
  const {
    open,
    setOpen,
    hideFooter,
    className,
    searchData,
  } = props;
  const [selectTemp, setSelectTemp] = useState();

  const requestsQuery = useQuery(GET_REQUESTS, {
    variables: {
    //   sort: "updated_at:desc",
      limit: 100,
      start: 0,
      where: searchData || {},
    },
    fetchPolicy: "cache-and-network",
  });

  const [sendRequest, responseAddRequestAttachment] = useMutation(
    SEND_FOLLOW_UP_MESSAGE,
  );

  const handleClose_ = () => {
    setOpen();
  };

  const result = _.get(requestsQuery, "data.requests", []);

  const handleSend = () => {
    const users = result?.map((item) => item.userId?.id);
    try {
      sendRequest({
        variables: {
          data: { users },
        },
      }).then(() => {
        enqueueSnackbar(t("successfullyInQueue"), {
          variant: "success",
        });
        setTimeout(() => handleClose_(), 100);
      }).catch((d) => {
        enqueueSnackbar(t("errorOnSending"), {
          variant: "error",
        });
      });
    } catch (e: any) {
      enqueueSnackbar(t("errorOnSending"), {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose_}
      aria-labelledby="form-dialog-title"
      className={className}
    >
      <DialogTitle id="form-dialog-title">{t("sendNoticeGroupTitle")}</DialogTitle>
      <>
        <DialogContent style={{ paddingTop: 0, textAlign: "justify" }}>
          <div>
            <p>
              {t("pleaseSelectMessage")}
            </p>
            <SelectMessage selectTemp={selectTemp} setSelectTemp={setSelectTemp} />
          </div>
          <div>
            <br />
            {t("sendMessageTo")}
            <b style={{ padding: "0 5px" }}>{result?.length || 0}</b>
            {t("usersText")}
          </div>
        </DialogContent>
        {!hideFooter ? (
          <DialogActions>
            {/* <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button> */}
            <Button
              disabled={!(selectTemp && result?.length)}
              color="primary"
              variant="outlined"
              onClick={handleSend}
            >
              {t("OkSend")}
            </Button>
          </DialogActions>
        ) : null }
      </>
    </Dialog>
  );
}
