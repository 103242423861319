import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { dateIsoFa, dateTimeIso8061_, timeIsoFa } from "../app/utils/helper";
import { GET_CONSULTANT_TIMES_ON_REQUEST } from "../pages/Profile/profileQueries";
import { UPDATE_EVAL_SESSION_TIME } from "../pages/Bookings/bookingsQueries";

import SelectBox from "./micro/SelectBox";

const SetSessionTimeComponentNew = (props: any) => {
  const {
    accessUser, isPaid, isAdmin, setOpen,
  } = props;
  const classes = useStyles();
  const params = useParams<{id: string}>();
  const nTime = dateTimeIso8061_(moment());

  const confirm = useConfirm();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const [sessionOptions, setSessionOptions] = useState<[]>([]);
  const [dateSession, setDateSession] = useState<string>(null);

  const [evaluationUpdate, responseEvaluationUpdate] = useMutation(
    UPDATE_EVAL_SESSION_TIME,
    {
      refetchQueries: ["GET_EVALUATION", "GET_CONSULTANT_TIMES_ON_REQUEST"],
    },
  );

  const quGetTimes = useQuery(GET_CONSULTANT_TIMES_ON_REQUEST, {
    variables: {
      nowTime: nTime,
    },
  });

  useEffect(() => {
    if (quGetTimes.data) {
      const tms = quGetTimes.data?.consultantTimes;
      if (tms) {
        const users = [];
        const times = tms.map((item: any) => {
          const date_ = dateIsoFa(item.start_time);
          const st_ = `ساعت ${timeIsoFa(item.start_time)} تا ${timeIsoFa(item.end_time)}`;
          const disabled = !!item.evaluation?.id;
          const consId = item.user?.id;
          if (!users.find((u) => u === consId)) {
            users.push(consId);
          }
          const findIndex = users.findIndex((u) => u === consId);

          // if (dataGetStaReq?.requestStatuses[0]?.id === item?.request_status?.id) {
          //   disabled = false;
          // }
          return {
            label: `${date_} | ${st_} | ${t("consultant_")}${Number(findIndex) + 1}`,
            value: item.id,
            disabled,
            timestamp: moment(item.start_time).unix(),
          };
        });
        const times_ = times.sort((a, b) => a.timestamp - b.timestamp);
        setSessionOptions(times_);
      }
    }
  }, [quGetTimes.data]);

  const handleSaveTime = (e) => {
    e?.preventDefault();
    // if (!date) return;
    const date = dateSession;
    confirm({
      title: t("AreYouSureForSaveTime"), description: "", confirmationText: t("yes"), cancellationText: t("no"),
    })
      .then(async () => {
        try {
          const resultRequest = await evaluationUpdate({
            variables: {
              id: params?.id,
              consultant_time: date,
            },
          });
          enqueueSnackbar(t("successFullyDone"), {
            variant: "success",
          });
          setTimeout(() => {
            setOpen(false);
          }, 1000);
        } catch (e1: any) {
          enqueueSnackbar(t("ErrorHappened"), {
            variant: "error",
          });
        }
      })
      .catch((e2: any) => {
        enqueueSnackbar(t("ErrorHappened"), {
          variant: "error",
        });
        setDateSession(null);
      });
  };

  const accessUser_ = isAdmin || accessUser;
  // const isCompleted = consultantTime?.id && isPaid;

  return (
    <div>
      <form onSubmit={handleSaveTime}>
        <div className={classes.changeTime}>
          <Grid container spacing={4} style={{ alignItems: "center" }}>
            <SelectBox
              name="dateSession"
              value={dateSession}
              setValue={(e) => {
                setDateSession(e);
              }}
              grid={12}
              items={sessionOptions}
              t={false}
              disabled={!accessUser_}
            />
          </Grid>
          <button type="submit" id="submit-change-time" style={{ display: "none" }}>submit</button>
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  saveButton: {
    backgroundColor: theme.palette.success.light,
    marginRight: "5px",
    fontSize: "1.1em",
    width: "150px",
    color: "#fff",
  },
  changeTime: {
    // padding: "5px 10px",
    borderRadius: "8px",
  },
}));

export default SetSessionTimeComponentNew;
