import React from "react";
import {
  Grid, TextField, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import { getGrid } from "../../app/utils/helper";

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: 120,
    width: "100%",
    // padding: 0,
    "& .MuiInputLabel-formControl": {
      top: "-11px",
      left: "12px",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectFormTest: {
    "&>.MuiSelect-outlined.MuiSelect-outlined": {
      padding: 10,
    },
  },
}));

type IItem = {
  label: string,
  value: string,
  disabled?: boolean,
}
type IPropsInput = {
    name: string,
    value: string,
    grid?: number,
    desc?: string,
    items: IItem[],
    multiple?: boolean,
    required?: boolean,
    setValue?: Function,
    handleSearch?: any,
    handler?: "formik" | "material",
    formikI?: any,
    readOnly?: boolean,
    disabled?: boolean,
    size?: "samll" | "default",
    t?: boolean,
    loading?: boolean,
}
const SelectSearch = (props: IPropsInput) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    name, value, setValue, grid, items, desc, multiple,
    required,
    handler,
    formikI,
    handleSearch,
    size,
    readOnly,
    disabled,
    loading,
  } = props;

  const content = () => (
    (
      <FormControl className={classes.formControl} required={required}>
        <Autocomplete
          loading={loading}
          className={classes.selectFormTest}
          onChange={(e: any, v: any) => (handler === "formik"
            ? formikI.handleChange(v?.value)
            : setValue(v?.value))}
          defaultValue={value}
          id={`${name}-id`}
          options={items}
          getOptionLabel={(option: any) => option.label}
          // style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={_.debounce(handleSearch, 500)}
              label={t(desc) || t(name)}
              variant="outlined"
            />
          )}
          size={size}
        />
      </FormControl>
    )
  );

  if (grid) {
    const [xs_, md_, lg_] = getGrid(grid);
    return (
      <Grid
        item
        md={md_}
        xs={xs_}
        lg={lg_}
      >
        {content()}
      </Grid>
    );
  }
  return (
    <>
      {content()}
    </>
  );
};

SelectSearch.defaultProps = {
  grid: 0,
  desc: "",
  multiple: false,
  required: false,
  handler: "material",
  formikI: null,
  setValue: null,
  handleSearch: null,
  readOnly: false,
  disabled: false,
  size: "small",
  t: true,
  loading: undefined,
};

export default SelectSearch;
