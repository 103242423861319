import React, { useState } from "react";
import { Fab, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import _, { truncate } from "lodash";
import { useConfirm } from "material-ui-confirm";
import ViewIcon from "@material-ui/icons/Edit";
import { DELETE_ATTACHMENT, GET_ATTACHMENTS } from "./attachmentsQueries";
import { GET_ME } from "../../app/layout/layoutQueries";
import TableAdvance from "../../components/micro/TableAdvance";
import Notification, { INotification } from "../../components/micro/SnackbarMicro";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  pendingForSetTimeSession: {
    backgroundColor: "orange",
    border: "1px solid #eee",
    padding: "5px",
    display: "inline",
    borderRadius: "5px",
  },
  fab: {
    position: "fixed",
    right: "50px",
    bottom: "50px",
    zIndex: 200,
  },
  attachments: {
    marginBottom: "5vh",
  },
}));

export default function Attachments(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [notif, setNotif] = useState<INotification>();

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  let uId;
  if (role === "Customer") {
    uId = _.get(Me, "data.me.id");
  }
  const {
    loading, error, data, refetch,
  } = useQuery(GET_ATTACHMENTS, { variables: { sort: "id:desc" } });
  const [deleteItem, responseDeleteItemAttach] = useMutation(DELETE_ATTACHMENT);

  if (loading) return null;
  if (error) return `Error! ${error}`;

  // TODO Sort by another column
  const dataTableData = data?.attachments?.map((item) => [
    item.id,
    item.title,
    item.title_en,
    item.description,
  ]);

  const deleteRow = (item) => {
    const itemID = item.tableMeta.rowData[0];
    confirm({
      title: t("SureForDelete"), description: "", confirmationText: t("yes"), cancellationText: t("no"),
    }).then(() => {
      deleteItem({
        variables: {
          id: itemID,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyDeleted", type: "success", setOpen: setNotif,
        });
        refetch();
      });
    }).catch((e1) => {
      setNotif({
        open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
      });
    });
  };

  const columns = [
    {
      name: t("id"),
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: t("title"),
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const text = value;
          return truncate(text, { length: 80 });
        },
      },
    },
    {
      name: t("titleEn"),
      options: {
        sort: true,
        filter: false,

      },
    },
    {
      name: t("description"),
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const text = value;
          return truncate(text, { length: 100 });
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ width: "150px" }}>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              openItem({
                value, tableMeta, updateValue,
              });
            }}
            >
              <ViewIcon />
            </IconButton>
            <IconButton onClick={() => (deleteRow({ value, tableMeta, updateValue }))}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    },
  ];

  const openItem = (item) => {
    const id_ = item.tableMeta.rowData[0];
    props.history.push(`/attachment/${id_}`);
  };

  return (
    <>
      <Grid container spacing={4} className={classes.attachments}>
        <Grid item xs={12}>
          <Fab
            onClick={() => props.history.push("attachment")}
            className={classes.fab}
            title={t("NewAttachment")}
          >
            <AddIcon />
          </Fab>
          <TableAdvance
            {...props}
            title="Attachments"
            data={dataTableData}
            columns={columns}
            itemPath="/attachment/"
            showFilter={false}
            pageOptions={
              {
                main: "attachments",
              }
            }
          />
        </Grid>
      </Grid>
      <Notification {...notif} />
    </>
  );
}
