import React, { useContext, useEffect } from "react";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { AppContext } from "./AppContext_";
import Themes from "../themes/themes";
import { GET_ME } from "./layoutQueries";
import statics from "../statics";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const jssNoRtl = create({ plugins: [...jssPreset().plugins] });

const RTL = (props: any) => {
  const queryMe = useQuery(GET_ME, { fetchPolicy: "cache-only" });
  const { i18n } = useTranslation();
  const app = useContext(AppContext);
  const { children } = props;

  useEffect(() => {
    if (queryMe.data?.me) {
      const serverLang = queryMe.data?.me?.lang;
      const isCustomer = queryMe.data?.me?.role?.name === statics.roles.CUSTOMER;

      if (!serverLang && !isCustomer) {
        i18n.changeLanguage("en");
        app.setOptions({ isRTL: false });
        localStorage.setItem("lang", "EN");
      } else if (serverLang) {
        i18n.changeLanguage(serverLang === "FA" ? "fa" : "en");
        app.setOptions({ isRTL: serverLang === "FA" });
        localStorage.setItem("lang", serverLang || "FA");
      }
      if (serverLang === "EN") {
        document.querySelector("body").setAttribute("dir", "ltr");
      } else {
        document.querySelector("body").setAttribute("dir", "rtl");
      }
    }
  }, [queryMe.data]);

  return (
    <ThemeProvider theme={app.isRTL ? Themes.default : Themes.defaultEn}>
      <StylesProvider jss={app.isRTL ? jss : jssNoRtl}>
        <div className={app.isRTL ? "main-rtl" : "main-ltr"}>
          {children}
        </div>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default RTL;
