import React from "react";
import {
  Grid, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { getGrid } from "../../app/utils/helper";

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

type IItem = {
  label: string,
  value: boolean
}
type IPropsInput = {
    label: string,
    name: string,
    value: boolean,
    grid?: number,
    setValue: Function
}
const InputCheckBox = (props: IPropsInput) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // const [state, setState] = React.useState<boolean>();
  const {
    name, value, setValue, grid, label,
  } = props;

  // useEffect(() => {
  //   setValue(state);
  // }, [state]);

  const content = () => (
    <FormGroup row>
      <FormControlLabel
        control={(
          <Checkbox
            checked={Boolean(value)}
            onChange={(e) => {
              setValue(e.target.checked);
            }}
            name={name}
          />
          )}
        label={t(label)}
      />
    </FormGroup>
  );
  if (grid) {
    const [xs_, md_, lg_] = getGrid(grid);
    return (
      <Grid
        item
        md={md_}
        xs={xs_}
        lg={lg_}
      >
        {content()}
      </Grid>
    );
  }
  return (
    <>
      {content()}
    </>
  );
};

InputCheckBox.defaultProps = {
  grid: 0,
};

export default InputCheckBox;
