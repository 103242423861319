/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import {
  Card, CardContent, Divider, Grid, Tab, Tabs, Theme, createStyles,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { GET_DASHBOARD_INFO } from "./dashboardQueries";
import { pagesMain, pagesOther } from "../../app/layout/Sidebar";
import { TabPanel } from "../Profile/Profile";
import TodoList from "../../components/TodoList";
import ContractsInvoicesTodo from "../../components/ContractsInvoicesTodo";
import { getRoutes } from "../../app/utils/helper";
import { useAccount } from "../../app/hooks";

const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    fontSize: "100px",
    margin: "10px",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#fff",
    boxShadow: "0 2px 10px 0 rgb(94 86 105 / 10%)",
    border: "1px solid #eee",
    flexGrow: 1,
  },
  blockContainer: {
    display: "flex",
    gap: "10px",
  },

  body: {
    // padding: "10px",
    marginLeft: "20px",
    "&>h3": {
      padding: "0px",
      // margin: "15px",
      // marginTop: "15px",
    },
  },
  bodyNews: {
    background: "#ffffff",
    borderRadius: "10px",
    padding: "20px",
  },
  titleNews: {
    padding: "10px",
    backgroundColor: "#fff",
    border: "1px solid #eee",
    display: "flex",
    alignItems: "center",
  },
  titleNewsIcon: {
    color: theme.palette.secondary.main,
    marginRight: "10px",
  },
  icon1: {
    minWidth: "unset",
    // color: theme.palette.icon.default,
    "&>svg": {
      fontSize: "5em",
    },
  },
  holder: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid #ddd",
    height: "100%",
    padding: "20px 0",
    // margin: "10px",
    borderRadius: "5px",
    "&>div:hover": {
      cursor: "pointer",
    },
  },
}));

const DashboardAgent = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [tab, setTab] = useState(1);
  const { data, loading } = useQuery(GET_DASHBOARD_INFO, {
    fetchPolicy: "cache-only",
  });
  const nav = useHistory();

  const { permissions } = useAccount();

  const quickAccess = useMemo(() => {
    const pgMain = pagesMain.filter((item) => getRoutes(item.permissions, permissions));
    const pgOther = pagesOther.filter((item) => getRoutes(item.permissions, permissions));
    const pages = [...pgMain, ...pgOther].filter((item) => item.dashboard);

    return pages;
  }, []);

  const goTo = (page) => {
    nav.push(page.slug);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Grid container spacing={6} xs={12} md={12}>
      <Grid item xs={12} md={12} lg={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <Card>
          <CardContent>
            <Tabs value={tab} onChange={handleChange}>
              <Tab label={t("Messages")} value={1} />
              <Tab label={t("InvoicesTodo")} value={2} />
            </Tabs>
            <TabPanel value={tab} index={1}>
              <TodoList />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <ContractsInvoicesTodo />
            </TabPanel>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <h2>Quick Access</h2>
        <Grid container spacing={2} xs={12} md={12}>
          {quickAccess.map((item) => (
            <Grid
              item
              lg={4}
              md={4}
              xs={6}
            >
              <div className={classes.holder} onClick={() => goTo(item)}>
                <div className={classes.icon1}>
                  {item.icon}
                </div>
                <div>
                  {t(item.title)}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>

    </Grid>
  );
};

export default DashboardAgent;
