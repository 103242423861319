import React, { useState } from "react";
import { Fab, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment-jalaali";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import { useConfirm } from "material-ui-confirm";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewIcon from "@material-ui/icons/Edit";
import { DELETE_ARTICLE, GET_ARTICLES } from "./articlesQueries";
import { GET_ME } from "../../app/layout/layoutQueries";
import TableAdvance from "../../components/micro/TableAdvance";
import { INotification } from "../../components/micro/SnackbarMicro";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  pendingForSetTimeSession: {
    backgroundColor: "orange",
    border: "1px solid #eee",
    padding: "5px",
    display: "inline",
    borderRadius: "5px",
  },
  fab: {
    position: "fixed",
    right: "50px",
    bottom: "50px",
    zIndex: 200,
  },
}));

export default function Articles(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [notif, setNotif] = useState<INotification>();

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  let uId;
  if (role === "Customer") {
    uId = _.get(Me, "data.me.id");
  }
  const {
    loading, error, data, refetch,
  } = useQuery(GET_ARTICLES, { variables: { sort: "id:desc" } });
  const [deleteItem, responseDeleteItem] = useMutation(DELETE_ARTICLE);

  if (loading) return null;
  if (error) return `Error! ${error}`;

  // TODO Sort by another column
  const dataTableData = data?.articles?.map((item) => [
    item.id,
    item.title,
    "-",
    item.category?.name,
    item.updated_at,
    item.created_at,
  ]);

  const deleteRow = (item) => {
    const itemID = item.tableMeta.rowData[0];
    confirm({
      title: t("SureForDelete"), description: "", confirmationText: t("yes"), cancellationText: t("no"),
    }).then(() => {
      deleteItem({
        variables: {
          id: itemID,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyDeleted", type: "success", setOpen: setNotif,
        });
        refetch();
      });
    }).catch((e1) => {
      setNotif({
        open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
      });
    });
  };

  const columns = [
    {
      name: t("id"),
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: t("title"),
      options: {
        filter: true,
      },
    },
    {
      name: t("slug"),
      options: {
        sort: true,
      },
    },
    {
      name: t("category"),
      options: {
        filter: true,
      },
    },
    {
      name: t("updated_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    },
    {
      name: t("created_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              openItem({
                value, tableMeta, updateValue,
              });
            }}
            >
              <ViewIcon />
            </IconButton>
            <IconButton onClick={() => (deleteRow({ value, tableMeta, updateValue }))}>
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];

  const openItem = (item) => {
    const id_ = item.tableMeta.rowData[0];
    props.history.push(`/article/${id_}`);
  };

  return (
    <>
      {/* <PageTitle title="Tables" /> */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Fab
            onClick={() => props.history.push("article")}
            className={classes.fab}
            title={t("NewArticle")}
          >
            <AddIcon />
          </Fab>
          <TableAdvance
            {...props}
            title="Articles"
            data={dataTableData}
            columns={columns}
            itemPath="/article/"
            showFilter={false}
            pageOptions={
              {
                main: "articles",
              }
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
