import React, { useMemo, useState } from "react";
import { Chip, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import moment from "moment-jalaali";
import { useConfirm } from "material-ui-confirm";
import ViewIcon from "@material-ui/icons/Edit";
import _ from "lodash";
import TableAdvance from "../../components/micro/TableAdvance";
import { GET_CAMPAIGN_USERS } from "./agileBookingQueries";
import DialogUpdateStatusBook from "../../components/dialogs/DialogUpdateStatusBook";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  pendingForSetTimeSession: {
    backgroundColor: "orange",
    border: "1px solid #eee",
    padding: "5px",
    display: "inline",
    borderRadius: "5px",
  },
  fab: {
    position: "fixed",
    right: "50px",
    bottom: "50px",
    zIndex: 200,
  },
}));

export const statusAgileBook = {
  new: "new",
  not_answered: "not_answered",
  ok: "ok",
  rejected: "rejected",
};
export default function AgileBooking(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [selectedBook, setSelectedBook] = useState();

  const mainQuery = useQuery(GET_CAMPAIGN_USERS, {
    variables: { sort: "id:desc", start: 0, limit: 10 },
  });

  const dataTableData = useMemo(() => {
    const a = 1;

    return mainQuery?.data?.campaignUsers?.map((item) => [
      item.id,
      item.name,
      item.phone,
      item.email,
      item.campaign?.title,
      item.status,
      item.created_at,
    ]);
  }, [mainQuery?.data?.campaignUsers]);

  const columns = [
    {
      name: t("id"),
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: t("name"),
      options: {
        filter: true,
      },
    },
    {
      name: t("phone"),
      options: {
        sort: true,
      },
    },
    {
      name: t("email"),
      options: {
        filter: true,
      },
    },
    {
      name: t("campaign_name"),
      options: {
        filter: true,
      },
    },
    {
      name: t("status"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            <Chip label={t(value ? `agile.${value}` : "agile.new")} className={`status__${value}`} />
          </div>
        ),
      },
    },
    {
      name: t("created_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              setSelectedBook(tableMeta?.rowData[0]);
            }}
            >
              <ViewIcon />
            </IconButton>
          </>
        ),
      },
    },
  ];
  const totalCount = _.get(mainQuery.data, "campaignUsersConnection.aggregate.count");

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableAdvance
            {...props}
            title="AgileBooksTitle"
            data={dataTableData}
            columns={columns}
            showFilter={false}
            serverSide
            totalCount={totalCount}
            query={mainQuery}
            pageOptions={
              {
                main: "agile-books",
              }
            }
          />
        </Grid>
      </Grid>
      {selectedBook ? <DialogUpdateStatusBook open selected={selectedBook} setOpen={setSelectedBook} /> : ""}
    </>
  );
}
