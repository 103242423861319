import { gql } from "@apollo/client";

export const GET_USERS = gql`  
query GET_USERS($sort: String, $limit: Int, $start: Int, $where: JSON) {
    usersConnection(sort: $sort, limit: $limit, start: $start, where: $where){
        values{
            id
            name
            phone
            created_at
            updated_at
            username
            email
            provider
            confirmed
            blocked
            role {
                id
                name
                type
            }
        }
        aggregate {
          count
          totalCount
        }
    }
}
`;

export const GET_USER = gql`  
query GET_USER($id: ID!) {
    user(id: $id){
        id
        name
        phone
        created_at
        updated_at
        username
        email
        provider
        confirmed
        blocked
        role {
            id
            name
            type
        }
    }
}
`;

export const UPDATE_PASSWORD_USER = gql`
mutation UPDATE_PASSWORD_USER(
  $userId: ID!,
  $passwordNew: String!
  ) {
  updateUser(input: {
    where: {
      id: $userId
    }
    data: {
      password: $passwordNew
    }
  }){
    user {
      id
    }
  }
}
`;

export const ADD_USER = gql`
mutation ADD_USER(
  $username: String!,
  $name: String!,
  $phone: String!,
  $email: String!,
  $password: String!,
){
  createUser(input: {
    data: {
      username: $username,
      name: $name,
      password: $password,
      phone: $phone,
      email: $email
    }
  }){
    user {
      id
    }
  }
}
`;

export default {};
