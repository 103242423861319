import { gql } from "@apollo/client";

export const GET_CAMPAIGN_USERS = gql`  
query GET_CAMPAIGN_USERS($start: Int, $sort: String, $limit: Int) {
  campaignUsers(sort: $sort, start: $start, limit: $limit){
    id
    created_at
    name
    email
    phone
    status
    description
    campaign {
      title
    }
  }
  campaignUsersConnection(start: $start, sort: $sort, limit: $limit){
    aggregate {
      count
      totalCount
    }
  }
}
`;

export const UPDATE_CAMPAIGN_BOOK = gql`
mutation UPDATE_CAMPAIGN_BOOK(
  $id: ID!,
  $status: ENUM_CAMPAIGNUSER_STATUS!,
  $description: String,
  ){
    updateCampaignUser(input: {
      where: {
        id: $id
      }
      data: {
        status: $status
        description: $description
      }
    }) {
      campaignUser {
        id
      }
    }
}
`;

export default {};
