import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  Card, Grid, Radio, RadioGroup, Theme, createStyles, makeStyles,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import DatePickerComponent from "./micro/Datepicker";
import InputBox from "./micro/InputBox";
import InputFile from "./micro/InputFile";
import TimePickerComponent from "./micro/TimePicker";

type IProps = {
    openDialog?: boolean,
    title: string,
    handleSubmit: Function,
    handleClose: Function,
    invoice: any,
    mode?: string,
    type?: "CONTRACT" | "NORMAL"
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  optionPay: {
    marginBottom: "5px",
    padding: "15px",
    backgroundColor: "#eee",
  },
  box: {
    padding: "30px 15px",
    borderWidth: "1px",
    borderColor: "#eee",
    borderStyle: "solid",
  },
  fieldset: {
    border: "1px solid #9f9f9f",
    borderRadius: "10px",
    background: "#eef9ff;",
  },
  infoCard: {
    "&>p": {
      display: "flex",
      justifyContent: "space-between",
      "&>b": {
        direction: "rtl",
      },
    },
  },
}));

export default function FormDialogConfirmPayment(props: IProps) {
  const {
    openDialog, title,
    handleSubmit,
    handleClose,
    invoice,
    mode,
    type,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(openDialog);
  const [messageL, setMessageL] = React.useState<string>();
  const [attachment, setAttachment] = React.useState<object>();
  const [transactionTime, setTransactionTime] = React.useState<string>(null);
  const [paymentType, setPaymentType] = React.useState<string>("online");
  const [offCode, setOffCode] = React.useState<string>();
  const [transactionNumber, setTransactionNumber] = React.useState<string>("");
  const [transactionDate, setTransactionDate] = React.useState<string>(null);
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  useEffect(() => {
    if (mode === "installment") {
      setPaymentType("offline");
    }
  }, [mode]);

  const handleClose_ = () => {
    setOpen(false);
    handleClose(false);
  };

  const handleSave = () => {
    if (offCode && invoice?.off_code !== offCode) {
      enqueueSnackbar(t("Invoice.offCodeInvalid"), {
        variant: "error",
      });
      return;
    }
    if (paymentType === "offline" && !attachment) {
      enqueueSnackbar(t("Invoice.errorAttachment"), {
        variant: "error",
      });
      return;
    }

    handleSubmit({
      transactionNumber,
      transactionDate: transactionDate ? moment(transactionDate).locale("en").format("YYYY-MM-DDTHH:mm:ssZ") : moment().locale("en").format("YYYY-MM-DDTHH:mm:ssZ"),
      transactionTime: transactionTime ? moment(transactionTime).locale("en").format("HH:mm:ss.SSS") : undefined,
      useOffCode: !!offCode,
      attachment: attachment?.id,
      invoice,
      paymentType,
    });
    setTimeout(() => {
      handleClose_();
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t(title)}</DialogTitle>
      <DialogContent>
        <RadioGroup value={paymentType} onChange={(e) => setPaymentType(e.target.value)}>
          {!mode ? (
            <Card className={classes.optionPay}>
              <Radio title={t("paymentOnline")} value="online" />
              {t("paymentOnline")}
            </Card>
          ) : ""}
          <Card className={classes.optionPay}>
            <Radio title={t("paymentOffline")} value="offline" />
            {t("paymentOffline")}
          </Card>
        </RadioGroup>
        {/* {paymentType === "online" && (
          <div>Online</div>
        )} */}

        {paymentType === "offline"
        && (
          <div className={classes.box}>
            {!type ? (
              <fieldset className={classes.fieldset}>
                <legend>{t("offlinePaymentInfo")}</legend>
                <div className={classes.infoCard}>
                  <p>
                    {t("offlineCardNumber")}
                    <b>6037 6974 4986 6174</b>
                  </p>
                  <p>
                    {t("offlineShebaNumber")}
                    <b>IR770190000000108340488005</b>
                  </p>
                  <p>
                    {t("offlineAccountNumber")}
                    <b>0108340488005</b>
                  </p>
                  <p>
                    {t("bankname")}
                  </p>
                </div>
              </fieldset>
            ) : "" }

            {type === "CONTRACT" ? (
              <div>
                <Alert>
                  {t("contractPaymentText")}
                  <br />
                  <h4>
                    {t("callNumber")}
                    :
                    <b style={{ direction: "ltr" }}> 02122555902 | 02122555903</b>
                  </h4>
                  <p>
                    {t("contractPayText2")}
                  </p>
                </Alert>
                <br />
              </div>
            ) : ""}

            <p>
              <b>{t("afterPaymentOffline")}</b>
            </p>
            <TextField
              autoFocus
              margin="dense"
              label={t("transactionNumber")}
              type="text"
              fullWidth
              value={transactionNumber}
              onChange={(e) => setTransactionNumber(e.target.value)}
              variant="outlined"
              required
            />
            <Grid container spacing={2}>
              <DatePickerComponent
                name="transactionDate"
                value={transactionDate}
                required
                setValue={setTransactionDate}
                grid={6}
              />
              <TimePickerComponent
                name="transactionTime"
                value={transactionTime}
                setValue={setTransactionTime}
                grid={4}
                required
              />
            </Grid>
            <br />
            <InputFile
              // multiple="false"
              name="attachmentReceipt"
              file={attachment}
              setFile={setAttachment}
              grid={12}
              required
              mode="singleImage"
            />
          </div>
        ) }
        {!mode ? (<InputBox name="offCode" value={offCode} setValue={setOffCode} />) : "" }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose_} color="primary">
          {t("Cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="outlined">
          {t("Pay")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormDialogConfirmPayment.defaultProps = {
  openDialog: false,
  mode: undefined,
  type: "NORMAL",
};
