import React from "react";

export const RequestContext = React.createContext<{
    requestData: any,
    setRequestData: React.Dispatch<any>,
    loading: boolean,
    setLoading: React.Dispatch<any>,
    activeStep?: string,
}>({
  requestData: {},
  loading: false,
  setRequestData: () => {},
  setLoading: () => {},
});

export default {
};
