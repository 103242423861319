/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Divider, Drawer, IconButton, List, ListItem, ListItemIcon,
  ListItemText, MenuItem, Theme, createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useMemo } from "react";
import Menu from "@material-ui/core/Menu";

import DashboardIcon from "@material-ui/icons/Dashboard";
import ContractIcon from "@material-ui/icons/ListAlt";
import EvalIcon from "@material-ui/icons/Pageview";
import PaymentIcon from "@material-ui/icons/Payment";
import InboxIcon2 from "@material-ui/icons/Inbox";
import HelpIcon from "@material-ui/icons/Help";
import ProfileIcon from "@material-ui/icons/Person";
import UserIcon from "@material-ui/icons/Group";
import VisaTypesIcon from "@material-ui/icons/LibraryBooks";
import BlogIcon from "@material-ui/icons/Web";
import AttachmentIcon from "@material-ui/icons/Attachment";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import ServiceIcon from "@material-ui/icons/Pages";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import { useTranslation } from "react-i18next";
import Fade from "@material-ui/core/Fade";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { Link } from "react-router-dom";
import { GET_ME, GET_ROLE_ME } from "./layoutQueries";
import { getRoutes } from "../utils/helper";

export const mainPage = "/";

export const pagesMain = [{
  title: "Dashboard",
  slug: "",
  icon: <DashboardIcon />,
  permissions: ["request"],
},
// {
//   title: "NewContract",
//   slug: "request",
//   icon: <AddBoxIcon />,
//   dashboard: true,
//   permissions: ["request"],
// },
{
  title: "Contracts",
  slug: "requests",
  icon: <ContractIcon />,
  dashboard: true,
  permissions: ["request"],
}, {
  title: "Evaluations",
  slug: "bookings",
  icon: <EvalIcon />,
  dashboard: true,
  permissions: ["evaluation"],
}, {
  title: "AgileBooks",
  slug: "agile-books",
  icon: <EvalIcon />,
  dashboard: true,
  permissions: ["evaluation"],
}, {
  title: "Payments",
  slug: "invoices",
  icon: <PaymentIcon />,
  dashboard: true,
  permissions: ["invoice"],
}, {
  title: "ContractTypes",
  slug: "visas",
  onlyAdmin: true,
  icon: <VisaTypesIcon />,
  dashboard: true,
  permissions: ["contract-type"],
}, {
  title: "Attachments",
  slug: "attachments",
  onlyAdmin: true,
  icon: <AttachmentIcon />,
  permissions: ["attachment"],
}, {
  title: "Services",
  slug: "services",
  onlyAdmin: true,
  icon: <ServiceIcon />,
  children: [
    {
      title: "Services",
      slug: "services",
      icon: "",
    },
    {
      title: "ServiceNew",
      slug: "service",
      icon: "",
    },
    {
      title: "Category",
      slug: "service_categories",
      icon: "",
    },
  ],
  permissions: ["service"],
}, {
  title: "Users",
  slug: "users",
  onlyAdmin: true,
  icon: <UserIcon />,
  permissions: ["evaluation"],
}];

export const pagesOther = [{
  title: "Blog",
  slug: "articles",
  onlyAdmin: true,
  icon: <BlogIcon />,
  children: [
    {
      title: "Articles",
      slug: "articles",
      icon: "",
    },
    {
      title: "ArticleNew",
      slug: "article",
      icon: "",
    },
    {
      title: "Category",
      slug: "categories",
      icon: "",
    },
  ],
  permissions: ["article"],
}, {
  title: "Messages",
  slug: "messages",
  icon: <InboxIcon2 />,
  onlyAdmin: true,
  children: [
    {
      title: "Messages",
      slug: "messages",
      icon: "",
      dashboard: true,
    },
    {
      title: "MessageTemplate",
      onlyAdmin: true,
      slug: "message-templates",
      icon: "",
    },
  ],
  permissions: ["message"],
},
{
  title: "Messages",
  slug: "messages",
  icon: <InboxIcon2 />,
  onlyClient: true,
  dashboard: true,
  permissions: ["message"],
}, {
  title: "Tickets",
  slug: "tickets",
  icon: <HelpIcon />,
  dashboard: true,
  permissions: ["ticket"],
}, {
  title: "Profile",
  slug: "profile",
  icon: <ProfileIcon />,
  dashboard: true,
}, {
  title: "Exit",
  slug: "Exit",
  icon: <PowerIcon />,
}];

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => createStyles({
  menuButton: {
    marginTop: 10,
    color: theme.palette.icon.default,
    // backgroundColor: "red",
    margin: "auto",
  },
  actived: { color: "#2378ff !important" },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    "&>div": {
      borderWidth: "0px",
      backgroundColor: "#f7fcfe",
      // color: "#fff",
    },
    "&::-webkit-scrollbar": {
      width: "0px",
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    boxShadow: "5px 5px 5px #eee",
    width: theme.spacing(9) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(13) + 1,
    },
  },
  icon: {
    minWidth: "unset",
    color: theme.palette.icon.default,
    "&>svg": {
      fontSize: "1.3em",
    },
  },
  iconOpen: {
    minWidth: "unset",
    "&>svg": {
      fontSize: "2.2em",
    },
    color: theme.palette.icon.default,
  },
  ListItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  ListItemTitle: {
    "&>span": {
      fontSize: ".9em",
    },
  },
  tools: {
    display: "flex",
    justifyContent: "flex-end",
  },
  toolsItems: {
    paddingRight: "35px",
  },
  iconBar: {
    color: theme.palette.icon.top,
    marginLeft: "0px",
  },
  link: {
    textAlign: "center",
    textDecoration: "none",
    color: "#000",
  },
}));

const Sidebar = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedMenu, setSelectedMenu] = React.useState([]);
  const [selectedPage, setSelectedPage] = React.useState<any>({});

  const Me = useQuery(GET_ME, {});
  const role = _.get(Me, "data.me.role.name");
  const roleId = _.get(Me, "data.me.role.id");

  const permissionQuery = useQuery(GET_ROLE_ME, {
    variables: {
      id: roleId,
    },
    skip: !roleId,
  });

  let uId;
  // let pagesMain_ = pagesMain;
  // let pagesOther_ = pagesOther;
  const [pagesMain_, pagesOther_] = useMemo(() => {
    const permissions = Me.data?.me2?.role?.permissions || [];
    let pgMain = pagesMain.filter((item) => getRoutes(item.permissions, permissions));
    let pgOther = pagesOther.filter((item) => getRoutes(item.permissions, permissions));

    if (role === "Customer") {
      uId = _.get(Me, "data.me.id");
      pgMain = pgMain.filter((page) => !page.onlyAdmin);
      pgOther = pgOther.filter((page) => !page.onlyAdmin);
    } else {
      pgOther = pgOther.filter((page) => !page.onlyClient);
    }
    // console.log("permissions-----", pgMain, _.keys(permissions));

    return [pgMain, pgOther];
  }, [permissionQuery.data]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, page: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(page.children);
    setSelectedPage(page);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goTo = (page: any) => {
    if (page.slug === "Exit") {
      localStorage.removeItem("token");
      localStorage.removeItem("roleType");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
      // setTimeout(() => {
      //   window.location.href = `${process.env.REACT_APP_LANDING}/login?clc=true`;
      // }, 500);
    } else {
      setSelectedPage(page);
      handleClose();
      props.history.push(mainPage + page.slug);
    }
  };

  const getLink = (page) => mainPage + page.slug;

  if (!role) return null;

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        // [classes.drawerOpen]: open,
        [classes.drawerClose]: true,
      })}
      classes={{
        paper: clsx({
        //   [classes.drawerOpen]: open,
          [classes.drawerClose]: true,
        }),
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          // onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            // [classes.hide]: !open,
          })}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <div>
        <nav>
          <List>
            {pagesMain_.map((page, index: number) => (
              <>
                <ListItem
                  className={classes.ListItem}
                  button
                  key={index}
                  onClick={(e: any) => (page?.children ? handleClick(e, page) : "")}
                  aria-controls={`simple-menu_${index}`}
                  aria-haspopup="true"
                >
                  {!page?.children ? (
                    <Link
                      key={index}
                      to={getLink(page)}
                      onClick={() => setSelectedPage(page)}
                      className={classes.link}
                    >
                      <ListItemIcon className={clsx({
                        [classes.iconOpen]: !open,
                        [classes.icon]: open,
                        [classes.actived]: page.slug === selectedPage?.slug,
                      })}
                      >
                        {page.icon}
                      </ListItemIcon>
                      <ListItemText className={classes.ListItemTitle} primary={t(page.title)} />
                    </Link>
                  ) : (
                    <>
                      <ListItemIcon className={clsx({
                        [classes.iconOpen]: !open,
                        [classes.icon]: open,
                        [classes.actived]: page.slug === selectedPage?.slug,
                      })}
                      >
                        {page.icon}
                      </ListItemIcon>
                      <ListItemText className={classes.ListItemTitle} primary={t(page.title)} />
                    </>
                  ) }
                </ListItem>
              </>
            ))}
          </List>
          <Menu
            // id="simple-menu_1"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
            elevation={1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            anchorPosition={{
              left: 140,
              top: 20,
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {selectedMenu?.filter((item) => {
              if (role === "Customer") {
                if (item.onlyAdmin) {
                  return false;
                }
              }
              return true;
            }).map((item) => (
              <MenuItem
                onClick={() => goTo(item)}
              >
                {t(item.title)}
              </MenuItem>
            ))}
          </Menu>
        </nav>
      </div>
      <Divider />
      <List>
        {pagesOther_.map((page, index: number) => (
          <>
            <ListItem
              className={classes.ListItem}
              button
              key={index}
              onClick={(e: any) => (page?.children ? handleClick(e, page) : goTo(page))}
              aria-controls={`simple-menu_n_${index}`}
              aria-haspopup="true"
            >
              <ListItemIcon className={clsx({
                [classes.iconOpen]: !open,
                [classes.icon]: open,
                [classes.actived]: page.slug === selectedPage?.slug,
              })}
              >
                {page.icon}
              </ListItemIcon>
              <ListItemText className={classes.ListItemTitle} primary={t(page.title)} />
            </ListItem>
          </>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
