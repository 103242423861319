const transFa = {
  title_site: "کهن بابا",
  Dashboard: "پیشخوان",
  NewEvaluation: "ارزیابی جدید",
  Evaluations: "ارزیابی ها",
  AgileBooks: "رزرو ها",
  AgileBooksTitle: "رزروهای کمپینی",
  EvaluationsAndConsultants: "درخواست های مشاوره و ارزیابی",
  Contracts: "قراردادها",
  Payments: "مالی",
  Messages: "پیام ها",
  message: "پیام",
  Tickets: "پشتیبانی",
  Profile: "پروفایل",
  Exit: "خروج",
  detail: "جزئیات",
  campaign_name: "عنوان کمپین",
  typeDisplay: "نوع نمایش",
  admin: "ادمین",
  SubmitChanges: "ثبت اطلاعات",
  password: "رمز عبور",
  operator: "کارشناس",
  email: "ایمیل",
  name: "نام",
  degree_edu: "مدرک تحصیلی",
  ielts: "آیتلس",
  tef: "تافل",
  number_childs: "تعداد فرزندان",
  partner_name: "نام همسر",
  total_assets: "مجموع دارایی به دلار",
  partner_degree: "مدرک تحصیلی همسر",
  partner_birthday: "تاریخ تولد همسر",
  nameEn: "نام به لاتین",
  phone: "موبایل",
  NewInvoice: "ایجاد صورت حساب",
  id: "شناسه",
  from: "از",
  timeConsultant: "زمان مشاوره",
  typeConsultant: "نوع مشاوره",
  statusCons: {
    ALL: "همه",
    DRAFT: "در انتظار تایید ارزیابی",
    WAITING_PAYMENT: "در انتظار پرداخت",
    WAITING_PAYMENT_ACCEPT: "در انتظار تایید پرداخت آفلاین",
    NEW: "در انتظار جلسه",
    DONE: "انجام شده",
    CANCELED: "لغو شده",
    WAITING_MEETING: "در انتظار جلسه",
    REJECTED: "رد شده",
  },
  ieltsStatus: {
    true: "دارد",
    false: "ندارد",
  },
  step_status: {
    all: "همه موارد",
    accepted: "تایید شده",
    rejected: "عدم تایید",
    waitUser: "در انتظار کاربر",
    draft: "در انتظار کاربر",
    waitReview: "در انتظار بررسی",
    inReview: "در حال بررسی",
    RequestReviewInfo: "در انتظار بررسی",
    InfoAccepted: "تایید شده",
    SelectVisaWasSet: "",
    contractCanceled: "لغو قرارداد",
    contractDone: "پایان قرارداد",
  },
  pendingUser: "در حال تکمیل",
  UpdateState: "اعمال تغییرات",
  SetContract: "تنظیم قرارداد جدید",
  step: "مرحله",
  error: "مرحله",
  currentStep: "مرحله جاری",
  STEP_INFORMATION: "مشخصات و اطلاعات",
  STEP_CONTRACT: "قرارداد",
  STEP_PAYMENT: "پرداخت",
  STEP_DOCUMENT: "تکمیل مدارک",
  STEP_END: "تحویل به اداره مهاجرت",
  "done?": "انجام شده؟",
  to: "تا",
  accept: "تایید",
  reject: "عدم تایید",
  pleaseWriteNote: "پیامی وجود ندارد!",
  EXPERT: "کارشناس",
  CLIENT: "کاربر",
  SEND: "ثبت",
  successfullyInQueue: "پیام ها با موفقیت در صف ارسال به کاربران قرار گرفتند'!",
  errorOnSending: "خطا، لطفا مجددا تلاش کنید!",
  messageTemplate: "قالب پیام",
  sendNoticeGroupTitle: "ارسال پیام گروهی",
  sendMessageTo: "ارسال پیامک و ایمیل همزمان به ",
  pleaseSelectMessage: "لطفا، پیام مورد نظر خود را انتخاب نمایید:",
  usersText: "کاربر",
  OkSend: "ارسال پیام",
  createDateFrom: "تاریخ ایجاد از",
  createDateEnd: "تاریخ ایجاد تا",
  ContractNotes: "یادداشت های پرونده",
  WriteANote: "پیامی بنویسید ..",
  ChangeStateOfContract: "فرم تغییر وضعیت قرارداد و اطلاع رسانی به کاربر",
  ForAcceptThisLevelSelectAccept: `
  برای تایید این مرحله، گزینه "در انتظار کاربر" و 
  برای رد "عدم تایید" را انتخاب نمایید. گزینه "تایید شده" را بعد از تایید ویزا انتخاب نمایید`,
  PleaseSelectMessageNotice: `از بین پیام های زیر پیام مربوط
   را انتخاب نمایید، لطفا توجه داشته باشید، این پیام برای کاربر ارسال خواهد شد.`,
  PleaseSelectMethodNotice: "لطفا روش/های ارسال پیام را انتخاب نمایید",
  AfterChangeStatusSelectStep: `بعد از تایید یا رد، کدام مرحله از مراحل قرار داد،
   برای کاربر پیش فرض نمایش داده شود، در صورت عدم انتخاب، کاربر در همین مرحله باقی می ماند`,
  evalId: "شناسه مشاوره",
  viewActivity: "آرشیو فعالیت ها",
  UserActivity: "فعالیت های کاربر",
  AdminActivity: "فعالیت های کارشناسان",
  DOWNLOAD_CONTRACT: "دانلود قرارداد",
  CHANGE_STATE_CONTRACT: "تغییر وضعیت قرارداد",
  SEEN_CONTRACT: "مشاهده قرارداد",
  UPDATE_CONTRACT: "اپدیت وضعیت قرارداد",
  SureForDone: "تغییر وضعیت مشاوره به انجام شده؟",
  createDate: "تاریخ ایجاد",
  Remember_me: "مرا با خاطر بسپار",
  SignInOrRegister: "ورود/ ثبت نام به کهن بابا",
  Sign_In_button: "ورود",
  Forgot_password: "رمز عبور را فراموش کردم",
  Not_have_account: "ایجاد حساب کاربری",
  terms_title: "قوانین و مقررات استفاده از سایت کهن بابا را می پذیرم.",
  Register_button: "ثبت نام",
  back: "برگشت",
  changeDateSession:
    "این بخش توسط کاربر انتخاب میشود،‌ در صورت نیاز به تغییر تاریخ جلسه، تاریخ مورد نظر را انتخاب نمایید:",
  Forgot_button: "یاداوری رمز عبور",
  "Auth.form.error.invalid": "نام کاربری یا رمز عبور اشتباه است",
  "Register.problem_not_updated_name":
    "در صورتی که ایمیل تاییدیه برای شما ارسال نشده. مجددا تلاش کنید.",
  "Register.successfully": "ثبت نام انجام شد.، لطفا ایمیل را تایید نمایید.",
  "Auth.form.error.email.taken": "خطا، ایمیل تکراری است.",
  "Auth.form.error.confirmed": "خطا، ایمیل را تایید کنید.",
  "Request.title": "ایجاد ارزیابی",
  "Request.titleNumber": "پرونده مهاجرتی شماره",
  "Request.detailPersonal": "مشخصات فردی",
  "Request.detailFamily": "مشخصات خانواده",
  "Request.question": "سؤالات تکمیلی",
  "Request.confirm": "تایید اطلاعات",
  "Request.addDocument": "افزودن مدرک",
  "Request.downloadContract": "دریافت قرارداد",
  "Request.Add": "افزودن مدرک",
  "Dialog.Upload": "آپلود مدرک",
  "Request.saveDate": "رزو جلسه",
  soldiery: "وضعیت نظام وظیفه",
  LatestConsultant: "جلسات مشاوره جاری",
  InvoicesTodo: "کارتابل فاکتورها",
  CostOfSessionWasPaid: "هزینه جلسه پرداخت شده: ",
  hasSchengen: "طی ۱۰ سال گذشته ویزای شینگن داشته اید؟",
  hasDisease: "بیماری خاص",
  hascriminal: "سو سابقه",
  marriedStatus: "وضعیت تاهل",
  married: "متاهل",
  consultant_: "مشاور",
  single: "مجرد",
  suggestedVisa: "ویزاهای پیشنهادی",
  sessionTime: "زمان جلسه مشاوره",
  show_more: "مطالعه بیشتر ...",
  child__1: "فرزند اول",
  child__2: "فرزند دوم",
  child__3: "فرزند سوم",
  child__4: "فرزند چهارم",
  divorce: "جدا شده",
  dead: "فوت شده",
  contained: "مشمول",
  ended: "پایان خدمت",
  exempt: "معاف",
  male: "آقا",
  female: "خانم",
  other: "سایر",
  residence: "اقامت",
  tourist: "توریستی",
  familyRequestDescription: "چه برنامه ای در چه تاریخی اقدام کردید؟",
  "Request.marriageStatus": "وضعیت تاهل",
  familyRequested:
    "آیا شما، همسر یا فرزندتان قبلا برای ورود به کانادا اقدام کرده‌اید؟",
  targetCanada: "هدف شما برای سفر به کانادا",
  Submit: "ثبت",
  SubmitEnd: "ثبت اطلاعات",
  yes: "بله",
  no: "خیر",
  Users: "کاربران",
  confirmed: "تایید شده",
  birthday: "تاریخ تولد",
  address: "ادرس",
  addressEn: "ادرس به لاتین",
  gender: "جنسیت",
  family: "نام خانوادگی",
  familyEn: "نام خانوادگی به لاتین",
  phoneHome: "تلفن منزل",
  Finish: "پایان",
  Next: "ثبت و ادامه ",
  Back: "برگرد",
  search: "جستجو",
  searchText: "جستجو ...",
  "Request.edit": "ویرایش مشخصات",
  partnerName: "نام و نام خانوادگی همسر",
  partnerNameEn: "نام و نام خانوادگی همسر لاتین",
  partnerFieldStudy: "رشته تحصیلی همسر",
  partnerFieldStudyEn: "رشته تحصیلی همسر لاتین",
  partnerAge: "سال تولد همسر",
  partnerDegree: "آخرین مدرک تحصیلی همسر",
  partnerJob: "وضعیت شغلی همسر",
  partnerInsurance: "سابقه بیمه شغلی همسر",
  yourIelts: "مدرک زبان انگلیسی آیلتس جنرال",
  partnerIelts: "مدرک زبان انگلیسی آیلتس جنرال همسر",
  partnerTef: "مدرک زبان فرانسه TEF همسر",
  yourTef: "مدرک زبان فرانسه TEF",
  "partnerIeltsScore.read": "نمره آیتلس Reading",
  "partnerIeltsScore.listen": "نمره آیتلس Listening",
  "partnerIeltsScore.write": "نمره آیتلس Writing",
  "partnerIeltsScore.speak": "نمره آیتلس Speaking",
  "partnerTefScore.read": "نمره TEF Reading",
  "partnerTefScore.listen": "نمره TEF Listening",
  "partnerTefScore.write": "نمره TEF Writing",
  "partnerTefScore.speak": "نمره TEF Speaking",
  pairInfo: "مشخصات همسر",
  father: "نام پدر",
  fatherEn: "نام پدر(لاتین)",
  childsInfo: "مشخصات فرزندان",
  numberChilds: "تعداد فرزندان زیر 22 سال",
  degree: "آخرین مدرک تحصیلی",
  fieldStudy: "رشته تحصیلی",
  nationalNumber: "کد ملی",
  fieldStudyEn: "رشته تحصیلی به لاتین",
  eduDateStart: "تاریخ شروع تحصیل",
  eduDateEnd: "تاریخ پایان تحصیل",
  totalAssets: "مجموع دارایی متقاضی و همسر شامل حساب بانکی و املاک به دلار",
  typeJob: "نوع شغل",
  isWorking: "آیا همچنان مشغول به کار هستید؟",
  workDescription: "توضیح مختصر شغل",
  workDescriptionEn: "توضیح مختصر شغل لاتین",
  jobState: "وضعیت شغلی",
  insuranceHistory: "سابقه بیمه شغلی",
  "yourIeltsScore.read": "نمره آیتلس Reading",
  "yourIeltsScore.listen": "نمره آیتلس Listening",
  "yourIeltsScore.write": "نمره آیتلس Writing",
  "yourIeltsScore.speak": "نمره آیتلس Speaking",
  "yourTefScore.read": "نمره تافل Reading",
  "yourTefScore.listen": "نمره تافل Listening",
  "yourTefScore.write": "نمره تافل Writing",
  "yourTefScore.speak": "نمره تافل Speaking",
  levelLang: "سطح زبان",
  familyLiveCanada: "اقوام مقیم کانادا",
  typeFindUs: "نحوه آشنایی با ما",
  fullTime: "تمام وقت",
  partTime: "پاره وقت",
  payment: "پرداخت",
  UnderDiploma: "زیر دیپلم",
  Diploma: "دیپلم",
  Associate: "کاردانی",
  Bachelor: "لیسانس",
  Master: "کارشناسی ارشد",
  Doctoral: "دکتری",
  PostDoc: "فوق دکتری",
  invoiceInstallmentTitle: "مرحله {{num}} پرداخت",
  endInvoiceInstallmentTitle: "زمان قبولی ویزا",
  num_0: "اول",
  num_1: "دوم",
  num_2: "سوم",
  num_3: "چهارم",
  num_4: "پنچم",
  num_5: "ششم",
  num_6: "هفتم",
  num_7: "هشتم",
  num_8: "نهم",
  num_9: "دهم",
  isLastPayment: "مرحله پایانی پرداخت میباشد.",
  titleInstallment: "عنوان مرحله پرداخت",
  costOfContract: "مبلغ قرارداد",
  percentInstallment: "درصد مرحله",
  amountInstallment: "مبلغ صورت حساب (دلار)",
  pleaseAddDescription: "لطفا فیلد توضیحات را تکمیل نمایید.",
  pleaseSetDueDate: "لطفا تاریخ سررسید را انتخاب نمایید.",
  hasDescription: "بدون تاریخ سررسید و فقط دارای توضیحات",
  hasDescriptionTitle: "توضیحات؟",
  "Request.InvoiceInstalment": "فرم ایجاد مرحله پرداخت (تقسیط پرداخت)",
  "Request.Accept": "تایید",
  "Request.Reject": "رد",
  "Request.registerInstallment": "افزودن مرحله پرداخت",
  otherDescription: "سایر توضیحات",
  PleaseConfirmInfo: "لطفا، بعد از تایید اطلاعات بر روی ثبت نهایی کلیک کنید.",
  confirmInfoDescription: "تمامی اطلاعات وارد شده مورد تایید است.",
  "Request.stepAPersonalInfo": "مشخصات و اطلاعات",
  "Request.stepBReviewRequest": "پاسخ ارزیابی",
  "Request.stepCSetSession": "جلسه مشاوره",
  "Request.stepDContract": "قرارداد",
  "Request.stepEPayment": "پرداخت",
  "Request.stepECompleteDocs": "تکمیل مدارک",
  "Request.stepFReviewDocs": "بررسی مدارک",
  "Request.stepGEnd": "تحویل به اداره مهاجرت و نتیجه",
  pendingForSetTimeSession: "منتظر تایید کارشناس",
  successRequestSubmitPleaseWaitForStep2:
    "اطلاعات اولیه با موفقیت ثبت شد. لطفا منتظر تایید کارشناسی باشید.",
  tourist_visa: "ویزای توریستی",
  education_visa: "ویزای تحصیلی",
  imp_visa: "ویزای کاری",
  owpp_visa: "ویزای باز کاری همسر",
  entrepreneurship_visa: "ویزای کار افرینی",
  selectVisaTypes: "از بین ویزاهای زیر، ویزاهای مربوطه را انتخاب کنید:",
  SelectVisaCanCustomerSelect:
    "از بین ویزاهای زیر، ویزاهایی که کاربر قابلیت انتخاب آن را دارد، فعال نمایید:",
  priceForTrySession: "هزینه جلسه مشاوره به ریال",
  "Request.saveSubmit": "ذخیره اطلاعات",
  "Request.goToContract": "رفتن به قرارداد",
  "Request.cancelRequest": "لغو درخواست",
  reportMessage: "گزارش جلسه",
  reportAttachment: "پیوست جلسه",
  "Request.Upload": "اپلود پیوست",
  "Request.UploadFile": "اپلود فایل",
  "Request.CancelUpload": "پاک کردن",
  "Request.paymentText": "لطفا مبلغ کل قرارداد را به دلار وارد کنید:",
  "Request.registerContractPrice": "ثبت مبلغ قرار داد",
  contractFile: "فایل قرارداد",
  // Cancel: "کنسل",
  Add_Attachment: "افزودن مدرک جدید",
  ADD: "ثبت",
  countInstallment: "تعداد مراحل پرداخت",
  totalPriceRial: "مبلغ قرارداد به ریال",
  totalPriceDollar: "مبلغ کل قرار داد",
  enterTotalPrice: "مبلغ قرار داد را وارد کنید",
  confirmContractStep: "من شرایط بالا را خواندم و می پذیرم",
  confirmContractStepEn: "I have read the above and agree",
  "Request.signContractAdmin": "محل امضای قرار داد مدیر (بوسیله موس یا تاچ پد)",
  "Request.signContractUser": "محل امضای قرار داد کاربر (بوسیله موس یا تاچ پد)",
  "Request.textContractFile": "فایل قرار داد را بارگذاری نمایید:",
  "Request.goToCompleteDocs": "رفتن به تکمیل مدارک",
  "Request.specify_the_number_installment": "تعداد مراحل پرداخت را مشخص کنید:",
  installmentPrice: "قیمت",
  installmentDueDate: "تاریخ سررسید",
  installmentPayment: "پرداخت",
  "Request.pay": "پرداخت",
  "Request.add_docs": "مدارک مورد نیاز جهت تکمیل پرونده مهاجرتی",
  "Request.DocName": "عنوان مدرک",
  "Request.DocFile": "فایل مدرک",
  "Request.DocDate": "تاریخ بروز رسانی",
  "Request.DocState": "وضعیت",
  customerDocNotes: {
    zero: "با تشکر از صبر و شکیبایی شما در بخش تکمیل مدارک پرونده، لطفا به موارد زیر توجه فرمایید:",
    one: "لطفا مدارک مورد نیاز ذکر شده در جدول زیر را به ترتیب آپلود نمایید.",
    two: "کاربر گرامی به تاریخ مهلت ارسال مدارک توجه کامل داشته باشید، پیامد های عدم ارسال مدارک تا تاریخ معین شده به عهده کاربر می باشد.",
    three:
      "کاربر عزیز توجه داشته باشید، در طی چند مرحله مدارک مورد نیاز از شما دریافت خواهد شد، در هر مرحله پس از تایید مدارک، مجددا اطلاع رسانی خواهد شد و در صورت نیاز به مدارک دیگر از همین بخش سایر مدارک مورد نیاز را آپلود نمایید.",
    four: "در صورت نیاز به راهنمایی بیشتر با کارشناس خود در تماس باشید.",
  },
  adminDocNotes: {
    zero: "موارد مهم زیر را در بررسی مدارک ارسالی در نظر داشته باشید:",
    one: "مدارک کاربر به صورت مرحله به مرحله از طریق ستون نمایش به کاربر فعال شود و تنها مدارکی که کاربر در هر مرحله باید آپلود نماید را فعال نمایید.",
    two: "بعد از تایید مدرک امکان ویرایش یا حذف مدرک وجود ندارد، در صورت نیاز به تغییرات با ادمین سیستم به ادمین سیستم تماس برقرار نمایید.",
    three:
      "مدارک دارای مهلت ارسال، در موعد سررسید اطلاع رسانی از طریق سیستم انجام میشود. در صورت نیاز می توانید اطلاع رسانی Manual انجام دهید.",
  },
  National_Card: "کارت ملی",
  Birth_Certificate: "شناسنامه",
  Profile_Image: "عکس ۳در۴",
  Finger_Print: "اسکن اثرانگشت",
  Other_Docs: "سایر مدارک",
  AreYouSureForSaveTime: "برای رزرو این تاریخ مطمئن هستید؟",
  AreYouSureForSave: "برای ذخیره مطمئن هستید؟",
  AreYouSureForSaveForCancelRequest: "مطمئن جهت لغو درخواست؟",
  successUploaded: "آپلود شد.",
  Requests: "قراردادها",
  FooterAdminText: "برای تایید یا رد این مرحله بر روی گزینه مورد کلیک کنید: ",
  date: "تاریخ",
  status: "وضعیت",
  Invoices: "صورت حسابها",
  due_date: "تاریخ سررسید",
  created_at: "تاریخ ایجاد",
  updated_at: "آخرین بروزرسانی",
  GetVisa: "دریافت ویزا",
  cost: "مبلغ",
  job: "شغل",
  subject: "موضوع",
  title: "عنوان",
  Services: "خدمات",
  clear: "دوباره",
  selectUser: "انتخاب کاربر",
  pleaseSelectUser:
    "لطفا کاربر مورد نظر خود را انتخاب و در پایان دکمه ثبت را کلیک کنید:",
  createContractText:
    "در صورتی که کاربر، بعد از مشاوره و ارزیابی اولیه نیاز به تشکیل پرونده یا قرار دارد، از این قسمت برای کاربر فرم پیش نویس قرارداد ارسال میشود.",
  AssignContractToUser: "تنظیم قرارداد جدید برای کاربر",
  AssignContractToOperator: "فرم تغییر کارشناس پرونده",
  pleaseSelectOperator: "لطفا کارشناس مربوط را انتخاب نمایید:",
  selectOperator: "کارشناس پرونده",
  visitLink: "تکمیل فرم",
  "Service.Edit": "ویرایش سرویس",
  "Service.New": "افزودن سرویس",
  "Dialog.ConfirmFile": "تایید/رجکت مدرک",
  "Dialog.AcceptThisAttachment": "لطفا، وضعیت مدرک را انتخاب نمایید",
  PleaseGoToFollowLinkAndCompleteForm:
    "لطفا از طریق لینک زیر، وارد فرم مربوط شده و بعد از تکمیل اطلاعات، خروجی PDF فایل مورد نظر را از قسمت زیر آپلود نمایید.",
  accepted: "تایید شد",
  link: "لینک",
  viewContract: "نمایش قرارداد",
  viewEval: "نمایش ارزیابی",
  RejectFile: "رد",
  unsent: "ارسال نشده",
  unknown: "ارسال نشده",
  sent: "منتظر تایید",
  dueSend: "مهلت ارسال",
  RejectReason: "دلیل رد مدرک ارسال شده",
  dateUpload: "تاریخ آپلود",
  acceptDate: "تاریخ تایید",
  visibleClient: "نمایش به کاربر",
  SuccessfullyHide: "مدرک مورد نظر برای کاربر غیرفعال شد",
  SuccessfullyShow: "مدرک برای مورد نظر برای کاربر فعال شد.",
  actions: "",
  zone: "ساعت پیش فرض سیستم",
  AcceptFile: "تایید",
  ServiceNew: "افزودن سرویس",
  bodyMessage: "متن پیام",
  typeMessage: "نوع پیام",
  title_en: "عنوان انگلیسی",
  council: "مشاوره",
  contract: "قرارداد",
  done: "انجام شده",
  draft: "پیش نویس",
  all: "همه",
  partPaymentDone: "بخشی انجام شده",
  waitForApprove: "منتظر تایید",
  offlinePaidAndWaitForApprove: "آفلاین پرداخت شده و منتظر تایید کارشناس است.",
  waitPayment: "در انتظار پرداخت",
  MessageNumber: "پیام",
  // profile: "پروفایل",
  "Profile.User": "پروفایل کاربر",
  "Profile.Title": "پروفایل",
  "Profile.Security": "تنظیمات امنیتی",
  "panel.customer": "پنل کاربر",
  "panel.admin": "پنل مدیریت",
  "Profile.Settings": "تنظیمات سیستم",
  "Profile.ConsultantTime": "زمان بندی مشاور",
  PaymentDone: "تایید پرداخت ها",
  errorUpload: "آپلود با خطا رو به رو شد، مجددا تلاش نمایید.",
  OpenRequest: "قراردادهای فعال",
  OpenTicket: "تیکت های باز",
  NewPayment: "صورت حساب باز",
  addConsultant: "افزودن زمان مشاوره",
  consultantType: "نوع مشاوره",
  NewMessage: "پیام خوانده نشده",
  SEEN: "خوانده شده",
  UN_SEEN: "خوانده نشده",
  NewUser: "کاربران جدید",
  dashboard: {
    titleNews: "اطلاعیه ها",
    statusContracts: "نمودار وضعیت قرارداد ها",
    rateFillEvaluation: "نمودار نرخ تکمیل فرم ارزیابی",
  },
  upgrade: "ارتقا",
  description: "توضیحات",
  content: "متن اصلی",
  requestId: "شناسه قرارداد",
  content_en: "متن اصلی به انگلیسی",
  description_en: "توضیحات انگلیسی",
  descriptionEn: "توضیحات انگلیسی",
  SureForAccept: "برای تایید مطمئن هستید؟",
  SureForDelete: "برای حذف این آیتم مطمئن هستید؟",
  AreYouSureForAbort: "لغو ارزیابی؟ ",
  surlyMessage: "پیام",
  RequestReviewInfo: "درخواست بررسی",
  InfoAccepted: "اطلاعات تایید شده",
  InfoAcceptedError: "رد اطلاعات اولیه",
  SelectVisaWasSet: "تایید پاسخ ارزیابی",
  SelectVisaError: "رد پاسخ ارزیابی",
  SessionDone: "مشاوره انجام شده",
  SessionError: "مشاوره انجام نشد",
  ContractDone: "تایید قرار داد",
  ContractError: "عدم تایید قرار داد",
  PaymentError: "خطای پرداخت",
  DocAdditionalError: "خطای مدارک",
  DocAdditionalDone: "تایید مدارک",
  GetVisaError: "خطای دریافت ویزا",
  Accept: "ثبت",
  Cancel: "بستن",
  CostOfContract: "هزینه و مراحل پرداخت",
  SureForReject: "مطمئن جهت رد درخواست؟",
  SureForLock: "مطمئن جهت بستن؟",
  SureForUnLock: "مطمئن جهت باز کردن؟",
  CanceledRequest: "لفو شده",
  CanceledOk: "لغو شد",
  SubmitEdit: "ثبت تغییرات",
  ConfirmOk: "تایید شد.",
  reasonReject: "علت رجکت مدرک",
  UpdatePasswordHasError: "رمز عبور قبلی صحیح نیست",
  SuccessfullyUpdated: "بروزرسانی با موفقیت انجام شد.",
  SuccessfullyCreated: "با موفقیت ایجاد شد.",
  SuccessfullyDeleted: "حذف شد.",
  SuccessfullySaved: "ذخیره شد.",
  DOCUMENT_ACCEPTED: "تایید مدرک",
  DOCUMENT_REJECTED: "ریجکت مدرک",
  ErrorHappened: "خطا، لطفا مجددا تلاش کنید.",
  PleaseSelectOneFile: "لطفا یک فایل انتخاب کنید.",
  transactionNumber: "شماره تراکنش",
  transactionDate: "تاریخ تراکنش",
  "Invoice.payment": "پرداخت",
  InvoiceNumber: "فاکتور شماره",
  paymentOnline: "پرداخت آنلاین",
  paymentOffline: "پرداخت کارت به کارت",
  Pay: "پرداخت",
  Search: "جستجو",
  ChangePassword: "تغییر رمز عبور",
  Blog: "بلاگ",
  slug: "نامک",
  installmentTitle: "عنوان فاکتور",
  uploadedFiles: "فایل های اپلود شده",
  newFiles: "فایل های در صف اپلود",
  Categories: "دسته ها",
  slug_en: "نامک انگلیسی",
  name_en: "نام انگلیسی",
  Articles: "مقالات",
  ArticleNew: "مقاله جدید",
  "Article.New": "مقاله جدید",
  "Article.Edit": "ویرایش مقاله",
  "Category.New": "دسته جدید",
  "Category.Edit": "ویرایش دسته",
  Category: "دسته ها",
  Visas: "ویزاها",
  UserInfo: "اطلاعات کاربر",
  ResetPassword: "تغییر رمزعبور",
  VisasDescription: "لیست انواع ویزاهای تعریف شده سیستم",
  isActive: "وضعیت",
  paragraph: "بند",
  online: "آنلاین",
  offline: "حضوری",
  active: "فعال",
  disable: "غیرفعال",
  titleEn: "عنوان انگلیسی",
  Attachments: "قالب مدارک",
  NewAttachment: "ایجاد مدرک",
  typeDocument: "نوع مدرک",
  "Visa.New": "ویزای جدید",
  "Visa.Edit": "ویرایش ویزا",
  "Attachment.New": "مدرک جدید",
  "Attachment.Edit": "ویرایش مدرک",
  DEFAULT: "نمایش معمولی با قابلیت آپلود و دانلود",
  LINK: "نمایش به صورت لینک حاوی لینک ماکروسافت",
  ONLY_ADMIN: "نمایش فقط به ادمین",
  documentType: {
    Finance: "مالی",
    Education: "تحصیلی",
    OTHER: "سایر",
    Authorization: "احراز هویت",
  },
  documentTypeUp: {
    FINANCE: "مدارک مالی",
    EDUCATION: "مدارک تحصیلی",
    OTHER: "سایر مدارک ",
    AUTH: "مدارک هویتی",
  },
  Default_contract_text: "متن پیش فرض قرارداد ویزا:",
  Default_contract_text_en: "متن پیش فرض انگلیسی قرارداد ویزا:",
  isOnline: "روش تحویل مدارک",
  ContractTypes: "قالب قراردادها",
  successPaymentDoneWaitForAccept:
    "پرداخت با موفقیت انجام شد. لطفا منتظر تایید کارشناس باشد.",
  errorPayment: "خطای پرداخت",
  thumbnail: "تصویر پیش فرض",
  motionGraphic: "موشن گرافی",
  defaultDocuments: "مدارک پیش فرض قرارداد",
  category: "دسته",
  Drop_files_here_or_click_to_upload:
    "جهت آپلود فایل اینجا کلیک یا درگ کنید...",
  For_attach_files_drag_here:
    "جهت ضمیمه مدارک یا مستندات اینجا کلیک یا درگ کنید",
  visaThatUserSelected: "ویزای منتخب کاربر",
  "Invoice.create": "ایجاد صورت حساب",
  "Invoice.update": "بروزرسانی   صورت حساب",
  "Invoice.offCodeInvalid": "کد تخفیف اشتباه است.",
  "Invoice.errorAttachment": "تصویر رسید پرداخت آپلود شود",
  attachmentReceipt: "رسید پرداخت",
  "Request.CreateInvoice": "ایجاد صورت حساب",
  "Request.UpdateInvoice": "بروزرسانی صورت حساب",
  "Request.contractAttachments": "ضمایم قرارداد",
  PleasePayPriceForSession: "هزینه جلسه مشاوره:",
  PleaseCreateInvoiceOfSession:
    "صورتحساب مربوط به هزینه جلسه مشاوره را ایجاد نمایید، (ایجاد صورت حساب منوط به انتخاب ویزا برای کاربر است).:",
  sessionNotes: "نکات مهم برگزاری جلسه:",
  sessionNotes_1:
    "در حال حاضر تمامی جلسات به صورت آنلاین و از طریق واتس آپ انجام خواهد گرفت.",
  sessionNotes_2:
    "در صورت عدم امکان برقراری ارتباط از طریق واتس اپ، در همان ساعت مشاوره با مشاور هماهنگ کنید.",
  sessionNotes_3:
    "در روز مشاوره، یادآوری جلسه از طریق راه های ارتباطی پیامک و ایمیل انجام خواهد گرفت",
  sessionNotes_4:
    "در صورت عدم امکان برای حضور در جلسه، حداکثر ۲ روز قبل از زمان جلسه با پشتیبان سایت (چت آنلاین یا بخش پشتیبانی - تیکت ) تماس حاصل فرمایید",
  currentVisa: "ویزای جاری",
  defaultVisaHusband: "ویزای پیشفرض همسر",
  defaultVisaChild: "ویزای پیشفرض فرزند",
  summary: "توضیح کوتاه",
  generateCode: "تولید کد",
  offCode: "کد تخفیف",
  offPercent: "درصد تخفیف",
  summaryEn: "توضیح کوتاه انگلیسی",
  linkPayment: "لینک پرداخت",
  dateSession: "تاریخ برگزاری",
  timeSession: "ساعت برگزاری",
  TimeOfSession: "زمان برگزاری جلسه:",
  fileTitles: "نام فایل",
  contract_text: "متن قرار داد",
  defaultAttachments: "مدارک پیش فرض ویزا",
  contract_text_en: "متن قرار داد انگلیسی",
  selectVisaTypeForContract: "نوع ویزا را مشخص کنید",
  setTimeSession: "زمان جلسه را مشخص کنید",
  "Request.getVsia": "ارسال مدارک به اداره مهاجرت و پایان",
  paymentDoneNeedForReview:
    "پرداخت آفلاین انجام شده و منتظر تایید و بررسی کارشناس است",
  text_help_get_visa:
    "کاربر عزیز، مدارک شما به دفتر کانادا ارسال شده و بعد از بررسی نهایی توسط کارشناسان دفتر کانادا در صورت نداشتن مغایرت به اداره مهاجرت ارسال خواهد شد. در صورت هرگونه مغایرت یا تغییر خاصی در اخذ مراحل دریافت ویزا،‌ اطلاع رسانی های لازم انجام خواهد شد.",
  elementarySchool: "دبستان",
  highSchool: "دبیرستان",
  studentUni: "دانشجو",
  noEducation: "مدرسه نمی رود",
  firstYear: "سال اول",
  secondYear: "سال دوم",
  thirdYear: "سال سوم",
  fourthYear: "سال چهارم",
  levelEducation: "سطح تحصیلات",
  levelEducationNumber: "سال تحصیلی",
  under_50: "زیر ۵۰ هزار دلار",
  between_50_100: "بین ۵۰ تا ۱۰۰ هزار دلار",
  between_100_200: "بین ۱۰۰ تا ۲۰۰ هزار دلار",
  between_200_350: "بین ۲۰۰ تا ۳۵۰ هزار دلار",
  between_350_500: "بین ۳۵۰ تا ۵۰۰ هزار دلار",
  between_500_750: "بین ۵۰۰ تا ۷۵۰ هزار دلار",
  between_750_1000: "بین ۷۵۰ تا ۱۰۰۰ هزار دلار",
  between_1000_1600: "بین ۱۰۰۰ تا ۱۶۰۰ هزار دلار",
  more_than_1600: "بیشتر از ۱۶۰۰ هزار دلار",
  Businesslicense: "پروانه کسب",
  Company_registered: "شرکت ثبتی",
  Medical_System_license: "پروانه نظام مهندسی یا نظام پزشکی",
  Management_history: "سابقه مدیریت",
  Management_farm: "سابقه کشاورزی",
  Athlete_art_pro: "ورزشکار یا هنرمند حرفه ای طی ۵ سال اخیر",
  Other: "دیگر",
  notHave: "ندارم",
  lessThan2: "کمتر از ۲ سال",
  years_3: "۳ سال",
  years_4: "۴ سال",
  years_5: "۵ سال",
  more_than_5: "بیشتر از ۵ سال",
  Excellent: "عالی",
  Normal: "معمولی",
  Weak: "ضعیف",
  brother_sister: "خواهر یا برادر",
  father_mother: "پدر یا مادر",
  grandPa_grandMa: "پدر بزرگ یا مادر بزرگ",
  aunt: "عمه یا خاله",
  uncle: "عمو یا دایی",
  child: "فرزند",
  google: "گوگل",
  socialMedia: "شبکه های اجتماعی",
  friends: "دوستان",
  wait: "در انتظار پرداخت",
  viewInvoiceDetail: "جزئیات فاکتور",
  SiteSupport: "پشتیبانی سایت",
  fillComplete: "لطفا تمام فیلد ها را پر کنید.",
  contractInstallment: "مرحله پرداخت قرارداد",
  byContractNumber: "با شماره قرارداد",
  visaCanYouSelected: `با تشکر از تکمیل فرم ارزیابی، با توجه به مشخصات ارسالی، شما میتوانید
   برای ویزاهای زیر اقدام کنید.
   در صورت تمایل جهت مشاوره، هزینه جلسه مشاوره را از قسمت پرداخت هزینه جلسه مشاوره پرداخت نمایید`,
  tracking_id: "شناسه",
  service_id: "شناسه خدمات",
  offline_paid: "پرداخت تکمیل شده",
  offlinePaymentInfo: "اطلاعات حساب جهت پرداخت کارت به کارت",
  offlineCardNumber: "شماره کارت",
  offlineShebaNumber: "شماره شبا",
  offlineAccountNumber: "شماره حساب",
  bankname: "بانک صادرات ایران - مهراد کهن بابا",
  afterPaymentOffline:
    "لطفا بعد از پرداخت، اطلاعات تراکنش به همراه تصویر رسید پرداخت را آپلود نمایید",
  waitConfirm: "منتظر تایید",
  rejected: "رد شده",
  paid: "پرداخت شده",
  callNumber: "شماره تماس",
  contractPaymentText: `کاربر عزیز، پرداخت های ارزی مراحل قرارداد
   نیاز به انجام هماهنگی های لازم با صرافی و دریافت شماره حساب پرداخت برای آن پرداخت می باشد. لذا قبل از هر گونه پرداخت از طریق گروه کاری 
   واتس آپ یا شماره های زیر هماهنگی های لازم را انجام دهید.`,
  contractPayText2: "در صورت بروز هرگونه مشکل، با پشتیبانی تماس حاصل فرمایید.",
  viewDetails: "جزئیات",
  installmentStatus: "وضعیت پرداخت",
  installmentViewPayments: "اطلاعات",
  installmentDelete: "حذف",
  installmentApprove: "تایید/ لغو",
  paymentsHistory: "تراکنش ها",
  useOffCode: "کد تخفیف",
  bankPaymentId: "شناسه پرداخت بانکی",
  paymentTime: "زمان پرداخت",
  paymentDate: "تاریخ پرداخت",
  SureForaccept: "برای تایید مطمئن هستید؟",
  SureForreject: "برای رد مطمئن هستید؟",
  confirmedState: "وضعیت تایید",
  confirmedState_: {
    confirmed: "تایید شده",
    not_confirmed: "تایید نشده",
    Unknown: "نامشخص",
  },
  Delete: "حذف",
  Unknown: "نامشخص",
  SaveChanges: "ثبت تغییرات",
  costDollar: "مبلغ دلار",
  dollar: "دلار",
  contractId: "شناسه قراداد",
  evaluationId: "شناسه مشاوره",
  rial: "ریال",
  booking: "مشاوره",
  costRial: "مبلغ ریال",
  dueDate: "تاریخ سررسید",
  typeCurrency: "واحد پول",
  transactionTime: "زمان تراکنش",
  attachment: "ضمیمه",
  ClientInformation: "اطلاعات کاربر",
  OrderInformation: "اطلاعات سفارش",
  SureForSave: "مطمن برای دخیره سازی",
  SAVE: "ثبت نغییرات",
  user: "کاربر",
  file: "ضمیمه",
  partnerBirthday: "تاریخ تولد همسر",
  validation: {
    emailNotValid: "ایمیل نامعتبر",
    phoneNotValid: "تلفن نامعتبر",
  },
  thisSectionNeedActivedByAdmin: `این قسمت درحال اعمال تنظیمات توسط کارشناس مربوطه است. 
      لطفا ساعتی بعد مجددا مراجعه نمایید
      و یا از طریق بخش تیکت با ما درتماس باشید.`,
  invoiceSuccessfullyPayed: "پرداخت با موفقیت انجام شد.",
  invoiceUnSuccessfullyPayed:
    "پرداخت با مشکل مواجه شد، لطفا دقایقی بعد مجددا تلاش نمایید.",
  dateEvent: "تاریخ رویداد",
  timeEvent: "زمان برگزاری",
  start_time: "ساعت شروع",
  approve: "منتظر تایید",
  startTime: "ساعت شروع",
  endTime: "ساعت پایان",
  end_time: "ساعت پایان",
  nameConsultant: "نام مشاور",
  requestEval: "درخواست مشاوره",
  ChangeTime: "تغییر زمان",
  reminder: "",
  CancelSession: "لغو جلسه",
  UpdateStatus: "تغییر وضعیت",
  DetailBook: "جزئیات رزرو مشاوره",
  bookingInformation: "اطلاعات کاربر",
  bookingConsultantSetting: "تنظیمات",
  bookingConsultantTime: "جزئیات برگزاری جلسه",
  bookingConsultantType: "اطلاعات پکیج جلسه",
  userId: "شناسه کاربر",
  ForUpdateStatus: "تغییر وضعیت مشاوره",
  ForUpdateTimeClick: "جهت تغییر زمان جلسه کلیک کنید:",
  ForCancelSessionClick: "جهت لغو زمان جلسه کلیک کنید:",
  price: "قیمت",
  RIAL: "ریال",
  Each: "هر",
  month: "ماه",
  view: "نمایش",
  remove: "حذف",
  phoneContact: "شماره تماس",
  request_link: "جزئیات مشاوره",
  AreYouSureForDelete: "برای حذف این ایتم مطمئن هستید؟",
  is_reserved: "رزرو شده",
  startDate: "تاریخ شروع",
  numberInstallment: "تعداد مراحل پرداخت",
  totalCost: "مبلغ کل قرارداد",
  dueDateDoc: "آخرین مهلت ارسال",
  document: "مدرک",
  Add: "افزودن",
  download: "دانلود",
  MessageFull: "متن پیام",
  HIGH: "فوری",
  QuickAccess: "میز خدمات",
  AnalyticInfo: "آمار و اطلاعات",
  request_id: "شناسه ارزیابی",
  body: "محتوا",
  type: "نوع",
  receipt: "رسید پرداخت",
  InvoiceCreated: "صورت حساب",
  InvoicePayment: "پرداخت صورت حساب",
  RequestCreated: "ایجاد ارزیابی",
  NewTicket: "ایجاد تیکت",
  "Ticket.New": "تیکت جدید",
  codeCountry: "کد کشور",
  lang: "زبان پیش فرض",
  MessageTemplate: "قالب پیام ها",
  hasMessageEmail: "ایمیل فارسی",
  hasMessageEmailEn: "ایمیل انگلیسی",
  hasSms: "پیامک",
  thisInvoicePaySuccess:
    "فاکتور با موفقیت پرداخت شده است. اطلاعات تکمیلی در پایان صفحه قابل مشاهده است.",
  ProfileInfo: "اطلاعات کاربری",
  MessageTemplates: "قالب پیام ها",
  messageEmailEnglish: "متن پیام ایمیل لاتین",
  messageEmailPersian: "متن پیام ایمیل فارسی",
  messageSms: "متن پیامک",
  "MessageTemplate.New": "ایجاد پیام جدید",
  "MessageTemplate.Edit": "ویرایش قالب پیام",
  sendSms: "ارسال پیامک",
  sendEmail: "ارسال ایمیل",
  sendEnglish: "ارسال پیام به لاتین",
  sendNotification: "تنظیمات اطلاع رسانی",
  descriptionReject: "دلایل رد درخواست",
  "Dialog.ResultPaymentSuccess": "پرداخت با موفقیت انجام شد.",
  "Dialog.ResultPaymentError":
    "پرداخت انجام نشد. در صورت کسر موجودی و عدم بازگشت وجه در ۲۴ ساعت آینده با پشتیبانی تماس حاصل فرمایید.",
  referenceNumber: "شماره پیگیری",
  Close: "بستن",
  passwordOld: "رمز عبور قبلی",
  passwordNew: "رمز عبور جدید",
  passwordNewRepeat: "تکرار رمز عبور جدید",
  invoiceWasPaid: "صورت حساب پرداخت شده",
  firstTimeTitle: "سلام، دوست عزیز!",
  Start: "شروع",
  Rows_per_page: "تعداد ایتمها",
  Rows_of: "از",
  not_match: "رکوردی یافت نشد",
  not_completed: "تکمیل نشده",
  canceled: "انصراف از پرداخت",
  completed: "انجام شد",
  SelectedTimeIs: "تاریخ برگزاری جلسه",
  hour: "ساعت",
  Settings: "تنظیمات",
  SuccessfullySavedWaitForAdmin:
    "اطلاعات قرارداد با موفقیت ثبت شد، منتظر تایید کارشناس باشید.",
  invoiceType: "نوع صورت حساب",
  instalment: "مراحل پرداخت قرارداد",
  resubmit: "هزینه رفع ریجکتی",
  historyPayments: "آرشیو پرداخت های این فاکتور",
  currency: {
    usd: "دلار",
    riyal: "ریال",
    euro: "یورو",
  },
  SelectedVisa: "ویزای منتخب",
  showEvalPage: "بازگشت به صفحه ارزیابی",
  showRequestPage: "بازگشت به صفحه قرارداد",
  costThatRemainedForInstallment: "مبلغ فاکتور نشده",
  installmentMoreThanContractCost:
    "مبلغ وارد شده بیشتر از میزان مبلغ قرار داد است.",
  ErrorNotAcceptedAllContractConditions:
    "لطفا، تمام بندهای قرار داد را تایید نمایید.",
  ErrorSignContract: "لطفا قرار داد را امضا کنید.",
  contract_: {
    PersianText: "ترجمه متن قراداد",
    EnglishText: "متن قرارداد",
  },
  PleaseReadContractAndAcceptThis: `لطفا، تمام قرار داد را با دقت مطالعه کرده
   و موافقت خود را در هر بند قرار داد با زدن
    تیک های
    "
     من شرایط بالا را خواندم و می پذیرم
     "
     اعلام فرمایید و در پایان بعد از
     "
      امضای قرار داد
      "
      ، قرار داد را ذخیره نمایید.`,
  successFullyDone: "با موفقیت انجام شد",
  contractIsReadyTitle: "دریافت قرارداد",
  yourContractIsReadyForDownload:
    "اطلاعات با موفقیت ذخیره شد. اکنون میتوانید فایل قرار داد را دانلود نمایید.",
  rateCouncil: "نرخ پیش فرض جلسه مشاوره - ریال",
  ticketStatus: {
    open: "باز",
    response: "پاسخ داده شده",
    close: "بسته",
  },
  ticket_id: "شناسه",
  ticket: "تیکت",
  titleTicket: "عنوان تیکت",
  username: "نام کاربری",
  selectDate: "انتخاب تاریخ",
  PasswordCurrentWrong: "رمز عبور جاری صحیح نیست",
  UserNotFound: "رمز عبور جاری صحیح نیست",
  NotMatch: "رمز عبور جدید تطابق ندارد",
  ReturnToHome: "بازگشت",
  contractTemp: {
    contractDetails: "اطلاعات و مشخصات",
    title: "قرارداد مشاوره مهاجرت کهن بابا",
    dateDownload: "تاریخ دریافت قرارداد",
    dateCreated: "تاریخ ایجاد قرارداد",
    timeSign: "تاریخ امضای قرارداد",
    dateSubmit: "تاریخ ثبت قرارداد",
    contractNumber: "شماره قرارداد",
    SignContract: "محل امضای قراداد",
    signOwner: "امضای نماینده قانونی شرکت",
    signUser: "امضای متقاضی",
    print: "پرینت قرارداد",
    ContextText: "متن قرارداد",
    text1: "این قرارداد مشاوره مهاجرت و خدمات مهاجرت، فیمابین متقاضی ",
    text2: "به شماره ملی ",
    text3: "به آدرس ",
    text4: "تلفن ",
    text5: `تحت عنوان یک کمپانی کانادایی
    و عضو رسمی و قانونی اداره مهاجرت کانادا به شماره `,
    text6: "به آدرس ",
    text7: "به نمایندگی ",
    and: "و ",
    text8: "سازمان مهاجرتی کهن بابا (KOHANBABA IMMIGRATION SERVICES LTD)",
    text9: "به کد ملی ",
    text10: `به آدرس : تهران ، فرمانیه، خ سعیدی، کوچه مهمانکده،
    کوچه چیت‌ساز، پلاک ۱ طلایی، واحد ۱، با شماره تلفن۰۲۱۲۲۸۲۰۸۴۵  منعقد میگردد . `,
    text11: " می باشد",
    name: "نام(Persian)",
    nameEn: "نام(English)",
    family: "نام خانوادگی(Persian)",
    familyEn: "نام خانوادگی(English)",
    email: "ایمیل",
    phone: "موبایل",
    phoneHome: "تلفن",
    birthday: "تاریخ تولد",
    marriedStatus: "تاهل",
    gender: "جنسیت",
    soldiery: "وضعیت سربازی",
    degree: "مدرک دانشگاهی",
    fieldStudy: "رشته تحصیلی(Persian)",
    fieldStudyEn: "رشته تحصیلی(English)",
    eduDateStart: "تاریخ شروع تحصیل",
    eduDateEnd: "تاریخ پایان تحصیل",
    totalAssets: "مجموع دارایی ها",
    typeJob: "نوع شغل",
    isWorking: "مشغول به کار",
    jobState: "وضعیت اشتغال",
    insuranceHistory: "وضعیت بیمه",
    workDescription: "توضیح شغل(Persian)",
    workDescriptionEn: "توضیح شغل(English)",
    familyLiveCanada: "اعضای خانواده مقیم کانادا",
    levelLang: "سطح زبان انگلیسی",
    address: "آدرس(Persian)",
    addressEn: "آدرس(English)",
    hasSchengen: "ویزای شنگن",
    hasDisease: "وضعیت بیماری",
    hascriminal: "سابقه کیفری",
    targetCanada: "هدف مهاجرت",
    familyRequested:
      "آیا شما، همسر یا فرزندتان قبلا برای ورود به کانادا اقدام کرده‌اید؟",
    familyRequestDescription: "هدف شما برای سفر به کانادا",
    yourIelts: "IELTS certificate",
    yourIeltsScore: {
      read: "Reading",
      write: "Writing",
      listen: "Listening",
      speak: "Speaking",
    },
    yourTef: "TEF certificate",
    yourTefScore: {
      read: "Reading",
      write: "Writing",
      listen: "Listening",
      speak: "Speaking",
    },
    pairInfo: "مشخصات همسر",
    partnerName: "نام(Persian)",
    partnerNameEn: "نام(English)",
    partnerFieldStudy: "رشته تحصیلی",
    partnerBirthday: "تاریخ تولد",
    partnerDegree: "مدرک دانشگاهی",
    partnerJob: "شغل",
    partnerInsurance: "بیمه",
    partnerIelts: "IELTS certificate",
    partnerIeltsScore: {
      read: "Reading",
      write: "Writing",
      listen: "Listening",
      speak: "Speaking",
    },
    partnerTef: "TEF certificate",
    partnerTefScore: {
      read: "Reading",
      write: "Writing",
      listen: "Listening",
      speak: "Speaking",
    },
    CostOfContract: "مبلغ قرارداد",
    installmentTitle: "عنوان",
    installmentDueDate: "تاریخ سررسید",
    installmentStatus: "وضعیت پرداخت",
    installmentPrice: "مبلغ قابل پرداخت",
    totalPriceDollar: "مبلغ کل قرار داد",
  },
  ActivityDialogTitle: "آرشیو فعالیت ها",
  sample_file: "فایل نمونه مدرک",
  PleaseUploadAsSample: "لطفا مانند نمونه، مدرک مورد نظر را آپلود نمایید",
  viewExample: "مشاهده نمونه",
  "Dialog.SetDueDate": "تنظیم تاریخ مهلت ارسال مدرک",
  "Dialog.pleaseSelectDueDate": "لطفا تاریخ مورد نظر را انتخاب نمایید:",
  "Dialog.SetUrlAttachment": "تنظیم لینک فرم",
  "Dialog.pleaseSetUrlAttachment":
    "لطفا لینک ماکروسافت ورد یا فرم را در باکس زیر وارد کنید:",
  acceptEvaluation: "تایید ارزیابی و ایجاد قابلیت رزرو جلسه برای کاربر",
  // acceptEvaluationWithoutPayment: "تایید مستقیم ارزیابی بدون پرداخت",
  rejectEvaluation: "رجکت ارزیابی",
  offerVisa: "ویزای پیشنهادی براساس اطلاعات فرم ارزیابی",
  doneEvaluation: "جلسه مشاوره برگزار شد",
  cancelEvaluation: "جلسه مشاوره لغو شد",
  titleSetEvaluationStatus: "تغییر وضعیت ارزیابی",
  errorPleaseWriteMessageReject: "لطفا، دلیل رد درخواست ارزیابی را بنویسید.",
  errorPleaseSelectOfferVisa:
    "لطفا، ویزای پیشنهادی به کاربر براساس اطلاعات فرم ارزیابی را انتخاب نمایید",
  rejection_description: "دلیل رد درخواست",
  messageStatus: {
    CONTRACT_UPDATED_BY_USER: "آپدیت قرارداد",
    EVALUATION_UPDATED_BY_USER: "اپدیت ارزیابی",
    PAYMENT_OFFLINE_DONE: "پرداخت آفلاین",
    CONTRACT_ATTACHMENT_UPDATED: "آپدیت مدارک قرارداد",
  },
  StatusChangedToSeen: "وضعیت پیام به مشاهده شده، تغییر یافت",
  titleChangeBookingTime: "تغییر زمان جلسه",
  titleChangeLocation: "تغییر نوع جلسه",
  pleaseSelectNewType:
    "در صورت نیاز، میتوانید از این قسمت نوع برگزاری جلسه را تغییر دهید:",
  pleaseSelectNewTimeForEval:
    "لطفا تاریخ جدید برای برگزاری جلسه را انتخاب نمایید",
  general: {
    saveChanges: "ثبت تغییرات",
  },
  locationType: "",
  consultantMethod: "نحوه برگزاری جلسه",
  consultant_type: {
    OFFLINE: "حضوری",
    ONLINE: "آنلاین",
    BOTH: "حضوری/آنلاین",
  },
  locationConsultant: "روش برگزاری جلسه",
  methodConsultant: "نوع",
  AreYouSureForSaveTypeHold: "آیا، برای تغییر نوع جلسه مطمئن هستید؟",
  FileIsToLargeMustBeUnder5Mb: "حداکثر حجم فایل ۴۰ مگابایت می باشد.",
  ErrorPleaseFirstFillForm:
    "خطا، بعد از تکمیل اطلاعات اولیه امکان تایید یا رد درخواست وجود دارد.",
  isServerSide: "پیام سیستمی",
  ContractsCount: "تعداد کل قراردادها",
  EvaluationsCount: "تعداد کل ارزیابی ها",
  CustomersCount: "تعداد کل کاربران",
  InvoicesCount: "فاکتورهای در انتظار",
  ConsultantCount: "مشاوره های انجام شده",
  invoiceRequestName: "قرارداد",
  invoiceStepName: "مرحله پرداخت",
  invoiceDueDate: "سررسید پرداخت",
  invoiceStatus: "وضعیت پرداخت",
  UpdateInvoiceStatus: "تغییر وضعیت فاکتور",
  UpdateInvoiceStatusText:
    "جهت تغییر وضعیت پرداخت فاکتور بر روی گزینه مورد نظر کلیک کنید:",
  INVOICE_DONE: "پرداخت کامل انجام شده",
  INVOICE_CANCEL: "این فاکتور لغو شود",
  INVOICE_PART_PAID: "بخشی از این فاکتور پرداخت شده",
  partAmount: "مبلغ پرداخت شده",
  dialog: {
    SureForDeleteDocument: "برای حذف این آیتم مطمئن هستید؟",
  },
  backToHome: "بازگشت",
  text404Error: "با عرض پوزش صفحه مورد نظر شما یافت نشد!",
  referer_source: "لینک ورودی",
  referer: "نحوه آشنایی با ما",
  search_engine: "موتورهای جستجو (گوگل، ...)",
  social_media: "شبکه های اجتماعی (ایستاگرام، ...)",
  advertisement: "تبلیغات",
  friends_family: "دوستان و خانواده",
  meetingLink: "لینک جلسه",
  meetingLink2: "لینک پشتیبانی",
  START: "ورود",
  pleaseSelectBetweenOptions: "لطفا عملیات مورد نظر خود را انتخاب نمایید: ",
  confirmStatus: {
    acceptedStep: "تایید اطلاعات مرحله جاری",
    rejectedStep: "عدم تایید اطلاعات مرحله جاری",
    onlySendNotification: "ارسال پیام پیگیری وضعیت به کاربر",
    contractDone: "تایید و پایان قرارداد",
    contractCanceled: "لغو قرارداد",
    forConfirmThisStepAndGoNext:
      "در صورت مورد تایید بودن اطلاعات کاربر در مرحله جاری این گزینه انتخاب شود",
    forRejectThisStepAndGoNext:
      "در صورت عدم تایید اطلاعات وارد شده توسط کاربر در مرحله جاری این گزینه انتخاب شود",
    onlySendNotificationMessage:
      "جهت ارسال یادآوری به کاربر برای تکمیل اطلاعات مرحله جاری این گزینه انتخاب شود",
    contractDoneDescription:
      "در صورت صدور ویزای کاربر و موفقیت آمیز بودن قرارداد این گزینه انتخاب شود",
    contractCanceledDescription:
      "در صورت انصراف یا لغو قرارداد این گزینه انتخاب شود",
  },
  settingNotifications: "تنظیمات اطلاعات رسانی",
  doesCurrentStepBeActiveOrOther: "آیا مرحله جدیدی برای کاربر فعال شود؟",
  settingStep: "تنظیم نمایش مرحله قرارداد",
  agile: {
    new: "جدید",
    not_answered: "پاسخ نداد",
    ok: "پیگیری شد",
    rejected: "رد شد",
  },
  updateStatusCampaignBook: "فرم تغییر وضعیت رزرو",
  pleaseSelectOption: "لطفا، گزینه مناسب را انتخاب نمایید:",
  consultantComment:
    "جهت پیگیری های آینده لطفا، کامنت خود را درباره این کاربر بنویسید:",
  feedbackStatus: "انتخاب وضعیت پیگیری",
  feedbackStatusTitle: "وضعیت پیگیری مجدد",
  feedbackDescription: "فیدبک نهایی جلسه",
  COMPLETE_REJECTED: "پیگیری نشود، کاربر شرایط مهاجرت ندارد",
  FOLLOW_UP: "درآینده مجددا پیگیری شود",
  OTHER: "نظری ندارم",
  READY_TO_CONTRACT: "آماده قرارداد",
  errorSelectFeedbackStatus: "لطفا وضعیت کلی کاربر را مشخص کنید",
  errorDescriptionFeedback: "لطفا فیلد سایر توضیحات را تکمیل نمایید",
  ERROR_THERE_ARE_ANY_TIME_FOR_RESERVE_NOT:
    "پیام سیستم: تمامی زمان های مشاوره رزرو شده اند، لطفا زمان جدید باز کنید.",
  ERROR_THERE_ARE_ANY_TIME_FOR_RESERVE:
    "پیام سیستم: تنها {{times}} تایم مشاوره جهت رزرو باقی مانده است، لطفا در اسرع وقت زمان جدید اضافه کنید.",
  AddTime: "افزودن زمان",
  errorTimeExist:
    "خطا،‌ رکورد تکراری یا در تایم وارد شده تایم دیگری ثبت شده است.",
  typeForm: "نوع فرم",
  EVALUATION: "ارزیابی رایگان",
  BOOKING: "رزرو مشاوره",
  ErrorExistActiveContract: "خطا، قرارداد فعال دیگری برای کاربر وجود دارد!",
  endStepVisaMessages: {
    intro: "ادمین محترم، لطفا در این مرحله به موارد زیر توجه داشته باشید:",
    m1: "لطفا در صورت دریافت ویزای موفق فایل مورد نظر را از قسمت زیر آپلود نمایید و وضعیت قرارداد را به انجام شده تغییر دهید.",
    m2: "در صورت ریجکتی ویزا، تصویر یا فایل مربوط به ریجکتی ویزا را از از قسمت زیر آپلود نمایید.",
    m3: "در صورت ریجکتی ویزا و تمایل کاربر به سابمیت مجدد با همین نوع ویزا پرداخت جدیدی برای کاربر به عنوان هزینه ری سابمیت ایجاد نمایید.",
    m4: "در صورتی ریجکتی ویزا و عدم تمایل کاربر به سابمیت مجدد وضعیت قرارداد را به لغو قرارداد تغییر دهید.",
  },
  country: "کشور",
  countryOptions: {
    Default: "کانادا",
    Canada: "کانادا",
    Italia: "ایتالیا",
    Spain: "اسپانیا",
    Portugal: "پرتغال",
    Oman: "عمان",
    Dominica: "دومنیکا",
    Emarat: "عمارات",
    Cyprus: "قبرس",
    France: "فرانسه",
    Lithuania: "لیتوانی",
  },
};

export default transFa;
