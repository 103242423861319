import { gql } from "@apollo/client";

export const GET_MESSAGE_BY_ID = gql`  
query GET_MESSAGE_BY_ID($id: ID!) {
    message(id: $id){
      id
      created_at
      updated_at
      title
      body
      type
      seen
      request{
        id
      }
    }
}`;

export const UPDATE_MESSAGE = gql`
mutation UPDATE_MESSAGE($mId: ID!){
  updateMessage(input: {
    where: {
      id:$mId
    }
    data: {
      seen: true
    }
  }){
    message {
      id
    }
  }
}
`;

export default {};
