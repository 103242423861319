import {
  Button, Grid, Theme, createStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";

import Alert from "@material-ui/lab/Alert";
import SectionTitle from "../../../components/SectionTitle";
import { RequestContext } from "../context/requestContext";
import { GET_REQUEST_STATUS } from "../requestQueries";

// import DefaultImage from "../../../assets/images/t12.png";
import {
  ACCEPT_REQUEST_ATTACHMENT,
  DELETE_REQUEST_ATTACHMENT_FILE, GET_REQUEST_ATTACHMENTS,
} from "./requestStepAQueries";
import FormDialogNewAttachment from "../../../components/FormDialogNewAttachment";
import AdminNavbarStepTools from "../component/AdminNavbarStepTools";
import { forceDownloadFile } from "../../../app/utils/helper";
import { API_SERVER } from "../../../Config";
import AttachmentTable from "../../../components/AttachmentTable";
import { GET_ME } from "../../../app/layout/layoutQueries";
import { STEPS } from "../../../app/statics";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    margin: "0px",
  },
  saveButton: {
    backgroundColor: theme.palette.success.light,
    marginRight: "5px",
    fontSize: "1.1em",
    width: "150px",
  },
  sigCanvas: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ddd",
    width: "100%",
  },
  intro: {
    padding: "0px !important",
    paddingBottom: "0px",
    marginBottom: "8px",
    "& ul": {
      margin: "0px",
      padding: "2px",
      paddingLeft: "16px",
    },
  },
  table: {
    margin: "20px",
  },
  displayFlexInline: {
    borderTop: "1px solid #eee",
    marginTop: "10px",
    paddingTop: "10px",
    display: "flex",
    alignItems: "center",
    color: "blue",
  },
  media: {
    // height: "20px",
    // overflow: "hidden",
    // background: "#eee",
  },

  titleSection: {
    backgroundColor: "#F8F8F8",
    width: "100%",
    padding: "8px",
    fontSize: "1.4em",
    margin: "0px",
    marginTop: "10px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
}));

const Title = (props: any) => {
  const { t } = useTranslation();

  const title = _.get(props, "attachment.title");
  const isHigh = _.get(props, "isHigh");
  return (
    <>
      {title}
      {isHigh ? <b>{`(${t("HIGH")})`}</b> : "" }
    </>
  );
};

const RequestStepFDocs = (props: any) => {
  const [files, setFiles] = useState<object[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [attachmentTypes, setAttachmentTypes] = useState<any[]>([]);
  const [dueDocumentDates, setDueDocumentDates] = useState<any>({});
  const [selectedAttachment, setSelectedAttachment] = useState<any>({});
  const [openDialogNewAttachment, setOpenDialogNewAttachment] = useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  const isCustomer = role === "Customer";

  const context = useContext(RequestContext);
  const { requestData } = context;

  const reqStatus = useQuery(GET_REQUEST_STATUS, {
    variables:
    { reqId: Number(requestData?.requestId) },
    fetchPolicy: "cache-only",
  });

  const requestAttachmentQuery = useQuery(GET_REQUEST_ATTACHMENTS, {
    variables: {
      reqId: requestData.requestId,
      section: "document",
    },
  });

  const [changeStateRequestAttachment] = useMutation(
    ACCEPT_REQUEST_ATTACHMENT,
  );

  const [deleteAttachmentRequest, responseDeleteAttachmentRequest] = useMutation(
    DELETE_REQUEST_ATTACHMENT_FILE,
    {
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    },
  );

  useEffect(() => {
    const attachmentsThisRequest = _.get(requestAttachmentQuery,
      "data.requestAttachments", []);
    const attachs_ = attachmentsThisRequest.map((item) => {
      const file = item?.file;

      return {
        ...item,
        // description: item.attachment?.description,
        attachId: file?.id,
        file: file ? file[0] : null,
        fileInfo: file,
        type_document: item.attachment?.type_document,
        sample_file: item.attachment?.sample_file,
        title: item?.attachment?.title || item.title,
      };
    });
    const attachTypes: string[] = ["OTHER"];
    attachmentsThisRequest.forEach((item) => {
      const x = item.attachment?.type_document;
      if (x && !attachTypes.includes(x)) {
        attachTypes.push(x);
      }
    });
    setAttachmentTypes(attachTypes.reverse());
    setAttachments(attachs_);
  }, [requestAttachmentQuery.data]);

  const { t } = useTranslation();
  const classes = useStyles();

  const handleUploadFile = (file, attachment) => {
    if (file) {
      setFiles([{ attachment, uploadFileId: file.id }]);
    }
    setAttachments((old) => {
      const new_ = old.map((item) => {
        if (item.id === attachment.id) {
          return { ...item, file };
        }
        return item;
      });
      return new_;
    });
  };

  const handleAddDocument = () => {
    setOpenDialogNewAttachment(true);
    setSelectedAttachment({
      title: t("Request.Add"),
    });
  };

  const handleEditDocument = (row) => {
    setOpenDialogNewAttachment(true);
    setSelectedAttachment({
      title: "Request.Edit",
      document: row.id,
    });
  };

  const confirmStatusAttachment = (attachment, status) => {
    changeStateRequestAttachment({
      variables: {
        id: attachment.attachId,
        status,
      },
      refetchQueries: ["GET_REQUEST_ATTACHMENTS"],
    });
  };

  const downloadFile = (e, file) => {
    e.preventDefault();
    if (file) {
      forceDownloadFile(API_SERVER() + file.url, file.name);
    }
  };

  const removeFile = (attachment) => {
    confirm({
      title: t("SureForDelete"), description: "", confirmationText: t("yes"), cancellationText: t("no"),
    }).then((r) => {
      deleteAttachmentRequest({
        variables: {
          id: Number(attachment.attachId),
        },
        refetchQueries: ["GET_REQUEST_STATUS", "GET_REQUEST_ATTACHMENTS"],
      }).then((resultUpload: any) => {
        // console.log("uploaded", resultUpload);
        enqueueSnackbar(t("SuccessfullyRemoved"), {
          variant: "success",
        });
      }).catch((errorUpload: any) => {
        enqueueSnackbar(t("ErrorHappened"), {
          variant: "error",
        });
      });
    }).catch((e) => {});
  };

  const isFileOnServer = (file) => {
    const isUploaded = !!file?.created_at;
    return isUploaded;
  };

  const requestSID = _.get(reqStatus, "data.requestStatuses[0].id");

  return (
    <>
      <SectionTitle
        title=""
      />
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={12} md={12} className={classes.intro}>
          <h2>{t("Request.add_docs")}</h2>
          {isCustomer ? (
            <Alert icon={false} severity="info">
              <div>
                <b>{t("customerDocNotes.zero")}</b>
              </div>
              <ul>
                <li>
                  {t("customerDocNotes.one")}
                </li>
                <li>
                  {t("customerDocNotes.two")}
                </li>
                <li>
                  {t("customerDocNotes.three")}
                </li>
                <li>
                  {t("customerDocNotes.four")}
                </li>
              </ul>
            </Alert>
          )
            : (
              <Alert icon={false} severity="info">
                <div>
                  <b>{t("adminDocNotes.zero")}</b>
                </div>
                <ul>
                  <li>
                    {t("adminDocNotes.one")}
                  </li>
                  <li>
                    {t("adminDocNotes.two")}
                  </li>
                  <li>
                    {t("adminDocNotes.three")}
                  </li>
                </ul>
              </Alert>
            )}
        </Grid>

        {!isCustomer ? (
          attachmentTypes.map((item: any) => {
            let attachments_ = attachments.filter((x) => x.type_document === item);
            if (item === "OTHER") {
              attachments_ = attachments
                .filter((x) => x.type_document === item || !x.type_document);
            }
            return (
              <>
                <h3 className={classes.titleSection}>{t(`documentTypeUp.${item}`)}</h3>
                <Grid key={item} item xs={12} md={12} className="admin-tables">
                  <Grid container spacing={4}>
                    <AttachmentTable
                      requestSID={requestSID}
                      handleUploadFile={handleUploadFile}
                      attachments={attachments_}
                      isFileOnServer={isFileOnServer}
                      removeFile={removeFile}
                      downloadFile={downloadFile}
                      dueDocumentDates={dueDocumentDates}
                      confirmStatusAttachment={confirmStatusAttachment}
                      handleEditDocument={handleEditDocument}
                      requestId={requestData?.requestId}
                    />
                  </Grid>
                </Grid>
              </>
            );
          })
        ) : false}

        {isCustomer
          ? (
            <Grid item xs={12} md={12} className="customer-table">
              <Grid container spacing={4}>
                <AttachmentTable
                  requestSID={requestSID}
                  handleUploadFile={handleUploadFile}
                  attachments={attachments}
                  isFileOnServer={isFileOnServer}
                  removeFile={removeFile}
                  downloadFile={downloadFile}
                  dueDocumentDates={dueDocumentDates}
                  confirmStatusAttachment={confirmStatusAttachment}
                  handleEditDocument={handleEditDocument}
                  requestId={requestData?.requestId}
                />
              </Grid>
            </Grid>
          ) : false}
        {!isCustomer ? (
          <Grid item xs={12} md={12} className="">
            <br />
            <br />
            <Button
              onClick={handleAddDocument}
              className={classes.saveButton}
            >
              {t("Request.addDocument")}
            </Button>
            {/* <Button
            onClick={handleSave}
            className={classes.saveButton}
          >
            {t("Request.saveSubmit")}
          </Button> */}
          </Grid>
        ) : ""}
        {!_.isEmpty(selectedAttachment) ? (
          <FormDialogNewAttachment
            title={selectedAttachment ? selectedAttachment.title : "Request.addDocument"}
            handleClose={() => setOpenDialogNewAttachment(false)}
            openDialog={openDialogNewAttachment}
            attachments={attachments}
            requestId={requestData?.requestId}
            documentProps={selectedAttachment}
          />
        ) : false}
      </Grid>
      <AdminNavbarStepTools stepNumber={STEPS.STEP_DOCUMENT} />
    </>
  );
};

export default RequestStepFDocs;
