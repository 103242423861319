/* eslint-disable no-nested-ternary */
import { useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  Button, Card, Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Person as PersonIcon } from "@material-ui/icons";
import { GET_ME } from "../../../app/layout/layoutQueries";
import InputBox from "../../../components/micro/InputBox";
import PageTitle from "../../../components/PageTitle";
import {
  CREATE_TICKET, CREATE_TICKET_MESSAGE, GET_MESSAGES_TICKET, GET_TICKET,
} from "../ticketsQueries";
import { dateIsoFa2, getFakeTicketId, timeIsoFa } from "../../../app/utils/helper";

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {},
  card: {
    padding: theme.spacing(5),
  },
  messagesBox: {
    background: "#fefefe",
    maxHeight: "50vh",
    overflowY: "auto",
    border: "1px solid #eee",
  },
  message: {
    border: "1px solid #eee",
    borderRadius: "8px",
    marginBottom: "8px",
  },
  messageOther: {
    display: "flex",
    flexDirection: "row-reverse",
    borderBottom: "1px solid #eee",
    backgroundColor: "#eee",
    borderRadius: "8px",
    marginBottom: "8px",
  },
  textOther: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingRight: "10px",
  },
  avatarOther: {
    background: "#4ab7f6",
  },
  date: {
    padding: "8px 8px",
  },
}));

const Ticket = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [title, setTitle] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [messages, setMessages] = useState<string>();
  const [file, setFile] = useState<object>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Me = useQuery(GET_ME);
  const uId = _.get(Me, "data.me.id");
  const role = _.get(Me, "data.me.role.name");
  const isCustomer = role === "Customer";

  const ticketId = _.get(props, "match.params.id");
  const messagesTicketQuery = useQuery(
    GET_MESSAGES_TICKET, { variables: { sort: "created_at:desc", ticketId: Number(ticketId) } },
  );
  const getTicketQuery = useQuery(
    GET_TICKET, { variables: { id: Number(ticketId) } },
  );

  const messages_ = _.get(messagesTicketQuery, "data.ticketMessages");
  useEffect(() => {
    setMessages(messages_);
  }, [messages_]);

  const [createTicketQuery, createTicketResponse] = useMutation(CREATE_TICKET);
  const [createTicketMessageQuery,
    createTicketMessageResponse] = useMutation(CREATE_TICKET_MESSAGE, {
    refetchQueries: ["GET_MESSAGES_TICKET"],
  });

  const handleSave = () => {
    if ((ticketId && message) || (title && message)) {
      if (!ticketId) {
        createTicketQuery({
          variables: {
            title,
            userId: uId,
          },
          refetchQueries: ["GET_MESSAGES_TICKET", "GET_TICKETS"],
        }).then((result) => {
          createMessageTicket(result);
        }).catch((e) => {
          enqueueSnackbar(t("ErrorHappened"), {
            variant: "error",
          });
        });
      } else {
        // update
        createMessageTicket();
      }
    } else {
      enqueueSnackbar(t("FillAllFields"), {
        variant: "error",
      });
    }
  };

  const createMessageTicket = (result = null) => {
    createTicketMessageQuery({
      variables: {
        message,
        userId: uId,
        ticketId: ticketId || _.get(result, "data.createTicket.ticket.id"),
        file: file?.id,
      },
      refetchQueries: ["GET_MESSAGES_TICKET", "GET_TICKETS"],
    }).then(() => {
      setMessage("");
    }).catch(() => {
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    });

    if (!ticketId) {
      setTimeout(() => {
        props.history.goBack();
      }, 200);
    }
  };

  const ticket = _.get(getTicketQuery, "data.ticket");

  return (
    <>
      <PageTitle
        title={ticket?.title ? (
          <div>
            {t("ticket")}
            {" "}
            {getFakeTicketId(ticket.id)}
            {" "}
            |
            {" "}
            {ticket?.title}
          </div>
        ) : t("Ticket.New")}
      />
      <Grid item xs={12} md={7}>
        <Card className={classes.card}>
          <Grid container spacing={4}>
            {ticketId ? (
              <Grid item xs={12} md={12} className={classes.messagesBox}>
                <div className={classes.demo}>
                  <List>
                    {messages?.map((message_, i) => (
                      <ListItem
                        key={i}
                        className={message_?.user_id?.id === uId
                          ? classes.message : classes.messageOther}
                      >
                        <ListItemAvatar>
                          <Avatar className={message_?.user_id?.id === uId
                            ? classes.avatar : classes.avatarOther}
                          >
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={message_?.user_id?.id === uId ? t("YOU")
                            : isCustomer ? t("Admin") : t("User")}
                          secondary={message_.message}
                          className={message_?.user_id?.id === uId
                            ? classes.text : classes.textOther}
                        />
                        <div className={classes.date}>{`${dateIsoFa2(message_?.created_at)} ${timeIsoFa(message_?.created_at)}`}</div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Grid>
            ) : "" }

            <Grid item xs={12} md={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                  {!ticketId ? (
                    <InputBox
                      name="title"
                      value={title}
                      setValue={setTitle}
                      grid={11}
                    />
                  ) : "" }
                  <InputBox
                    name="message"
                    value={message}
                    setValue={setMessage}
                    grid={12}
                    rows={3}
                  />
                  {/* <InputFile
              // multiple="false"
                    name="file"
                    file={file}
                    setFile={setFile}
                    grid={11}
                    required
                    mode="singleImage"
                  /> */}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleSave}
                    className={classes.button}
                    type="submit"
                    variant="contained"
                  >
                    {t("Submit")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default Ticket;
