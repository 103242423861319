import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card, CardContent, CardHeader, Grid,
} from "@material-ui/core";
import _ from "lodash";
import React, { useMemo } from "react";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import PageTitle from "../../components/PageTitle";
import { GET_USER, UPDATE_PASSWORD_USER } from "./usersQueries";

import "./index.scss";
import InputBox from "../../components/micro/InputBox";

const validationSchema = yup.object({
  passwordNew: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("passwordNew is required"),
  passwordNewRepeat: yup
    .string()
    .when("passwordNew", {
      is: (val) => (!!(val && val.length > 0)),
      then: yup.string().oneOf(
        [yup.ref("passwordNew")],
        "Both password need to be the same",
      ),
    }),
});

const UserDetail = (props: any) => {
  const { t } = useTranslation();
  const params = useParams();
  const userId = _.get(params, "id");
  const userQuery = useQuery(GET_USER, { variables: { id: Number(userId) } });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [muUpdatePasswordUser] = useMutation(UPDATE_PASSWORD_USER, {
    refetchQueries: [],
  });

  const user = useMemo(() => {
    let u = userQuery.data?.user;
    if (u) {
      u = { ...u };
      if (u?.__typename) delete u.__typename;
      return {
        ...u,
        role: u.role.name,
      };
    }
    return {};
  }, [userQuery.data]);

  const formik = useFormik({
    initialValues: {
      passwordNew: "",
      passwordNewRepeat: "",
    },
    validationSchema,
    onSubmit: (values) => {
      muUpdatePasswordUser({
        variables: {
          userId,
          ...values,
        },
        refetchQueries: [],
      }).then((r) => {
        const data = r.data?.updateUser;
        if (data) {
          enqueueSnackbar(t("SuccessfullyUpdated"), {
            variant: "success",
          });
          formik.resetForm();
        } else {
          enqueueSnackbar("ErrorHappened", {
            variant: "error",
          });
        }
      });
    },
  });
  const { passwordNew, passwordNewRepeat } = formik.values;

  return (
    <div className="user-detail">
      <Grid container>
        <Grid item xs={6}>
          <PageTitle
            title={`${t("User")}#${(user.id)}`}
          />
        </Grid>
        <Grid item xs={6} className="link-back">
          {/* <Link to={`/request/${values.contract}`}><ArrowBack /></Link> */}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={12} lg={6}>
          <Card>
            <CardHeader title={t("UserInfo")} />
            <CardContent>
              <table className="user-detail__table">
                {_.keys(user).map((key) => (
                  <tr>
                    <td>{t(key)}</td>
                    <td>{user[key]}</td>
                  </tr>
                ))}
              </table>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} lg={6}>
          <Card>
            <CardHeader title={t("ResetPassword")} />
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                  <InputBox
                    name="passwordNew"
                    value={passwordNew}
                    grid={12}
                    handler="formik"
                    formikI={formik}
                    type="password"
                    required
                  />
                  <InputBox
                    name="passwordNewRepeat"
                    value={passwordNewRepeat}
                    grid={12}
                    handler="formik"
                    formikI={formik}
                    type="password"
                    required
                  />
                  <Grid item xs={12} md={12}>
                    <Button
                      type="submit"
                      variant="contained"
                    >
                      {t("SubmitEdit")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserDetail;
