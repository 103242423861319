import axios, { Method } from "axios";
import { API_SERVER } from "../../Config";

// const baseUrl = "http://localhost:1337/";
const baseUrl = `${API_SERVER()}/`;

const call = (method: Method, url: string, data: Object) => {
  axios({
    method,
    url,
    baseURL: baseUrl,
    data,
  });
};

const API = {
  call,
};

export default API;
