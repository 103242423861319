/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import momentTz from "moment-timezone";
import moment from "moment-jalaali";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { useFormik } from "formik";
import { GET_ME } from "../../app/layout/layoutQueries";
import {
  GET_APP_CONFIG, GET_USER,
  PROFILE_UPDATE,
  UPDATE_PASSWORD,
} from "./profileQueries";
import InputBox from "../../components/micro/InputBox";
import {
  dateTimeIso8061_, mutateI,
} from "../../app/utils/helper";
import SelectBox from "../../components/micro/SelectBox";
import { AppContext } from "../../app/layout/AppContext_";
import SystemSetting from "./SystemSetting";
import TimeSheet from "./TimeSheet";

momentTz.tz.setDefault("Asia/Tehran");

const langOptions = [
  {
    label: "Persian",
    value: "FA",
  }, {
    label: "English",
    value: "EN",
  },
];

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {},
  buttonLine: { marginTop: 15, height: 38 },
  card: {
    padding: theme.spacing(5),
    minHeight: "60vh",
  },
  wrap: {
    padding: 0,
  },
  rowPhoneRtl: {
    display: "flex",
    "&>div:first-child": {
      width: "100px",
      direction: "rtl",
    },
    "&>div:nth-child(2)": {
      width: "100%",
      direction: "rtl",
    },
  },
  rowPhone: {
    display: "flex",
    "&>div:first-child": {
      width: "150px",
      direction: "ltr",
    },
    "&>div:nth-child(2)": {
      width: "100%",
      direction: "ltr",
    },
  },
}));

const nTime = dateTimeIso8061_(moment());

const validationSchema = yup.object({
  passwordOld: yup
    .string()
    .required("PasswordOld is required"),
  passwordNew: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("passwordNew is required"),
  passwordNewRepeat: yup
    .string()
    .when("passwordNew", {
      is: (val) => (!!(val && val.length > 0)),
      then: yup.string().oneOf(
        [yup.ref("passwordNew")],
        "Both password need to be the same",
      ),
    }),
});

const Profile = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [name, setName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [lang, setLang] = useState<string>();
  const [language, setLanguage] = useState<string>("fa");
  const [codeCountry, setCodeCountry] = useState<string>("+98");
  const [nameConsultant, setNameConsultant] = useState<string>();
  const [open, setOpen] = useState<boolean>();
  const [startTime, setStartTime] = useState<string>(null);
  const [endTime, setEndTime] = useState<string>(null);
  const [consultantType, setConsultantType] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const appContext = useContext(AppContext);
  const isRtl = appContext.isRTL;

  const [muUpdatePassword] = useMutation(UPDATE_PASSWORD, {
    refetchQueries: [],
  });

  const formik = useFormik({
    initialValues: {
      passwordOld: "",
      passwordNew: "",
      passwordNewRepeat: "",
    },
    validationSchema,
    onSubmit: (values) => {
      muUpdatePassword({
        variables: {
          ...values,
        },
        refetchQueries: [],
      }).then((r) => {
        const data = r.data?.updatePassword;
        if (data.message) {
          enqueueSnackbar(t(data.message?.id || "ErrorHappened"), {
            variant: "error",
          });
        } else if (data) {
          enqueueSnackbar(t("SuccessfullyUpdated"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("UpdatePasswordHasError"), {
            variant: "error",
          });
        }
      });
    },
    // validateOnChange: true,
  });
  const { passwordNew, passwordOld, passwordNewRepeat } = formik.values;

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  const isAdmin = role === "Authenticated";
  const uId = _.get(Me, "data.me.id");
  useEffect(() => {
    if (!isAdmin) {
      setTab(2);
    }
  }, [isAdmin]);

  const MeInfo = useQuery(GET_USER, { variables: { uId: Number(uId) } });
  const ConfigApp = useQuery(GET_APP_CONFIG);

  const mData = MeInfo.data;

  const [muProfileUpdate] = useMutation(PROFILE_UPDATE, {
    refetchQueries: [],
  });

  useEffect(() => {
    if (mData) {
      setLoading(true);
      setName(mData.user.name);
      setPhone(mData.user.phone);
      setEmail(mData.user.email);
      setLang(mData.user.lang || "FA");
      setCodeCountry(mData.user.country || "+98");
      setTimeout(() => setLoading(false), 100);
    }
  }, [MeInfo.data]);

  const handleSave = (e) => {
    e.preventDefault();
    mutateI(() => muProfileUpdate({
      variables: {
        name,
        email,
        phone,
        lang,
        uId,
        country: codeCountry,
      },
      refetchQueries: ["GET_USER", "GET_ME"],
    }).then(() => {
      if (lang !== mData.user.lang) {
        window.location.reload();
      }
    }), t, enqueueSnackbar, "SuccessfullyUpdated");
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const changePassword = () => (
    <Grid item md={6} xs={12}>
      <h3>{t("ChangePassword")}</h3>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <InputBox
            name="passwordOld"
            value={passwordOld}
            grid={12}
            handler="formik"
            formikI={formik}
            type="password"
          />
          <InputBox
            name="passwordNew"
            value={passwordNew}
            grid={12}
            handler="formik"
            formikI={formik}
            type="password"
          />
          <InputBox
            name="passwordNewRepeat"
            value={passwordNewRepeat}
            grid={12}
            handler="formik"
            formikI={formik}
            type="password"
          />
          <Grid item xs={12} md={12}>
            <Button
            // onClick={handleSave}
              className={classes.button}
              type="submit"
              variant="contained"
            >
              {t("SubmitEdit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );

  return (
    <>
      {!loading
        ? (
          <>
            <>
              <Tabs value={tab} onChange={handleChange}>
                {isAdmin ? (<Tab label={t("Profile.ConsultantTime")} key={0} />
                ) : null }
                {isAdmin ? (
                  <Tab label={t("Profile.Settings")} key={1} />
                ) : null }

                <Tab label={t("Profile.Security")} value={2} />
              </Tabs>
              {isAdmin ? (
                <TabPanel value={tab} index={0}>
                  <TimeSheet />
                </TabPanel>
              ) : null }
              {isAdmin ? (
                <TabPanel value={tab} index={1}>
                  <Card className={classes.card}>
                    <SystemSetting />
                  </Card>
                </TabPanel>
              ) : null }

              <TabPanel value={tab} index={2}>
                <Card className={classes.card}>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <h3>{t("ProfileInfo")}</h3>
                      <Grid container spacing={4}>
                        <Grid
                          item
                          xs={12}
                          md={8}
                          className={isRtl ? classes.rowPhoneRtl : classes.rowPhone}
                        >
                          <InputBox
                            name="codeCountry"
                            value={codeCountry}
                            setValue={setCodeCountry}
                          />
                          <InputBox
                            name="phone"
                            value={phone}
                            setValue={setPhone}
                          />
                        </Grid>

                        <InputBox
                          name="name"
                          value={name}
                          setValue={setName}
                          grid={6}
                        />
                        <InputBox
                          name="email"
                          value={email}
                          setValue={setEmail}
                          grid={6}
                        />

                        <Grid item xs={12} md={12}>
                          <SelectBox
                            name="lang"
                            value={lang}
                            setValue={setLang}
                            items={langOptions}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Button
                            onClick={handleSave}
                            className={classes.button}
                            type="submit"
                            variant="contained"
                          >
                            {t("SubmitEdit")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {changePassword()}
                  </Grid>
                </Card>
              </TabPanel>
            </>
          </>
        )
        : ""}

    </>
  );
};

interface TabPanelProps {
  children: any;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default Profile;
