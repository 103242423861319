import React from "react";
import LoadingImage from "../../assets/images/loading.svg";

const LoadingApp = () => {
  const a = 1;
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <img style={{ margin: "auto" }} src={LoadingImage} alt="logo" className="logo" />
    </div>
  );
};

export default LoadingApp;
