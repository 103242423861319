import { gql } from "@apollo/client";

export const GET_MESSAGES = gql`  
query GET_MESSAGES($limit: Int, $sort: String, $start: Int, $seen: Boolean) {
  messages(
    start: $start,
    sort: $sort,
    limit: $limit,
    where: {
      to_user_id: {
        id_null: true
      },
      seen: $seen
    }
  ){
    id
    created_at
    title
    body
    type
    seen
    request {
      id
      name
      family
    }
    evaluation {
      id
      name
      family
    }
    user_id {
      role {
        type
        name
      }
    }
  }

  messagesAggregate(
      sort: $sort
      limit: $limit
      start: $start
      where: {
        to_user_id: {
          id_null: true
        },
        seen: $seen
      }
  )
}
`;

export const GET_MESSAGES_CUSTOMER = gql`  
query GET_MESSAGES_CUSTOMER($sort: String, $uId: String) {
  messages(
    sort: $sort,
    where: {
      # request: {
      #   id_null: false
      #   userId:{
      #     id: $uId
      #   }
      # }
      to_user_id: {
        id: $uId
      }
      # user_id :{
      #   role :{
      #     type: "customer"
      #   }
      # }
    }
  ){
    id
    created_at
    title
    body
    type
    seen
    request{
      id
      name
    }
    user_id {
      role {
        type
        name
      }
    }
  }
}
`;

export const GET_MESSAGE = gql`  
query GET_MESSAGE($id: ID!) {
  message(id:$id){
    id
    created_at
    title
    body
    type
    seen
    request{
      id
      name
    }
  }
}
`;

export const UPDATE_MESSAGE = gql`
mutation UPDATE_MESSAGE($mId: ID!){
  updateMessage(input: {
    where: {
      id:$mId
    }
    data: {
      seen: true
    }
  }){
    message {
      id
    }
  }
}
`;

export default {};
