import React from "react";

import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

// components
import Layout from "./app/layout/default";

import Error from "./pages/Error/Error";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Forgot/Forgot";

import "./App.scss";

const App = () => {
  const isAuthenticated = (params) => {
    const queryParams = params.location.search.split("&");
    const [token, lang] = queryParams;
    const hasToken = token.includes("token");
    if (hasToken) {
      // Call When Login
      const token_ = token.replace("?token=", "");
      const lang_ = lang.replace("lang=", "");

      localStorage.setItem("token", token_);
      localStorage.setItem("lang", lang_);

      return true;
    }
    if (localStorage.getItem("token")) return true;
    return false;
    // eslint-disable-next-line react/destructuring-assignment
  };

  const PrivateRoute = (params: any) => {
    const { component, ...rest } = params;

    // must be call api me and store in state global
    return (
      <Route
        {...rest}
        render={(props) => (isAuthenticated(params) ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              // state: {
              //   from: props.location,
              // },
            }}
          />
        ))}
      />
    );
  };

  const PublicRoute = (params: any) => {
    const { component, ...rest } = params;

    return (
      <Route
        {...rest}
        render={(props) => (isAuthenticated(params) ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          React.createElement(component, props)
        ))}
      />
    );
  };

  return (
    <Router>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        {/* <PublicRoute path="/register" component={Register} /> */}
        <PublicRoute path="/forgot" component={ForgotPassword} />
        <PrivateRoute path="/" component={Layout} />
        <Route path="*" component={Error} />
      </Switch>
    </Router>
  );
};

export default App;
