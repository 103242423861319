import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card, Checkbox, Grid,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { useTranslation } from "react-i18next";
import InputBox from "../../../components/micro/InputBox";
import PageTitle from "../../../components/PageTitle";
import Notification, { INotification } from "../../../components/micro/SnackbarMicro";
import { CREATE_MESSAGE_TEMPLATE, GET_MESSAGE_TEMPLATE, UPDATE_MESSAGE_TEMPLATE } from "../messagesQueries";
import InputEditor2 from "../../../components/micro/InputEditor2";

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {},
  card: {
    padding: theme.spacing(5),
  },
  codeWrap: {
    border: "1px solid #eee",
    padding: "10px",
  },
  code: {
    margin: "0 10px",
    textAlign: "right",
    direction: "rtl",
    "&>b": {
      backgroundColor: "#eee",
      marginLeft: "10px",
      padding: "5px",
      borderRadius: "5px",
    },
  },
}));

const MessageTemplate = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [title, setTitle] = useState<string>();
  const [titleEn, setTitleEn] = useState<string>();
  const [messageEmailPersian, setMessageEmailPersian] = useState<string>();
  const [messageEmailEnglish, setMessageEmailEnglish] = useState<string>();
  const [messageSms, setMessageSms] = useState<string>();
  const [messageSmsTemplateId, setMessageSmsTemplateId] = useState<string>();
  const [messageSmsTemplateKey, setMessageSmsTemplateKey] = useState<string>();
  const [serverSide, setServerSide] = useState<boolean>();

  const [notif, setNotif] = useState<INotification>();
  const currentId = _.get(props, "match.params.id");

  const { data, loading, refetch } = useQuery(GET_MESSAGE_TEMPLATE, {
    skip: !currentId,
    variables: { id: Number(currentId) },
  });
  const [create, responseCreate] = useMutation(CREATE_MESSAGE_TEMPLATE, {
    refetchQueries: ["GET_MESSAGE_TEMPLATE", "GET_MESSAGE_TEMPLATES"],
  });
  const [update, responseUpdate] = useMutation(UPDATE_MESSAGE_TEMPLATE, {
    refetchQueries: ["GET_MESSAGE_TEMPLATE", "GET_MESSAGE_TEMPLATES"],
  });

  const msg = data?.messageTemplate;

  useEffect(() => {
    if (data) {
      setTitle(msg.title);
      setTitleEn(msg.title_en);
      setMessageEmailPersian(msg.message_email);
      setMessageEmailEnglish(msg.message_email_en);
      setMessageSms(msg.message_sms);
      setMessageSmsTemplateId(msg.message_sms_template_id);
      setMessageSmsTemplateKey(msg.key);
      setServerSide(msg.server_side);
    }
  }, [data]);

  const handleSave = () => {
    if (currentId) {
      update({
        variables: {
          id: currentId,
          title,
          titleEn,
          messageEmailPersian,
          messageEmailEnglish,
          messageSms,
          messageSmsTemplateId,
          key: messageSmsTemplateKey,
          serverSide,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyUpdated", type: "success", setOpen: setNotif,
        });
        setTimeout(() => props.history.goBack(), 1000);
      }).catch((error) => {
        setNotif({
          open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
        });
      });
    } else {
      create({
        variables: {
          title,
          titleEn,
          messageEmailPersian,
          messageEmailEnglish,
          messageSms,
          messageSmsTemplateId,
          key: messageSmsTemplateKey,
          serverSide,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyCreated", type: "success", setOpen: setNotif,
        });
        setTimeout(() => props.history.goBack(), 1000);
      }).catch((error) => {
        setNotif({
          open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
        });
      });
    }
  };

  return (
    <>
      <PageTitle
        title={currentId ? t("MessageTemplate.Edit") : t("MessageTemplate.New")}
      />
      {!loading ? (
        <Card className={classes.card}>
          <Grid container spacing={4}>
            <InputBox
              name="title"
              value={title}
              setValue={setTitle}
              grid={6}
            />

            <InputBox
              name="titleEn"
              value={titleEn}
              setValue={setTitleEn}
              grid={6}
            />

            <Grid item xs={12} md={12}>
              <div className={classes.codeWrap}>
                <div>
                  متغیرهای قابل استفاده:
                </div>
                <div className={classes.code}>
                  <b>%GENDER%</b>
                  <b>%NAME% </b>
                  <b>%REQUEST_ID%</b>
                  <b>%LINK_REQUEST%</b>
                </div>
                <p>
                  برای مثال:
                  دوست گرامی
                  <b>%GENDER%</b>
                  {" "}
                  <b>%NAME% </b>
                  درخواست شما
                  با شماره
                  {" "}
                  <b>%REQUEST_ID%</b>
                  {" "}
                  توسط کارشناس مربوطه بررسی و تایید شد،
                  <br />
                  لینک پیگیری: %LINK_REQUEST%
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputEditor2
                label="messageEmailPersian"
                value={msg?.message_email}
                setValue={setMessageEmailPersian}
                dir="rtl"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputEditor2
                label="messageEmailEnglish"
                value={msg?.message_email_en}
                setValue={setMessageEmailEnglish}
                dir="ltr"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputBox
                name="messageSms"
                value={messageSms}
                setValue={setMessageSms}
                grid={12}
                rows={3}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <InputBox
                name="messageSmsTemplateId"
                value={messageSmsTemplateId}
                setValue={setMessageSmsTemplateId}
                grid={12}
                rows={3}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <InputBox
                name="messageSmsTemplateKey"
                value={messageSmsTemplateKey}
                setValue={setMessageSmsTemplateKey}
                grid={12}
                rows={3}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Checkbox
                name="serverSide"
                checked={!!serverSide}
                onChange={(e) => {
                  setServerSide(e.target.checked);
                }}
              />
              {t("isServerSide")}
            </Grid>

            <Grid item md={12}>
              <Button
                onClick={handleSave}
                className={classes.button}
                type="submit"
                variant="contained"
              >
                {t("Submit")}
              </Button>
            </Grid>
          </Grid>
        </Card>
      ) : ""}
      <Notification {...notif} />
    </>
  );
};

export default MessageTemplate;
