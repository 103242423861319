import React, { useMemo, useState } from "react";
import {
  Button, Fab, Grid, IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import moment from "moment-jalaali";
import _ from "lodash";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import AddIcon from "@material-ui/icons/Add";
import { GET_USERS } from "./usersQueries";
import { GET_ME } from "../../app/layout/layoutQueries";
import TableAdvance from "../../components/micro/TableAdvance";
import InputBox from "../../components/micro/InputBox";
import DialogAddUserManual from "../../components/dialogs/DialogAddUser";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  pendingForSetTimeSession: {
    backgroundColor: "orange",
    border: "1px solid #eee",
    padding: "5px",
    display: "inline",
    borderRadius: "5px",
  },
  fab: {
    position: "fixed",
    right: "50px",
    bottom: "50px",
    zIndex: 200,
  },
}));

export default function Users(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openAddUser, setOpenAddUser] = useState<boolean>(false);

  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  let uId;
  const isCustomer = role === "Customer";
  if (role === "Customer") {
    uId = _.get(Me, "data.me.id");
  }
  const getUsers = useQuery(GET_USERS, {
    variables:
     {
       //  sort: "created_at:desc",
       sort: "id:desc",
       limit: 10,
       start: 0,
       where: undefined,
     },
  });

  // if (loading) return null;
  if (getUsers.error) return `Error! ${getUsers.error}`;

  // TODO Sort by another column
  const dataTableData = useMemo(() => {
    const usersData = _.get(getUsers.data, "usersConnection.values", []);

    return usersData.map((item) => {
      let color;
      return [
        item.id,
        item.name,
        item.phone,
        item.email,
        item.confirmed ? t("yes") : t("no"),
        item.updated_at,
        item.created_at,
        item.id,
      ];
    });
  }, [getUsers.data]);

  const columns = [
    {
      name: t("id"),
      options: {
        filter: true,
        // display: false,
      },
    }, {
      name: t("name"),
      options: {
        filter: true,
      },
    }, {
      name: t("phone"),
      key: "user",
      options: {
        filter: true,
      },
    }, {
      name: t("email"),
      options: {
        filter: true,
      },
    },
    {
      name: t("confirmed"),
      options: {
        filter: true,
      },
    },
    {
      name: t("updated_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    }, {
      name: t("created_at"),
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ direction: "ltr" }}>
            {moment(value).format("jYYYY-jMM-jDD HH:mm")}
          </div>
        ),
      },
    }, {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue, ...els) => (
          <>
            <Link to={`/user/${value}`}>
              <IconButton className="icon-go" size="small" title={t("detail")}>
                <ArrowBack />
              </IconButton>
            </Link>
          </>
        ),
      },
    },
  ];

  const formik = useFormik({
    initialValues: {
      searchText: null,
      id: null,
      name: null,
      phone: null,
      email: null,
    },
    onSubmit: (e) => {
      handleSearch(e);
    },
  });

  const handleSearch = (e: any) => {
    getUsers.refetch({
      where: {
        _q: e.searchText || undefined,
        name_contains: e.name || undefined,
        phone_contains: e.phone || undefined,
        id: e.id || undefined,
        email_contains: e.email || undefined,
      },
    });
  };

  if (isCustomer) {
    return "";
  }
  const totalCount = _.get(getUsers.data, "usersConnection.aggregate.count");

  return (
    <>
      {/* <PageTitle title="Tables" /> */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className="filter-bar">
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} className="filter-bar__container">
                <InputBox
                  handler="formik"
                  name="id"
                  placeholder="id"
                  value={formik.values.id}
                  formikI={formik}
                  grid={2}
                />
                <InputBox
                  handler="formik"
                  name="name"
                  placeholder="name"
                  value={formik.values.name}
                  formikI={formik}
                  grid={2}
                />
                <InputBox
                  handler="formik"
                  name="phone"
                  placeholder="phone"
                  value={formik.values.phone}
                  formikI={formik}
                  grid={2}
                />
                <InputBox
                  handler="formik"
                  name="email"
                  placeholder="email"
                  value={formik.values.email}
                  formikI={formik}
                  grid={2}
                />
                <Grid item xs={1} className="filter-bar__btn">
                  <Button
                    type="submit"
                    variant="contained"
                  >
                    {t("Search")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>

          <TableAdvance
            {...props}
            title="Users"
            data={dataTableData}
            columns={columns}
            itemPath="/user/"
            showFilter={false}
            loading={getUsers.loading}
            serverSide
            totalCount={totalCount}
            query={getUsers}
          />
        </Grid>
      </Grid>
      <Fab
        className="fab-icon"
        onClick={() => setOpenAddUser(true)}
        title={t("NewUser")}
      >
        <AddIcon />
      </Fab>
      <DialogAddUserManual open={openAddUser} setOpen={setOpenAddUser} />
    </>
  );
}
