import { gql } from "@apollo/client";

export const GET_SERVICE_CATEGORY = gql`
query GET_SERVICE_CATEGORY($id: ID!) {
    serviceCategory(
      id: $id
    ){
        id
        name
        name_en
        slug
        slug_en
    }
}
`;

export const CREATE_SERVICE_CATEGORY = gql`
mutation CREATE_SERVICE_CATEGORY(
    $name: String!, 
    $nameEn: String,
    $slug: String,
    $slugEn: String,
    ){
        createServiceCategory(input: {
            data: {
              name: $name
              name_en: $nameEn
              slug: $slug
              slug_en: $slugEn
            }
          }){
            serviceCategory{
              id
            }
          }
    }
`;
export const UPDATE_SERVICE_CATEGORY = gql`
mutation UPDATE_SERVICE_CATEGORY(
    $id: ID!,
    $name: String!, 
    $nameEn: String,
    $slug: String,
    $slugEn: String,
    ){
        updateServiceCategory(input: {
            data: {
              name: $name
              name_en: $nameEn
              slug: $slug
              slug_en: $slugEn
            }
            where: {
                id: $id
            }
          }){
            serviceCategory{
              id
            }
          }
    }
`;

export default {};
