import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { SnackbarProvider } from "notistack";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { Provider } from "react-redux";
import { setContext } from "@apollo/client/link/context";
import { ConfirmProvider } from "material-ui-confirm";
import App from "./App";
import RTL from "./app/layout/RTL";
import "./app/layout/il8n";
import { store } from "./app/store";
import * as serviceWorker from "./serviceWorker";
import { API_SERVER } from "./Config";
import AppContextCore from "./app/layout/AppContext_";

// eslint-disable-next-line new-cap
const httpLink = new createUploadLink({
  uri: `${API_SERVER()}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(
  ({
    graphQLErrors, networkError, operation, forward,
  }) => {
    if (graphQLErrors) {
      console.log("graphQLErrors------>", graphQLErrors);
      // graphQLErrors.forEach(({ message, locations, path }) => console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      // ));
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  },
);

// If you provide a link chain to ApolloClient, you
// don't provide the `uri` option.
const client = new ApolloClient({
  // The `from` function combines an array of individual links
  // into a link chain
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

const renderPages = () => {
  const w = window.innerWidth;
  const b = document.querySelector("body");
  if (w < 1200) {
    b?.classList.remove("zoom80");
    b?.classList.remove("zoom90");
  } else if (w < 1340) {
    b?.classList.add("zoom80");
  } else if (w < 1440) {
    b?.classList.add("zoom90");
  }
};

renderPages();

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AppContextCore>
        <Provider store={store}>
          <RTL>
            <ConfirmProvider>
              <SnackbarProvider maxSnack={3}>
                <App />
              </SnackbarProvider>
            </ConfirmProvider>
          </RTL>
        </Provider>
      </AppContextCore>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
