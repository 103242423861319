import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { CircularProgress, DialogContent, Grid } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { useSnackbar } from "notistack";
import SelectBox from "./micro/SelectBox";
import SelectSearch from "./micro/SelectSearch";
import { GET_MESSAGE_TEMPLATES_BY_STEP } from "../pages/MessageTemplates/messagesQueries";
import InputCheckBox from "./micro/InputCheckBox";
import InputBox from "./micro/InputBox";
import RadioGroupBox from "./micro/RadioGroupBox";
import { STEPS } from "../app/statics";
import "./styles.scss";

import { useContractState } from "../app/hooks";
import { UPDATE_STATE_REQUEST } from "../pages/Request/steps/requestStepAQueries";

type IProps = {
    openDialog?: boolean,
    title: string,
    nextStatus: string,
    handleSubmit: Function,
    handelClose: Function,
}

const STEP_STATUS = {
  acceptedStep: {
    key: "waitUser",
    description: "confirmStatus.forConfirmThisStepAndGoNext",
  },
  rejectedStep: {
    key: "rejected",
    description: "confirmStatus.forRejectThisStepAndGoNext",
  },
  onlySendNotification: {
    key: "sendNotification",
    description: "confirmStatus.onlySendNotificationMessage",
  },
  contractDone: {
    key: "contractDone",
    description: "confirmStatus.contractDoneDescription",
  },
  contractCanceled: {
    key: "contractCanceled",
    description: "confirmStatus.contractCanceledDescription",
  },
};

export default function FormDialogConfirmStepPrime(props: IProps) {
  const { t } = useTranslation();
  const defaultOption = _.keys(STEP_STATUS).map((key) => ({
    label: t(`confirmStatus.${key}`),
    description: STEP_STATUS[key].description,
    value: STEP_STATUS[key].key,
  }));

  const confirmOptions = [
    {
      label: t("yes"),
      value: "1",
    },
    {
      label: t("no"),
      value: "0",
    },
  ];

  const {
    currentActiveIndex,
    reqStatus,
    reqId,
    reqSId,
  } = useContractState();

  const {
    openDialog,
    title,
    handleSubmit,
    nextStatus,
    handelClose,
  } = props;
  const [messageL, setMessageL] = React.useState<string>();
  const [messageTemp, setMessageTemp] = React.useState<string>();
  const [status, setStatus] = React.useState<string>();
  const [activeStep, setActiveStep] = React.useState<string>("0");
  const [currentStep, setCurrentStep] = React.useState<string>();
  const [sendSms, setSendSms] = React.useState<boolean>(true);
  const [sendEmail, setSendEmail] = React.useState<boolean>(true);
  const [sendEnglish, setSendEnglish] = React.useState<boolean>();
  const [messageOptions, setMessageOptions] = React.useState<[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    data: templates, refetch, loading, error,
  } = useQuery(GET_MESSAGE_TEMPLATES_BY_STEP, {
    variables: {
      contract_step_number: `S${currentActiveIndex + 1}`,
    },
  });

  useEffect(() => {
    if (templates?.messageTemplates?.length) {
      const temps = templates.messageTemplates.filter((item) => !item.server_side).map((item) => ({
        value: item.id,
        label: item.title,
      }));
      setMessageOptions(temps);
    }
  }, [templates]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    }
  }, [error]);

  useEffect(() => {
    if (!_.isUndefined(refetch)) {
      if (status === "sendNotification") {
        refetch({
          contract_step_number: "S_REMEMBER",
        });
      } else {
        refetch({
          contract_step_number: `S${currentActiveIndex + 1}`,
        });
      }
    }
  }, [status]);

  const handleClose_ = () => {
    handelClose();
  };

  const handleSave_ = () => {
    let status_ = status;

    if (status === "sendNotification") {
      status_ = undefined;
    }
    handleSubmit({
      notification: {
        sendEmail,
        sendSms,
        sendEnglish,
        messageTemplateId: messageTemp,
        message: messageL,
      },
      status: status_,
      currentStep,
      nextStatus,
    });
    handelClose();
  };

  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openConfirmReject, setOpenConfirmReject] = React.useState(false);

  const [updateRequestState, responseUpdateRequestState] = useMutation(
    UPDATE_STATE_REQUEST,
  );

  const handleSave = () => {
    let status_ = status;

    if (status === "sendNotification") {
      status_ = undefined;
    }

    const notification = {
      sendEmail,
      sendSms,
      sendEnglish,
      messageTemplateId: messageTemp,
      message: messageL,
    };

    if (reqStatus.data?.requestStatuses?.length) {
      let lockSteps = _.get(reqStatus.data, "requestStatuses[0].lock_steps", {});
      if (status === "true") {
        const sId = currentActiveIndex + 1;
        const sIdNext = sId + 1;
        // const sId = statics.StepsStatus[stepNumber].maxStepPermission;
        // const sIdNext = statics.StepsStatus[stepNumber].maxStepPermission + 1;
        lockSteps = { ...lockSteps, [`step${sIdNext}`]: false, [`step${sId}`]: true };
      }

      updateRequestState({
        variables: {
          reqId,
          status: status_,
          current_step: currentStep,
          reqSId,
          lockSteps,
          notification,
        },
        refetchQueries: ["GetRequest", "GET_REQUESTS", "GET_REQUEST_STATUS"],
        awaitRefetchQueries: true,
      }).then((result) => {
        enqueueSnackbar(t("SuccessfullyUpdated"), {
          variant: "success",
        });
        handleClose_();
      }).catch((errord: any) => {
        enqueueSnackbar(errord.message || t("ErrorHappened"), {
          variant: "error",
        });
      });
    } else {
      enqueueSnackbar(t("ErrorHappened"), {
        variant: "error",
      });
    }
  };

  const stepOptions = _.keys(STEPS).map((key, i) => ({
    value: key,
    label: `${t("step")} ${(i + 1)}- ${t(key)}`,
  }));

  return (
    <Dialog
      className="dialog-confirm-step"
      open={openDialog || false}
      maxWidth="sm"
      fullWidth
      onClose={handleClose_}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t(title)}</DialogTitle>
      <DialogContent>
        <div>
          {t("pleaseSelectBetweenOptions")}
        </div>
        <div className="radio-options">
          <RadioGroupBox
            name="status"
            handler="material"
            value={status}
            setValue={setStatus}
            grid={12}
            items={defaultOption}
            required
            hideLabel
          />
        </div>
        {status === STEP_STATUS.rejectedStep.key ? (
          <InputBox
            name="descriptionReject"
            value={messageL}
            setValue={setMessageL}
            grid={12}
            rows={3}
            required
          />
        ) : "" }

        {status && status !== STEP_STATUS.onlySendNotification.key ? (
          <fieldset className="fieldset-normal">
            <legend>{t("settingStep")}</legend>
            <p className="message_active">
              {t("doesCurrentStepBeActiveOrOther")}
              <RadioGroupBox
                name="activeStep"
                handler="material"
                value={activeStep}
                setValue={setActiveStep}
                grid={4}
                items={confirmOptions}
                required
                row
                hideLabel
                size="small"
              />
            </p>
            {activeStep === "1" ? (
              <>
                <SelectBox
                  name="step"
                  value={currentStep}
                  setValue={setCurrentStep}
                  grid={12}
                  items={stepOptions}
                />
                <br />
              </>
            ) : false}
          </fieldset>
        ) : false}

        <fieldset className="fieldset-normal">
          <legend>{t("settingNotifications")}</legend>
          <p>
            {t("PleaseSelectMessageNotice")}
          </p>
          <SelectSearch
            name="message"
            value={messageTemp}
            setValue={setMessageTemp}
            grid={12}
            items={messageOptions}
            loading={loading}
            handleSearch={() => ({})}
            required
          />

          <Grid container>
            <InputCheckBox
              name="sendSms"
              label="sendSms"
              value={sendSms}
              setValue={setSendSms}
              grid={4}
            />
            <InputCheckBox
              name="sendEmail"
              label="sendEmail"
              value={sendEmail}
              setValue={setSendEmail}
              grid={4}
            />
          </Grid>
        </fieldset>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose_} variant="outlined" color="secondary">
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          className="button-primary"
          disabled={!messageTemp || !status || (status === "rejected" && !messageL)}
          endIcon={responseUpdateRequestState.loading
            ? <CircularProgress size="18px" style={{ color: "#fff" }} /> : undefined}
        >
          {t("SubmitEnd")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormDialogConfirmStepPrime.defaultProps = {
  openDialog: false,
};
