import React from "react";
import { Box, CircularProgress as LoadingIcon } from "@material-ui/core";

const Loader = () => {
  const a = 1;
  return (
    <Box
      component="span"
      style={{
        display: "flex", justifyContent: "center", alignItems: "center", height: "250px",
      }}
    >
      <LoadingIcon />
    </Box>
  );
};

export default Loader;
