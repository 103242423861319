import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card, Grid,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { useTranslation } from "react-i18next";
import InputBox from "../../../components/micro/InputBox";
import PageTitle from "../../../components/PageTitle";
import Notification, { INotification } from "../../../components/micro/SnackbarMicro";
import {
  CREATE_ATTACHMENT, GET_ATTACHMENT, UPDATE_ATTACHMENT,
} from "../attachmentsQueries";
import SelectBox from "../../../components/micro/SelectBox";
import InputFile from "../../../components/micro/InputFile";

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {},
  card: {
    padding: theme.spacing(5),
  },
}));

const Attachment = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [title, setTitle] = useState<string>();
  const [titleEn, setTitleEn] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [descriptionEn, setDescriptionEn] = useState<string>();
  const [type, setType] = useState<string>();
  const [typeDisplay, setTypeDisplay] = useState<string>("DEFAULT");
  const [sampleFile, setSampleFile] = useState<any>();
  const [notif, setNotif] = useState<INotification>();
  const currentId_ = _.get(props, "match.params.id");
  const [currentId, setCurrentId] = useState(currentId_);

  const { data, loading, refetch } = useQuery(GET_ATTACHMENT, {
    skip: !currentId,
    variables: { id: Number(currentId) },
  });
  const [create, responseCreate] = useMutation(CREATE_ATTACHMENT, {
    refetchQueries: [
      "GetAttachments",
    ],
  });
  const [update, responseUpdate] = useMutation(UPDATE_ATTACHMENT, {
    refetchQueries: [
      "GetAttachments", "GetATTACHMENT",
    ],
  });

  useEffect(() => {
    if (data) {
      const { attachment } = data;
      setTitle(attachment.title);
      setTitleEn(attachment.title_en);
      setDescription(attachment.description);
      setTypeDisplay(attachment.type || "DEFAULT");
      setType(attachment.type_document);
      setSampleFile(attachment.sample_file);
      setDescriptionEn(attachment.description_en);
    }
  }, [loading]);

  const typeDisplayOptions = [{
    label: t("DEFAULT"),
    value: "DEFAULT",
  }, {
    label: t("LINK"),
    value: "LINK",
  }, {
    label: t("ONLY_ADMIN"),
    value: "ONLY_ADMIN",
  }];

  const typeOptions = [{
    label: t("documentType.Finance"),
    value: "FINANCE",
  }, {
    label: t("documentType.Education"),
    value: "EDUCATION",
  }, {
    label: t("documentType.Authorization"),
    value: "AUTH",
  }, {
    label: t("documentType.OTHER"),
    value: "OTHER",
  }];

  const handleSave = () => {
    if (currentId) {
      update({
        variables: {
          id: currentId,
          title,
          titleEn,
          description,
          descriptionEn,
          type: typeDisplay,
          type_document: type,
          sampleFile: sampleFile?.id,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyUpdated", type: "success", setOpen: setNotif,
        });
        setTimeout(() => props.history.goBack(), 1000);
      }).catch((error) => {
        setNotif({
          open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
        });
      });
    } else {
      create({
        variables: {
          title,
          titleEn,
          description,
          descriptionEn,
          type: typeDisplay,
          type_document: type,
          sampleFile: sampleFile?.id,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyCreated", type: "success", setOpen: setNotif,
        });
        setCurrentId(response.data.createAttachment.attachment.id);
        setTimeout(() => {
          props.history.goBack();
        }, 1000);
      }).catch((error) => {
        setNotif({
          open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
        });
      });
    }
  };

  return (
    <>
      <PageTitle
        title={currentId_ ? t("Attachment.Edit") : t("Attachment.New")}
      />
      {!loading ? (
        <Card className={classes.card}>
          <Grid container spacing={4} xs={12} md={8}>
            <InputBox
              name="title"
              value={title}
              setValue={setTitle}
              grid={6}
            />
            <InputBox
              name="titleEn"
              value={titleEn}
              setValue={setTitleEn}
              grid={6}
            />

            <InputBox
              name="description"
              value={description}
              setValue={setDescription}
              grid={6}
              rows={3}
            />
            <InputBox
              name="descriptionEn"
              value={descriptionEn}
              setValue={setDescriptionEn}
              grid={6}
              rows={3}
            />

            <SelectBox
              name="typeDocument"
              value={type}
              setValue={setType}
              grid={6}
              items={typeOptions}
            />

            <SelectBox
              name="typeDisplay"
              value={typeDisplay}
              setValue={setTypeDisplay}
              grid={6}
              items={typeDisplayOptions}
            />

            <InputFile
              // multiple="false"
              name="sample_file"
              file={sampleFile}
              setFile={setSampleFile}
              grid={6}
              required
              mode="singleImage"
            />

            <Grid item xs={12}>
              <Button
                onClick={handleSave}
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
                // style={{ width: "100px" }}
              >
                {currentId_ ? t("SaveChanges") : t("Submit")}
              </Button>
            </Grid>
          </Grid>
        </Card>
      ) : ""}
      <Notification {...notif} />
    </>
  );
};

export default Attachment;
