import jMoment from "moment-jalaali";
import React from "react";
import JalaliUtils from "@date-io/jalaali";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import {
  Grid,
} from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import { getGrid } from "../../app/utils/helper";

jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: false });

type IProps = {
    name: string,
    value: string | null,
    grid?: number,
    required?: boolean,
    label?: boolean,
    setValue?: Function,
    minDate?: string,
    handler?: "formik" | "material",
    formikI?: any,
    maxDate?: string,
    views?: string
}
function DatePickerComponent(props: IProps) {
  const { t } = useTranslation();
  const {
    name, value, setValue, grid, required,
    label, minDate,
    handler,
    formikI,
    maxDate,
    views,
  } = props;

  let views_;
  let openTo_;
  if (views === "bth") {
    views_ = ["year", "date"];
    openTo_ = "year";
  }

  let error_ = true;
  if (handler === "formik") {
    error_ = formikI.touched[name] && Boolean(formikI.errors[name]);
  } else {
    error_ = (required && (value !== undefined) && !value);
  }

  const content = () => (
    <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
      <KeyboardDatePicker
        className="date-picker-ms"
        name={name}
        clearable
        okLabel="تأیید"
        cancelLabel="لغو"
        clearLabel="پاک کردن"
        labelFunc={(date) => (date ? date.format("jYYYY/jMM/jDD") : "")}
        value={value || null}
        onChange={(e:any) => {
          if (handler !== "formik") {
            if (_.isFunction(setValue)) {
              if (_.isString(e?._i) && e._i.includes("0000-01-01")) {
                let x = e._i.replace("0000-01-01-", "");
                x = jMoment(x, "jYYYY/jMM/jDD").locale("en").format();
                setValue(x);
              } else {
                setValue(e);
              }
            }
          }
          if (handler === "formik") {
            if (_.isString(e?._i) && e._i.includes("0000-01-01")) {
              const x = e._i.replace("0000-01-01-", "");
              const d = jMoment(x, "jYYYY/jMM/jDD").locale("en").format("YYYY-MM-DD");
              formikI.setFieldValue(name, d);
            } else {
              formikI.handleChange(moment(e).locale("en").format("YYYY-MM-DD"));
              formikI.setFieldValue(name, moment(e).locale("en").format("YYYY-MM-DD"));
            }
          }
        }}
        format="YYYY/MM/DD"
        fullWidth
        required={required}
        label={label && t(name)}
        inputVariant="outlined"
        variant="inline"
        minDate={minDate}
        maxDate={maxDate}
        views={views_}
        style={{ direction: "ltr" }}
        openTo={openTo_}
        error={error_}
        size="small"
      />
    </MuiPickersUtilsProvider>
  );
  if (grid) {
    const [xs_, md_, lg_] = getGrid(grid);

    return (
      <Grid
        item
        md={md_}
        xs={xs_}
        lg={lg_}
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        {content()}
      </Grid>
    );
  }
  return (
    <>
      {content()}
    </>
  );
}

DatePickerComponent.defaultProps = {
  grid: 0,
  required: false,
  label: true,
  minDate: undefined,
  handler: "material",
  formikI: undefined,
  setValue: undefined,
  maxDate: undefined,
  views: undefined,
};

export default DatePickerComponent;
