import { gql } from "@apollo/client";

const LOGIN_USER = gql`
mutation LoginUser($username: String!, $password: String!) {
 login(input: {identifier: $username, password: $password}) {
   jwt
   user {
     id
     username
   }
 }
}
`;

const CHECK_USER = gql`
mutation CheckUser($username: String){
 checkExistUser(username: $username)
}
`;

export {
  LOGIN_USER,
  CHECK_USER,
};
