import { gql } from "@apollo/client";

export const GET_CATEGORY = gql`
query GetCategory($id: ID!) {
    category(
      id: $id
    ){
        id
        name
        name_en
        slug
        slug_en
    }
}
`;

export const CREATE_CATEGORY = gql`
mutation CREATE_CATEGORY(
    $name: String!, 
    $nameEn: String,
    $slug: String,
    $slugEn: String,
    ){
        createCategory(input: {
            data: {
              name: $name
              name_en: $nameEn
              slug: $slug
              slug_en: $slugEn
            }
          }){
            category{
              id
            }
          }
    }
`;
export const UPDATE_CATEGORY = gql`
mutation UPDATE_CATEGORY(
    $id: ID!,
    $name: String!, 
    $nameEn: String,
    $slug: String,
    $slugEn: String,
    ){
        updateCategory(input: {
            data: {
              name: $name
              name_en: $nameEn
              slug: $slug
              slug_en: $slugEn
            }
            where: {
                id: $id
            }
          }){
            category{
              id
            }
          }
    }
`;

export default {};
