import { combineReducers } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import { userSlice } from "../features/user/userSlice";

const rootReducers = combineReducers({
  counter: counterReducer,
  user: userSlice.reducer,
});

export default rootReducers;
