import { gql } from "@apollo/client";

export const GET_USER = gql`  
query GET_USER($uId: ID!) {
    user(id: $uId){
        username
        email
        name
        phone
        last_login_time
        lang
        country
    }
  }
`;

export const ADD_CONSULTANT_TIME = gql`
mutation ADD_CONSULTANT_TIME(
  $name: String!, 
  $nameEn: String,
  $startTime: DateTime!,
  $endTime: DateTime!,
  ) {
  createConsultantTime(input: {
    data: {
      name: $name,
      name_en: $nameEn
      start_time: $startTime
      end_time: $endTime
    }
  }){
    consultantTime {
      id
    }
  }
}
`;

// # query GET_CONSULTANT_TIMES($nowTime: DateTime) {
// # where: {
//   #   start_time_gte: $nowTime
//   # }
export const GET_CONSULTANT_TIMES = gql`
query GET_CONSULTANT_TIMES($sort: String, $limit: Int, $start: Int, $where: JSON) {
  consultantTimesConnection(sort: $sort, where: $where, start:$start, limit:$limit){
    values{
      id
      name
      name_en
      start_time
      end_time
      user {
        id
        phone
        name
      }
      request_status {
        id
        requestId {
          id
        }
      }
      evaluation {
        id
      }
      consultant_type {
        id
        title
        price
      }
      is_online
      is_selectable
    }
    aggregate {
      count
      totalCount
    }
  }
}
`;

export const GET_CONSULTANT_TIMES_FOR_ALERT = gql`
query GET_CONSULTANT_TIMES_FOR_ALERT($where: JSON) {
  consultantTimesConnection(where: $where){
    aggregate {
      count
      totalCount
    }
  }
}
`;

export const GET_CONSULTANT_TIMES_ON_REQUEST = gql`
query GET_CONSULTANT_TIMES_ON_REQUEST($nowTime: DateTime) {
  consultantTimes(
      sort: "start_time:asc"
      where: {
        start_time_gte: $nowTime
      }
    ){
    id
    name
    name_en
    start_time
    end_time
    user {
      id
      phone
      name
    }
    evaluation {
      id
      # requestId {
      #   id
      # }
    }
  }
}
`;

export const GET_CONSULTANT_TIMES_ON_TODO_LIST = gql`
query GET_CONSULTANT_TIMES_ON_TODO_LIST($nowTime: DateTime, $endToday: DateTime) {
  consultantTimes(
      sort: "start_time:ASC"
      where: {
        start_time_gte: $nowTime
        end_time_lte: $endToday
        evaluation: {
          id_null: false
          status: "WAITING_MEETING"
        }
      }
    ){
    id
    name
    name_en
    start_time
    end_time
    user {
      id
      phone
      name
    }
    request_status {
      id
      requestId {
        id
        name
        family
      }
    }
    evaluation {
      id
      name
      family
      meeting_link
      meeting_link_support
    }
  }
}
`;

export const PROFILE_UPDATE = gql`
mutation PROFILE_UPDATE(
  $lang: ENUM_USERSPERMISSIONSUSER_LANG,
  $phone: String, 
  $email: String, 
  $name: String,
  $country: String,
  $uId: ID!,
  ) {
  updateUser(input: {
    data: {
      lang: $lang
      phone: $phone
      email: $email
      country: $country
      name: $name
    },
    where: {
      id: $uId
    }
  }){
    user{
      id
    }
  }
}
`;

export const UPDATE_PASSWORD = gql`
mutation UPDATE_PASSWORD(
  $passwordOld: String!,
  $passwordNew: String!,
  $passwordNewRepeat: String!,
  ) {
  updatePassword(passwordNew:$passwordNew, passwordNewConfirm: $passwordNewRepeat, passwordOld:$passwordOld)
}
`;

export const UPDATE_CONFIG_APP = gql`
mutation UPDATE_CONFIG_APP(
  $rateCouncil: String,
  ) {
  updateConfigApp(input: {
    data: {
      rateCouncil: $rateCouncil
    }
  }){
    configApp {
      id
    }
  }
}
`;

export const GET_APP_CONFIG = gql`
query GET_APP_CONFIG{
  configApp{
    id
    rateCouncil
  }
}`;

export const GET_USERS = gql`
query GET_USERS($role: Int){
  users(where: {
    role: $role
  }){
    id
    name
    username
    phone
    email
  }
}`;

export const ADD_CONSULTANT_TIME_BATCH = gql`
mutation ADD_CONSULTANT_TIME_BATCH(
  $name: String!, 
  $nameEn: String,
  $startTime: String!,
  $endTime: String!,
  $dates: [String],
  $userId: String!,
  $consultantType: ID!,
  $isOnline: Boolean,
  $isSelectable: Boolean,
  ) {
  createBatchConsultantTimes(
      name: $name,
      name_en: $nameEn
      start: $startTime
      end: $endTime
      dates: $dates
      user: $userId
      consultant_type: $consultantType
      is_online: $isOnline
      is_selectable: $isSelectable
    )
}
`;

export const DELETE_CONSULTANT = gql`
mutation DELETE_CONSULTANT(
  $id: ID!, 
  ) {
  deleteConsultantTime(input: {
    where: {
      id:$id
    }
  }){
    consultantTime {
      id
    }
  }
}
`;

export const GET_CONSULTANT_TYPES = gql`
query GET_CONSULTANT_TYPES($limit: Int){
  consultantTypes(limit: $limit) {
    id
    title
    title_en
    price
    price_en
    currency
    currency_en
    description
  }
}
`;

export default {};
