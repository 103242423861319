import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import InputBox from "../../components/micro/InputBox";
import { mutateI } from "../../app/utils/helper";
import { GET_APP_CONFIG, UPDATE_CONFIG_APP } from "./profileQueries";
import SelectBox from "../../components/micro/SelectBox";

const zoneOptions = [
  {
    label: "Asia/Tehran",
    value: "Asia/Tehran",
  }, {
    label: "America/Toronto",
    value: "America/Toronto",
  },
];

const SystemSetting = () => {
  const [rateCouncil, setRateCouncil] = useState<string>();
  const [zone, setZone] = useState<string>("Asia/Tehran");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const ConfigApp = useQuery(GET_APP_CONFIG, { fetchPolicy: "cache-only" });

  useEffect(() => {
    if (ConfigApp.data) {
      setRateCouncil(ConfigApp.data.configApp.rateCouncil);
    }
  }, [ConfigApp.data]);

  const [muConfigUpdate] = useMutation(UPDATE_CONFIG_APP, {
    refetchQueries: ["GET_APP_CONFIG"],
  });

  const handleSaveConfig = () => {
    mutateI(() => muConfigUpdate({
      variables: {
        rateCouncil,
      },
    }), t, enqueueSnackbar);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12}>
        <InputBox
          name="rateCouncil"
          value={rateCouncil}
          setValue={setRateCouncil}
          grid={4}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <SelectBox
          name="zone"
          value={zone}
          setValue={setZone}
          grid={4}
          items={zoneOptions}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Button
          onClick={handleSaveConfig}
          type="submit"
          variant="contained"
        >
          {t("Submit")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SystemSetting;
