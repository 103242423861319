import { useMutation, useQuery } from "@apollo/client";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
// import html2pdf from "html2pdf.js";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import moment from "moment-jalaali";
import { GET_ME } from "../../app/layout/layoutQueries";
import LogoImage from "../../assets/images/logo.png";

import "./index.scss";
import { DOWNLOAD_CONTRACT_BY_REQUEST_ID } from "./steps/requestStepAQueries";
import { getImageFromB64, useQueryParams } from "../../app/utils/helper";

const Contract = (props: any) => {
  const { t, i18n } = useTranslation();
  const reqId_ = _.get(props, "match.params.id");
  const query = useQueryParams();
  const isEnglish = query.get("mode") || "en";
  useEffect(() => {
    if (isEnglish) {
      i18n.changeLanguage("en");
    }
  }, []);

  const [downloadContract] = useMutation(DOWNLOAD_CONTRACT_BY_REQUEST_ID);
  const [dataContract, setDataContract] = useState<any>();
  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  const isCustomer = role === "Customer";
  const uId = _.get(Me, "data.me.id");

  useEffect(() => {
    // i18n.changeLanguage("en");
    const handleDownloadContract = () => {
      downloadContract({
        variables: {
          id: String(reqId_),
          en: isEnglish === "en",
        },
      })
        .then((result) => {
          const data_ = _.get(result, "data.downloadContract");
          data_.data.signUser = getImageFromB64(data_?.data?.signUser);
          data_.data.signAdmin = getImageFromB64(data_?.data?.signAdmin);
          setDataContract(data_);
          i18n.changeLanguage("en");
        })
        .catch((errorUpload) => {
          console.log("upload-error");
        });
    };
    handleDownloadContract();

    const b = document.querySelector("body");
    b.classList.add("body-contract");
  }, []);

  const onPrint = () => {
    window.print();
    // const element = document.getElementById("contract");
    // const opt = {
    //   margin: [5, 10, 5, 10],
    //   filename: "myfile.pdf",
    //   // image: { type: "png", quality: 1 },
    //   html2canvas: { scale: 2 },
    //   jsPDF: {
    //     unit: "pt",
    //     format: "a4",
    //     orientation: "portrait",
    //   },
    // };

    // html2pdf(element);
    // console.log("html2pdf-----", html2pdf);
    // const worker = html2pdf().from(element).set(opt).save();
  };

  const getSubmitDate = () => {
    if (!detail?.signDate) {
      return "--/--/--";
    }
    if (isEnglish) {
      return moment(detail?.signDate).locale("en").format("YYYY/MM/DD");
    }
    return moment(detail?.signDate).locale("en").format("jYYYY/jMM/jDD");
  };

  const detail = dataContract?.data || {};
  document.title = `Kohanbaba-Contract-${detail.contractId}`;
  const detailRaw = dataContract?.data?.data || {};

  return (
    <div
      className={`contract ${isEnglish ? "contract__english" : ""}`}
      id="contract"
    >
      <div id="pageborder" />
      <div className="contract__header">
        <br />
        <Grid container spacing={4}>
          <Grid item xs={8} className="print-area">
            <Button variant="contained" onClick={onPrint} color="primary">
              {t("contractTemp.print")}
            </Button>
          </Grid>
          <Grid item xs={8}>
            <div className="d-flex">
              {detail.contractId ? (
                <div className="qr-code">
                  <QRCode
                    size={65}
                    value={`KOHANBABA CONTRACT: https://panel.kohanbaba.com/contract/${detail.contractId}`}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="contract__title">
                <h2>{t("contractTemp.title")}</h2>
                <div>
                  {t("contractTemp.contractNumber")}
                  :
                  <b>{detail.contractId}</b>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="header__left">
              <img
                src={LogoImage}
                alt="x"
                style={{ width: "100%", maxWidth: "250px" }}
              />
              <div>
                {t("contractTemp.dateSubmit")}
                :
                {" "}
                <b>{getSubmitDate(detail?.signDate)}</b>
              </div>
              {/* <div>
                {t("contractTemp.dateCreated")}
                :
                <b>{dateIsoFa2En(detail.data?.created_at)}</b>
              </div> */}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <div className="contract__text text_intro">
              <span>{t("contractTemp.text1")}</span>
              <span>{detail.gender}</span>
              <b>{detail.name}</b>
              <span>{t("contractTemp.text2")}</span>
              <b>{detailRaw.national_number}</b>
              <span>{t("contractTemp.text3")}</span>
              <span style={{ fontWeight: "bold" }}>
                {isEnglish ? detailRaw.address_en : detailRaw.address}
                {" "}
              </span>
              <span>{t("contractTemp.text4")}</span>
              <b>{detailRaw.phone}</b>
              <span>{t("contractTemp.and")}</span>
              <b>{t("contractTemp.text8")}</b>
              <span>{t("contractTemp.text5")}</span>
              <b className="textEn">R524495</b>
              <span>{t("contractTemp.text6")}</span>
              <span className="textEn">
                50Monkswood Crescent, Newmarket, Ontario, Canada L3Y-2K1
              </span>
              {/* {t("contractTemp.text7")} */}
              {/* <b>{t("contractTemp.text8")}</b> */}
              {/* {t("contractTemp.text9")}
              <b>0440015537</b> */}
              {t("contractTemp.text11")}
              .
              {/* {t("contractTemp.text10")} */}
            </div>
          </Grid>
          <Grid item xs={12}>
            <h4 className="section-header">{t("contractTemp.ContextText")}</h4>
            <div className="contract__text">
              {dataContract?.mainText && parse(dataContract?.mainText)}
            </div>
          </Grid>
          <div style={{ breakAfter: "page" }} />
          <Grid item xs={12}>
            <h4 className="section-header">
              {t("contractTemp.contractDetails")}
              :
            </h4>
            <table>
              <tr>
                <td className="hide-en">
                  {t("contractTemp.name")}
                  <div>{detailRaw?.name}</div>
                </td>
                <td>
                  {t("contractTemp.nameEn")}
                  <div>{detailRaw?.name_en}</div>
                </td>
                <td className="hide-en">
                  {t("contractTemp.family")}
                  <div>{detailRaw?.family}</div>
                </td>
                <td>
                  {t("contractTemp.familyEn")}
                  <div>{detailRaw?.family_en}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {t("contractTemp.email")}
                  <div>{detailRaw?.email}</div>
                </td>
                <td>
                  {t("contractTemp.phone")}
                  <div>{detailRaw?.phone}</div>
                </td>
                <td>
                  {t("contractTemp.phoneHome")}
                  <div>{detailRaw?.phone_home}</div>
                </td>
                <td>
                  {t("contractTemp.birthday")}
                  <div>{detail?.birthday}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {t("contractTemp.marriedStatus")}
                  <div>{detail?.married}</div>
                </td>
                <td>
                  {t("contractTemp.gender")}
                  <div>{detail?.gender_m}</div>
                </td>
                <td>
                  {t("contractTemp.soldiery")}
                  <div>{detail?.soldiery}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {t("contractTemp.degree")}
                  <div>{detail?.degree_edu}</div>
                </td>
                <td className="hide-en">
                  {t("contractTemp.fieldStudy")}
                  <div>{detailRaw?.field_study}</div>
                </td>
                <td>
                  {t("contractTemp.fieldStudyEn")}
                  <div>{detail?.field_study_en}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {t("contractTemp.eduDateStart")}
                  <div>{detail?.date_start_edu}</div>
                </td>
                <td>
                  {t("contractTemp.eduDateEnd")}
                  <div>{detail?.date_end_edu}</div>
                </td>
                <td>
                  {t("contractTemp.insuranceHistory")}
                  <div>{detail?.insurance_history}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {t("contractTemp.totalAssets")}
                  <div>{detail?.total_assets}</div>
                </td>
                <td>
                  {t("contractTemp.typeJob")}
                  <div>{detail?.type_job}</div>
                </td>
                <td>
                  {t("contractTemp.isWorking")}
                  <div>{detail?.is_working}</div>
                </td>
                <td>
                  {t("contractTemp.jobState")}
                  <div>{detail?.job_state}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="hide-en">
                  {t("contractTemp.workDescription")}
                  <div>{detail?.work_description}</div>
                </td>
                <td colSpan={2}>
                  {t("contractTemp.workDescriptionEn")}
                  <div>{detail?.work_description_en}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {t("contractTemp.familyLiveCanada")}
                  <div>{detail?.family_live_canada}</div>
                </td>
                <td>
                  {t("contractTemp.levelLang")}
                  <div>{detailRaw?.language_level}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="hide-en">
                  {t("contractTemp.address")}
                  <div>{detailRaw?.address}</div>
                </td>
                <td colSpan={2}>
                  {t("contractTemp.addressEn")}
                  <div>{detailRaw?.address_en}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {t("contractTemp.hasSchengen")}
                  <div>{detail?.has_schengen_visa}</div>
                </td>
                <td>
                  {t("contractTemp.hasDisease")}
                  <div>{detail?.has_disease}</div>
                </td>
                <td>
                  {t("contractTemp.hascriminal")}
                  <div>{detail?.criminal_record}</div>
                </td>
                <td>
                  {t("contractTemp.targetCanada")}
                  <div>{detail?.target_canada}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  {t("contractTemp.familyRequested")}
                  <div>
                    <div>{detail?.family_requested}</div>
                  </div>
                  {t("contractTemp.familyRequestDescription")}
                  <div>
                    <div>{detail?.family_request_description}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  {t("contractTemp.yourIelts")}
                  <div>{detail?.ielts}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {t("contractTemp.yourIeltsScore.read")}
                  <div>{detail?.ielts_?.read || "-"}</div>
                </td>
                <td>
                  {t("contractTemp.yourIeltsScore.listen")}
                  <div>{detail?.ielts_?.listen || "-"}</div>
                </td>
                <td>
                  {t("contractTemp.yourIeltsScore.write")}
                  <div>{detail?.ielts_?.write || "-"}</div>
                </td>
                <td>
                  {t("contractTemp.yourIeltsScore.speak")}
                  <div>{detail?.ielts_?.speak || "-"}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  {t("contractTemp.yourTef")}
                  <div>{detail?.tef}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {t("contractTemp.yourTefScore.read")}
                  <div>{detail?.tef_?.read || "-"}</div>
                </td>
                <td>
                  {t("contractTemp.yourTefScore.listen")}
                  <div>{detail?.tef_?.listen || "-"}</div>
                </td>
                <td>
                  {t("contractTemp.yourTefScore.write")}
                  <div>{detail?.tef_?.write || "-"}</div>
                </td>
                <td>
                  {t("contractTemp.yourTefScore.speak")}
                  <div>{detail?.tef_?.speak || "-"}</div>
                </td>
              </tr>
              {detail?.married ? (
                <>
                  <tr>
                    <td colSpan={4}>
                      <h4 className="section-header">
                        {t("contractTemp.pairInfo")}
                      </h4>
                    </td>
                  </tr>
                  <tr>
                    {/* <td>
                      {t("contractTemp.partnerName")}
                      <div>{detail?.data?.partner_name}</div>
                    </td> */}
                    <td>
                      {t("contractTemp.partnerNameEn")}
                      <div>{detail?.data?.partner_name_en}</div>
                    </td>
                    <td>
                      {t("contractTemp.partnerFieldStudy")}
                      <div>{detail?.data?.partner_field_study_en}</div>
                    </td>
                    <td>
                      {t("contractTemp.partnerBirthday")}
                      <div>{detail?.partner_birthday}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("contractTemp.partnerDegree")}
                      <div>{detail?.partner_degree}</div>
                    </td>
                    <td>
                      {t("contractTemp.partnerJob")}
                      <div>{detail?.data?.partner_job}</div>
                    </td>
                    <td>
                      {t("contractTemp.partnerInsurance")}
                      <div>{detail?.data?.partner_insurance}</div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      {t("contractTemp.partnerIelts")}
                      <div>{detail?.partner_ielts}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("contractTemp.partnerIeltsScore.read")}
                      <div>{detail?.partner_ielts_?.read || "-"}</div>
                    </td>
                    <td>
                      {t("contractTemp.partnerIeltsScore.listen")}
                      <div>{detail?.partner_ielts_?.listen || "-"}</div>
                    </td>
                    <td>
                      {t("contractTemp.partnerIeltsScore.write")}
                      <div>{detail?.partner_ielts_?.write || "-"}</div>
                    </td>
                    <td>
                      {t("contractTemp.partnerIeltsScore.speak")}
                      <div>{detail?.partner_ielts_?.speak || "-"}</div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      {t("contractTemp.partnerTef")}
                      <div>{detail?.partner_tef || "-"}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("contractTemp.partnerTefScore.read")}
                      <div>{detail?.partner_tef_?.read || "-"}</div>
                    </td>
                    <td>
                      {t("contractTemp.partnerTefScore.listen")}
                      <div>{detail?.partner_tef_?.listen || "-"}</div>
                    </td>
                    <td>
                      {t("contractTemp.partnerTefScore.write")}
                      <div>{detail?.partner_tef_?.write || "-"}</div>
                    </td>
                    <td>
                      {t("contractTemp.partnerTefScore.speak")}
                      <div>{detail?.partner_tef_?.speak || "-"}</div>
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
              {detail?.childs?.length ? (
                <tr>
                  <td colSpan={4}>
                    <h4 className="section-header">
                      {t("contractTemp.childsInfo")}
                    </h4>
                  </td>
                </tr>
              ) : (
                ""
              )}
              {detail?.childs?.map((child) => (
                <tr>
                  {/* <td>
                    {t("contractTemp.name")}
                    <div>{child?.name}</div>
                  </td> */}
                  <td>
                    {t("contractTemp.nameEn")}
                    <div>{child?.name_en}</div>
                  </td>
                  <td>
                    {t("contractTemp.birthday")}
                    <div>{child?.birthday}</div>
                  </td>
                  <td>
                    {t("levelEducation")}
                    <div>{t(child?.levelEdu)}</div>
                  </td>
                  <td>
                    {t("levelEducationNumber")}
                    <div>
                      <div>{t(child?.levelEducationNumber)}</div>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </Grid>

          <div style={{ breakAfter: "page" }} />
          <Grid item xs={12}>
            {/* <h3>{t("contractTemp.CostOfContract")}</h3> */}
            <h4 className="section-header">
              {t("contractTemp.CostOfContract")}
            </h4>
            <table className="cost-contract">
              <tr>
                <th>{t("contractTemp.installmentTitle")}</th>
                <th>{t("contractTemp.installmentDueDate_")}</th>
                <th>{t("contractTemp.installmentStatus")}</th>
                <th>{t("contractTemp.installmentPrice")}</th>
              </tr>

              {detail?.installments?.map((installment) => (
                <tr>
                  <td>
                    <div>
                      {(isEnglish
                        ? installment?.title_en
                        : installment?.title) || "-"}
                    </div>
                  </td>
                  <td>
                    <div>
                      {installment?.message
                        ? installment?.message
                        : (
                          <div>
                            {(!installment?.dueDate?.includes("Invalid")
                              ? installment?.dueDate
                              : "-")}
                          </div>
                        )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {installment?.status ? t(installment?.status) : "-"}
                    </div>
                  </td>
                  <td>
                    <div>{installment?.cost}</div>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={2} />
                <td colSpan={1}>
                  <b>{t("contractTemp.totalPriceDollar")}</b>
                </td>
                <td colSpan={1}>
                  <div>{detail?.contractData?.price}</div>
                </td>
              </tr>
            </table>
          </Grid>

          <Grid item xs={12}>
            <h3>{t("contractTemp.SignContract")}</h3>
            <table>
              <tr>
                <td colSpan={2}>
                  <p>{t("contractTemp.signOwner")}</p>
                  <img
                    className="contract__sign"
                    src="https://api.kohanbaba.com/uploads/logo_org_95219ec3f9.png"
                    alt="x"
                  />
                </td>
                <td colSpan={2}>
                  <p>{t("contractTemp.signUser")}</p>
                  <img
                    className="contract__sign"
                    src={detail?.signUser}
                    alt="x"
                  />
                </td>
              </tr>
            </table>

            <br />
            <br />
            <div>
              {t("contractTemp.dateDownload")}
              :
              {" "}
              <b>{detail.dateDownload}</b>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Contract;
