import React from "react";

// eslint-disable-next-line no-unused-vars
const Error = (props: any) => (
  <div>
    Error
  </div>
);

export default Error;
