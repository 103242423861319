const gender = [
  {
    label: "male",
    value: "male",
  },
  {
    label: "female",
    value: "female",
  },
  {
    label: "other",
    value: "other",
  },
];

const soldiery = [
  {
    label: "contained",
    value: "contained",
  },
  {
    label: "ended",
    value: "ended",
  },
  {
    label: "exempt",
    value: "exempt",
  },
];

const defaultOption = [
  {
    label: "yes",
    value: "true",
  },
  {
    label: "no",
    value: "false",
  },
];

const targetCanadaOptions = [
  {
    label: "residence",
    value: "residence",
  },
  {
    label: "tourist",
    value: "tourist",
  },
];

const marriedOptions = [
  {
    label: "single",
    value: "single",
  },
  {
    label: "married",
    value: "married",
  },
  {
    label: "divorce",
    value: "divorce",
  },
  {
    label: "dead",
    value: "dead",
  },
];

const degreesOptions = [
  {
    label: "UnderDiploma",
    value: "UnderDiploma",
  },
  {
    label: "Diploma",
    value: "Diploma",
  },
  {
    label: "Associate",
    value: "Associate",
  },
  {
    label: "Bachelor",
    value: "Bachelor",
  },
  {
    label: "Master",
    value: "Master",
  },
  {
    label: "Doctoral",
    value: "Doctoral",
  },
  {
    label: "PostDoc",
    value: "PostDoc",
  },
];

const jobOptions = [
  {
    label: "Businesslicense",
    value: "Businesslicense",
  },
  {
    label: "Company_registered",
    value: "Company_registered",
  },
  {
    label: "Medical_System_license",
    value: "Medical_System_license",
  },
  {
    label: "Management_history",
    value: "Management_history",
  },
  {
    label: "Management_farm",
    value: "Management_farm",
  },
  {
    label: "Athlete_art_pro",
    value: "Athlete_art_pro",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const insuranceOptions = [
  {
    label: "notHave",
    value: "0",
  },
  {
    label: "lessThan2",
    value: "2",
  },
  {
    label: "years_3",
    value: "3",
  },
  {
    label: "years_4",
    value: "4",
  },
  {
    label: "years_5",
    value: "5",
  },
  {
    label: "more_than_5",
    value: "5",
  },
];

const totalAssetsOptions = [
  {
    label: "under_50",
    value: "under_50",
  },
  {
    label: "between_50_100",
    value: "between_50_100",
  },
  {
    label: "between_100_200",
    value: "between_100_200",
  },
  {
    label: "between_200_350",
    value: "between_200_350",
  },
  {
    label: "between_350_500",
    value: "between_350_500",
  },
  {
    label: "between_500_750",
    value: "between_500_750",
  },
  {
    label: "between_750_1000",
    value: "between_750_1000",
  },
  {
    label: "between_1000_1600",
    value: "between_1000_1600",
  },
  {
    label: "more_than_1600",
    value: "more_than_1600",
  },
];

const typeJobOptions = [
  {
    label: "fullTime",
    value: "true",
  },
  {
    label: "partTime",
    value: "false",
  },
];

const locationTypeOptions = [
  {
    label: "consultant_type.ONLINE",
    value: "ONLINE",
  },
  {
    label: "consultant_type.OFFLINE",
    value: "OFFLINE",
  },
];

const typeFindUsOptions = [
  {
    label: "google",
    value: "google",
  },
  {
    label: "socialMedia",
    value: "socialMedia",
  },
  {
    label: "friends",
    value: "friends",
  },
  {
    label: "other",
    value: "other",
  },
];

const familyLiveCanadaOptions = [
  {
    label: "notHave",
    value: "notHave",
  },
  {
    label: "brother_sister",
    value: "brother_sister",
  },
  {
    label: "father_mother",
    value: "friends",
  },
  {
    label: "grandPa_grandMa",
    value: "grandPa_grandMa",
  },
  {
    label: "aunt",
    value: "aunt",
  },
  {
    label: "uncle",
    value: "uncle",
  },
  {
    label: "child",
    value: "child",
  },
];

const langLevelOptions = [
  {
    label: "Excellent",
    value: "Excellent",
  }, {
    label: "Normal",
    value: "Normal",
  }, {
    label: "Weak",
    value: "Weak",
  },
];

const visaTypesOptions = [
  {
    key: "tourist_visa",
    description: "",
  },
  {
    key: "education_visa",
    description: "",
  },
  {
    key: "imp_visa",
    description: "",
  },
  {
    key: "owpp_visa",
    description: "",
  },
  {
    key: "entrepreneurship_visa",
    description: "",
  },
];

export enum STEPS {
  STEP_INFORMATION = "STEP_INFORMATION",
  STEP_CONTRACT= "STEP_CONTRACT",
  STEP_PAYMENT= "STEP_PAYMENT",
  STEP_DOCUMENT= "STEP_DOCUMENT",
  STEP_END= "STEP_END",
}

export enum STEP_STATUS {
  accepted = "accepted",
  rejected= "rejected",
  waitUser= "waitUser",
  waitReview= "waitReview",
  inReview= "inReview",
  contractDone= "contractDone",
  contractCanceled= "contractCanceled",
}

const StepsStatus = [
  {
    maxStepPermission: 0,
    key: "RequestReviewInfo",
  },
  {
    maxStepPermission: 1,
    key: "InfoAccepted",
  },
  {
    maxStepPermission: 0,
    key: "InfoAcceptedError",
  },
  {
    maxStepPermission: 2,
    key: "SelectVisaWasSet",
  },
  {
    maxStepPermission: 1,
    key: "SelectVisaError",
  },
  {
    maxStepPermission: 3,
    key: "SessionDone",
  },
  {
    maxStepPermission: 2,
    key: "SessionError",
  },
  {
    maxStepPermission: 4,
    key: "ContractDone",
  },
  {
    maxStepPermission: 3,
    key: "ContractError",
  },
  {
    maxStepPermission: 5,
    key: "PaymentDone",
  },
  {
    maxStepPermission: 4,
    key: "PaymentError",
  },
  {
    maxStepPermission: 6,
    key: "DocAdditionalDone",
  },
  {
    maxStepPermission: 5,
    key: "DocAdditionalError",
  },
  {
    maxStepPermission: 6,
    key: "GetVisa",
  },
  {
    maxStepPermission: 4,
    key: "GetVisaError",
  },
  {
    maxStepPermission: 6,
    key: "DoneRequest",
  },
  {
    maxStepPermission: 0,
    key: "CanceledRequest",
  },
];

const onlineOptions = [
  {
    label: "online",
    value: "true",
  }, {
    label: "offline",
    value: "false",
  },
];

const activeOptions = [
  {
    label: "active",
    value: "true",
  }, {
    label: "disable",
    value: "false",
  },
];

const levelEducationOptions = [
  {
    label: "noEducation",
    value: "noEducation",
  }, {
    label: "elementarySchool",
    value: "elementarySchool",
  }, {
    label: "highSchool",
    value: "highSchool",
  }, {
    label: "studentUni",
    value: "studentUni",
  },
];

const levelEducationNumberOptions = [
  {
    label: "firstYear",
    value: "1",
  }, {
    label: "secondYear",
    value: "2",
  }, {
    label: "thirdYear",
    value: "3",
  }, {
    label: "fourthYear",
    value: "4",
  },
];

const statusInvoiceOptions = [
  {
    label: "waitPayment",
    value: "waitPayment",
  }, {
    label: "waitForApprove",
    value: "waitForApprove",
  }, {
    label: "done",
    value: "done",
  },
  {
    label: "draft",
    value: "draft",
  },
  {
    label: "canceled",
    value: "canceled",
  },
  {
    label: "partPaymentDone",
    value: "partPaymentDone",
  },
];

const subjectInvoiceOptions = [
  {
    label: "council",
    value: "council",
  }, {
    label: "resubmit",
    value: "resubmit",
  }, {
    label: "contractInstallment",
    value: "contractInstallment",
  },
];

const typeCurrency = [
  {
    label: "usd",
    value: "usd",
  }, {
    label: "riyal",
    value: "riyal",
  }, {
    label: "euro",
    value: "euro",
  },
];

const roleType = [
  {
    label: "adviser",
    value: 4,
  },
];

const BASIC_COST_COUNCIL = 3500000;

const roles = {
  CUSTOMER: "Customer",
  Authenticated: "Authenticated",
  Editor: "Editor",
  Secretory: "Secretory",
  Accountant: "Accountant",
  AgentMaster: "AgentMaster",
  Agent: "Agent",
};

const statusBooking = {
  ALL: "ALL",
  DRAFT: "DRAFT",
  // NEW: "NEW",
  DONE: "DONE",
  CANCELED: "CANCELED",
  REJECTED: "REJECTED",
  WAITING_MEETING: "WAITING_MEETING",
  WAITING_PAYMENT: "WAITING_PAYMENT",
  WAITING_PAYMENT_ACCEPT: "WAITING_PAYMENT_ACCEPT",
};

const consultantMethod = {
  ONLINE: true,
  OFFLINE: false,
  BOTH: "BOTH",
};

const statics = {
  gender,
  soldiery,
  defaultOption,
  marriedOptions,
  targetCanadaOptions,
  degreesOptions,
  jobOptions,
  insuranceOptions,
  totalAssetsOptions,
  typeJobOptions,
  typeFindUsOptions,
  familyLiveCanadaOptions,
  langLevelOptions,
  visaTypesOptions,
  StepsStatus,
  onlineOptions,
  activeOptions,
  levelEducationOptions,
  levelEducationNumberOptions,
  statusInvoiceOptions,
  subjectInvoiceOptions,
  BASIC_COST_COUNCIL,
  typeCurrency,
  roleType,
  roles,
  statusBooking,
  consultantMethod,
  locationTypeOptions,
};

export default statics;
