import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card, Divider,
  Grid,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { useTranslation } from "react-i18next";
import InputBox from "../../../components/micro/InputBox";
import PageTitle from "../../../components/PageTitle";
import Notification, { INotification } from "../../../components/micro/SnackbarMicro";
import {
  CREATE_SERVICE, GET_SERVICE, GET_SERVICE_CATEGORIES, UPDATE_SERVICE,
} from "../servicesQueries";
import InputEditor2 from "../../../components/micro/InputEditor2";
import InputFile from "../../../components/micro/InputFile";
import SelectBox from "../../../components/micro/SelectBox";
import { convertToSlug } from "../../../app/utils/helper";

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {},
  card: {
    padding: theme.spacing(5),
  },
  toolbar: {
    padding: theme.spacing(1),
  },
  dividerToolbar: {
    margin: "10px 0px",
  },
}));

const Service = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [title, setTitle] = useState<string>();
  const [titleEn, setTitleEn] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [descriptionEn, setDescriptionEn] = useState<string>();
  const [content, setContent] = useState<string>();
  const [contentEn, setContentEn] = useState<string>();
  const [slug, setSlug] = useState<string>();
  const [slugEn, setSlugEn] = useState<string>();
  const [attachment, setAttachment] = useState<any>();
  const [thumbnail, setThumbnail] = useState<object>();
  const [category, setCategory] = useState<string>();
  const [motionGraphic, setMotionGraphic] = useState<object>();
  const [categories, setCategories] = useState<[]>();
  const [notif, setNotif] = useState<INotification>();
  const currentId_ = _.get(props, "match.params.id");
  const [currentId, setCurrentId] = useState(currentId_);

  const { data, loading, refetch } = useQuery(GET_SERVICE, {
    skip: !currentId,
    variables: { id: Number(currentId) },
  });
  const service = data?.service;

  const categoriesHandler = useQuery(GET_SERVICE_CATEGORIES, {
    // skip: !currentId,
    // variables: { id: Number(currentId) },
  });
  const [create, responseCreate] = useMutation(CREATE_SERVICE, {
    refetchQueries: [
      "GetServices",
    ],
  });
  const [update, responseUpdate] = useMutation(UPDATE_SERVICE, {
    refetchQueries: [
      "GetServices",
      "GetService",
    ],
  });

  useEffect(() => {
    if (data) {
      setTitle(service.title);
      setTitleEn(service.title_en);
      setDescription(service.description);
      setDescriptionEn(service.description_en);
      setContent(service.content);
      setContentEn(service.content_en);
      setSlug(service.slug);
      setSlugEn(service.slug_en);
      setThumbnail(service.image);
      setAttachment(service.attachment);
      setMotionGraphic(service.motion);
      setCategory(service.service_category?.id);
    }
  }, [loading]);

  useEffect(() => {
    if (categoriesHandler?.data) {
      setCategories(categoriesHandler.data?.serviceCategories?.map((cat) => ({
        value: cat.id,
        label: cat.name,
      })));
    }
  }, [categoriesHandler?.data]);

  useEffect(() => {
    setSlug(convertToSlug(title));
  }, [title]);

  useEffect(() => {
    setSlugEn(convertToSlug(titleEn));
  }, [titleEn]);

  const handleSave = () => {
    // if (true) return;
    if (currentId) {
      update({
        variables: {
          id: currentId,
          title,
          titleEn,
          description,
          descriptionEn,
          content,
          contentEn,
          slug,
          slugEn,
          thumbnail: thumbnail?.id,
          attachment,
          category,
          motionGraphic: motionGraphic?.id,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyUpdated", type: "success", setOpen: setNotif,
        });
        // refetch();
        setTimeout(() => props.history.goBack(), 1000);
      }).catch((error) => {
        setNotif({
          open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
        });
      });
    } else {
      create({
        variables: {
          title,
          titleEn,
          description,
          descriptionEn,
          content,
          contentEn,
          slug,
          slugEn,
          thumbnail: thumbnail?.id,
          attachment,
          category,
          motionGraphic: motionGraphic?.id,
        },
      }).then((response) => {
        setNotif({
          open: true, message: "SuccessfullyCreated", type: "success", setOpen: setNotif,
        });
        setCurrentId(response.data.createService.service.id);
        // refetch();
        setTimeout(() => props.history.goBack(), 1000);
      }).catch((error) => {
        setNotif({
          open: true, message: "ErrorHappened", type: "error", setOpen: setNotif,
        });
      });
    }
  };

  return (
    <>
      <PageTitle
        title={currentId_ ? t("Service.Edit") : t("Service.New")}
      />
      {!loading ? (
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Card className={classes.card}>
              <Grid container spacing={4}>
                <InputBox
                  name="title"
                  value={title}
                  setValue={setTitle}
                  grid={6}
                />
                <InputBox
                  name="title_en"
                  value={titleEn}
                  setValue={setTitleEn}
                  grid={6}
                />
                <InputBox
                  name="description"
                  value={description}
                  setValue={setDescription}
                  rows={3}
                  grid={6}
                />
                <InputBox
                  name="description_en"
                  value={descriptionEn}
                  setValue={setDescriptionEn}
                  rows={3}
                  grid={6}
                />

                <Grid item xs={12}>
                  <InputEditor2
                    label="content"
                    value={service?.content}
                    setValue={setContent}
                    dir="rtl"
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputEditor2 label="content_en" value={service?.content_en} setValue={setContentEn} />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={handleSave}
                    className={classes.button}
                    type="submit"
                    variant="contained"
                  >
                    {t("Submit")}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.toolbar}>
              <InputFile
              // multiple="false"
                name="thumbnail"
                file={thumbnail}
                setFile={setThumbnail}
                grid={12}
                required
                mode="singleImage"
              />
              <Divider className={classes.dividerToolbar} />
              {!categoriesHandler.loading ? (
                <SelectBox
                  name="category"
                  value={category}
                  setValue={setCategory}
                  grid={12}
                  items={categories}
                />
              ) : ""}
              <Divider className={classes.dividerToolbar} />
              <InputFile
              // multiple="false"
                name="motionGraphic"
                file={motionGraphic}
                setFile={setMotionGraphic}
                grid={12}
                required
                mode="singleVideo"
              />
            </Card>
          </Grid>
        </Grid>
      ) : ""}
      <Notification {...notif} />
    </>
  );
};

export default Service;
