import { gql } from "@apollo/client";

export const GET_ATTACHMENTS = gql`  
query GetAttachments($sort: String) {
  attachments(
    sort: $sort
  ){
    id
    title
    title_en
    description
    created_at
    updated_at
    type
    sample_file {
      id
      created_at
      updated_at
      name
      ext
      url
    }
  }
}
`;

export const GET_ATTACHMENT = gql`  
query GetATTACHMENT($id: ID!) {
  attachment(
    id: $id
  ){
    created_at
    updated_at
    id
    title
    title_en
    description
    description_en
    type
    type_document
    sample_file {
      id
      name
      caption
      width
      height
      previewUrl
      url
      ext
      mime
    }
  }
}
`;

export const CREATE_ATTACHMENT = gql`
mutation CREATE_ATTACHMENT(
  $title: String!, 
  $titleEn: String!, 
  $description: String, 
  $descriptionEn: String,
  $type: ENUM_ATTACHMENT_TYPE,
  $type_document: ENUM_ATTACHMENT_TYPE_DOCUMENT,
  $sampleFile: ID,
  ){
  createAttachment(input: {
    data: {
      title: $title
      title_en: $titleEn
      description: $description
      description_en: $descriptionEn
      type: $type
      type_document: $type_document
      sample_file: $sampleFile
    }
  }){
    attachment {
      id
    }
  }
}
`;

export const UPDATE_ATTACHMENT = gql`
mutation UPDATE_ATTACHMENT(
  $id: ID!, 
  $title: String!, 
  $titleEn: String!, 
  $description: String,
  $descriptionEn: String,
  $type: ENUM_ATTACHMENT_TYPE,
  $type_document: ENUM_ATTACHMENT_TYPE_DOCUMENT,
  $sampleFile: ID,
  ){
    updateAttachment(input: {
      where: {
        id: $id
      }
      data: {
        title: $title
        title_en: $titleEn
        description: $description
        description_en: $descriptionEn
        type: $type
        type_document: $type_document
        sample_file: $sampleFile
      }
    }) {
      attachment {
        id
      }
    }
}
`;

export const DELETE_ATTACHMENT = gql`
mutation DELETE_ATTACHMENT(
  $id: ID!,
){
  deleteAttachment(input: {
    where: {
      id:$id
    }
  }){
    attachment {
      id
    }
  }
}
`;

export default {};
