import { gql } from "@apollo/client";

export const GET_REQUESTS = gql`
  query GET_REQUESTS(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    requests(
      sort: $sort
      limit: $limit
      start: $start
      where: $where
    ) {
      id
      display_id
      name
      created_at
      updated_at
      name
      family
      phone
      email
      userId {
        id
        name
      }
      request_status {
        id
        status
        created_at
        updated_at
        current_step
      }
      operator {
        id
        name
      }
    }

    requestsAggregate(
      sort: $sort
      limit: $limit
      start: $start
      where: $where
    )
  }
`;

export const GET_REQUESTS_MA = gql`
  query GET_REQUESTS($sort: String, $limit: Int, $start: Int, $where: JSON) {
    requestsAll(sort: $sort, limit: $limit, start: $start, where: $where)
  }
`;

export const GET_OLD_REQUESTS = gql`
  query GET_OLD_REQUESTS(
    $sort: String
    $uId: String
    $limit: Int
    $start: Int
  ) {
    requests(
      sort: $sort
      limit: $limit
      start: $start
      where: { userId: $uId }
    ) {
      id
      name
      created_at
      updated_at
      name
      family
      phone
      email
      userId {
        id
        name
      }
    }
    requestStatuses(sort: $sort, where: { requestId: { userId: $uId } }) {
      id
      requestId {
        id
      }
      status
      created_at
      updated_at
    }
  }
`;

export const DELETE_REQUEST = gql`
  mutation DELETE_REQUEST($id: ID!) {
    deleteRequest(input: { where: { id: $id } }) {
      request {
        id
      }
    }
  }
`;

export const SEND_FOLLOW_UP_MESSAGE = gql`
  mutation SEND_FOLLOW_UP_MESSAGE($data: JSON!) {
    sendFollowUpRequest(data: $data)
  }
`;
export default {};
