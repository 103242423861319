import React, { useContext, useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { GET_USERS } from "../../pages/User/usersQueries";
import SelectSearch from "../micro/SelectSearch";
import { CREATE_REQUEST_FOR_USER } from "../../pages/Request/steps/requestStepAQueries";
import { mutateI } from "../../app/utils/helper";
import statics from "../../app/statics";
import { AppContext } from "../../app/layout/AppContext_";

export default function DialogAssignContract(props: any) {
  const history = useHistory();
  const [user, setUser] = useState("");
  const userQuery = useQuery(GET_USERS, {
    variables: {
      sort: "created_at:desc",
      limit: 20,
      where: {
        _q: undefined,
        role: {
          name: statics.roles.CUSTOMER,
        },
      },
    },
  });
  const app = useContext(AppContext);

  const [createRequest, responseCreateRequest] = useMutation(CREATE_REQUEST_FOR_USER);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const {
    open,
    setOpen,
  } = props;

  const users = useMemo(() => {
    const data = userQuery.data?.usersConnection?.values?.map((item) => {
      const label = `${item.phone} - ${item.name}`;
      return {
        label,
        value: item.id,
        detail: item,
      };
    });

    return data || [];
  }, [userQuery.data]);

  const handleClose_ = () => {
    setOpen();
  };

  const handleSubmit = () => {
    // create empty contract for user
    const uDetail = users.find((item) => item.value === user)?.detail;
    mutateI(() => createRequest({
      variables: {
        userId: user,
        name: uDetail.name,
        family: "",
        email: uDetail.email,
        address: "",
        phone: uDetail.phone,
      },
      refetchQueries: ["GET_REQUESTS"],
    }), t, enqueueSnackbar, null, () => handleClose_());
  };

  const handleSearchUser = (e: any) => {
    userQuery.refetch({
      where: {
        _q: e.target.value || undefined,
        role: {
          name: statics.roles.CUSTOMER,
        },
      },
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={handleClose_}
      // className={!app.isRTL ? "rtl-dialog" : undefined}
    >
      <DialogTitle id="form-dialog-title">{t("AssignContractToUser")}</DialogTitle>
      <>
        <DialogContent style={{ paddingTop: 0, textAlign: "justify" }}>
          <Grid container spacing={1}>
            <p>
              {t("createContractText")}
              <br />
              {t("pleaseSelectUser")}
            </p>
            <SelectSearch
              name="selectUser"
              value={user}
              setValue={setUser}
              grid={12}
              items={users}
              handleSearch={handleSearchUser}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose_} color="primary">
              {t("Cancel")}
            </Button> */}
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            {t("Submit")}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
