import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useContext, useMemo } from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import type { AppDispatch, RootState } from "./store";
import { GET_REQUEST_STATUS } from "../pages/Request/requestQueries";
import { GET_ME } from "./layout/layoutQueries";
import statics, { STEPS } from "./statics";
import { RequestContext } from "../pages/Request/context/requestContext";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useContractState = () => {
//   const reqId_ = _.get(props, "match.params.id");
  const params = useParams();
  const reqId = params?.id;
  const context = useContext(RequestContext);

  const reqStatus = useQuery(GET_REQUEST_STATUS, {
    variables: { reqId: Number(reqId) },
    fetchPolicy: "cache-only",
    skip: !reqId,
  });

  const currentActiveIndex = _.keys(STEPS).findIndex((k) => k === context.activeStep) || 0;

  const availableStep = _.get(reqStatus.data, "requestStatuses[0].current_step") || STEPS.STEP_INFORMATION;
  const availableStepIndex = _.keys(STEPS).findIndex((k) => k === availableStep) || 0;

  const reqSId = _.get(reqStatus.data, "requestStatuses[0].id");

  return useMemo(() => ({
    reqId,
    availableStep,
    availableStepIndex,
    reqStatus: reqStatus || {},
    reqSId,
    currentActiveIndex,
  }), [
    reqId,
    availableStep,
    availableStepIndex,
    reqStatus,
    reqSId,
    currentActiveIndex,
  ]);
};

export const useAccount = () => {
  const Me = useQuery(GET_ME);
  const role = _.get(Me, "data.me.role.name");
  const permissions = _.get(Me, "data.me2.role.permissions", []);
  const isCustomer = role === statics.roles.CUSTOMER;
  const isAdmin = role === statics.roles.Authenticated;
  const isAgent = role === statics.roles.Agent;
  const isAgentMaster = role === statics.roles.AgentMaster;
  const uId = _.get(Me, "data.me.id");

  return useMemo(() => ({
    Me, role, isCustomer, uId, isAdmin, isAgentMaster, isAgent, permissions,
  }), [Me, role, isCustomer, uId]);
};
