import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card, CardContent, CardHeader,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import Check from "@material-ui/icons/Check";
import { ArrowBack, Cancel } from "@material-ui/icons";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { GET_ME } from "../../app/layout/layoutQueries";
import InputBox from "../../components/micro/InputBox";
import PageTitle from "../../components/PageTitle";
import { GET_INVOICE_BY_ID, UPDATE_PAYMENT } from "./invoiceQueries";
import SelectBox from "../../components/micro/SelectBox";
import { GET_REQUESTS } from "../Requests/requestsQueries";
import statics from "../../app/statics";
import { CREATE_PAYMENT, UPDATE_INVOICE } from "../Request/steps/requestStepBQueries";
import DatePickerComponent from "../../components/micro/Datepicker";
import PaymentResultDialog from "../../components/PaymentResultDialog";
import {
  GetFakeInvoiceId, IntlFormat, dateIsoFa2, forceDownloadFile,
  getDateTime, getFakeEvalId, getFakeRequestId, getRefNumber, priceFormat,
} from "../../app/utils/helper";
import FormDialogConfirmPayment from "../../components/FormDialogConfirmPayment";
import TableSimple from "../../components/micro/TableSimple";
import { API_SERVER } from "../../Config";
import Loader from "../../components/extra/loader";

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {},
  card: {
    padding: theme.spacing(5),
  },
  cardItem: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageFactor: {
    width: "125px",
    borderRadius: "5px",
    height: "125px",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    "&>div:first-child": {
      fontWeight: "bold",
      marginRight: "5px",
    },
  },
  link: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    "&>a": {
      textDecoration: "none",
      marginRight: theme.spacing(1),
    },
  },
  linkBack: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  priceInput: {
    display: "flex",
    gap: "5px",
    "&>div:first-child": {
      width: "80%",
      direction: "rtl",
      margin: "0px",
    },
    "&>div:nth-child(2)": {
      width: "80px",
      minWidth: "auto",
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    "&>div": {
      padding: "5px",
    },
    "& b": {
      marginRight: "5px",
    },
  },
}));

const Invoice = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dialogResultPayment, setDialogResultPayment] = useState<boolean>(false);
  const [openModalPayment, setOpenModalPayment] = useState<boolean>(false);

  const [createPayment, responseCreatePayment] = useMutation(CREATE_PAYMENT, {
    refetchQueries: ["GetRequestPaymentInstallment", "GET_INVOICE_BY_ID"], // "GET_PAYMENT_BY_INVOICE_ID"],
  });
  const [payments, setPayments] = useState<any>([]);
  const validationSchema = yup.object({
    // contract: yup
    //   .string().required(),
    typeCurrency: yup
      .string().required(),
  });
  const confirm = useConfirm();

  const formik = useFormik({
    initialValues: {
      id: "",
      status: "",
      subject: "",
      message: "",
      dueDate: "",
      offCode: "",
      offPercent: "",
      title: "",
      requestId: "",
      cost: "",
      contract: "",
      typeCurrency: "",
    },
    validationSchema,
    onSubmit: (values) => {
      updateInvoice({
        variables: {
          ...({
            ...values,
            // reqId: values.contract,
            due_date: values.dueDate,
            // || moment(values.dueDate).locale("en").format("YYYY-MM-DD"),
          }),
          id: Number(invoiceId),
        },
      }).then((r) => {
        enqueueSnackbar(t("SuccessfullyUpdated"), {
          variant: "success",
        });
      }).catch((e) => {
        enqueueSnackbar(t("ErrorHappened"), {
          variant: "error",
        });
      });
    },
  });

  const {
    values, touched, errors,
  } = formik;

  const Me = useQuery(GET_ME);
  const isCustomer = _.get(Me, "data.me.role.name") === "Customer";

  const invoiceId = _.get(props, "match.params.id");
  const invoiceQuery = useQuery(GET_INVOICE_BY_ID, { variables: { id: Number(invoiceId) } });

  const [updatePayment, responseUpdatePayment] = useMutation(UPDATE_PAYMENT, {
    refetchQueries: ["GET_INVOICE_BY_ID"],
  });

  const [updateInvoice, responseUpdateInvoice] = useMutation(UPDATE_INVOICE, {
    refetchQueries: ["GET_INVOICE_BY_ID"],
  });

  const invoiceData = _.get(invoiceQuery, "data.invoice");

  const downloadFile = (e, file) => {
    e.preventDefault();
    if (file) {
      forceDownloadFile(API_SERVER() + file.url, file.name || `receipt-${moment().unix()}`);
    }
  };

  useEffect(() => {
    if (invoiceQuery.data) {
      formik.setFieldValue("id", invoiceData.id);
      formik.setFieldValue("title", invoiceData.title);
      // formik.setFieldValue("contract", invoiceData.request_id.id);
      formik.setFieldValue("cost", invoiceData.cost);
      formik.setFieldValue("typeCurrency", invoiceData.type || typeCurrencyOptions[1].value);
      // formik.setFieldValue("costRial", invoiceData.cost_rial);
      formik.setFieldValue("subject", invoiceData.subject);
      formik.setFieldValue("message", invoiceData.message);
      // formik.setFieldValue("dueDate", moment(invoiceData.due_date).format("jYYYY-jMM-jDD"));
      formik.setFieldValue("dueDate", invoiceData.due_date);
      formik.setFieldValue("offPercent", `${invoiceData.off_percent || 0}`);
      formik.setFieldValue("offCode", `${invoiceData.off_code || ""}`);
      formik.setFieldValue("status", invoiceData.status);

      if (invoiceData.payments.length) {
        const pays_ = invoiceData.payments.map((item) => {
          let confirmState = "Unknown";
          if (!_.isNull(item.confirmed)) {
            confirmState = item.confirmed ? "confirmed" : "not_confirmed";
          }
          let cost_ = `${priceFormat(invoiceData.cost, t("rial"))}`;
          if (invoiceData.request_id?.id) {
            cost_ = `${priceFormat(item.amount, t("dollar"))}`;
          }

          return {
            id: item.id,
            status: item.status,
            cost: cost_,
            confirmedState: `confirmedState_.${confirmState}`,
            bankPaymentId: getRefNumber(item.bank_payment_id) || "-",
            useOffCode: item.use_off_code ? "yes" : "no",
            paymentDate: getDateTime(item.payment_date, item.payment_time),
            receipt:
            item?.attachment?.id ? (
              <Button
                variant="text"
                onClick={(e) => downloadFile(e, item?.attachment)}
              >
                {t("download")}
              </Button>
            ) : "-",
            custom: () => (
              <div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => handleConfirmPayment(item, "accept")}
                  edge="start"
                  disabled={item.confirmed}
                >
                  <Check />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => handleConfirmPayment(item, "reject")}
                  edge="start"
                  disabled={item.confirmed === false}
                >
                  <Cancel />
                </IconButton>
              </div>
            ),
          };
        });
        setPayments(pays_.reverse());
      }
    }
  }, [invoiceQuery.data]);

  useEffect(() => {
    const queryString = _.get(props, "location.search");
    if (queryString) {
      const isPaymentDone = queryString.includes("Authority");
      setDialogResultPayment(isPaymentDone);
    }
  }, []);

  const requestQuery = useQuery(GET_REQUESTS);
  const contractsOptions = _.get(requestQuery, "data.requests", []).map((item) => ({
    value: item.id,
    label: `${item.name} ${item.family} (${t("byContractNumber")} ${item.id})`,
  })) || [];

  const handleConfirmPayment = (paymentInfo: any, type: "accept" | "reject") => {
    const confirmed = type === "accept";
    confirm({
      title: t(`SureFor${type}`),
      description: "",
      confirmationText: t("yes"),
      cancellationText: t("no"),
    }).then((rx) => {
      // removeFile(file);
      updatePayment({
        variables: {
          id: paymentInfo.id,
          confirmed,
        },
        refetchQueries: ["GET_INVOICE_BY_ID"],
      }).then((r) => {
        // TODO response
        enqueueSnackbar(t("SuccessfullyUpdated"), {
          variant: "success",
        });
      }).catch((e) => {
        // TODO error
      });
    }).catch((e) => {
      // TODO error
    });
  };

  const getStateConfirmed = (state: any) => {
    if (state) {
      return t("confirmed");
    } if (state === false) {
      return t("rejected");
    }
    return t("Unknown");
  };

  const handlePayment = (paymentInfo: any) => {
    if (paymentInfo.transactionDate && paymentInfo.transactionNumber) {
      const pDate = paymentInfo.transactionDate;
      createPayment({
        variables: {
          invoiceId: Number(paymentInfo.invoice.id),
          status: "offline_paid",
          bankPaymentId: paymentInfo.transactionNumber,
          payDate: pDate,
          payTime: paymentInfo.transactionTime,
          useOffCode: paymentInfo.useOffCode,
          attachment: paymentInfo.attachment ? Number(paymentInfo.attachment) : "",
        },
      }).then((result) => {
        enqueueSnackbar(t("invoiceSuccessfullyPayed"), {
          variant: "success",
        });
      }).catch((e) => {
        enqueueSnackbar(t("invoiceUnSuccessfullyPayed"), {
          variant: "error",
        });
      });
    }
  };

  const typeCurrencyOptions = statics.typeCurrency.map((item) => ({ label: `currency.${item.label}`, value: item.value }));

  const headerPayments = [
    {
      title: "id",
    },
    {
      title: "cost",
      key: "t",
    },
    {
      title: "status",
      key: "t",
    },
    {
      title: "confirmedState",
      key: "t",
    },
    {
      title: "bankPaymentId",
      key: "t",
    },
    {
      title: "useOffCode",
      key: "t",
    },
    {
      title: "paymentDate",
      // key: "dateTime",
    },
    {
      title: "receipt",
      // key: "dateTime",
    },
  ];

  if (!isCustomer) {
    headerPayments.push({
      title: "",
      key: "custom",
    });
  }

  const evalData = invoiceData?.evaluation || invoiceData?.request_id;
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <PageTitle
            title={`${t("InvoiceNumber")}#${GetFakeInvoiceId(values.id)}`}
          />
        </Grid>
        <Grid item xs={6} className={classes.linkBack}>
          {invoiceData?.subject === statics.subjectInvoiceOptions[0].value
            ? (
              <div className={classes.link}>
                <Link to={`/booking/${getFakeEvalId(invoiceData?.evaluation?.id)}`}>{t("showEvalPage")}</Link>
                <ArrowBack />
              </div>
            )
            : (
              <div className={classes.link}>
                <Link to={`/request/${invoiceData?.request_id?.id}`}>{t("showRequestPage")}</Link>
                <ArrowBack />
              </div>
            )}
        </Grid>
      </Grid>

      {!invoiceQuery.loading
        ? (
          <Grid container spacing={3}>
            {invoiceData?.status === "done" ? (
              <Grid item md={12} lg={12}>
                <Alert variant="standard" color="success">{t("thisInvoicePaySuccess")}</Alert>
              </Grid>
            ) : "" }
            <Grid item md={12} lg={6}>
              {!isCustomer ? (
                <Card>
                  <CardHeader title={t("OrderInformation")} />
                  <CardContent>
                    <form
                      onSubmit={formik.handleSubmit}
                  // className={classes.root}
                      // noValidate
                      autoComplete="on"
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                          {invoiceData?.request_id?.id
                            ? (
                              <div>
                                <b>{t("contractId")}</b>
                                {": "}
                                <Link to={`/request/${invoiceData?.request_id?.id}`}>{getFakeRequestId(invoiceData?.request_id?.id)}</Link>
                              </div>
                            ) : ""}
                          {invoiceData?.evaluation?.id
                            ? (
                              <div>
                                <b>{t("evaluationId")}</b>
                                {": "}
                                <Link to={`/booking/${getFakeEvalId(invoiceData?.evaluation?.id)}`}>{getFakeEvalId(invoiceData?.evaluation?.id)}</Link>
                              </div>
                            ) : ""}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div>
                            <b>{t("invoiceType")}</b>
                            {": "}
                            {t(invoiceData?.subject)}
                          </div>
                        </Grid>

                        <InputBox
                          handler="formik"
                          name="title"
                          value={values.title}
                          formikI={formik}
                          grid={6}
                          variant="standard"
                        />
                        {/* <Grid item xs={12} md={6} className={classes.priceInput}> */}
                        {/* <Grid container spacing={0}> */}
                        <InputBox
                          handler="formik"
                          name="cost"
                          value={values.cost}
                          formikI={formik}
                          grid={4}
                          variant="standard"
                        />

                        <SelectBox
                          handler="formik"
                          name="typeCurrency"
                          value={values.typeCurrency}
                          formikI={formik}
                          variant="standard"
                          grid={2}
                          items={typeCurrencyOptions}
                          margin="normal"
                        />
                        {/* </Grid> */}
                        {/* </Grid> */}

                        {/* <SelectBox
                          handler="formik"
                          name="subject"
                          value={values.subject}
                          formikI={formik}
                          grid={4}
                          items={statics.subjectInvoiceOptions}
                          disabled
                        /> */}

                        <DatePickerComponent
                          handler="formik"
                          name="dueDate"
                          value={values.dueDate}
                          formikI={formik}
                          grid={6}
                          required
                        />

                        <SelectBox
                          handler="formik"
                          name="status"
                          value={values.status}
                          formikI={formik}
                          grid={6}
                          items={statics.statusInvoiceOptions}
                        />

                        <InputBox
                          handler="formik"
                          name="message"
                          value={values.message}
                          formikI={formik}
                          grid={12}
                          rows={2}
                        />

                        {/* <InputBox
                        handler="formik"
                        name="offPercent"
                        value={values.offPercent}
                        formikI={formik}
                        grid={6}
                        readOnly
                      /> */}

                        {/* <InputBox
                        handler="formik"
                        name="offCode"
                        value={values.offCode}
                        formikI={formik}
                        grid={6}
                        readOnly
                      /> */}
                        {/* <Grid item xs={4} /> */}

                        <Grid item xs={12}>
                          <Button
                        // disabled
                        // onClick={handleSave}
                            // className={classes.button}
                            color="primary"
                            type="submit"
                            variant="contained"
                          >
                            {t("SaveChanges")}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              ) : "" }

              {isCustomer ? (
                <Card className={classes.card}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <div className={classes.item}>
                            <div>
                              {t("title")}
                              :
                            </div>
                            <div>
                              {invoiceData?.title || "-"}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={classes.item}>
                            <div>
                              {t("contract")}
                              :
                            </div>
                            <div>
                              {getFakeRequestId(invoiceData?.request_id?.id) || "-"}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">
                          <div className={classes.item}>
                            <div>
                              {t("cost")}
                              :
                            </div>
                            <div>
                              {IntlFormat(invoiceData.cost) || "-"}
                              {invoiceData.type ? t(`currency.${invoiceData.type}`) : ""}
                            </div>
                          </div>
                        </TableCell>

                        <TableCell>
                          <div className={classes.item}>
                            <div>
                              {t("subject")}
                              :
                            </div>
                            <div>
                              {t(invoiceData.subject) || "-"}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <div className={classes.item}>
                            <div>
                              {t("createDate")}
                              :
                            </div>
                            <div>
                              {dateIsoFa2(invoiceData.created_at) || "-"}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.item}>
                            <div>
                              {t("dueDate")}
                              :
                            </div>
                            <div>
                              {dateIsoFa2(invoiceData.due_date) || "-"}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <div className={classes.item}>
                            <div>
                              {t("status")}
                              :
                            </div>
                            <div>
                              {t(invoiceData.status) || "-"}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <div className={classes.item}>
                            <div>
                              {t("description")}
                              :
                            </div>
                            <div>
                              {invoiceData.description || "-"}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <br />
                  <Grid container spacing={2}>
                    {invoiceData.status === "wait" ? (
                      <Grid
                        item
                        md={12}
                        xs={12}
                      >
                        <Button variant="contained" onClick={() => setOpenModalPayment(true)}>
                          {t("Pay")}
                        </Button>
                      </Grid>
                    ) : "" }
                  </Grid>
                </Card>
              ) : ""}
            </Grid>
            {!isCustomer ? (
              <Grid item md={12} lg={6}>
                <Card>
                  <CardHeader title={t("ClientInformation")} />
                  <CardContent>
                    <div className={classes.list}>
                      <div>
                        <b>{t("userId")}</b>
                        :
                        {evalData?.user?.id || evalData?.userId?.id}
                      </div>
                      <div>
                        <b>{t("name")}</b>
                        :
                        {`${evalData?.name} ${evalData?.family}`}
                      </div>
                      <div>
                        <b>{t("phone")}</b>
                        :
                        {evalData?.user?.phone}
                      </div>
                      <div>
                        <b>{t("email")}</b>
                        :
                        {evalData?.user?.email}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ) : false}
            {payments.length ? (
              <Grid item md={12} lg={12}>
                <Typography variant="h4">
                  {t("paymentsHistory")}
                </Typography>
                <br />
                <Card className={classes.cardItem}>
                  <TableSimple size="small" headers={headerPayments} rows={payments} />
                </Card>
              </Grid>
            ) : "" }
          </Grid>
        )
        : <Loader />}

      <PaymentResultDialog
        invoice={invoiceData}
        open={dialogResultPayment}
        setOpen={setDialogResultPayment}
      />

      {openModalPayment ? (
        <FormDialogConfirmPayment
          mode="installment"
          title="Invoice.payment"
          openDialog={openModalPayment}
          handleSubmit={handlePayment}
          handleClose={() => setOpenModalPayment(false)}
          invoice={invoiceData}
        />
      ) : "" }

    </>
  );
};

export default Invoice;
